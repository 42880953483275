import axios from "axios";
import {
  DELIVERY_ALL,
  DELIVERY_GET,
  DELIVERY_PUT,
  DELIVERY_PUT_STATUS,
  DELIVERY_DEL,
  DELIVERY_POST,
  DELIVERY_GET_BY_STATUS,
  DELIVERY_GET_BY_DRIVER,
  DELIVERY_GET_INVERT_TYPE,
  DELIVERY_GET_BY_PROCESSING_STATUS,
} from "../types";

export const deliveryAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/delivery").then((res) => {
      console.log("Request Server to Get All Deliverys");
      dispatch({ type: DELIVERY_ALL, payload: res.data });
    });
  };
};

export const deliveryGetByStatus = (payload) => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/delivery/by/status/" + payload)
    .then((res) => {
      console.log("Request Server to Get All by status Deliverys");
      dispatch({ type: DELIVERY_GET_BY_STATUS, payload: res.data });
    });
  };
};

export const deliveryGetProcessingStatus = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/delivery/by/processing/status")
    .then((res) => {
      console.log("Request Server to Get All by status Deliverys");
      dispatch({ type: DELIVERY_GET_BY_PROCESSING_STATUS, payload: res.data });
    });
  };
};


export const deliveryGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/delivery/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Deliverys");
        if (res.data) {
          dispatch({ type: DELIVERY_GET, payload: res.data });
        } else {
          dispatch({ type: DELIVERY_GET, payload: null });
        }
      });
  };
};


export const deliveryGetByDriver = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/delivery/driver/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Deliverys by Driver_id");
        if (res.data) {
          dispatch({ type: DELIVERY_GET_BY_DRIVER, payload: res.data });
        } else {
          dispatch({ type: DELIVERY_GET_BY_DRIVER, payload: null });
        }
      });
  };
};

export const deliveryGetInvertType = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/delivery/by/invert/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Deliverys invert type");
        if (res.data) {
          dispatch({ type: DELIVERY_GET_INVERT_TYPE, payload: res.data });
        } else {
          dispatch({ type: DELIVERY_GET_INVERT_TYPE, payload: null });
        }
      });
  };
};

export const deliveryPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/delivery/", payload)
      .then((res) => {
        console.log("Request Server to post an Deliverys");
        dispatch({ type: DELIVERY_POST, payload: null });
      });
  };
};

export const deliveryPut = (id, payload) => {
 
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/delivery/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Deliverys");
        dispatch({ type: DELIVERY_PUT, payload: null });
      });
  };
};

export const deliveryPutStatusSuccessAndCancle = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/delivery/status/end/" + id, payload)
      .then((res) => {
        console.log("Request Server to put status success and cancle an Deliverys");
        dispatch({ type: DELIVERY_PUT_STATUS, payload: null });
      });
  };
};

export const deliveryDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/delivery/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Deliverys");
        dispatch({ type: DELIVERY_DEL, payload: null });
      });
  };
};
