import {
    REPAIR_WHEEL_ALL,
    REPAIR_WHEEL_GET,
    REPAIR_WHEEL_POST,
    REPAIR_WHEEL_PUT,
    REPAIR_WHEEL_DEL
} from "../../actions/types";

const initialState = {
    repair_wheel: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case REPAIR_WHEEL_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_WHEEL_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_WHEEL_POST:
            return action.payload;
        case REPAIR_WHEEL_PUT:
            return action.payload;
        case REPAIR_WHEEL_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}