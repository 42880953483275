import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import RouteLists from './components/RouteLists';

export default function RouteManagement() {

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const route = useSelector((state) => state.route);
    const [route_data, setRouteData] = useState();

    useEffect(() => {
        dispatch(actions.routeAll());
        return () => { }
    }, [])

    useEffect(() => {
        setRouteData(route?.arr);
        setIsLoading(true);
        return () => {}
    }, [route])

    console.log("route", route_data);

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    จัดการเส้นทางการเดินรถ
                </h2>
            </div>
            <div className="flex justify-between py-4">
                <Link to={"/fms/route/create"}>
                    <Button colorScheme="blue">เพิ่ม</Button>
                </Link>
            </div>
            <div className="py-4">
                {_.size(route_data) ? (
                    <div>
                        {" "}
                        <RouteLists route={route_data} />
                    </div>
                ) : (
                    <div className="flex justify-center p-4">
                            <div>ไม่มีข้อมูล</div>
                        </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}