const statusNew = 'ใหม่';
const statusAllow = 'อนุมัติ';
const statusFinish = 'เสร็จสิ้น';
const statusCancle = 'ยกเลิก';

export const REPAIR_STATUS = [
    statusNew,
    statusAllow,
    statusFinish,
    statusCancle,
];

export const WORD_REPAIR_STATUS = {
    statusNew,
    statusAllow,
    statusFinish,
    statusCancle,
};
