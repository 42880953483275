import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import Pagination from 'components/common/Pagination/Pagination';
import * as actions from '../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import RepairVehicleLists from './components/RepairVehicleLists';
import ModalVehicleRepair from '../../../components/Modal/VehicleRepairModal/BodyVehicleRepair';
import ModalRepairInfoRecord from '../../../components/Modal/RepairInfoRecordModal/ModalRepairInfoRecord';
import NotifyModal from '../../../components/Modal/NotifyModal/NotifyModal';
import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import TypeMenu from 'components/CategoriesMenu/TypeMenu';

export default function RepairVehicleManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const repair = useSelector((state) => state.repair);
    const type = useSelector((state) => state.type);
    const company = useSelector((state) => state.company);

    const [repair_data, setRepairData] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [selectType, setSelectType] = useState({
        type_id: '',
        detail: 'ประเภททั้งหมด',
    });
    const [selectedRepairData, setSelectedRepairData] = useState({});
    const [isRepairModalOpen, setIsRepairModalOpen] = useState(false);
    const [isRecordModalOpen, setIsRecordModalOpen] = useState(false);
    const [isModalNotifyOpen, setIsModalNotifyOpen] = useState(false);

    useEffect(() => {
        dispatch(actions.repairAllVehicle({ size: size, page: page })).then(
            () => {
                dispatch(actions.repairTypeAll());
                dispatch(actions.typeAll());
                dispatch(actions.companyAll());
            }
        );

        return () => {};
    }, [page, size]);

    useEffect(() => {
        setRepairData(repair?.rows);
        setIsLoading(true);
        return () => {};
    }, [repair]);

    useEffect(() => {
        dispatch(actions.repairAllVehicle({ size: size, page: page, type: selectType?.type_id }));
        return () => {};
    }, [selectType]);

    const handleOpenRepairModal = () => {
        setIsRepairModalOpen(true);
    };

    const handleCloseRepairModal = () => {
        setIsRepairModalOpen(false);
    };

    const handleOpenRecordModel = () => {
        setIsRecordModalOpen(true);
    };

    const handleCloseRecordModal = () => {
        setSelectedRepairData({});
        setIsRecordModalOpen(false);
    };

    const handleSuccessfulClose = () => {
        setIsRecordModalOpen(false);
        setIsModalNotifyOpen(true);
    };

    const handleCloseNotifyModal = () => {
        setIsModalNotifyOpen(false);
    };

    useEffect(() => {
        dispatch(actions.repairAllVehicleByStatus()).then(() => {
            dispatch(actions.companyAll());
        });

        return () => {};
    }, []);

    const showRepairVehicleLists = () => {
        return (
            <div>
                {_.size(repair_data) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        <RepairVehicleLists
                            repair={repair_data}
                            sub_vehicle={SUB_CATEGORY_REPAIR[0]}
                            company={company}
                            setIsSelectedRepairData={setSelectedRepairData}
                            handleModalOn={handleOpenRepairModal}
                            handleRecordModalOn={handleOpenRecordModel}
                            size={size}
                            page={page}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        );
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                รายการแจ้งซ่อม
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Link to={'/cmms/repair/vehicle/create/admin'}>
                        <Button colorScheme="blue">เพิ่ม</Button>
                    </Link>
                </div>
                <div>
                    <TypeMenu
                        selectType={selectType}
                        setSelectType={setSelectType}
                        type={type?.arr}
                    />
                </div>
            </div>
            <ModalVehicleRepair
                closeHandle={handleCloseRepairModal}
                openStatus={isRepairModalOpen}
                repairDetail={selectedRepairData}
            />
            <ModalRepairInfoRecord
                closeHandle={handleCloseRecordModal}
                openStatus={isRecordModalOpen}
                repair={selectedRepairData}
                successfulCloseHandle={handleSuccessfulClose}
            />

            <div className="p-4">{showRepairVehicleLists()}</div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination
                    currPage={repair?.currPage}
                    lastPage={repair?.lastPage}
                    setPage={setPage}
                />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
