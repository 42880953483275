import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";

import { useForm } from "react-hook-form";
import ImageUploader from "components/ImageUpload/ImageUpload";
import { uploadImageFile } from "util/image.functions";
import CardNote from "components/common/Cards/CardNote";
import { Button } from "@chakra-ui/react";

import _ from "lodash";
import hash from "object-hash";

export default function CustomerNote({ note }) {
  const { register, handleSubmit, reset, errors } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [images, setImages] = useState([]);

  useEffect(() => {
    return () => {};
  }, []);

  const genCustomerNoteList = () =>
    _.map(note, (_note) => {
      return (
        <div key={_note.note_id} className="my-4">
          <CardNote
            className="shadow-md"
            note_id={_note.note_id}
            subtitle={_note.note_detail}
            image={_note.note_image}
            onDelete={onDelete}
            note_date={_note.note_date}
          />
        </div>
      );
    });

  const onSubmit = (data, e) => {
    // เตรียมข้อมูล
    data.note_date = new Date();
    data.customer_id = id;
    const filename = hash({ images: images, date: new Date() });
    if (_.size(images)) {
      uploadImageFile(filename, images[0].data_url).then((url) => {
        console.log("URL : " + url);
        data.note_image = url;
        dispatch(actions.notePost(data)).then(() => {
          dispatch(actions.noteAllByCustomer(id));
          setImages([]);
          reset();
        });
      });
    } else {
      dispatch(actions.notePost(data)).then(() => {
        dispatch(actions.noteAllByCustomer(id));
        setImages([]);
        reset();
      });
    }
  };
  const onDelete = (note_id) => {
    // เตรียมข้อมูล
    dispatch(actions.noteDelete(note_id)).then(() => {
      dispatch(actions.noteAllByCustomer(id));
      alert("ลบบันทึกสำเร็จ");
    });
  };

  return (
    <div className="w-full  px-2">
      <div className="mb-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" shadow-md rounded-lg bg-white border-0 p-4 mb-2 ">
            <div className="relative w-full  mb-3">
              <label
                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                บันทึก
              </label>
              <textarea
                name="note_detail"
                required
                ref={register}
                rows="3"
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow-md focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="บันทึกข้อมูลต่าง ๆ เกี่ยวกับลูกค้า"
              />
            </div>
            <div className="rounded-lg  bg-white p-4  mt-3 mb-6">
              <ImageUploader
                images={images}
                setImages={setImages}
                preview_size={"100"}
              />
            </div>
            <div>
              <Button colorScheme="blue" isFullWidth={true} type="submit">
                บันทึก
              </Button>
            </div>
          </div>
        </form>
      </div>

      <div className="shadow-md rounded-lg bg-white border-0 p-4 my-4">
        <label
          className="block uppercase text-gray-700 text-xs font-bold mb-4 "
          htmlFor="grid-password"
        >
          รายการบันทึก
        </label>
        {_.size(note) ? (
          <div style={{ height:"500px" }} className="overflow-y-auto">
            {genCustomerNoteList()}
          </div>
        ) : (
          <div className="flex  justify-center " style={{ height:"500px"}}>
            <div className="text-xs my-32">ไม่มีรายการบันทึก</div>
          </div>
        )}
      </div>
    </div>
  );
}
