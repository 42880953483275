import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import RepairTypeLists from './components/RepairTypeLists';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import TypeMenu from 'components/CategoriesMenu/TypeMenu';
import Pagination from 'components/common/Pagination/Pagination';

export default function RepairTypeManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const repair_type = useSelector((state) => state.repair_type);
    const type = useSelector((state) => state.type);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [repair_type_data, setRepairType] = useState([]);
    const [selectType, setSelectType] = useState({
        type_id: '',
        detail: 'ประเภททั้งหมด',
    });

    useEffect(() => {
        dispatch(actions.repairTypeAll({ size: size, page: page })).then(() => {
            dispatch(actions.typeAll());
            setIsLoading(true);
        });
        return () => {};
    }, [page, size]);

    useEffect(() => {
        setRepairType(repair_type?.rows);
        return () => {};
    }, [repair_type]);

    useEffect(() => {
        dispatch(
            actions.repairTypeAll({
                size: size,
                page: page,
                type: selectType?.type_id,
            })
        );
        return () => {};
    }, [selectType]);

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                จัดการรายละเอียดการซ่อม
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div className="">
                    <Link to={'/cmms/type/create'}>
                        <Button colorScheme="blue">เพิ่ม</Button>
                    </Link>
                </div>
                <div>
                    <TypeMenu
                        selectType={selectType}
                        setSelectType={setSelectType}
                        type={type?.arr}
                    />
                </div>
            </div>

            <div className="p-4">
                {_.size(repair_type_data) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        {' '}
                        <RepairTypeLists
                            repair_type={repair_type_data}
                            size={size}
                            page={page}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination
                    currPage={repair_type?.currPage}
                    lastPage={repair_type?.lastPage}
                    setPage={setPage}
                />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
