import axios from "axios";
import {
  TRANSPORT_ASSIGNMENT_ALL,
  TRANSPORT_ASSIGNMENT_GET,
  TRANSPORT_ASSIGNMENT_PUT,
  TRANSPORT_ASSIGNMENT_DEL,
  TRANSPORT_ASSIGNMENT_POST,
  TRANSPORT_ASSIGNMENT_GET_BY_DRIVER,
  TRANSPORT_ASSIGNMENT_GET_BY_STATUS,
  TRANSPORT_ASSIGNMENT_GET_BY_PROCESSING_STATUS,
  TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE,
} from "../types";

export const transportAssignmentAll = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/transport_assignment")
      .then((res) => {
        console.log("Request Server to Get All TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_ALL, payload: res.data });
      });
  };
};

export const transportAssignmentGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/transport_assignment/" + payload)
      .then((res) => {
        console.log("Request Server to Get an TransportAssignments");
        if (res.data) {
          dispatch({ type: TRANSPORT_ASSIGNMENT_GET, payload: res.data });
        } else {
          dispatch({ type: TRANSPORT_ASSIGNMENT_GET, payload: null });
        }
      });
  };
};

export const transportAssignmentGetByStatus = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/transport_assignment/by/status/"+ payload)
      .then((res) => {
        console.log("Request Server to Get All by status TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_GET_BY_STATUS, payload: res.data });
      });
  };
};

export const transportAssignmentGetProcessingStatus = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/transport_assignment/by/processing/status")
      .then((res) => {
        console.log("Request Server to Get All by processing status TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_GET_BY_PROCESSING_STATUS, payload: res.data });
      });
  };
};

export const transportAssignmentGetByDriver = (payload) => {
  return async (dispatch) => {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/transport_assignment/driver/" +
          payload
      )
      .then((res) => {
        console.log(
          "Request Server to Get an TransportAssignments by Driver_id"
        );
        if (res.data) {
          dispatch({
            type: TRANSPORT_ASSIGNMENT_GET_BY_DRIVER,
            payload: res.data,
          });
        } else {
          dispatch({ type: TRANSPORT_ASSIGNMENT_GET_BY_DRIVER, payload: null });
        }
      });
  };
};

export const transportAssignmentGetByType = (payload) => {
  return async (dispatch) => {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          "/transport_assignment/by/assignment_type/" +
          payload.assignment_type +
          "/" +
          payload.pickup_date +"/"+ payload.assignment_status
      )
      .then((res) => {
        console.log(
          "Request Server to Get an TransportAssignments by Assignment Type"
        );
        if (res.data) {
          dispatch({
            type: TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE,
            payload: res.data,
          });
        } else {
          dispatch({
            type: TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE,
            payload: null,
          });
        }
      });
  };
};

export const transportAssignmentPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/transport_assignment/", payload)
      .then((res) => {
        console.log("Request Server to post an TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_POST, payload: null });
      });
  };
};

export const transportAssignmentPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(
        process.env.REACT_APP_API_URL + "/transport_assignment/" + id,
        payload
      )
      .then((res) => {
        console.log("Request Server to put an TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_PUT, payload: null });
      });
  };
};
export const transportAssignmentDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(
        process.env.REACT_APP_API_URL + "/transport_assignment/" + payload
      )
      .then((res) => {
        console.log("Request Server to Delete an TransportAssignments");
        dispatch({ type: TRANSPORT_ASSIGNMENT_DEL, payload: null });
      });
  };
};
