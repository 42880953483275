import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import { TYPE_REPAIR } from 'config/constant/TypeRepair';

export default function CreateTypeMaster() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        return () => {};
    }, []);

    const onSubmit = (data) => {
        dispatch(actions.typePost(data)).then(() => {
            reset();
            alert('สำเร็จ');
        });
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                เพิ่มประเภทการซ่อม
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex ml-4 mt-4 justify-between">
                    <div>
                        <Link to={'/cmms/master/type'}>
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <div className="mt-2">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ประเภทการซ่อม{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="detail"
                                        type="text"
                                        required
                                        ref={register}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="รายละเอียด"
                                    />
                                </div>
                                <div className="text-center mt-6">
                                    <Button
                                        isFullWidth
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
