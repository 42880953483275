import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link } from "react-router-dom";

// components
import EmployeeLists from "./components/EmployeeLists";
import { Button } from "@chakra-ui/react";
import EmployeeSearch from "components/Search/EmployeeSearch";
import CategoriesMenu from "../../../components/CategoriesMenu/EmployeeCategoriesMenu";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";

export default function ManagementEmployee() {
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const [employee_data, setEmployee] = useState(employee.arr);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.employeeAll()).then(() => {
      dispatch(actions.departmentAll()).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);

  useEffect(() => {
    setEmployee(employee?.arr);
    return () => {};
  }, [employee]);

  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        จัดการพนักงานทั้งหมด
      </h2>

      <div className="flex justify-between py-6">
        <div className="flex flex-wrap">
          <div className="pr-2">
            <Link to={"/hrms/employee/create"}>
              <Button colorScheme="blue" variant="solid">
                เพิ่ม
              </Button>
            </Link>
          </div>

          <div>
            <CategoriesMenu
              department={department}
              data={employee.arr} //ข้อมูลทั้งหมด
              setData={setEmployee} // เซ็ต temp data ให้เหลือเท่าที่กรอง
            />
          </div>
        </div>

        <EmployeeSearch
          data={employee.arr} //ข้อมูลทั้งหมด
          setData={setEmployee} // เซ็ต temp data ให้เหลือเท่าที่กรอง
        />
      </div>
      <div className="py-4 w-full">
        {_.size(employee_data) ? (
          <div>
            {" "}
            <EmployeeLists employee={employee_data} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
