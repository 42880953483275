import React, { useEffect, useState } from "react";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import * as actions from "../../../redux/actions/index";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box, Button, HStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import CardInfo from "components/common/Cards/CardInfo";

export default function DetailRepairType() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const repair_type = useSelector((state) => state.repair_type);

    useEffect(() => {
        dispatch(actions.repairTypeGet(id)).then(() => {
            setIsLoading(true);
        })
        return () => {};
    }, []);

    const onDelete = () => {
        dispatch(actions.repairTypeDelete(id)).then(() =>{
            history.push("/cmms/type");
        })
    }

    console.log("repair_type", repair_type);
    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    {" "}
                    รายละเอียดประเภทการซ่อม
                </h2>
            </div>
            <div className="flex py-4 justify-between">
                <div>
                    <Link to="/cmms/type">
                        <Button variant="outline">กลับ</Button>
                    </Link>
                </div>
                <div>
                    <HStack spacing={2}>
                        <Box>
                            <Link to={"/cmms/type/edit/" + id}>
                                {" "}
                                <Button colorScheme="yellow">แก้ไข</Button>
                            </Link>
                        </Box>

                        <Button
                            colorScheme="red"
                        onClick={() => {
                            const confirm = window.confirm("ยืนยันการลบ");
                            if (confirm) {
                                onDelete();
                            }
                        }}
                        >
                            ลบ
                        </Button>
                    </HStack>
                </div>
            </div>
            <div className="p-4">
                <div className="w-full lg:w-6/12">
                    <CardInfo
                        title={repair_type.repair_detail}
                        subtitle={" "}
                        icon="fa fa-list-alt"
                    />
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}