import axios from "axios";
import {
  EMPLOYEE_ALL,
  EMPLOYEE_ALL_BY_DELIVERY,
  EMPLOYEE_ALL_BY_STATUS,
  EMPLOYEE_ALL_BY_DEPARTMENT,
  EMPLOYEE_GET,
  EMPLOYEE_PUT,
  EMPLOYEE_DEL,
} from "../types";

export const employeeAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/employee").then((res) => {
      console.log("Request Server to Get All Employees");
      dispatch({ type: EMPLOYEE_ALL, payload: res.data });
    });
  };
};

export const employeeAllByDelivery = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/employee/by/delivery", payload)
      .then((res) => {
        console.log("Request Server to Get  All By Delivery Employees");
        dispatch({ type: EMPLOYEE_ALL_BY_DELIVERY, payload: res.data });
      });
  };
};

export const employeeGetByStatus = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/employee/by/status/" + payload)
      .then((res) => {
        console.log("Request Server to Get  All By Status Employees");
        dispatch({ type: EMPLOYEE_ALL_BY_STATUS, payload: res.data });
      });
  };
};

export const employeeGetByDepartment = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/employee/by/department/" + payload)
      .then((res) => {
        console.log("Request Server to Get  All By Department Employees");
        dispatch({ type: EMPLOYEE_ALL_BY_DEPARTMENT, payload: res.data });
      });
  };
}

export const employeeGet = (payload) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/employee/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Employees");
        if (res.data) {
          dispatch({ type: EMPLOYEE_GET, payload: res.data });
        } else {
          dispatch({ type: EMPLOYEE_GET, payload: null });
        }
      });
  };
};

export const employeePut = (payload) => {
  return async (dispatch) => {
    await axios
      .put(
        process.env.REACT_APP_API_URL + "/employee/" + payload.employee_id,
        payload
      )
      .then((res) => {
        console.log("Request Server to put an Employees");
        if (res.data) {
          dispatch({ type: EMPLOYEE_PUT, payload: null });
        }
      });
  };
};

export const employeeDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/employee/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Employees");
        if (res.data) {
          dispatch({ type: EMPLOYEE_DEL, payload: null });
          dispatch(employeeAll());
        }
      });
  };
};
