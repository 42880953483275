import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as actions from '../../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';

export default function RepairRecordVehicle() {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm();
    const { id } = useParams();
    const history = useHistory();
    const repair = useSelector((state) => state.repair);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.repairGetVehicle(id));
        return () => {};
    }, []);

    useEffect(() => {
        setIsLoading(true);
        return () => {};
    }, [repair]);

    const onSubmit = (data) => {
        data.repair_status = REPAIR_STATUS[2];
        console.log('data', data);
        dispatch(actions.repairPut(id, data)).then(() => {
            repair.repair_status = REPAIR_STATUS[2];
            repair.cause = data.cause;
            repair.repair_modify = data.repair_modify;
            dispatch(actions.repairLogPost(repair)).then(() => {
                alert('สำเร็จ');
                history.push("/cmms/repair/vehicle")
            })
        });
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                บันทึกการซ่อมรถ
            </h2>
            <div className="py-4">
                <Link to={'/cmms/repair/vehicle'}>
                    <Button variant="outline">กลับ</Button>
                </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    สาเหตุการแจ้งซ่อม
                                </label>
                                <textarea
                                    name="cause"
                                    type="textfield"
                                    rows={3}
                                    defaultValue={repair.cause}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="สาเหตุ"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    วิธีแก้ไข
                                </label>
                                <textarea
                                    name="repair_modify"
                                    type="textfield"
                                    rows={3}
                                    defaultValue={repair.repair_modify}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="วิธีแก้ไข"
                                />
                            </div>
                            <div className="text-center mt-6">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
