import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { MonthDeliveryReport } from './report/MonthDeliveryReport';

export default function DeliveryHistoryLists({ month, company }) {

    const genTd = () => {
        return _.map(month, (data, index) => (
            <Tr key={data.month_id}>
                <Td>{index + 1}</Td>

                <Td>
                    <Link to={`/tms/history/delivery/${data.month_id}`}>
                        <div>
                            <b>{moment(data.month).format("MM/YYYY")}</b>
                        </div>
                        <div>
                            <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center">
                                รายละเอียด
                            </span>
                        </div>

                    </Link>
                </Td>
                <Td>
                    <div className="flex justify-center">
                        <Button
                            size="xs"
                            onClick={() => {
                                MonthDeliveryReport(month[0], company)
                            }}
                        >
                            <i
                                className="fas fa-print cursor-pointer mr-1"

                            ></i>{" "}พิมพ์
                        </Button>
                    </div>
                </Td>
                <Td><div className="text-center">{_.size(data?.delivery_logs) + " รายการ"}</div></Td>
            </Tr>
        ))
    }

    return (
        <div className="flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>เดือน</Th>
                        <Th><div className="text-center">ใบจัดส่งสินค้า</div></Th>
                        <Th><div className="text-center">รายการจัดส่งสินค้าทั้งหมด</div></Th>
                    </Tr>
                </Thead>
                <Tbody >{genTd()}</Tbody>
            </Table>
        </div>
    )
}