import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import { SUB_CATEGORY_REPAIR } from 'config/constant/SubCategoryRepair';
import { HeadWheelPrintPDF } from '../../report/HeadWheelPrintPDF';
import { TailWheelPrintPDF } from '../../report/TailWheelPrintPDF';
import { HeadAndTailWheelPrintPDF } from '../../report/HeadAndTailWheelPrintPDF';

export default function RepairMonthWheelLists({ repair_log, company, id }) {
    const showStatus = (status) => {
        if (status === REPAIR_STATUS[2]) {
            return (
                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        } else {
            return (
                <div className="text-xs text-center px-2 bg-red-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        }
    };

    const genRepairWheel = (data) => {
        return _.map(data, (_data) => (
            <div key={_data.repair_wheel_log_id} className="pt-2">
                {'๐ ' + _data?.wheel_detail}
            </div>
        ));
    };

    const genPrice = (data) => {
        let sum = 0;
        for (let i = 0; i < _.size(data); i++) {
            sum = parseFloat(sum) + parseFloat(data[i].price);
        }
        return sum;
    };

    const genTd = () => {
        return _.map(repair_log, (log, index) => (
            <Tr key={log.repair_log_id}>
                <Td>{index + 1}</Td>
                <Td>{moment(log?.start_date).format('L')}</Td>
                <Td>{moment(log?.end_date).format('L')}</Td>
                <Td>
                    {log?.mileage == null ? (
                        <div className=""> - </div>
                    ) : (
                        <div>{log?.mileage?.mileage}</div>
                    )}
                </Td>
                <Td>
                    {log?.vehicle == null ? (
                        <div className=""> - </div>
                    ) : (
                        <div className="">{log?.vehicle?.serial_number}</div>
                    )}
                </Td>

                <Td>{genRepairWheel(log?.repair_wheel_logs)}</Td>
                <Td>{genPrice(log?.repair_wheel_logs)}</Td>
                <Td>
                    {log?.cause == null ? (
                        <div className=""> - </div>
                    ) : (
                        <div className="">{log?.cause}</div>
                    )}
                </Td>
                <Td>
                    {log?.repair_modify == null ? (
                        <div className=""> - </div>
                    ) : (
                        <div className="">{log?.repair_modify}</div>
                    )}
                </Td>
                <Td>
                    <div className="">
                        <Button
                            size="xs"
                            onClick={() => {
                                if (
                                    log?.sub_vehicle === SUB_CATEGORY_REPAIR[0]
                                ) {
                                    HeadWheelPrintPDF(log, company);
                                } else if (
                                    log?.sub_vehicle === SUB_CATEGORY_REPAIR[1]
                                ) {
                                    TailWheelPrintPDF(log, company);
                                } else if (
                                    log?.sub_vehicle === SUB_CATEGORY_REPAIR[2]
                                ) {
                                    HeadAndTailWheelPrintPDF(log, company);
                                }
                            }}
                        >
                            <i className="fas fa-print cursor-pointer mr-1"></i>{' '}
                            พิมพ์
                        </Button>
                    </div>
                    <div>
                        <Link
                            to={`/cmms/history/repair/wheel/${id}/detail/${log.repair_log_id}`}
                        >
                            <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center">
                                รายละเอียด
                            </span>
                        </Link>
                    </div>
                </Td>
                <Td>{showStatus(log?.repair_status)}</Td>
            </Tr>
        ));
    };

    console.log('repair_log', repair_log);
    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>วันที่แจ้งซ่อม</Th>
                        <Th>วันที่ซ่อมสำเร็จ</Th>
                        <Th>เลขไมล์</Th>
                        <Th>ทะเบียนรถ</Th>
                        <Th>รายละเอียดการซ่อม</Th>
                        <Th>ราคารวม</Th>
                        <Th>สาเหตุ</Th>
                        <Th>วิธีแก้ไข</Th>
                        <Th>ใบแจ้งซ่อมยาง</Th>
                        <Th>สถานะ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
