import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/";
import { useForm } from "react-hook-form";
import { Button, Checkbox } from "@chakra-ui/react";
import _ from "lodash";
import axios from "axios";
import SpinnerLoading from "components/Loading/SpinnerLoading";

export default function EditDepartment() {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const [module_lists, setModuleLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data, e) => {
    console.log(data);
    data.department_id = department.department_id;
    dispatch(actions.departmentPut(data)).then(() => {
      console.log("แก้ไขแผนกสำเร็จ");
      history.push("/hrms/department/details/" + department.department_id);
    });
  };
  useEffect(() => {
    findAllModuleLists().then(() => {
      dispatch(actions.departmentGet(id)).then(() => {
        setIsLoading(true);
      });
    });
    return () => {};
  }, []);

  const findAllModuleLists = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/module_lists/")
      .then((res) => {
        console.log("Request Server to Get all module_lists");
        setModuleLists(res.data);
      });
  };

  const genModuleListsCheckBox = () =>
    _.map(module_lists, (module) => {
      const temp = _.find(department.department_roles, (role) => {
        return parseInt(role.module_id) === module.module_id;
      });
      if (temp != null) {
        return (
          <div key={module.module_id}>
            <Checkbox
              name="module_lists"
              value={module.module_id}
              ref={register}
              defaultIsChecked={true}
            >
              {module.module_name_th}
            </Checkbox>
          </div>
        );
      } else {
        return (
          <div key={module.module_id}>
            <Checkbox
              name="module_lists"
              value={module.module_id}
              ref={register}
            >
              {module.module_name_th}
            </Checkbox>
          </div>
        );
      }
    });
//   console.log(module_lists);
  return isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">แก้ไขแผนก</h2>
      <div className="py-6">
        <Link to={"/hrms/department/details/" + department.department_id}>
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อแผนก <label className="text-red-600 text-xs"> *</label>
                </label>
                <input
                  name="department_name"
                  type="text"
                  required
                  ref={register}
                  defaultValue={department.department_name}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อแผนก"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายละเอียด <label className="text-red-600 text-xs"> *</label>
                </label>
                <textarea
                  name="department_detail"
                  type="textfield"
                  required
                  ref={register}
                  defaultValue={department.department_detail}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="รายละเอียด"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  จัดการโมดูล
                </label>
                {genModuleListsCheckBox()}
              </div>

              <div className="text-center mt-6">
                <Button isFullWidth colorScheme="blue" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
