import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import event from '../../../../util/mock/event';
import { DeliveryToEvent } from '../../../../util/DeliveryToEvent';
import { WORD_TRANSPORT_SERVICE } from '../../../../config/constant/TransportService';
import { WORD_DELIVERY_STATUS } from '../../../../config/constant/DeliveryStatus';
import './styles.css';
const localizer = momentLocalizer(moment);

export default function DeliveryWeekLists({
    delivery,
    defaultDate,
    handleDeliveryModal,
}) {
    const [events, setEvents] = useState(event);
    const [isLoading, setIsLoading] = useState(true);
    // console.log(defaultDate);
    useEffect(() => {
        setEvents(DeliveryToEvent(delivery));
        return () => {};
    }, [delivery]);

    useEffect(() => {
        setIsLoading((prev) => setIsLoading(!prev));
        return () => {};
    }, [defaultDate]);

    const eventStyleGetter = (event, start, end, isSelected) => {
        if (event.type === WORD_TRANSPORT_SERVICE.intExport)
            var backgroundColor = '#F59E0B';
        if (event.type === WORD_TRANSPORT_SERVICE.domestic)
            var backgroundColor = '#F87171';
        if (event.status === WORD_DELIVERY_STATUS.cancle)
            var backgroundColor = '#FF0000';
        if (event.status === WORD_DELIVERY_STATUS.success)
            var backgroundColor = '#32CD32';
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '4px',
            opacity: 0.8,
            color: 'white',
            border: '4px',
            display: 'flex',
            flexWrap: 'wrap',
            margin: '4px 0px 4px 0px',
            padding: '10px 10px 10px 10px',
        };
        return {
            style: style,
        };
    };

    const handleSelectedCellEvent = (event) => {
        handleDeliveryModal(event.id);
    };

    return (
        <div>
            {isLoading && (
                <div>
                    <div className="flex justify-end mb-2">
                        <Link to="/tms/dashboard/delivery/calendar">
                            <Button variant="outline" size="sm">
                                รายการทั้งหมด
                            </Button>
                        </Link>
                    </div>
                    <Calendar
                        defaultDate={defaultDate}
                        step={500}
                        timeslots={8}
                        localizer={localizer}
                        defaultView={Views.WEEK}
                        events={events}
                        toolbar={false}
                        eventPropGetter={eventStyleGetter}
                        onSelectEvent={(event) =>
                            handleSelectedCellEvent(event)
                        }
                        popup={true}
                        dayLayoutAlgorithm="no-overlap"
                        resizable
                    />
                </div>
            )}
        </div>
    );
}
