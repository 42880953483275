import {
  PRODUCT_ALL,
  PRODUCT_GET,
  PRODUCT_PUT,
  PRODUCT_DEL,
  PRODUCT_POST,
} from "../../actions/types";

const initialState = {
  product: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case PRODUCT_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case PRODUCT_POST:
      return action.payload;
    case PRODUCT_PUT:
      return action.payload;
    case PRODUCT_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
