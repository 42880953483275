import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, Input, Textarea } from '@chakra-ui/react';
import _ from 'lodash';

import * as actions from '../../../../redux/actions';

const Detail = ({ repairDetail, register }) => {
    const dispatch = useDispatch();
    const [vehicleByType, setVehicleByType] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const vehicle = useSelector((state) => state.vehicle);

    useEffect(() => {
        dispatch(actions.vehicleAll()).then(() => {
            dispatch(actions.sub_category_vehicleAll()).then(() => {
                setIsLoading(true);
            });
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (vehicle && vehicle.arr) {
            const tempVehicleByType = _.groupBy(vehicle.arr, 'category_id');
            setVehicleByType(tempVehicleByType);
        }
        return () => {};
    }, [vehicle]);

    if (isLoading)
        return (
            <div className="flex flex-wrap justify-between h-full">
                <div className="w-full px-4  ">
                    <div className="flex flex-wrap">
                        <div className="relative w-3/12 mb-3 mr-2">
                            <FormControl>
                                <label className="block uppercase text-gray-700 text-sm font-bold ">
                                    ชื่อผู้แจ้ง
                                </label>
                                <Input
                                    type="text"
                                    size="sm"
                                    disabled
                                    ref={register}
                                    value={
                                        repairDetail?.employee
                                            ?.employee_first_name +
                                        ' ' +
                                        repairDetail?.employee
                                            ?.employee_last_name
                                    }
                                />
                            </FormControl>
                        </div>
                        {repairDetail?.vehicle?.sub_category_vehicle
                            .sub_category_name && (
                            <div className="relative w-3/12 mb-3 mr-2">
                                <label className="block uppercase text-gray-700 text-sm font-bold ">
                                    ประเภท
                                </label>
                                <Input
                                    type="text"
                                    size="sm"
                                    disabled
                                    ref={register}
                                    value={
                                        repairDetail?.vehicle
                                            ?.sub_category_vehicle
                                            .sub_category_name
                                    }
                                />
                            </div>
                        )}
                        {repairDetail?.vehicle?.serial_number && (
                            <div className="relative w-3/12 mb-3 mr-2">
                                <label className="block uppercase text-gray-700 text-sm font-bold ">
                                    ทะเบียนรถ
                                </label>
                                <Input
                                    type="text"
                                    size="sm"
                                    disabled
                                    ref={register}
                                    value={
                                        repairDetail?.vehicle?.serial_number ||
                                        '-'
                                    }
                                />
                            </div>
                        )}
                        {/* {repairDetail?.vehicle_tail?.serial_number && (
                            <div className="relative w-3/12 mb-3 mr-2">
                                <label className="block uppercase text-gray-700 text-sm font-bold ">
                                    ทะเบียนหาง
                                </label>
                                <Input
                                    type="text"
                                    size="sm"
                                    disabled
                                    ref={register}
                                    value={
                                        repairDetail?.vehicle_tail
                                            ?.serial_number || '-'
                                    }
                                />
                            </div>
                        )} */}
                        {repairDetail?.mileage?.mileage && (
                            <div className="relative w-3/12 mb-3 mr-2">
                                <FormControl>
                                    <label className="block uppercase text-gray-700 text-sm font-bold ">
                                        เลขไมล์
                                    </label>
                                    <Input
                                        type="number"
                                        name="mileage.mileage"
                                        ref={register}
                                        size="sm"
                                        defaultValue={
                                            repairDetail?.mileage?.mileage
                                        }
                                    />
                                </FormControl>
                            </div>
                        )}
                    </div>

                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            รายละเอียด
                        </label>
                        <Textarea
                            type="textarea"
                            name="repair_detail"
                            size="sm"
                            ref={register}
                            rows="2"
                            defaultValue={repairDetail?.repair_detail}
                        />
                    </div>
                </div>
            </div>
        );
    else {
        return <></>;
    }
};

export default React.forwardRef(Detail);
