import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SUB_CATEGORY_REPAIR } from "../../../config/constant/SubCategoryRepair";
import { Button, Textarea, Input } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalFooter
} from '@chakra-ui/react';

export default function CardDetailWheel({ repair_wheel }) {

    const [editIndex, setEditIndex] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [editText, setEditText] = useState();
    const [editPrice, setEditPrice] = useState();

    const handleOnEdit = (index) => {
        setEditIndex(index)
        setEditText(repair_wheel[index]?.wheel_detail);
        setEditPrice(repair_wheel[index]?.price);
        setIsOpenModal(true)
    }
    const handleOnClose = () => {
        setIsOpenModal(!isOpenModal)
    }
    const showDetail = (data) => {

        return data.map((wheel, index) => {

            if (wheel?.index_wheel != null) {
                // if (index >= 1 && index < 11 && wheel?.index_wheel != null) {
                return (<div className="m-2 p-4 rounded-md hover:shadow-lg border-2 w-3/12">
                    <div className="flex justify-between">
                        <p><b>ล้อที่</b> {wheel?.index_wheel} </p>
                        <Button
                            size="xs"
                            onClick={() => handleOnEdit(index)}
                        >
                            <EditIcon />
                        </Button>
                    </div>
                    <p>{wheel?.wheel_detail}</p>
                    <div className="flex justify-end">
                        <p >ราคา {wheel?.price ? (wheel?.price) : (0)} บาท</p>
                    </div>
                </div>)
            }
        })
    }

    const changeText = (index) => {

        const data = {
            index_wheel: repair_wheel[editIndex]?.index_wheel,
            wheel_detail: " ",
            price: " "
        }
        if (editText != undefined) {
            data.wheel_detail = editText;
            setEditText();
        } else {
            data.wheel_detail = " ";
            setEditText();
        }
        if (editPrice != undefined) {
            data.price = editPrice;
            setEditPrice();
        } else {
            data.price = 0;
            setEditPrice();
        }

        repair_wheel[editIndex] = data;
        setIsOpenModal(false);

    }

    const editDetailModal = () => {

        return (<Modal
            isOpen={isOpenModal}
            onClose={() => handleOnClose()}
            isCentered
        >
            <ModalOverlay />
            <ModalContent className="font-sans">

                <ModalHeader>
                    แก้ไขล้อที่ {repair_wheel[editIndex]?.index_wheel}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <label className="block uppercase text-gray-700 text-sm mb-2">
                        รายละเอียด
                    </label>
                    <Textarea
                        placeholder="รายละเอียด"
                        defaultValue={repair_wheel[editIndex]?.wheel_detail}
                        onChange={(e) => { setEditText(e.target.value) }}

                    />
                    <label className="block uppercase text-gray-700 text-sm mb-2">
                        ราคา
                    </label>
                    <Input
                        placeholder="ราคา"
                        defaultValue={repair_wheel[editIndex]?.price}
                        onChange={(e) => { setEditPrice(e.target.value) }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        type="submit"
                        onClick={() => { changeText(editIndex) }}
                    >
                        บันทึก
                    </Button>
                    <Button
                        colorScheme="gray"
                        type="button"
                        onClick={() => handleOnClose()}

                    >
                        ยกเลิก
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>)
    }

    return (
        <>
            <div className="flex flex-wrap">
                {showDetail(repair_wheel)}
                {editDetailModal()}
            </div>
        </>
    );
}

CardDetailWheel.defaultProps = {
    repair_wheel: 'repair_wheel',
};

CardDetailWheel.propTypes = {
    repair_wheel: PropTypes.object,
};
