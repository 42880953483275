const intExport = 'ส่งออก';
const intImport = 'นำเข้า';
const domestic = 'ภายใน';

export const TRANSPORT_SERVICE = [intImport, intExport, domestic];

export const WORD_TRANSPORT_SERVICE = {
    intImport,
    intExport,
    domestic,
};
