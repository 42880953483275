import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/index";
import { useForm } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import { PERMISSION_LEVEL } from "../../../config/constant/PermissionLevel"
import _ from "lodash";

export default function CreatePosition() {
  const { register, handleSubmit, errors } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const department = useSelector((state) => state.department);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.departmentAll());
    return () => { };
  }, []);
  const genDepartmentOption = () =>
    _.map(department, (_department) => {
      if (_department.department_id == id) {
        return (
          <option
            key={_department.department_id}
            selected
            value={_department.department_id}
          >
            {_department.department_name}
          </option>
        );
      } else {
        return (
          <option
            key={_department.department_id}
            value={_department.department_id}
          >
            {_department.department_name}
          </option>
        );
      }
    });
  const genPermissionLavel = () => {
    return _.map(PERMISSION_LEVEL, (level, index) => (
      <option key={index} value={level}>
        {level}{" "}
      </option>
    ))
  }
  const onSubmit = (data, e) => {
    // console.log(data);
    dispatch(actions.positionPost(data)).then(() => {
      console.log("เพิ่มตำแหน่งสำเร็จ");
      history.push("/hrms/department/details/" + id);
    });
  };
  return (
    <div >
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        เพิ่มตำแหน่ง
      </h2>
      <div className="flex justify-between py-6">
        <Link to={"/hrms/department/details/" + id}>
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อตำแหน่ง <label className="text-red-600 text-xs"> *</label>
                </label>
                <input
                  name="position_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อตำแหน่ง"
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  แผนก <label className="text-red-600 text-xs"> *</label>
                </label>
                <select
                  name="department_id"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                >
                  {genDepartmentOption()}
                </select>
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายละเอียด <label className="text-red-600 text-xs"> *</label>
                </label>
                <textarea
                  name="position_salary"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="รายละเอียด"
                />

              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  จัดการสิทธิการเข้าถึง <label className="text-red-600 text-xs"> *</label>
                </label>
                <select
                  name="permission_level"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                >
                  {genPermissionLavel()}
                </select>
              </div>
              <div className="text-center mt-6">
                <Button isFullWidth colorScheme="blue" type="submit">
                  เพิ่ม
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
