import axios from "axios";
import {
    PROBLEM_ALL,
    PROBLEM_GET,
    PROBLEM_POST,
    PROBLEM_PUT,
    PROBLEM_DEL,
} from "../types";

export const problemAll = () => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + "/problem").then((res) => {
            console.log("Request Server to Get All Problem");
            dispatch({ type: PROBLEM_ALL, payload: res.data });
        });
    };
}

export const problemGet = (payload) => {
    return async (dispatch) => {
        await axios
        .get(process.env.REACT_APP_API_URL + "/problem/" + payload)
        .then((res) => {
            console.log("Request Server to Get an Problem");
            if (res.data) {
                dispatch({ type: PROBLEM_GET, payload: res.data })
            } else {
                dispatch({ type: PROBLEM_GET, payload: null });
            }
        });
    };
};

export const problemPost = (payload) => {
    return async (dispatch) => {
        await axios
        .post(process.env.REACT_APP_API_URL + "/problem", payload)
        .then((res) => {
            console.log("Request Server to post an Problem");
            dispatch({ type: PROBLEM_POST, payload: null });
        });
    };
};

export const problemPut = (id, payload) => {
    console.log("id", id);
    console.log("payload", payload);
    return async (dispatch) => {
        await axios
        .put(process.env.REACT_APP_API_URL + "/problem/" + id, payload)
        .then((res) => {
            console.log("Request Server to put an Problem");
            dispatch({ type: PROBLEM_PUT, payload: null });
        });
    };
};

export const problemDelete = (payload) => {
    return async (dispatch) => {
        await axios
        .delete(process.env.REACT_APP_API_URL + "/problem/" + payload)
        .then((res) => {
            console.log("Request Server to Delete an Problem");
            dispatch({ type: PROBLEM_DEL, payload: null });
        });
    };
};