import axios from 'axios';
import {
    //ดึงข้อมูลการแจ้งซ่อมทั้งหมดตามเดือน
    REPAIR_MONTH_ALL_VEHICLE,
    REPAIR_MONTH_GET_VEHICLE,
    REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL,
    REPAIR_MONTH_ALL_WHEEL,
    REPAIR_MONTH_GET_WHEEL,
    REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL,
} from '../types';

const defaultSize = 5;
const defaultPage = 1;

// VEHICLE //
export const repairMonthAllVehicle = ({
    size = defaultSize,
    page = defaultPage,
}) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    `/repair_month/vehicle?size=${size || defaultSize}&page=${
                        page || defaultPage
                    }`
            )
            .then((res) => {
                console.log('RES', res.data);
                console.log('Request Server to Get All repair month vehicle');
                dispatch({ type: REPAIR_MONTH_ALL_VEHICLE, payload: res.data });
            });
    };
};

export const repairMonthGetVehicle = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_month/vehicle/' + id)
            .then((res) => {
                console.log('Request Server to Get repair month vehicle');
                dispatch({ type: REPAIR_MONTH_GET_VEHICLE, payload: res.data });
            });
    };
};

export const repairMonthGetVehicleByHeadAndTail = (
    id,
    vehicleId,
    driverId,
    type
) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/repair_month/vehicle/' +
                    id +
                    '/head/' +
                    vehicleId +
                    '/and/tail/' +
                    driverId +
                    '/type/' +
                    type
            )
            .then((res) => {
                console.log('Request Server to Get repair month vehicle');
                dispatch({
                    type: REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL,
                    payload: res.data,
                });
            });
    };
};

// WHEEL //
export const repairMonthAllWheel = ({
    size = defaultSize,
    page = defaultPage,
}) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    `/repair_month/wheel?size=${size || defaultSize}&page=${
                        page || defaultPage
                    }`
            )
            .then((res) => {
                console.log('Request Server to Get All repair month wheel');
                dispatch({ type: REPAIR_MONTH_ALL_WHEEL, payload: res.data });
            });
    };
};

export const repairMonthGetWheel = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_month/wheel/' + id)
            .then((res) => {
                console.log('Request Server to Get repair month wheel');
                dispatch({ type: REPAIR_MONTH_GET_WHEEL, payload: res.data });
            });
    };
};

export const repairMonthGetWheelByHeadAndTail = (id, headId, tailId) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/repair_month/wheel/' +
                    id +
                    '/head/' +
                    headId +
                    '/and/tail/' +
                    tailId
            )
            .then((res) => {
                console.log('Request Server to Get repair month wheel');
                dispatch({
                    type: REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL,
                    payload: res.data,
                });
            });
    };
};
