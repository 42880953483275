import React from 'react';
import { auth, signInAuthProvider } from './firebase-client';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import axios from 'axios';
import { setStorage, removeStorage } from 'util/localstorage';

export const authMethods = {
    // firebase helper methods go here...
    signup: async (email, password, data, setErrors, setToken) => {
        await auth
            .createUserWithEmailAndPassword(email, password)
            //make res asynchronous so that we can make grab the token before saving it.
            .then(async (res) => {
                const token = await Object.entries(res.user)[5][1].b;
                await axios
                    .post(process.env.REACT_APP_API_URL + '/employee', data)
                    .then(async (res) => {
                        console.log(
                            'Request Server to Generate Employee Success'
                        );
                        // console.log('Access Token', res.data?.accessToken);
                        await setStorage('token_erp', token);
                        await setStorage('remember_erp', data.uid);
                        window.localStorage.setItem(
                            'accesstoken_erp',
                            res.data?.accessToken
                        );
                        setToken(window.localStorage.token_erp);
                    })
                    .catch((e) => {
                        if (e) {
                            console.log(e);
                        }
                    });
                console.log(res);
            })
            .catch((err) => {
                if (err.code === 'auth/email-already-in-use') {
                    alert('อีเมลนี้ถูกใช้เเล้ว');
                } else if (err.code === 'auth/weak-password') {
                    alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร');
                }
                setErrors((prev) => [...prev, err.message]);
            });
    },
    signupByHRMS: async (email, password, data, setErrors, setToken) => {
        await auth
            .createUserWithEmailAndPassword(email, password)
            //make res asynchronous so that we can make grab the token before saving it.
            .then(async (res) => {
                // const token = await Object.entries(res.user)[5][1].b;
                console.log(res);
                data.uid = res.user.uid;
                await axios
                    .post(process.env.REACT_APP_API_URL + '/employee', data)
                    .then(async (res) => {
                        console.log('Request Server to Generate User');
                    })
                    .catch((e) => {
                        if (e) {
                            console.log(e);
                        }
                    });
                console.log(res);
            })
            .catch((err) => {
                if (err.code === 'auth/email-already-in-use') {
                    alert('อีเมลนี้ถูกใช้เเล้ว');
                } else if (err.code === 'auth/weak-password') {
                    alert('กรุณาตั้งรหัสผ่านอย่างน้อย 6 ตัวอักษร');
                }
                setErrors((prev) => [...prev, err.message]);
            });
    },
    signin: async (email, password, setErrors, setToken) => {
        //change from create users to...
        await auth
            .signInWithEmailAndPassword(email, password)
            //everything is almost exactly the same as the function above
            .then(async (res) => {
                console.log('พบผู้ใช้ใน firebase กำลังค้นหาใน databse erp');
                const data = res.user;
                await axios
                    .get(
                        process.env.REACT_APP_API_URL +
                            '/employee/signin/' +
                            res.user.uid
                    )
                    .then(async (res) => {
                        console.log('Request Server to Sigin and Found User');
                        //console.log('Access Token', res.data?.accessToken);
                        const token = await Object.entries(data)[5][1].b;
                        await setStorage('token_erp', token);
                        await setStorage('remember_erp', data.uid);
                        window.localStorage.setItem(
                            'accesstoken_erp',
                            res.data?.accessToken
                        );
                        setToken(window.localStorage.token_erp);
                        console.log(res);
                    })
                    .catch((e) => {
                        if (e) {
                            alert('บัญชีผู้ใช้ไม่ถูกต้อง');
                            console.log(e);
                            console.log('ไม่พบผู้ใช้ใน databse erp');
                        }
                    });
            })
            .catch((err) => {
                // console.log(err.message);
                if (err.code === 'auth/user-not-found') {
                    alert('ไม่พบบัญชีผู้ใช้ในระบบ');
                } else if (err.code === 'auth/wrong-password') {
                    alert('รหัสผ่านไม่ถูกต้อง');
                } else if (err.code === 'auth/invalid-email') {
                    alert('รูปเเบบอีเมลไม่ถูกต้อง');
                }
                setErrors((prev) => [...prev, err]);
            });
    },
    siginWithProvider: (setToken) => {
        const uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'redirect',
            signInSuccessUrl: '/',
            // We will display Google and Facebook as auth providers.
            signInOptions: signInAuthProvider,
            tosUrl: 'https://eonlineshop.net/policy/docs/condition',
            privacyPolicyUrl: 'https://eonlineshop.net/policy/docs/',
            callbacks: {
                // Avoid redirects after sign-in.
                signInSuccessWithAuthResult: async (res) => {
                    const data = res.user;
                    const token = await Object.entries(res.user)[5][1].b;
                    // await localStorage.setItem("token", token);
                    await setStorage('token_erp', token);
                    await setStorage('remember_erp', data.uid);
                    window.localStorage.setItem(
                        'accesstoken_erp',
                        data.accessToken
                    );

                    setToken(window.localStorage.token_erp);
                    console.log(res);
                },
            },
        };
        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
    },
    signout: async (setErrors, setToken) => {
        // signOut is a no argument function
        await auth
            .signOut()
            .then((res) => {
                //remove the token
                removeStorage('token_erp');
                removeStorage('remember_erp');
                removeStorage('accesstoken_erp');
                //set the token back to original state
                setToken(null);
            })
            .catch((err) => {
                //there shouldn't every be an error from firebase but just in case
                setErrors((prev) => [...prev, err.message]);
                //whether firebase does the trick or not i want my user to do there thing.
                removeStorage('token_erp');
                removeStorage('remember_erp');
                removeStorage('accesstoken_erp');
                setToken(null);
                console.error(err.message);
            });
    },
    forgotenPassword: async (email, setErrors, setToken) => {
        await auth
            .sendPasswordResetEmail(email)
            .then((res) => {
                //remove the token
                removeStorage('token_erp');
                removeStorage('remember_erp');
                removeStorage('accesstoken_erp');
                // localStorage.removeItem("token");
                //set the token back to original state
                setToken(null);
            })
            .catch((err) => {
                //there shouldn't every be an error from firebase but just in case
                setErrors((prev) => [...prev, err.message]);
                //whether firebase does the trick or not i want my user to do there thing.
                removeStorage('token_erp');
                removeStorage('remember_erp');
                removeStorage('accesstoken_erp');
                setToken(null);
                console.error(err.message);
            });
    },
};
