import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import { VEHICLE_STATUS } from '../../../config/constant/VehicleStatus';
import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';
import { EMPLOYEE_STATUS } from '../../../config/constant/EmployeeStatus';
import CheckDriver from '../../../util/CheckDriver';

export default function MatchVehicleAndDriver() {
    const history = useHistory();
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicle);
    const drivers = useSelector((state) => state.employee);
    const [driver_data, setDriver] = useState([]);
    const [vehicle_data, setVehicle] = useState([]);

    useEffect(() => {
        // TODO: ตอนนี้ยังเป็นการเอามาจากรถทุกคน เพราะยังไม่มีกรองแบบ Status อย่างเดียว มันมีทั้ง Category และ Status
        dispatch(actions.vehicleAll()).then(() => {
            dispatch(actions.employeeGetByStatus(EMPLOYEE_STATUS[0]));
        });
        return () => {};
    }, []);

    useEffect(() => {
        setVehicle(vehicles?.arr);
        setDriver(CheckDriver(drivers?.arr));
        setIsLoading(true);
        return () => {};
    }, [vehicles, drivers]);

    // console.log("vehicle", vehicle_data);
    // console.log("driver", driver_data);

    const onSubmit = (data) => {
        const payload = {
            vehicle_status: VEHICLE_STATUS[1],
        };
        const driverPayload = {
            employee_id: data.employee_id,
            employee_status: EMPLOYEE_STATUS[1],
        };
        dispatch(actions.carOwnerPost(data)).then(() => {
            dispatch(actions.vehiclePut(data.vehicle_id, payload)).then(() => {
                dispatch(actions.employeePut(driverPayload)).then(() => {
                    alert('สำเร็จ');
                    window.location.reload();
                    // history.push('/fms/vehicle/driver');
                });
            });
        });
        console.log('data', data);
    };

    const genVehicleList = () =>
        _.map(vehicle_data, (vehicle) => (
            <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                {vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle.serial_number}
            </option>
        ));

    const genDriverLists = () =>
        _.map(driver_data, (driver) => (
            <option key={driver.employee_id} value={driver.employee_id}>
                {driver.employee_first_name + ' ' + driver.employee_last_name}
            </option>
        ));

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                เลือกเจ้าของรถ
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4 justify-between">
                    <div>
                        <Link to={'/fms/vehicle/driver'}>
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                    {/* <div>
                        <Button type="submit" colorScheme="blue">
                            บันทึก
                        </Button>
                    </div> */}
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    รถ{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="vehicle_id"
                                    type="text"
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือกรถ
                                    </option>
                                    {genVehicleList()}
                                </select>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    พนักงานขับ{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="employee_id"
                                    type="text"
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือกพนักงานขับ
                                    </option>
                                    {genDriverLists()}
                                </select>
                            </div>
                            <div className="text-center mt-6">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
