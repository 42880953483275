import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Badge } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import CONST_CONFIG from '../../../../config/constant';
export default function TransportAssignmentLists({
    setLoadWeight,
    transport_assignment,
    selectedTransportAssignmentLists,
    setSelectedTransportAssignmentLists,
    filterTransportAssignment,
    setAssignmentType,
    clearAllList,
    setDefaultDate,
    handleAssignmentModal,
    setSelectedConsigneeId,
    setSelectedShipperId,
    setStartReservation,
    setEndReservation,
    setSelectedVehicleContainer
}) {
    const genTd = () => {
        return _.map(transport_assignment, (assignment, index) => (
            <Tr key={assignment?.assignment_id} className="whitespace-nowrap">
                <Td>
                    <Checkbox
                        name={'assignment_id'}
                        value={assignment?.assignment_id}
                        onChange={(e) => {
                            if (!_.isEmpty(selectedTransportAssignmentLists)) {
                                if (
                                    selectedTransportAssignmentLists.hasOwnProperty(
                                        assignment?.assignment_id
                                    )
                                ) {
                                    //นำรายการออก เมื่อกดเลือกอีกครั้ง
                                    setSelectedTransportAssignmentLists(
                                        (prev) => {
                                            delete prev[
                                                assignment?.assignment_id
                                            ];
                                            setLoadWeight(
                                                (prev) =>
                                                    parseFloat(prev) -
                                                    parseFloat(
                                                        assignment.load_weight
                                                    )
                                            );
                                            if (_.isEmpty(prev)) {
                                                //หากนำออกแล้วไม่มีรายการเหลืออยู่
                                                clearAllList();
                                                setSelectedShipperId('');
                                                setSelectedConsigneeId('');
                                                setDefaultDate(new Date());
                                                setLoadWeight(0);
                                                setStartReservation(new Date())
                                                setEndReservation(moment(new Date()).add(1, 'days').toDate())
                                                setSelectedVehicleContainer()

                                            }
                                            return prev;
                                        }
                                    );
                                } else {
                                    //เพิ่มรายการใหม่
                                    setSelectedTransportAssignmentLists(
                                        (prev) => {
                                            prev[
                                                `${assignment?.assignment_id}`
                                            ] = {
                                                assignment_id:
                                                    assignment?.assignment_id,
                                            };
                                            return prev;
                                        }
                                    );
                                    setLoadWeight(
                                        (prev) =>
                                            parseFloat(prev) +
                                            parseFloat(assignment.load_weight)
                                    );
                                }
                            } else {
                                //เพิ่มอันแรก
                                setSelectedTransportAssignmentLists((prev) => {
                                    prev[`${assignment?.assignment_id}`] = {
                                        assignment_id:
                                            assignment?.assignment_id,
                                    };
                                    return prev;
                                });
                                setAssignmentType(assignment.assignment_type);
                                setSelectedShipperId(assignment.shipper_id);
                                setSelectedConsigneeId(assignment.consignee_id);
                                filterTransportAssignment(assignment);
                                setDefaultDate(
                                    new Date(assignment.pickup_date)
                                );
                                setLoadWeight(assignment.load_weight);
                                setStartReservation(assignment.pickup_date)
                                setEndReservation(assignment.delivery_date)


                                if (assignment?.vehicle?.serial_number) {
                                    const data = {
                                        value: assignment?.vehicle?.vehicle_id,
                                        label:
                                            assignment?.vehicle?.category_vehicle?.category_name +
                                            '/' +
                                            assignment?.vehicle?.sub_category_vehicle?.sub_category_name +
                                            '/' +
                                            assignment?.vehicle?.serial_number,
                                    };
                                    setSelectedVehicleContainer(data)
                                }
                            }
                        }}
                    ></Checkbox>
                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    <div className="text-xs">
                        {moment(assignment?.pickup_date).format('DD/MM/YYYY')}
                    </div>
                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    <div className="text-xs">
                        {moment(assignment?.delivery_date).format('DD/MM/YYYY')}
                    </div>
                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    <div className="text-xs text-center font-semibold py-1">
                        {assignment.invoice_number}
                    </div>
                    {assignment?.assignment_type ===
                        CONST_CONFIG.WORD_TRANSPORT_SERVICE.domestic && (
                            <div className="text-xs text-center px-1  bg-red-200 hover:bg-red-400 rounded whitespace-nowrap">
                                {assignment?.assignment_type}
                            </div>
                        )}
                    {assignment?.assignment_type ===
                        CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport && (
                            <div className="text-xs text-center px-1  bg-blue-200 hover:bg-blue-400 rounded whitespace-nowrap">
                                {assignment?.assignment_type}
                            </div>
                        )}

                    {assignment?.assignment_type ===
                        CONST_CONFIG.WORD_TRANSPORT_SERVICE.intExport && (
                            <div className="text-xs text-center px-1  bg-yellow-200 hover:bg-yellow-400 rounded whitespace-nowrap">
                                {assignment?.assignment_type}
                            </div>
                        )}

                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    {' '}
                    <div className="font-semibold">
                        {assignment?.shipper?.customer_company}
                    </div>
                    <div className="text-xs whitespace-normal">
                        {assignment?.shipper_address +
                            ' ' +
                            assignment?.shipper_postcode}
                    </div>
                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    <div className="font-semibold">
                        {assignment?.consignee?.customer_company}
                    </div>
                    <div className="text-xs whitespace-normal">
                        {assignment?.consignee_address +
                            ' ' +
                            assignment?.consignee_postcode}
                    </div>
                </Td>
                <Td
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    {(assignment?.vehicle?.serial_number) ? (
                        <div className="font-semibold text-center">
                            {assignment?.vehicle?.serial_number}
                        </div>
                    ) : (
                        <div className="font-semibold text-center">
                            -
                        </div>
                    )}

                </Td>
                <Td
                    className="cursor-pointer"
                    onClick={() =>
                        handleAssignmentModal(assignment)
                    }
                >
                    {(assignment.container_size) ? (
                        <div className="text-xs text-center">
                            {assignment.container_size +
                                '/' +
                                assignment?.load_weight +
                                ' กิโลกรัม '}
                        </div>
                    ) : (
                        <div className="text-xs text-center">
                            {
                                assignment?.load_weight +
                                ' กิโลกรัม '
                            }
                        </div>

                    )}


                </Td>
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto">
            <Table
                className="bg-white   break-words rounded-sm "
                variant="simple"
                size="sm"
            >
                <Thead>
                    <Tr className="whitespace-nowrap">
                        <Th>เลือก</Th>
                        <Th>วันรับ</Th>
                        <Th>วันส่ง</Th>
                        <Th>ประเภท</Th>
                        <Th>ต้นทาง</Th>
                        <Th>ปลายทาง</Th>
                        <Th>หมายเลขคอนเทนเนอร์</Th>
                        <Th>ขนาด</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
