import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/actions/index";

import DepartmentLists from "./components/DepartmentLists";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import _ from "lodash";

export default function Department() {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  useEffect(() => {
    dispatch(actions.departmentAll());
    return () => {};
  }, []);

  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">แผนกทั้งหมด</h2>

      <div className="py-6">
        <Link to="/hrms/department/create">
          <Button colorScheme="blue">เพิ่ม</Button>
        </Link>
      </div>
      <div>
        {_.size(department) ? (
          <div>
            {" "}
            <DepartmentLists department={department} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </div>
  );
}
