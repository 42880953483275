import {
  SUB_CATEGORY_ALL,
  SUB_CATEGORY_GET,
  SUB_CATEGORY_PUT,
  SUB_CATEGORY_DEL,
  SUB_CATEGORY_POST,
  SUB_CATEGORY_ALL_BY_CATEGORY,
} from "../../actions/types";

const initialState = null;
export default function (state = initialState, action) {
  switch (action.type) {
    case SUB_CATEGORY_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case SUB_CATEGORY_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case SUB_CATEGORY_ALL_BY_CATEGORY:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case SUB_CATEGORY_POST:
      return action.payload;
    case SUB_CATEGORY_PUT:
      return action.payload;
    case SUB_CATEGORY_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
