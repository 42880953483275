import React from "react";
import CardVehicleDashbroad from "components/common/Cards/CardVehicleDashbroad";
import { Link } from "react-router-dom";
import _ from "lodash";
export default function VehicleLists({ vehicle, id }) {
  const genCardVehicle = () => {
    return _.map(vehicle, (_vehicle) => (
      <div key={_vehicle.vehicle_id} className="w-full lg:4/12 xl:w-3/12 p-2">
        <Link to={"/fms/category/" + id + "/vehicle/detail/" + _vehicle.vehicle_id}>
          <CardVehicleDashbroad
            serial_number={_vehicle?.serial_number}
            status={_vehicle?.vehicle_status}
            vehicle_image={_vehicle?.vehicle_image}
            category_name={_vehicle?.category_vehicle?.category_name}
            sub_category_name={
              _vehicle?.sub_category_vehicle?.sub_category_name
            }
          />
        </Link>
      </div>
    ));
  };
  return (
    <div className=" flex-auto">
      <div className="relative flex flex-wrap">{genCardVehicle()}</div>
    </div>
  );
}
