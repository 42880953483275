import { Button } from "@chakra-ui/button";
import React from "react";
import { useHistory } from "react-router-dom";

export default function NotFound() {
    const history = useHistory()
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="-mt-64 md:-mt-28 p-4">
          <div className="font-bold text-xl text-center p-4">
            ขออภัยไม่พบหน้าที่คุณค้นหา
          </div>
          <div className="font-normal text-base  text-center p-4">
            ดูเหมือนว่าหน้าเว็บคุณพยายามเข้าถึงหน้าที่ไม่มีอยู่อีกต่อไป
          </div>
          <div className="font-normal text-base  text-center p-2">
              กดปุ่ม
        
           
              <Button variant="outline" colorScheme="blue" onClick={()=>history.goBack()} >ย้อนกลับ</Button>
       
            เพื่อดำเนินการต่อ
          </div>
        </div>
      </div>
    </div>
  );
}
