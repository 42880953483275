import React from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

export default function CardContact({
  contact_id,
  contact_name,
  contact_phone_number,
  contact_email,
  onDelete,
}) {
  const { id } = useParams();
  const history = useHistory();

  return (
    <div className="p-2 bg-gray-100 rounded-lg shadow dark:bg-gray-800">
      <div className="flex flex-wrap  w-full">
        <div className="w-full xl:w-8/12">
          <div className="px-6">
            {contact_name && (
              <p className="mb-2 text-sm font-medium text-gray-700 "><i className="fa fa-user-alt mr-2 text-xs text-gray-900" />{contact_name}</p>
            )}
            {contact_phone_number && (
              <p className="mb-2 text-sm font-medium text-gray-700 "><i className="fa fa-phone mr-2 text-xs text-gray-900" />{contact_phone_number}</p>
            )}
            {contact_email && (
              <p className="mb-2 text-sm font-medium text-gray-700 "><i className="fa fa-envelope mr-2 text-xs text-gray-900" />{contact_email}</p>
            )}
          </div>
        </div>
        <div className="w-full xl:w-4/12 flex justify-end">
          <Button colorScheme="yellow" size="xs" className="mr-2"
            onClick={() => {
              history.push("/crm/" + id + "/contact/edit/" + contact_id);
            }}
          >แก้ไข</Button>{" "}
          <Button colorScheme="red" size="xs"
            onClick={() => {
              const confirm = window.confirm("ยืนยันการลบผู้ติดต่อ");
              if (confirm) onDelete(contact_id);
            }}
          >ลบ</Button>
        </div>
      </div>

    </div>
  );
}
CardContact.defaultProps = {
  contact_id: "",
  contact_name: "ชื่อผู้ติดต่อ",
  contact_phone_number: "เบอร์ผู้ติดต่อ",
  contact_email: "อีเมล",
  onDelete: () => { },
};

CardContact.propTypes = {
  contact_id: PropTypes.string,
  contact_name: PropTypes.string,
  contact_phone_number: PropTypes.string,
  contact_email: PropTypes.string,
  onDelete: PropTypes.func,
};
