import {
    SYSTEM_INFO_ALL,
    SYSTEM_INFO_GET,
    SYSTEM_INFO_PUT,
    SYSTEM_INFO_DEL,
    SYSTEM_INFO_POST,
  } from "../../actions/types";
  
  const initialState = {
    company: null,
    isLoading: false,
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case SYSTEM_INFO_ALL:
        return {
          ...action.payload,
          isLoading: true,
        };
      case SYSTEM_INFO_GET:
        return {
          ...action.payload,
          isLoading: true,
        };
      case SYSTEM_INFO_POST:
        return action.payload;
      case SYSTEM_INFO_PUT:
        return action.payload;
      case SYSTEM_INFO_DEL:
        return { isLoading: true };
      default:
        return state;
    }
  }
  