import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import * as actions from "../../../../redux/actions";
import _ from "lodash";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EMPLOYEE_STATUS } from "../../../../config/constant/EmployeeStatus";
import { VEHICLE_STATUS } from "../../../../config/constant/VehicleStatus";

export default function VehicleDriverLists({ car_owner }) {

    const dispatch = useDispatch();

    const onDelete = (data) => {
        const driver = {
            employee_id: data.employee_id,
            employee_status: EMPLOYEE_STATUS[0]
        }
        const vehicle = {
            vehicle_status: VEHICLE_STATUS[0]
        }
        dispatch(actions.employeePut(driver)).then(() => {
            dispatch(actions.vehiclePut(data.vehicle_id, vehicle)).then(() => {
                dispatch(actions.carOwnerDelete(data.car_owner_id)).then(() => {
                    dispatch(actions.carOwnerAll())
                })
            })
        })

        console.log("data", data);
    }

    const genTd = () => {
        return _.map(car_owner, (_car_owner, index) => (
            <Tr key={_car_owner.car_owner_id}>
                <Td>{index + 1}</Td>
                <Td>{_car_owner?.vehicle?.serial_number}</Td>
                <Td>{_car_owner?.vehicle?.sub_category_vehicle.sub_category_name}</Td>
                <Td>{_car_owner?.employee?.employee_first_name + " " + _car_owner?.employee?.employee_last_name}</Td>
                <Td>{_car_owner?.employee?.employee_phone_number}</Td>
                <Td>
                    <div> 
                        <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => {
                                const confirm = window.confirm("ยืนยันการลบ");
                                if (confirm) {
                                    onDelete(_car_owner);
                                }
                            }}
                        >
                            ลบ
                        </Button>
                    </div>
                </Td>
            </Tr>
        ))
    }

    console.log("car owmer", car_owner);
    return (
        <div className="flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>ป้ายทะเบียน</Th>
                        <Th>ประเภท</Th>
                        <Th>พนักงานขับรถ</Th>
                        <Th>เบอร์ติดต่อ</Th>
                        <Th>ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    )
}