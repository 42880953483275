import {
    REPAIR_TYPE_ALL,
    REPAIR_TYPE_GET,
    REPAIR_TYPE_POST,
    REPAIR_TYPE_PUT,
    REPAIR_TYPE_DEL,
    REPAIR_TYPE_ALL_BY_STATUS,
} from '../../actions/types';

const initialState = {
    repair_type: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case REPAIR_TYPE_ALL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_TYPE_ALL_BY_STATUS:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_TYPE_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_TYPE_POST:
            return action.payload;
        case REPAIR_TYPE_PUT:
            return action.payload;
        case REPAIR_TYPE_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}
