import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// components

export default function CardProfile({
    first_name,
    last_name,
    department,
    position,
    email,
    phone_number,
    address,
    postcode,
    image,
    gender,
    driving_license,
}) {
    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
                <div className=" flex flex-wrap">
                    <div className="w-full lg:w-4/12">
                        <div className="w-full px-4 lg:my-10 my-4 flex justify-center">
                            <div className="relative ">
                                {image ? (
                                    <>
                                        {' '}
                                        <img
                                            alt="..."
                                            src={image}
                                            className="max-h-60 rounded-full"
                                        />
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <img
                                            alt="..."
                                            src={require('assets/img/user-profile.png')}
                                            className="max-h-60 rounded-full"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="w-full lg:w-8/12">
                        <div className="ml-8 mr-4 border-b-4 border-blue-400 inline-flex ">
                            <div className="mt-2 text-left">
                                <div className="lg:text-3xl text-xl leading-normal mt-0 mb-2 text-gray-900 font-bold uppercase ">
                                    {first_name + ' ' + last_name}
                                </div>
                                <div className="lg:text-xl text-sm leading-normal mt-0 mb-2 text-gray-600">
                                    {'แผนก' + department}
                                </div>
                                <div className="lg:text-xl text-sm leading-normal mt-0 mb-2 text-gray-600">
                                    {'ตำแหน่ง' + position}
                                </div>
                            </div>
                        </div>

                        <div className="justify-center">
                            <hr className="ml-8 mr-4" />
                            <div className="mt-4 mb-5 ml-8 mr-2 text-sm lg:text-xl">
                                <div className="mb-2 text-gray-700 ">
                                    {'เพศ : ' + gender}
                                </div>
                                <div className="mb-2 text-gray-700 ">
                                    {'ที่อยู่ : ' + address + ' ' + postcode}
                                </div>
                                <div className="mb-2 text-gray-700 ">
                                    {'เบอร์โทรศัพท์ : ' + phone_number}
                                </div>
                                <div className="mb-2 text-gray-700 ">
                                    {'อีเมล : ' + email}
                                </div>

                                <div className="mb-2 text-gray-700 ">
                                    {driving_license
                                        ? 'วันหมดอายุใบขับขี่ : ' +
                                          moment(driving_license).format('L')
                                        : ''}
                                    {}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

CardProfile.defaultProps = {
    first_name: 'ชื่อ',
    last_name: 'สกุล',
    department: 'แผนก',
    position: 'ตำแหน่ง',
    email: 'อีเมล',
    phone_number: 'เบอร์โทรศัพท์',
    address: 'ที่อยู่',
    postcode: 'รหัสไปรษณีย์',
    image: null,
    gender: 'เพศ',
    driving_license: null,
};

CardProfile.propTypes = {
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    department: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    address: PropTypes.string,
    postcode: PropTypes.string,
    image: PropTypes.string,
    gender: PropTypes.string,
    driving_license: PropTypes.string,
};
