import axios from 'axios';
import {
    REPAIR_ALL_VEHICLE,
    REPAIR_ALL_WHEEL,
    REPAIR_ALL_VEHICLE_BY_STATUS,
    REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE,
    REPAIR_ALL_WHEEL_BY_STATUS,
    REPAIR_GET_VEHICLE,
    REPAIR_GET_WHEEL,
    REPAIR_POST_VEHICLE,
    REPAIR_POST_WHEEL,
    REPAIR_PUT,
    REPAIR_DEL,
} from '../types';

export const repairAllVehicle = (params = {}) => {
    const { size = '', page = 1, type = '' } = params;

    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + `/repair/vehicle?size=${size}&page=${page}&type=${type}`)
            .then((res) => {
                console.log('Request Server to Get All repair vehicle');
                dispatch({ type: REPAIR_ALL_VEHICLE, payload: res.data });
            });
    };
};

export const repairAllWheel = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair/wheel/')
            .then((res) => {
                console.log('Request Server to Get All repair wheel');
                dispatch({ type: REPAIR_ALL_WHEEL, payload: res.data });
            });
    };
};

export const repairAllVehicleByStatus = (params = {}) => {
    const { size = null, page = 1 } = params;

    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    `/repair/vehicle/by/status?size=${size}&page=${page}`
            )
            .then((res) => {
                console.log('Request Server to Get All repair vehicle');
                dispatch({
                    type: REPAIR_ALL_VEHICLE_BY_STATUS,
                    payload: res.data,
                });
            });
    };
};

export const repairAllVehicleBySubVehicle = (payload) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/repair/vehicle/by/sub_vehicle/' +
                    payload
            )
            .then((res) => {
                console.log(
                    'Request Server to Get All repair vehicle by sub_vehicle'
                );
                dispatch({
                    type: REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE,
                    payload: res.data,
                });
            });
    };
};

export const repairAllWheelByStatus = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair/wheel/by/status/')
            .then((res) => {
                console.log('Request Server to Get All repair wheel');
                dispatch({
                    type: REPAIR_ALL_WHEEL_BY_STATUS,
                    payload: res.data,
                });
            });
    };
};

export const repairGetVehicle = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair/vehicle/' + payload)
            .then((res) => {
                console.log('Request Server to Get an repair vehicle');
                if (res.data) {
                    dispatch({ type: REPAIR_GET_VEHICLE, payload: res.data });
                } else {
                    dispatch({ type: REPAIR_GET_VEHICLE, payload: null });
                }
            });
    };
};

export const repairGetWheel = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair/wheel/' + payload)
            .then((res) => {
                console.log('Request Server to Get an repair wheel');
                if (res.data) {
                    dispatch({ type: REPAIR_GET_WHEEL, payload: res.data });
                } else {
                    dispatch({ type: REPAIR_GET_WHEEL, payload: null });
                }
            });
    };
};

export const repairPostVehicle = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/repair/vehicle', payload)
            .then((res) => {
                console.log('Request Server to post an repair vehicle');
                dispatch({ type: REPAIR_POST_VEHICLE, payload: null });
            });
    };
};

export const repairPostWheel = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/repair/wheel', payload)
            .then((res) => {
                console.log('Request Server to post an repair wheel');
                dispatch({ type: REPAIR_POST_WHEEL, payload: null });
            });
    };
};

export const repairPut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/repair/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an repair');
                dispatch({ type: REPAIR_PUT, payload: res.data });
            });
    };
};

export const repairDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/repair/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an repair');
                dispatch({ type: REPAIR_DEL, payload: null });
            });
    };
};
