import axios from "axios";
import {
    REPAIR_TYPE_ALL,
    REPAIR_TYPE_GET,
    REPAIR_TYPE_POST,
    REPAIR_TYPE_PUT,
    REPAIR_TYPE_DEL,
    REPAIR_TYPE_ALL_BY_STATUS
} from "../types";

export const repairTypeAll = (params = {}) => {
    const { size = '', page = 1, type = '' } = params;

    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + `/repair_type?size=${size}&page=${page}&type=${type}`).then((res) => {
            console.log("Request Server to Get All repair_type");
            dispatch({ type: REPAIR_TYPE_ALL, payload: res.data });
        });
    };
};

export const repairTypeAllByStatus = (status) => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + "/repair_type/by/status/" + status).then((res) => {
            console.log("Request Server to Get All by status repair_type");
            dispatch({ type: REPAIR_TYPE_ALL_BY_STATUS, payload: res.data });
        });
    };
};

export const repairTypeGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + "/repair_type/" + payload)
            .then((res) => {
                console.log("Request Server to Get an repair_type");
                if (res.data) {
                    dispatch({ type: REPAIR_TYPE_GET, payload: res.data })
                } else {
                    dispatch({ type: REPAIR_TYPE_GET, payload: null });
                }
            });
    };
}

export const repairTypePost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + "/repair_type", payload)
            .then((res) => {
                console.log("Request Server to post an repair_type");
                dispatch({ type: REPAIR_TYPE_POST, payload: null });
            });
    };
}

export const repairTypePut = (id, payload) => {
    return async (dispatch) => {
        await axios
        .put(process.env.REACT_APP_API_URL + "/repair_type/" + id, payload)
        .then((res) => {
            console.log("Request Server to put an repair_type");
            if (res.data)
                dispatch({ type: REPAIR_TYPE_PUT, payload: res.data });
        });
    };
};

export const repairTypeDelete = (payload) => {
    return async (dispatch) => {
        await axios
        .delete(process.env.REACT_APP_API_URL + "/repair_type/" + payload)
        .then((res) => {
            console.log("Request Server to Delete an repair_type");
            dispatch({ type: REPAIR_TYPE_DEL, payload: null });
        });
    };
};