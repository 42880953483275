import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import VehicleLists from './components/VehicleLists';
import Search from '../../../components/Search/VehicleSearch';

export default function VehiclesManagement() {
    const vehicle = useSelector((state) => state.vehicle);
    const { id } = useParams();
    const [vehicle_data, setVehicle] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.vehicleAllByCategory(id)).then(() => {});
        return () => {};
    }, [id]);
    useEffect(() => {
        setVehicle(vehicle?.arr);
        setIsLoading(true);
        return () => {};
    }, [vehicle]);

    // console.log(vehicle_data);
    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    จัดการรถ
                </h2>
            </div>
            <div className="flex justify-between py-4">
                <Link to={'/fms/category/' + id + '/vehicle/create'}>
                    <Button colorScheme="blue">เพิ่ม</Button>
                </Link>
                <Search setData={setVehicle} data={vehicle?.arr} />
            </div>
            <div className="py-4">
                {_.size(vehicle_data) ? (
                    <div>
                        <VehicleLists vehicle={vehicle_data} id={id} />
                    </div>
                ) : (
                    <div className="flex justify-center p-4">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
