import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';

// components
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import CardCompany from 'components/common/Cards/CardCompany';

export default function Company() {
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const [module_lists, setModuleLists] = useState([]);
    const company = useSelector((state) => state.company);
    const dispatch = useDispatch();

    const findAllModuleLists = async () => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/module_lists/')
            .then((res) => {
                console.log('Request Server to Get all module_lists');
                setModuleLists(res.data);
            });
    };
    useEffect(() => {
        dispatch(actions.companyAll()).then(() => {
            findAllModuleLists().then(() => {
                setIsLoading(true);
            });
        });
        return () => {};
    }, []);
    console.log(module_lists);
    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    ข้อมูลบริษัท
                </h2>
            </div>
            <div className="flex py-4 justify-end">
                <Link to={'/system/company/edit'}>
                    <Button colorScheme="yellow" variant="solid">
                        แก้ไข
                    </Button>
                </Link>
            </div>
            <div className="flex flex-wrap justify-center">
                {' '}
                <div className=" p-4 w-full lg:w-8/12 ">
                    <CardCompany
                        name={company.company_name}
                        email={company.company_email}
                        phone_number={company.company_phone_number}
                        address={company.company_address}
                        fax={company?.company_fax}
                        taxes_id={company?.company_taxes_id}
                        logo={company?.company_logo}
                    />
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
