import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { FormControl, Input, Button, Textarea } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';

import CardInfoBackward from '../../common/Cards/CardInfoBackward';
import DeliveryAssignment from '../../Calendar/Components/DeliveryAssignment';
import * as actions from '../../../redux/actions';
import { VEHICLE_STATUS } from 'config/constant/VehicleStatus';
import { CATEGORY_VEHICLE } from 'config/constant/CategoryVehicle';

export default function EditorMode({
    selectedDeliveryOrder,
    allAssignments,
    handleExitModal,
}) {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(
        selectedDeliveryOrder.start_reservation
    );
    const [endDate, setEndDate] = useState(
        selectedDeliveryOrder.end_reservation
    );

    const { register, handleSubmit } = useForm();
    const vehicle = useSelector((state) => state.vehicle);


    const genVehicleComponent = (vehicles) =>
        _.map(vehicles, (eachVehiclePart, index) => (
            <div key={index}>
                <CardInfoBackward
                    flat
                    icon="fas fa-truck"
                    subtitle={
                        eachVehiclePart.vehicle?.sub_category_vehicle
                            ?.sub_category_name +
                        ' ' +
                        eachVehiclePart.vehicle?.serial_number
                    }
                    title={
                        eachVehiclePart.vehicle?.category_vehicle?.category_name
                    }
                />
            </div>
        ));

    const handleUpdateDelivery = (data) => {

        const prepareDelivery = {
            ...selectedDeliveryOrder,
            ...data,
            start_reservation: startDate,
            end_reservation: endDate,
            delivery_vehicles: selectVechicleData
        };

        dispatch(
            actions.deliveryPut(
                selectedDeliveryOrder.delivery_id,
                prepareDelivery
            )
        ).then(() => {
            dispatch(actions.deliveryAll()).then(() => {
                dispatch(actions.transportAssignmentAll()).then(() => {
                    // handleToDisplayMode();
                    handleExitModal();
                    window.location.reload();
                });
            });
        });
    };

    const filterVehicleData = (type) => {
        const vehicleResult = _.filter(vehicle?.arr, (_vehicle) => {
            if (type === CATEGORY_VEHICLE[0]) {
                return (
                    _vehicle?.category_id === type
                    && _vehicle?.vehicle_status === VEHICLE_STATUS[1]
                );
            } else {
                return (
                    _vehicle?.category_id === type
                );
            }

        });

        const result = _.map(vehicleResult, (vehicle) => {
            return {
                value: vehicle?.vehicle_id,
                label:
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle?.serial_number,
            };
        });
        return result;
    };

    const filterValue = (type, delivery_vehicles) => {

        const result = _.find(delivery_vehicles, (_vehicle) => {

            if (_vehicle?.vehicle?.category_id === type) {
                return {
                    value: _vehicle?.vehicle_id,
                    label:
                        _vehicle?.vehicle?.sub_category_vehicle?.sub_category_name +
                        '/' +
                        _vehicle?.vehicle?.serial_number,
                };
            }

        });
        if (result != undefined) {
            return result;
        } else {
            return null;
        }

    }

    const [selectVechicleData, setSelectVechicleData] = useState([filterValue(CATEGORY_VEHICLE[0], selectedDeliveryOrder.delivery_vehicles), filterValue(CATEGORY_VEHICLE[1], selectedDeliveryOrder.delivery_vehicles), filterValue(CATEGORY_VEHICLE[2], selectedDeliveryOrder.delivery_vehicles)]);

    const handleOnChange = (option, index) => {
        selectVechicleData[index] = {
            vehicle_id: option.value
        }
        setSelectVechicleData(selectVechicleData)
    }
    useEffect(() => {

        return () => {

        }
    }, [selectVechicleData])

    const showValue = (value) => {
        if (value) {
            return {
                value: value?.vehicle_id,
                label:
                    value?.vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    value?.vehicle?.serial_number,
            }
        }
    }
    return (
        <div>
            {' '}
            <form onSubmit={handleSubmit(handleUpdateDelivery)}>
                <div className="flex flex-wrap gap-2 font-sans">

                    <div className="w-full  ml-3">

                        <div className="flex mb-2 gap-2">
                            <div className="w-1/2">
                                <FormControl>
                                    <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                                        เริ่มจอง
                                    </label>
                                    <Flatpickr
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-base shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        // data-enable-time
                                        value={startDate}
                                        onChange={(date) => {
                                            setStartDate(date[0]);
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="w-1/2">
                                <FormControl>
                                    <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                                        สิ้นสุด
                                    </label>
                                    <Flatpickr
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-base shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        // data-enable-time
                                        value={endDate}
                                        onChange={(date) => {
                                            setEndDate(date[0]);
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className="mb-2">
                            <label className="block uppercase text-gray-700 text-base font-bold mb-2">
                                ยานพาหนะ
                            </label>
                            {/* {genVehicleComponent(
                                selectedDeliveryOrder.delivery_vehicles
                            )} */}

                            <div className="w-full flex gap-1">
                                <div className="w-1/3">
                                    <label className="text-sm font-semibold">
                                        หัวรถ
                                    </label>
                                    <Select
                                        name={"หัวรถ"}
                                        defaultValue={showValue(selectVechicleData[0])}
                                        placeholder={'กรุณาเลือกข้อมูลหัวรถ'}
                                        onChange={(option) => {
                                            handleOnChange(option, 0)
                                        }}
                                        options={filterVehicleData(CATEGORY_VEHICLE[0])}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <label className="text-sm font-semibold">
                                        หางรถ
                                    </label>
                                    <Select
                                        name={"หางรถ"}
                                        defaultValue={showValue(selectVechicleData[1])}
                                        placeholder={'กรุณาเลือกข้อมูลหางรถ'}
                                        onChange={(option) => {
                                            handleOnChange(option, 1)
                                        }}
                                        options={filterVehicleData(CATEGORY_VEHICLE[1])}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <label className="text-sm font-semibold">
                                        ตู้คอนเทนเนอร์
                                    </label>
                                    <Select
                                        name={"ตู้คอนเทนเนอร์"}
                                        defaultValue={showValue(selectVechicleData[2])}
                                        placeholder={'กรุณาเลือกข้อมูลตู้คอนเทนเนอร์'}
                                        onChange={(option) => {
                                            handleOnChange(option, 2)
                                        }}
                                        options={filterVehicleData(CATEGORY_VEHICLE[2])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">
                            <FormControl>
                                <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                                    หมายเลขซีล
                                </label>
                                <Input
                                    defaultValue={
                                        selectedDeliveryOrder?.seal_no
                                    }
                                    ref={register}
                                    name="seal_no"
                                />
                            </FormControl>
                        </div>

                        <div className="flex">
                            <div className="w-1/2">
                                <label className="block uppercase text-gray-700 text-base mb-1">
                                    <b>น้ำหนักรวม</b> {_.sum(
                                        _.map(
                                            selectedDeliveryOrder.delivery_assignments,
                                            (eachAssignment) =>
                                                parseFloat(
                                                    eachAssignment?.assignment
                                                        ?.load_weight
                                                )
                                        )
                                    ).toFixed(2)}{' '}
                                    กิโลกรัม
                                </label>
                            </div>
                            <div className="w-1/2">
                                <label className="block uppercase text-gray-700 text-base mb-1">
                                    <b>จำนวนรวม</b> {_.sum(
                                        _.map(
                                            selectedDeliveryOrder.delivery_assignments,
                                            (eachAssignment) =>
                                                parseFloat(
                                                    eachAssignment?.assignment
                                                        ?.number_of_packages
                                                )
                                        )
                                    ).toFixed(0)}{' '}
                                    ชิ้น
                                </label>
                            </div>{' '}
                        </div>
                    </div>
                </div>
                <div className="font-sans mt-2 ml-3">
                    <FormControl>
                        <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                            หมายเหตุ
                        </label>
                        <Textarea
                            defaultValue={selectedDeliveryOrder.delivery_note}
                            name="delivery_note"
                            ref={register}
                        />
                    </FormControl>
                </div>{' '}

                <div className="font-sans mt-2 ml-3">
                    <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                        รายการการจัดส่ง
                    </label>
                    {_.isEmpty(selectedDeliveryOrder.delivery_assignments) ? (
                        <p>ยังไม่มีรายการจัดส่ง</p>
                    ) : (
                        <DeliveryAssignment
                            assignments={
                                selectedDeliveryOrder.delivery_assignments
                            }

                            allSystemAssignments={allAssignments}
                        />
                    )}
                </div>
                <div className="font-sans mt-2 flex justify-end gap-2">
                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit(handleUpdateDelivery)}
                    >
                        บันทึก
                    </Button>{' '}
                    <Button colorScheme="gray" onClick={handleExitModal}>
                        ยกเลิก
                    </Button>
                </div>
            </form>
        </div>
    );
}
