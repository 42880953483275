import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Button, HStack } from '@chakra-ui/react';
import CardInfo from 'components/common/Cards/CardInfo';
import _ from 'lodash';
import * as actions from '../../../redux/actions/index';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import PathLists from './components/PathLists';

export default function DetailRoute() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const route = useSelector((state) => state.route);

    useEffect(() => {
        dispatch(actions.routeGet(id))
        setIsLoading(true);
        return () => { }
    }, [])

    const onDelete = () => {
        dispatch(actions.routeDel(id)).then(() => {
            history.push('/fms/route/management');
        })
    }

    console.log("ROUTE", route);
    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                {' '}
                รายละเอียด {route?.title}
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Button
                        onClick={() => history.goBack()}
                        variant="outline"
                    >
                        กลับ
                    </Button>
                </div>
                <div>
                    <HStack spacing={2}>
                        <Box>
                            <Button
                                colorScheme="yellow"
                                onClick={() => {
                                    history.push(`/fms/route/detail/${id}/edit`)
                                }}
                            >
                                แก้ไข
                            </Button>
                        </Box>

                        <Button
                            colorScheme="red"
                            onClick={() => {
                                const confirm = window.confirm(
                                    `ยืนยันการลบข้อมูล ${route?.title}`
                                );
                                if (confirm) {
                                    onDelete();
                                }
                            }}
                        >
                            ลบ
                        </Button>
                    </HStack>
                </div>
            </div>
            <div className="p-4">
                <div className="w-full lg:w-6/12">
                    <CardInfo
                        title={route?.title}
                        subtitle={route?.title}
                        icon="fas fa-list-alt"
                    />
                </div>
                <div className="my-6">
                    <div className="my-4">
                        <Button 
                        colorScheme="blue"
                        disabled={_.size(route?.paths) === 5}
                        onClick={() => {
                            history.push(`/fms/route/detail/${id}/create/path`)
                        }}
                        >
                            เพิ่มเส้นทาง
                        </Button>
                    </div>
                    <div>
                        {_.size(route?.paths) ? (
                            <PathLists  path={route?.paths} />
                        ) : (
                            <div className="flex content-center items-center justify-center  rounded rounded-t-lg bg-white p-4">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}