import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
} from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import Select from 'react-select';
import { EMPLOYEE_STATUS } from '../../../../config/constant/EmployeeStatus';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import CheckDriver from '../../../../util/CheckDriver';
import RepairHistoryTypeMenu from 'components/CategoriesMenu/RepairHistoryTypeMenu';

import * as actions from '../../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import Pagination from 'components/common/Pagination/Pagination';
import RepairMonthVehicleLists from './components/RepairMonthVehicleLists';

import { MonthDataVehiclePrintPDF } from '../report/MonthDataVehiclePrintPDF';

export default function DetailHistoryRepairVehicle() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const repair_month = useSelector((state) => state.repair_month);
    const repair_log = useSelector((state) => state.repair_log);
    const type = useSelector((state) => state.type);
    const vehicle = useSelector((state) => state.vehicle);
    const employee = useSelector((state) => state.employee);
    const company = useSelector((state) => state.company);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [month, setMonth] = useState();
    const [vehicle_data, setVehicleData] = useState([]);
    const [driver_data, setDriverData] = useState([]); // รองรับ user ที่เป็นพนักงานขับรถ
    const [driver, setDriver] = useState([]); // จัดข้อมูลเพื่อแสดงผล
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectType, setSelectType] = useState({
        type_id: '',
        detail: 'ประเภททั้งหมด',
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatch(actions.repairMonthGetVehicle(id)).then(() => {
            dispatch(actions.employeeGetByStatus(EMPLOYEE_STATUS[0])).then(
                () => {
                    dispatch(actions.vehicleAll()).then(() => {
                        dispatch(actions.companyAll());
                        dispatch(actions.typeAll());
                    });
                }
            );
        });
        return () => {};
    }, []);

    useEffect(() => {
        setMonth(repair_month[0]);
        dispatch(
            actions.repairLogAllVehicle({
                size: size,
                page: page,
                month_id: repair_month[0]?.month_id,
                // repair_status: REPAIR_STATUS[2],
            })
        );
        setIsLoading(true);
        return () => {};
    }, [repair_month[0], page, size]);

    useEffect(() => {
        vehicle?.arr && setVehicleData(filterVehicleData());
        setDriverData(CheckDriver(employee?.arr));
        return () => {};
    }, [vehicle, employee]);

    useEffect(() => {
        driver_data && setDriver(filterDriverData());
        return () => {};
    }, [driver_data]);

    // useEffect(() => {
    //     if (
    //         selectedVehicle != null ||
    //         selectedDriver != null ||
    //         selectType != null
    //     ) {
    //         dispatch(
    //             actions.repairMonthGetVehicleByHeadAndTail(
    //                 id,
    //                 selectedVehicle?.value,
    //                 selectedDriver?.value,
    //                 selectType?.type_id
    //             )
    //         );
    //     }
    //     return () => {};
    // }, [selectedVehicle, selectedDriver, selectType]);

    useEffect(() => {
        dispatch(
            actions.repairLogAllVehicle({
                size: size,
                page: page,
                month_id: repair_month[0]?.month_id,
                // repair_status: REPAIR_STATUS[2],
                type: selectType?.type_id,
                driver_id: selectedDriver?.value,
                vehicle_id: selectedVehicle?.value,
            })
        );
        return () => {};
    }, [selectType, selectedDriver, selectedVehicle]);

    // console.log('selectedDriver', selectedDriver);
    // console.log('selectedVehicle', selectedVehicle);

    // useEffect(() => {
    //     dispatch(actions.repairMonthGetVehicle(id));
    //     setSelectedDriver(null);
    //     setSelectedVehicle(null);
    //     return () => {};
    // }, [reload]);

    console.log('repair_log', repair_log);
    // console.log('repair_month', repair_month);

    const filterVehicleData = () => {
        const result = _.map(vehicle?.arr, (vehicle) => {
            return {
                value: vehicle?.vehicle_id,
                label:
                    vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle?.serial_number,
                data: vehicle,
            };
        });
        return result;
    };

    const filterDriverData = () => {
        const result = _.map(driver_data, (driver) => {
            return {
                value: driver?.employee_id,
                label:
                    driver?.employee_first_name +
                    ' ' +
                    driver?.employee_last_name,
                data: driver,
            };
        });
        return result;
    };

    const handleChangeSelectedVehicle = (option) => {
        setSelectedVehicle(option);
    };

    const handleChangeSelectedDriver = (option) => {
        setSelectedDriver(option);
    };

    const renderTypeMenu = () => (
        <div className="shadow rounded">
            <Menu isLazy>
                <MenuButton as={Button} variant="outline">
                    {selectType ? selectType?.detail : 'ประเภททั้งหมด'}
                    <i className="fas fa-sort-down px-2"></i>
                </MenuButton>
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            setSelectType(null);
                        }}
                    >
                        ประเภททั้งหมด
                    </MenuItem>
                    <MenuDivider />
                    {_.map(type?.arr, (_type) => (
                        <MenuItem
                            key={_type?.type_id}
                            onClick={(e) => {
                                setSelectType(_type);
                            }}
                        >
                            {_type?.detail}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </div>
    );

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                ประวัติการแจ้งซ่อมประจำเดือน{' '}
                {moment(month?.month).format('MMMM')}/
                {moment(month?.month).format('YYYY')}
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Button onClick={() => history.goBack()} variant="outline">
                        กลับ
                    </Button>
                </div>
                <div className="mt-1 flex flex-wrap">
                    <div>
                        <RepairHistoryTypeMenu
                            selectType={selectType}
                            setSelectType={setSelectType}
                            type={type?.arr}
                            setSelectedDriver={setSelectedDriver}
                            setSelectedVehicle={setSelectedVehicle}
                        />
                    </div>
                    <div>
                        <Button
                            className="ml-2"
                            colorScheme="blue"
                            onClick={() => {
                                MonthDataVehiclePrintPDF(month, company);
                            }}
                        >
                            พิมพ์
                        </Button>
                    </div>
                </div>
            </div>
            <div className="relative flex px-4 mt-4 justify-between ">
                <div className="w-5/12">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ค้นหาทะเบียนรถ{' '}
                    </label>
                    <Select
                        placeholder={'ค้นหาทะเบียนรถ'}
                        onChange={handleChangeSelectedVehicle}
                        options={vehicle_data}
                    />
                </div>
                <div className="w-5/12">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ค้นหาพนักงานขับรถ{' '}
                    </label>
                    <Select
                        placeholder={'ค้นหาพนักงานขับรถ'}
                        onChange={handleChangeSelectedDriver}
                        options={driver}
                    />
                </div>
            </div>
            <div className="p-4">
                {_.size(repair_log?.rows) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        {' '}
                        <RepairMonthVehicleLists
                            repair_log={repair_log?.rows}
                            company={company}
                            id={id}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination
                    currPage={repair_log?.currPage}
                    lastPage={repair_log?.lastPage}
                    setPage={setPage}
                />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
