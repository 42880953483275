import {
    DELIVERY_MONTH_ALL,
    DELIVERY_MONTH_GET,
    DELIVERY_MONTH_GET_ALL_TYPE,
} from "../../actions/types";

const initialState = {
    delivery_month: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case DELIVERY_MONTH_ALL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case DELIVERY_MONTH_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case DELIVERY_MONTH_GET_ALL_TYPE:
            return {
                ...action.payload,
                isLoading: true,
            };
        default:
            return state;
    }
}