import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import hash from 'object-hash';
import { uploadImageFile } from '../../../util/image.functions';
import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';

export default function CreateVehicle() {
    const { register, handleSubmit, errors, reset } = useForm();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [image, setImage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const sub_category = useSelector((state) => state.sub_category_vehicle);
    const [sub_category_data, setSubCategoryData] = useState([]);
    useEffect(() => {
        dispatch(actions.sub_category_vehicleAllByCategory(id));

        return () => {};
    }, []);
    useEffect(() => {
        setSubCategoryData(sub_category.arr);
        setIsLoading(true);
        return () => {};
    }, [sub_category]);

    const onSubmit = (data, e) => {
        data.attribute = {
            brand: data.brand,
            generation: data.generation,
            buy_date: data.buy_date,
            price: data.price,
            insurance_start: data.insurance_start,
            insurance_end: data.insurance_end,
            car_keeper: data.car_keeper,
            insurance_company: data.insurance_company,
            insurance_type: data.insurance_type,
            invoice_date: data.invoice_date,
        };
        if (_.size(image)) {
            const filename = hash({ image, date: new Date() });
            data.category_id = id;
            uploadImageFile(filename, image[0].data_url).then((url) => {
                console.log('URL : ' + url);
                data.vehicle_image = url;
                dispatch(actions.vehiclePost(data)).then(() => {
                    reset();
                    setImage([]);
                    alert('บันทึกยานพาหนะสำเร็จ');
                });
            });
        } else {
            data.category_id = id;
            dispatch(actions.vehiclePost(data)).then(() => {
                reset();
                setImage([]);
                alert('บันทึกยานพาหนะสำเร็จ');
            });
        }
    };
    const genSubcategoryList = () =>
        _.map(sub_category_data, (sub_category) => (
            <option
                key={sub_category.sub_category_id}
                value={sub_category.sub_category_id}
            >
                {sub_category.sub_category_name}
            </option>
        ));

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    เพิ่มข้อมูล{id === CATEGORY_VEHICLE[0] && <>หัวรถ</>}
                    {id === CATEGORY_VEHICLE[1] && <>หางรถ</>}
                    {id === CATEGORY_VEHICLE[2] && <>ตู้คอนเทนเนอร์</>}
                </h2>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4 justify-between">
                    <div>
                        <Link to={'/fms/category/' + id + '/vehicle'}>
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap  justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ประเภท{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="sub_category_id"
                                    type="text"
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือกประเภท
                                    </option>
                                    {genSubcategoryList()}
                                </select>
                            </div>
                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        ป้ายทะเบียน{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="serial_number"
                                        required
                                        ref={register}
                                        placeholder="ป้ายทะเบียน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        น้ำหนักที่รองรับได้{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="vehicle_load_weight"
                                        type="number"
                                        required
                                        ref={register}
                                        placeholder="น้ำหนัก"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ผู้ดูแลรถ{' '}
                                </label>
                                <input
                                    name="car_keeper"
                                    type="text"
                                    ref={register}
                                    placeholder="ผู้ดูแลรถ"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    รายละเอียด
                                </label>
                                <textarea
                                    name="vehicle_detail"
                                    type="textfield"
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 h-24"
                                    placeholder="รายละเอียด"
                                />
                            </div>

                            <div className="w-full ">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    รูปภาพ
                                </label>
                                <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                                    <ImageUpload
                                        images={image}
                                        setImages={setImage}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex flex-wrap w-full "></div>
                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        เลขไมล์{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="mileage"
                                        type="number"
                                        required
                                        ref={register}
                                        placeholder="เลขไมล์"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        บริษัทประกัน{' '}
                                    </label>
                                    <input
                                        name="insurance_company"
                                        type="text"
                                        ref={register}
                                        placeholder="บริษัทประกัน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        ประเภทประกัน
                                    </label>
                                    <input
                                        name="insurance_type"
                                        type="text"
                                        ref={register}
                                        placeholder="ประเภทประกัน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        วันหมดอายุภาษี
                                    </label>
                                    <input
                                        name="invoice_date"
                                        type="date"
                                        defaultValue={'1970-01-01'}
                                        ref={register}
                                        placeholder="วันหมดอายุประกัน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>

                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        ยี่ห้อ
                                    </label>
                                    <input
                                        name="brand"
                                        ref={register}
                                        placeholder="ยี่ห้อ"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        รุ่น
                                    </label>
                                    <input
                                        name="generation"
                                        ref={register}
                                        placeholder="รุ่น"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        วันที่ซื้อ
                                    </label>
                                    <input
                                        name="buy_date"
                                        type="date"
                                        defaultValue={'1970-01-01'}
                                        ref={register}
                                        placeholder="วันที่ซื้อ"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        ราคา
                                    </label>
                                    <input
                                        name="price"
                                        type="number"
                                        ref={register}
                                        placeholder="ราคา"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>

                            <div className="flex flex-row ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        วันที่เริ่มทำประกัน
                                    </label>
                                    <input
                                        name="insurance_start"
                                        type="date"
                                        defaultValue={'1970-01-01'}
                                        ref={register}
                                        placeholder="วันที่เริ่มทำประกัน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        วันที่สิ้นสุดทำประกัน
                                    </label>
                                    <input
                                        name="insurance_end"
                                        type="date"
                                        defaultValue={'1970-01-01'}
                                        ref={register}
                                        placeholder="วันที่สิ้นสุดทำประกัน"
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>

                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    หมายเหตุ
                                </label>
                                <textarea
                                    name="vehicle_note"
                                    type="textfield"
                                    ref={register}
                                    className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="หมายเหตุ"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-6 px-4">
                        <Button isFullWidth colorScheme="blue" type="submit">
                            บันทึก
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <>
            <SpinnerLoading />
        </>
    );
}
