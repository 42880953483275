import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';

// components

import Sidebar from 'components/common/Sidebar/Sidebar-CRM';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views
import Customers from 'views/crm/customers/Customers';
import CustomerProfile from 'views/crm/customerProfile/CustomerProfile';
import CreateCustomer from 'views/crm/createCustomer/CreateCustomer';
import CreateContact from 'views/crm/createContact/CreateContact';
import EditCustomer from 'views/crm/editCustomer/EditCustomer';
import EditContact from 'views/crm/editContact/EditContact';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import checkRole from '../util/checkroles';

export default function CRM() {
    const location = useLocation();
    const history = useHistory();
    const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
    const system_info = useSelector((state) => state.system_info);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('CRM Layout : ACTIVE');

        dispatch(actions.systemInfoAll()).then(() => {
            dispatch(actions.meGet(storage_remember)).then(() => { });
        });
        return () => {
            dispatch(actions.meReset());
        };
    }, []);

    useEffect(() => {
        if (me && system_info && system_info.isLoading === true) {
            const isAccess = checkRole(
                me?.position?.department?.department_roles,
                location.pathname
            );
            console.log(isAccess);
            if (isAccess) {
                setIsLoading(true);
                console.log('ได้รับการอนุญาตเข้าถึง');
            } else {
                console.log('ไม่ได้รับอนุญาต');
                alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้');
                history.push('/');
            }
        }
        return () => { };
    }, [me]);
    return isLoading ? (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-gray-200">
                <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
                    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
                        <div className="rounded-t mb-0 px-4 pt-3 bg-transparent">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full max-w-full flex-grow flex-1">
                                    <h6 className="uppercase text-gray-500 text-xs font-semibold">
                                        ระบบบริหารลูกค้าสัมพันธ์
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="px-4">
                            <Switch>
                                <Route
                                    path="/crm/customers"
                                    exact
                                    component={Customers}
                                />
                                <Route
                                    path="/crm/customer/profile/:id"
                                    exact
                                    component={CustomerProfile}
                                />
                                <Route
                                    path="/crm/customer/create"
                                    exact
                                    component={CreateCustomer}
                                />
                                <Route
                                    path="/crm/customer/edit/:id"
                                    exact
                                    component={EditCustomer}
                                />
                                <Route
                                    path="/crm/:id/contact/create"
                                    exact
                                    component={CreateContact}
                                />
                                <Route
                                    path="/crm/:id/contact/edit/:cid"
                                    exact
                                    component={EditContact}
                                />
                                <Redirect from="/crm" to="/crm/customers" />
                            </Switch>
                        </div>
                        <div />
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </>
    ) : (
        <>
            <SpinnerLoading />
        </>
    );
}
