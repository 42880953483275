import axios from 'axios';
import { TYPE_ALL, TYPE_GET, TYPE_POST, TYPE_PUT, TYPE_DEL } from '../types';

export const typeAll = () => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + '/type').then((res) => {
            console.log('Request Server to Get All type');
            dispatch({ type: TYPE_ALL, payload: res.data });
        });
    };
};

export const typeGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/type/' + payload)
            .then((res) => {
                console.log('Request Server to Get an type');
                if (res.data) {
                    dispatch({ type: TYPE_GET, payload: res.data });
                } else {
                    dispatch({ type: TYPE_GET, payload: null });
                }
            });
    };
};

export const typePost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/type', payload)
            .then((res) => {
                console.log('Request Server to post an type');
                dispatch({ type: TYPE_POST, payload: null });
            });
    };
};

export const typePut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/type/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an type');
                if (res.data) dispatch({ type: TYPE_PUT, payload: res.data });
            });
    };
};

export const typeDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/type/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an type');
                dispatch({ type: TYPE_DEL, payload: null });
            });
    };
};
