import React from 'react';
import PropTypes from 'prop-types';

export default function CardEmployee({
    first_name,
    last_name,
    title,
    subtitle,
    email,
    phone_number,
    image,
}) {
    return (
        <>
            <div className="rounded  overflow-hidden shadow  bg-white py-4">
                <div className="flex justify-center object-cover h-48 w-full">
                    {image ? (
                        <>
                            <img
                                alt="..."
                                className="rounded"
                                src={image}
                                // className="shadow-md rounded-full h-auto align-middle border-none  my-2 max-w-150-px max-h-150-px"
                            />
                        </>
                    ) : (
                        <>
                            {' '}
                            {/* <i className="fa fa-user fa-5x rounded-full border-solid border-gray-700 border-2 -mt-3"></i> */}
                            <img
                                alt="..."
                                className="rounded"
                                src={require("assets/img/user.png")}
                                // className="shadow-md rounded-full h-auto align-middle border-none  my-2 max-w-150-px max-h-150-px"
                            />
                        </>
                    )}
                </div>
                <div className="text-center px-3 pb-6 pt-2">
                    <h3 className="text-black text-sm bold font-sans">
                        {first_name + ' ' + last_name}
                    </h3>
                    <p className="mt-2  text-gray-500">
                        {subtitle ? subtitle : <br />}
                    </p>
                    <p className="mt-2">{title ? title : <br />}</p>
                </div>
                <hr />
                <div className="flex  flex-wrap justify-center my-2 text-grey-dark">
                    <div className="text-center  mr-3 border-r pr-3">
                        <i className="fa fa-mail-bulk fa-1x"></i>
                        <div className="text-xs">{email ? email : <br />}</div>
                    </div>
                    <div className="text-center min-w-max-content">
                        <i className="fa fa-phone"></i>
                        <div className="text-xs">
                            {phone_number ? phone_number : <br />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

CardEmployee.defaultProps = {
    first_name: '',
    last_name: '',
    title: '',
    subtitle: '',
    email: 'อีเมล',
    phone_number: 'เบอร์โทรศัพท์',
    image: null,
};

CardEmployee.propTypes = {
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    image: PropTypes.string,
};
