import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../../../redux/actions';
import LogRepairVehicle from './LogRepairWheel';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import moment from 'moment';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';

const History = ({ repairDetail }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const repair_log = useSelector((state) => state.repair_log);
    const [repairLog, setRepairLog] = useState([]);

    useEffect(() => {
        if (
            repairDetail?.vehicle_id != '-' &&
            repairDetail?.vehicle_tail_id != '-'
        ) {
            console.log('-----vehicle_id AND vehicle_tail_id-----');
            dispatch(
                actions.repairLogAllWheelByHeadAndTail(
                    repairDetail?.vehicle_id,
                    repairDetail?.vehicle_tail_id
                )
            );
        } else if (repairDetail?.vehicle_tail_id != '-') {
            console.log('-----vehicle_tail_id-----');
            dispatch(
                actions.repairLogAllWheelByHeadAndTail(
                    null,
                    repairDetail?.vehicle_tail_id
                )
            );
        } else if (repairDetail?.vehicle_id != '-') {
            console.log('-----vehicle_id------');
            dispatch(
                actions.repairLogAllWheelByHeadAndTail(
                    repairDetail?.vehicle_id,
                    null
                )
            );
        }

        return () => {};
    }, []);

    useEffect(() => {
        setRepairLog(repair_log?.arr);
        setIsLoading(true);
        return () => {};
    }, [repair_log]);

    const textColor = (data) => {
        if (data == REPAIR_STATUS[2]) {
            return <div className="font-bold text-green-600">{data}</div>;
        } else {
            return <div className="font-bold text-red-600 ">{data}</div>;
        }
    };

    const genRepairWheel = (data) => {
        // console.log(data);
        return _.map(data, (_data) => (
            <div key={_data?.repair_wheel_log_id} className="pt-2">
                {'ล้อที่ ' +
                    _data?.index_wheel +
                    ' : ' +
                    _data?.wheel_detail +
                    ' , ' +
                    'ราคา ' +
                    _data?.price +
                    ' บาท'}
            </div>
        ));
    };

    const genLogRepair = () => {
        return _.map(repair_log.arr, (_log, index) => (
            <Tr key={_log?.repair_id}>
                <Td>{index + 1}</Td>
                <Td>{moment(_log?.createdAt).format('DD/MM/YYYY')}</Td>
                <Td>{moment(_log?.updatedAt).format('DD/MM/YYYY')}</Td>
                <Td>
                    {_log?.mileage?.mileage == null ? (
                        <div> - </div>
                    ) : (
                        <>{_log?.mileage?.mileage}</>
                    )}
                </Td>
                <Td>
                    {_log?.vehicle?.serial_number == null ? (
                        <div> - </div>
                    ) : (
                        <>{_log?.vehicle?.serial_number}</>
                    )}
                </Td>
                {/* <Td>
                    {_log?.vehicle_tail?.serial_number == null ? (
                        <div> - </div>
                    ) : (
                        <>
                            {_log?.vehicle_tail?.serial_number}
                        </>
                    )}
                </Td> */}
                <Td>{genRepairWheel(_log?.repair_wheel_logs)}</Td>
                <Td>{textColor(_log?.repair_status)}</Td>
            </Tr>
        ));
    };

    if (isLoading)
        return (
            <div className="p-4 pr-4">
                <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                    ประวัติการซ่อม
                </label>

                <div className="overflow-y-auto max-h-48">
                    <Table className="bg-white" size="sm">
                        <Thead>
                            <Tr>
                                <Th>ลำดับ</Th>
                                <Th>วันที่แจ้งซ่อม</Th>
                                <Th>วันที่ซ่อมสำเร็จ</Th>
                                <Th>เลขไมล์</Th>
                                <Th>ทะเบียนหัว</Th>

                                <Th>รายละเอียดการซ่อม</Th>
                                <Th>สถานะ</Th>
                            </Tr>
                        </Thead>
                        <Tbody className="text-center">{genLogRepair()}</Tbody>
                    </Table>
                </div>
            </div>
        );
    else {
        return <></>;
    }
};

export default History;
