import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import Select from 'react-select';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';

import TransportAssignmentLists from './components/TransportAssignmentLists';
import _ from 'lodash';
import DeliveryWeekLists from './components/DeliveryWeekLists';
import DeliveryModal from '../DeliveryModal/DeliveryModal';
import { ASSIGNMENT_STATUS } from '../../../config/constant/AssignmentStatus';
import moment from 'moment';
import CONST_CONFIG from '../../../config/constant';

import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';
import { VEHICLE_STATUS } from '../../../config/constant/VehicleStatus';

import AddHeadVechicleModal from 'components/Modal/AddHeadVechicleModal/AddHeadVechicleModal';
import AddTailVechicleModal from 'components/Modal/AddTailVechicleModal/AddTailVechicleModal';
import AddContainerModal from '../../../components/Modal/AddContainerModal/AddContainerModal';

export default function CreateDomesticDeliveryModal() {
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const vehicle = useSelector((state) => state.vehicle);
    const transport_assignment = useSelector(
        (state) => state.transport_assignment
    );
    const delivery = useSelector((state) => state.delivery);
    const route = useSelector((state) => state.route);
    const [isLoading, setIsLoading] = useState(false);
    const [transport_assignmet_data, setTransportAssignment] = useState([]);
    const [
        selectedTransportAssignmentLists,
        setSelectedTransportAssignmentLists,
    ] = useState({});
    const [defaultDate, setDefaultDate] = useState(new Date());
    const [start_reservation, setStartReservation] = useState(new Date());
    const [end_reservation, setEndReservation] = useState(
        moment(new Date()).add(1, 'days').toDate()
    );

    const [assignment_type, setAssignmentType] = useState('');
    const [vehicle_data, setVehicleData] = useState([]);
    const [vehicle_tail_data, setVehicleTailData] = useState([]);
    const [vehicle_container_data, setVehicleContainerData] = useState([]);
    const [route_data, setRouteData] = useState([]);
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [selectedConsigneeId, setSelectedConsigneeId] = useState('');
    const [selectedAssignment, setSelectedAssignment] = useState()
    const [selectedShipperId, setSelectedShipperId] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState();
    const [selectedVehicleContainer, setSelectedVehicleContainer] = useState();
    const [selectedVehicleTail, setSelectedVehicleTail] = useState();
    const [selectedRoute, setSelectedRoute] = useState();

    //น้ำหนักของรายการจัดส่งสินค้า
    const [loadWeight, setLoadWeight] = useState(0);
    //น้ำหนักที่รองรับได้
    const [availableLoadWeight, setAvailableLoadWeight] = useState(0);
    const [selectedDeliveryId, setSelectedDeliveryId] = useState();
    const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState();

    const [addHeadVechicleModalOpen, setAddHeadVechicleModalOpen] = useState(false);
    const [addTailVechicleModalOpen, setAddTailVechicleModalOpen] = useState(false);
    const [addContainerModalOpen, setAddContainerModalOpen] = useState(false);
    const [isEditorMode, setIsEditorMode] = useState(false);

    useEffect(() => {
        dispatch(
            actions.transportAssignmentGetByStatus(ASSIGNMENT_STATUS[4])
        ).then(() => {
            dispatch(actions.vehicleAll()).then(() => {
                dispatch(actions.employeeAll()).then(() => {
                    dispatch(actions.routeAll())
                })
            });
        });
        return () => { };
    }, []);

    useEffect(() => {
        setTransportAssignment(filterImportTransportAssignmentData());
        vehicle.arr && setVehicleData(filterVehicleData());
        vehicle.arr && setVehicleTailData(filterVehicleTailData());
        vehicle.arr && setVehicleContainerData(filterVehicleContainerData());
        route?.arr && setRouteData(filterRouteData());
        setIsLoading(true);
        return () => { };
    }, [transport_assignment, vehicle, route]);

    useEffect(() => {
        if ('') {
            dispatch(actions.deliveryAll());
        } else {
            dispatch(actions.deliveryAll());
        }

        return () => { };
    }, [assignment_type]);

    const onSubmit = (data, e) => {
        console.log(selectedVehicleContainer);
        if (_.size(selectedTransportAssignmentLists)) {

            data.assignment_type = assignment_type;
            data.consignee_id = selectedConsigneeId;
            data.shipper_id = selectedShipperId;
            data.delivery_assignments = selectedTransportAssignmentLists;
            data.delivery_vehicles = [];
            data.start_reservation = start_reservation;
            data.end_reservation = end_reservation;

            if (selectedVehicle) {
                data.vehicle_id = selectedVehicle?.value;
                data.delivery_vehicles.push({ vehicle_id: data.vehicle_id });

            }
            if (selectedVehicleTail) {
                data.vehicle_tail_id = selectedVehicleTail?.value;
                data.delivery_vehicles.push({ vehicle_id: data.vehicle_tail_id });
            }
            if (selectedVehicleContainer) {
                data.vehicle_container_id = selectedVehicleContainer?.value;
                data.delivery_vehicles.push({
                    vehicle_id: data.vehicle_container_id,
                });
            }
            if (selectedRoute) {
                data.route_id = selectedRoute?.value;
            }
            // console.log(selectedConsigneeId);
            dispatch(actions.deliveryPost(data)).then(() => {
                alert('สำเร็จ');
                window.location.reload();
            });
        } else {
            alert('กรุณาเลือกรายการจัดส่งสินค้า');
            return;
        }
    };



    const filterTransportAssignment = (payload) => {
        dispatch(actions.transportAssignmentGetByType(payload));
    };

    const clearAllList = () => {
        dispatch(actions.transportAssignmentGetByStatus(ASSIGNMENT_STATUS[4]));
    };


    const handleModalDeliveryOpen = (deliveryId) => {
        setSelectedDeliveryId(deliveryId);
        setIsDeliveryModalOpen(true);
    };

    const assignmentModalCloseHandle = () => {
        setIsAssignmentModalOpen(false);
        setSelectedAssignment();
    };

    const deliveryModalCloseHandle = () => {
        setIsDeliveryModalOpen(false);
        setSelectedDeliveryId('');
    };

    const handleModalAssignmentOpen = (assignment) => {
        setSelectedAssignment(assignment);
        setIsAssignmentModalOpen(true);
    };


    const handleFindLoadWeightVehicle = (vehicle_id) => {
        const result = _.find(
            vehicle?.arr,
            (vehicle) => vehicle.vehicle_id === vehicle_id
        );
        // console.log(result);
        setAvailableLoadWeight(result?.vehicle_load_weight);
    };

    const createLoadWeightSuggestion = (loadWeight, availableLoadWeight) => {
        const diff = loadWeight - availableLoadWeight;
        let result = (diff / availableLoadWeight) * 100;
        //แนวโน้มว่าไปด้านบวหรือลบ
        let message = '';
        if (loadWeight === 0 && availableLoadWeight === 0) {
            message = '';
        } else if (result > 0) {
            message =
                'น้ำหนักสินค้ามากกว่าน้ำหนักที่รองรับได้อยู่ ' +
                result.toFixed(2) +
                ' %';
        } else if (result < 0) {
            message =
                'น้ำหนักสินค้าน้อยกว่าน้ำหนักที่รองรับได้อยู่ ' +
                (result * -1).toFixed(2) +
                ' %';
        } else if (result === 0) {
            message = 'น้ำหนักสินค้าเท่ากับน้ำหนักที่รองรับได้';
        }
        return {
            trend: result,
            message: message,
        };
    };

    const filterImportTransportAssignmentData = () => {
        const result = _.filter(
            transport_assignment?.arr,
            (_transport_assignment) => {
                return (
                    _transport_assignment?.assignment_type ===
                    CONST_CONFIG.WORD_TRANSPORT_SERVICE.domestic
                );
            }
        );
        return result;
    };

    //แปลงข้อมูล container ให้พร้อมใช้งาน

    const filterVehicleData = () => {
        const vehicleResult = _.filter(vehicle.arr, (_vehicle) => {
            return (
                _vehicle?.category_id === CATEGORY_VEHICLE[0] &&
                _vehicle?.vehicle_status === VEHICLE_STATUS[1]
            );
        });

        const result = _.map(vehicleResult, (vehicle) => {
            return {
                value: vehicle?.vehicle_id,
                label:
                    vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle?.serial_number,
            };
        });
        return result;
    };

    const filterVehicleTailData = () => {
        const tailResult = _.filter(vehicle.arr, (_vehicle) => {
            return _vehicle?.category_id === CATEGORY_VEHICLE[1];
        });

        const result = _.map(tailResult, (tail) => {
            return {
                value: tail?.vehicle_id,
                label:
                    tail?.category_vehicle?.category_name +
                    '/' +
                    tail?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    tail?.serial_number,
            };
        });

        return result;
    };

    const filterVehicleContainerData = () => {
        const containerResult = _.filter(vehicle.arr, (_vehicle) => {
            return _vehicle?.category_id === CATEGORY_VEHICLE[2];
        });

        const result = _.map(containerResult, (container) => {
            return {
                value: container?.vehicle_id,
                label:
                    container?.category_vehicle?.category_name +
                    '/' +
                    container?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    container?.serial_number,
            };
        });

        return result;
    };

    const filterRouteData = () => {
        const result = _.map(route?.arr, (_route) => {
            return {
                value: _route?.route_id,
                label: _route?.title
            };
        });
        return result;
    }

    //1
    const handleChangeSelectedVehicle = (option) => {
        setSelectedVehicle(option);
    };

    //2
    const handleChangeSelectedTail = (option) => {
        setSelectedVehicleTail(option);
    };

    //3
    const handleChangeSelectedContainer = (option) => {
        setSelectedVehicleContainer(option);
    };

    const handleChangeSelectedRoute = (option) => {
        setSelectedRoute(option);
    }

    const handleAddHeadVechicle = () => {
        setAddHeadVechicleModalOpen(!addHeadVechicleModalOpen);
        dispatch(actions.sub_category_vehicleAllByCategory(CATEGORY_VEHICLE[0]));

    }

    const handleAddTailVechicle = () => {
        setAddTailVechicleModalOpen(!addTailVechicleModalOpen);
        dispatch(actions.sub_category_vehicleAllByCategory(CATEGORY_VEHICLE[1]));

    }

    const handleAddContainer = () => {
        setAddContainerModalOpen(!addContainerModalOpen);
        dispatch(actions.sub_category_vehicleAllByCategory(CATEGORY_VEHICLE[2]));

    }

    return isLoading ? (
        <div>
            <DeliveryModal
                allDelivery={delivery?.arr}
                closeHandle={deliveryModalCloseHandle}
                allAssignments={transport_assignment?.arr}
                deliveryId={selectedDeliveryId}
                openStatus={isDeliveryModalOpen}
            />
            <AddHeadVechicleModal
                openStatus={addHeadVechicleModalOpen}
                closeHandle={handleAddHeadVechicle}
            />
            <AddTailVechicleModal
                openStatus={addTailVechicleModalOpen}
                closeHandle={handleAddTailVechicle}
            />
            <AddContainerModal
                openStatus={addContainerModalOpen}
                closeHandle={handleAddContainer}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative flex flex-wrap justify-center">
                    <div className="w-full mt-4 ">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                            รายการจัดส่งสินค้า{' '}
                            <label className="text-red-600 text-xs"> *</label>
                        </label>
                        {_.size(transport_assignmet_data) ? (
                            <div className="overflow-x-auto  overflow-y-auto  max-h-screen rounded border-black border-2 border-opacity-10 bg-white">
                                <TransportAssignmentLists
                                    loadWeight={loadWeight}
                                    setLoadWeight={setLoadWeight}
                                    setSelectedConsigneeId={
                                        setSelectedConsigneeId
                                    }
                                    setSelectedShipperId={setSelectedShipperId}
                                    filterTransportAssignment={
                                        filterTransportAssignment
                                    }
                                    selectedTransportAssignmentLists={
                                        selectedTransportAssignmentLists
                                    }
                                    setSelectedTransportAssignmentLists={
                                        setSelectedTransportAssignmentLists
                                    }
                                    transport_assignment={
                                        transport_assignmet_data
                                    }
                                    setAssignmentType={setAssignmentType}
                                    clearAllList={clearAllList}
                                    setDefaultDate={setDefaultDate}
                                    handleAssignmentModal={
                                        handleModalAssignmentOpen
                                    }
                                    setStartReservation={setStartReservation}
                                    setEndReservation={setEndReservation}
                                    setSelectedVehicleContainer={setSelectedVehicleContainer}

                                />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                <div>ไม่มีข้อมูล</div>
                            </div>
                        )}
                    </div>
                    <div className="w-full mt-4 px-1 ">
                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                            รายการจอง
                        </label>
                        <div className="relative  flex flex-col p-2  min-h-70-screen max-h-screen  break-words bg-white   rounded  border-black border-2 border-opacity-10">
                            <div className="relative w-full mb-3 px-2">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    เริ่มจอง{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    value={start_reservation}
                                    onChange={(date) => {
                                        setStartReservation(date[0]);
                                    }}
                                />
                            </div>
                            <div className="relative w-full mb-3 px-2">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    สิ้นสุดการจอง{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    value={end_reservation}
                                    onChange={(date) => {
                                        setEndReservation(date[0]);
                                    }}
                                />
                            </div>
                            <div className="relative w-full mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    หัวรถ{' '}
                                </label>
                                <Select
                                    name="vehicle_id"
                                    value={selectedVehicle}
                                    placeholder={'กรุณาเลือกข้อมูลหัวรถ'}
                                    onChange={(option) => {
                                        handleChangeSelectedVehicle(option);
                                        handleFindLoadWeightVehicle(
                                            option.value
                                        );
                                    }}
                                    options={vehicle_data}
                                />
                            </div>

                            <div className="relative w-full mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    หางรถ{' '}
                                </label>
                                <div className="flex flex-wrap">
                                    <div className="w-11/12">
                                        <Select
                                            name="vehicle_tail_id"
                                            value={selectedVehicleTail}
                                            placeholder={'กรุณาเลือกข้อมูลหางรถ'}
                                            onChange={(option) => {
                                                handleChangeSelectedTail(option);
                                            }}
                                            options={vehicle_tail_data}
                                        />
                                    </div>
                                    <div className="w-1/12 p-1">
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => handleAddTailVechicle()}
                                        >เพิ่ม</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="relative w-full mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ตู้คอนเทนเนอร์{' '}
                                </label>
                                <div className="flex flex-wrap">
                                    <div className="w-11/12">
                                        <Select
                                            name="vehicle_container_id"
                                            value={selectedVehicleContainer}
                                            placeholder={
                                                'กรุณาเลือกข้อมูลตู้คอนเทนเนอร์'
                                            }
                                            onChange={handleChangeSelectedContainer}
                                            options={vehicle_container_data}
                                        />
                                    </div>
                                    <div className="w-1/12 p-1">
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => handleAddContainer()}
                                        >เพิ่ม</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="relative w-full mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    เส้นทางการเดินรถ{' '}
                                </label>
                                <div className="flex flex-wrap">
                                    <div className="w-full">
                                        <Select
                                            name="route_id"
                                            value={selectedRoute}
                                            placeholder={
                                                'กรุณาเลือกข้อมูลเส้นทางการเดินรถ'
                                            }
                                            onChange={handleChangeSelectedRoute}
                                            options={route_data}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="relative w-full mb-3 px-2">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    หมายเลขซีล
                                </label>
                                <input
                                    name="seal_no"
                                    placeholder="หมายเลขซีล"
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                            </div>

                            <div className="relative w-full mb-3 px-2">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    หมายเหตุ
                                </label>
                                <textarea
                                    name="delivery_note"
                                    type="textfield"
                                    rows={2}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="หมายเหตุ"
                                />
                            </div>
                            <div className="relative mb-3 p-2 ">
                                {!(
                                    loadWeight === 0 ||
                                    availableLoadWeight === 0
                                ) && (
                                        <div className="p-2 rounded border-black border-2 border-opacity-10">
                                            <div className="bg-green-100 px-4 rounded text-xs font-bold ">
                                                คำแนะนำ
                                            </div>
                                            <div className="flex flex-wrap justify-center  my-1">
                                                <div className="w-1/2 text-center">
                                                    <div className="text-xs font-semibold">
                                                        น้ำหนักสินค้าทั้งหมด
                                                    </div>
                                                    {loadWeight + ' กิโลกรัม'}{' '}
                                                </div>
                                                <div className="w-1/2 text-center">
                                                    <div className="text-xs font-semibold">
                                                        น้ำหนักที่รองรับได้
                                                    </div>{' '}
                                                    {availableLoadWeight + ' กิโลกรัม'}
                                                </div>
                                            </div>

                                            <div className="text-center cursor-pointer">
                                                {createLoadWeightSuggestion(
                                                    loadWeight,
                                                    availableLoadWeight
                                                )?.trend <= 0 ? (
                                                    <div className=" text-semibold text-green-500 hover:text-green-700">
                                                        {
                                                            createLoadWeightSuggestion(
                                                                loadWeight,
                                                                availableLoadWeight
                                                            )?.message
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className=" text-semibold text-red-500 hover:text-red-700">
                                                        {
                                                            createLoadWeightSuggestion(
                                                                loadWeight,
                                                                availableLoadWeight
                                                            )?.message
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex mt-4 justify-end">
                    <div>
                        <Button type="submit" colorScheme="blue">
                            บันทึก
                        </Button>
                    </div>
                </div>
            </form>
            {/* <div className="p-4">
                <DeliveryWeekLists
                    defaultDate={defaultDate}
                    delivery={delivery?.arr}
                    handleDeliveryModal={handleModalDeliveryOpen}
                />
            </div> */}
        </div>
    ) : (
        <SpinnerLoading />
    );
}
