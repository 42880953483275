import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import RepairTypeLists from './RepairTypeGenerator';
import { TYPE_REPAIR } from 'config/constant/TypeRepair';

const RepairType = ({ repairDetail, price, setPrice }) => {

    return (
        <div className="relative w-full mb-3 px-4">
            <label className="flex flex-wrap uppercase text-gray-700 text-sm font-bold mb-2">
                <div>ประเภทการแจ้งซ่อม :</div>{' '}
                <div className="ml-2 text-blue-700">{` ${repairDetail?.typeData?.detail || ''}`}</div>
            </label>

            <div>
                <RepairTypeLists
                    repairDetail={repairDetail?.problems}
                    type={repairDetail?.type}
                    price={price}
                    setPrice={setPrice}
                />
            </div>
        </div>
    );
};

export default RepairType;
