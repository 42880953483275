import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import TypeLists from './components/TypeLists';

export default function TypeManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const type = useSelector((state) => state.type);
    const repair_type = useSelector((state) => state.repair_type);

    useEffect(() => {
        dispatch(actions.typeAll());
        dispatch(actions.repairTypeAll());
        setIsLoading(true);
        return () => {};
    }, []);

    return isLoading ? (
        <siv>
            <h2 className="text-gray-800 text-xl font-semibold">
                จัดการประเภทการซ่อม
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div className="">
                    <Link to={'/cmms/master/type/create'}>
                        <Button colorScheme="blue">เพิ่ม</Button>
                    </Link>
                </div>
            </div>
            <div className="p-4">
                {_.size(type?.arr) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        <TypeLists type={type?.arr} repair_type={repair_type?.arr} />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        </siv>
    ) : (
        <SpinnerLoading />
    );
}
