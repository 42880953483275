import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import moment from 'moment';
import { MonthDataVehiclePrintPDF } from '../../report/MonthDataVehiclePrintPDF';

export default function RepairVehicleLists({ month, company }) {
    const checkLogStatus = (repair_logs) => {
        const result = [];
        _.map(repair_logs, (log) => {
            if (log?.repair_status === REPAIR_STATUS[2]) {
                result.push(log);
            }
        });
        return result;
    };

    const genTd = () => {
        return _.map(month, (data, index) => (
            <Tr key={data?.month_id}>
                <Td>{index + 1}</Td>
                <Td>
                    <Link to={`/cmms/history/repair/vehicle/${data?.month_id}`}>
                        <div>
                            <b>
                                {moment(data.month).format('MMMM')}/
                                {moment(data.month).format('YYYY')}
                            </b>
                        </div>
                        <div>
                            <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center">
                                รายละเอียด
                            </span>
                        </div>
                    </Link>
                </Td>
                <Td>
                    <div className="">
                        <Button
                            size="xs"
                            onClick={() => {
                                MonthDataVehiclePrintPDF(data, company);
                            }}
                        >
                            <i className="fas fa-print cursor-pointer mr-1"></i>{' '}
                            พิมพ์
                        </Button>
                    </div>
                </Td>
                <Td>{_.size(checkLogStatus(data?.repair_logs)) + ' รายการ'}</Td>
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>เดือน</Th>
                        <Th>ใบแจ้งซ่อม</Th>
                        <Th>รายการแจ้งซ่อมทั้งหมด</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
