import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

export default function RepairVehicleQuarterLists({ repair_data }) {

    const [quarter, setQuarter] = useState([]);

    const filterQuarter = () => {
        _.map(repair_data, (data) => {
            console.log("Quarter", moment(data.month).quarter())
            console.log("data", data);
            const checkIndex = moment(data.month).quarter()
            console.log(checkIndex);

            const result = _.find(quarter, function (_quarter) { return _quarter?.quarter === checkIndex; });

            if (!result) {
                const value = {
                    "quarter": checkIndex, "month_id": data.month_id
                }
                quarter.push(value)
            } else {
                console.log("result", result);
                const month_id = result?.month_id;
                const value = {
                    "quarter": checkIndex, "month_id": [month_id, data.month_id]
                }
                quarter[checkIndex] = (value)
            }

        })

        console.log("quarter", quarter);
    }

    const genTd = () => {
        return _.map("4", (data, index) => (
            <Tr>
                <Td>{filterQuarter()}</Td>
            </Tr>
        ))
    }

    // console.log("DATA", repair_data);
    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>เดือน</Th>
                        <Th>ใบแจ้งซ่อมรถ</Th>
                        <Th>รายการแจ้งซ่อมทั้งหมด</Th>
                    </Tr>
                </Thead>
                <Tbody >{genTd()}</Tbody>
            </Table>
        </div>
    )
}