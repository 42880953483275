import { Button } from "@chakra-ui/react";
import React from "react";
import ImageUploading from "react-images-uploading";
import _ from "lodash";
import PropTypes from "prop-types";

export default function ImageUpload(props) {
  const { images, setImages, preview_size } = props;
  const onChange = (imageList, addUpdateIndex) => {
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={1}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <div>
          <div className="flex justify-center">
            {imageList.map((image, index) => (
              <div key={index} className="my-4">
                <div className="flex justify-end text-red-500">
                  <i
                    className="fas fa-window-close cursor-pointer"
                    aria-hidden="true"
                    onClick={() => onImageRemove(index)}
                  ></i>
                </div>

                <img src={image.data_url} alt="" width={preview_size} />
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            {_.size(imageList) ? (
              <>
                {" "}
                <Button
                  colorScheme="teal"
                  variant="outline"
                  style={isDragging ? { color: "red" } : null}
                  onClick={() => onImageUpdate(0)}
                  {...dragProps}
                >
                  แก้ไขรูปภาพ
                </Button>
              </>
            ) : (
              <>
                {" "}
                <Button
                  colorScheme="teal"
                  variant="outline"
                  style={isDragging ? { color: "red" } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  อัพโหลดรูปภาพ
                </Button>
              </>
            )}
          </div>

          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
        </div>
      )}
    </ImageUploading>
  );
}

ImageUpload.defaultProps = {
  preview_size: "250",
};

ImageUpload.propTypes = {
  preview_size: PropTypes.string,
};
