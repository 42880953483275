import React from 'react';
import { Badge } from '@chakra-ui/react';
import _ from 'lodash';
import { ASSIGNMENT_STATUS } from '../../../config/constant/AssignmentStatus';

export default function CardAsssignmentFlatInfo({
    eachAssignment,
    allSystemAssignments,
}) {
    const assignmentId = eachAssignment.assignment_id;

    const selectedAssignmentFullInfo = _.find(
        allSystemAssignments,
        (assignment) => assignment.assignment_id === assignmentId
    );

    return (
        <div className="border rounded-lg px-4 py-2 hover:shadow-md m-1">
            {/* <h5 className='block uppercase text-gray-700 text-xs font-bold mb-1'>
            เลขการจัดส่ง
          </h5> */}
            {(eachAssignment.assignment?.invoice_number) ? (
                <label className="text-sm font-semibold flex">
                    เลขที่ใบแจ้งหนี้ {eachAssignment.assignment?.invoice_number}{' '}
                    <div className="ml-2">
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[0] && (
                                <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[1] && (
                                <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[2] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[3] && (
                                <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[4] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                    </div>
                </label>
            ) : (
                <label className="text-sm font-semibold flex">
                    เลขที่ใบแจ้งหนี้ -
                    <div className="ml-2">
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[0] && (
                                <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[1] && (
                                <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[2] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[3] && (
                                <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                        {eachAssignment.assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[4] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {eachAssignment.assignment?.assignment_status}
                                </div>
                            )}
                    </div>
                </label>
            )}


            <div className="flex gap-2 mt-2">
                <div>
                    <h4 className="text-xs mb-0 font-bold ">
                        {selectedAssignmentFullInfo?.shipper?.customer_company}
                    </h4>{' '}
                    <h4 className="text-xs mb-0">
                        {eachAssignment.assignment?.shipper_address}{' '}
                    </h4>
                    <p className="text-xs mt-0">
                        {eachAssignment.assignment?.shipper_postcode}{' '}
                    </p>
                </div>
                <div>
                    <h3 className="my-5 inset-0 flex items-center justify-center">
                        <i className="fas fa-arrow-right" />
                    </h3>
                </div>
                <div>
                    <h4 className="text-xs mb-0 font-bold ">
                        {
                            selectedAssignmentFullInfo?.consignee
                                ?.customer_company
                        }
                    </h4>{' '}
                    <h4 className="text-xs  mb-0">
                        {eachAssignment.assignment?.consignee_address}{' '}
                    </h4>
                    <p className="text-xs mt-0">
                        {eachAssignment.assignment?.consignee_postcode}{' '}
                    </p>
                </div>
            </div>

            <br />
            <div className="flex">
                <div className="w-6/12">
                    <p className="text-gray-500 text-sm">
                        <i className="fas fa-weight  mr-2" />
                        {eachAssignment.assignment?.load_weight} กิโลกรัม
                    </p>
                </div>

                <div className="w-6/12">
                    <p className="text-gray-500 text-sm">
                        <i className="fas fa-box-open mr-2" />
                        {eachAssignment.assignment?.number_of_packages} ชิ้น
                    </p>
                </div>
            </div>
        </div>
    );
}
