import moment from 'moment';
import _ from 'lodash';

const genetateCarVehicleList = (
    deliveryVehicles,
    showSubVehicalType,
    showDescriptionWord
) => {
    const headWord = _.find(deliveryVehicles, (eachVechicle) =>
        _.includes(
            ['หัวรถ', 'หัว', 'Head', 'head'],
            eachVechicle?.vehicle?.category_vehicle?.category_name
        )
    );

    const tailWord = _.find(deliveryVehicles, (eachVechicle) =>
        _.includes(
            ['หางรถ', 'หาง', 'ท้ายรถ', 'ท้าย', 'Tail', 'tail'],
            eachVechicle?.vehicle?.category_vehicle?.category_name
        )
    );

    const containerWord = _.find(deliveryVehicles, (eachVechicle) =>
        _.includes(
            [
                'ตู้คอนเทนเนอร์',
                'คอนเทนเนอ',
                'คอนเทนเนอร์',
                'ตู้',
                'container',
                'Container',
            ],
            eachVechicle?.vehicle?.category_vehicle?.category_name
        )
    );

    const headCategory = _.find(
        deliveryVehicles,
        (eachVechicle) =>
            eachVechicle?.vehicle?.category_vehicle?.category_id == 1
    );

    const tailCategory = _.find(
        deliveryVehicles,
        (eachVechicle) =>
            eachVechicle?.vehicle?.category_vehicle?.category_id == 2
    );

    const containerCategory = _.find(
        deliveryVehicles,
        (eachVechicle) =>
            eachVechicle?.vehicle?.category_vehicle?.category_id == 3
    );

    const head = headCategory || headWord;

    const tail = tailCategory || tailWord;
    const container = containerCategory || containerWord;

    if (head && tail && container) {
        const vehicleList = [head, tail, container];
        return _.map(
            vehicleList,
            (eachVechicle) =>
                ' ' +
                eachVechicle.vehicle?.category_vehicle?.category_name +
                ' ' +
                (showSubVehicalType
                    ? eachVechicle?.vehicle?.sub_category_vehicle
                          ?.sub_category_name + ' '
                    : ' ') +
                (showDescriptionWord ? '| เลขทะเบียน ' : '') +
                eachVechicle?.vehicle?.serial_number
        );
    } else {
        return _.map(
            deliveryVehicles,
            (eachVechicle) =>
                ' ' +
                eachVechicle.vehicle?.category_vehicle?.category_name +
                ' ' +
                (showSubVehicalType
                    ? eachVechicle?.vehicle?.sub_category_vehicle
                          ?.sub_category_name + ' '
                    : ' ') +
                (showDescriptionWord ? '| เลขทะเบียน ' : '') +
                eachVechicle?.vehicle?.serial_number
        );
    }
};

export const DeliveryToEvent = (
    deliverys,
    showDeliveryType = true,
    showSubVehicalType = true,
    showDescriptionWord = false
) => {
    return _.map(deliverys, (delivery, index) => {
        const returnedObject = {
            type: delivery.assignment_type,
            id: delivery.delivery_id,
            title: ` ${
                showDeliveryType
                    ? delivery.assignment_type + ' |'
                    : delivery.assignment_type === 'ส่งออก'
                    ? '▶️'
                    : '◀️'
            } 
            ${delivery?.consignee?.customer_company}
            ${delivery?.delivery_status}
            ${genetateCarVehicleList(
                delivery?.delivery_vehicles,
                showSubVehicalType,
                showDescriptionWord
            )}`,
            allDay: true,
            status: delivery.delivery_status,
            start: moment(new Date(delivery.start_reservation)).startOf('day'),
            end: moment(new Date(delivery.end_reservation)).endOf('day'),
        };
        return returnedObject;
    });
};
