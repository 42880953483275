import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import DeliveryListContainer from './components/DomesicDeliveryListContainer';
import CONST_CONFIG from '../../../config/constant';

export default function ImportDeliveryManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const delivery = useSelector((state) => state.delivery);
    const [delivery_data, setDelivery] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.deliveryGetProcessingStatus()).then(() => {
            dispatch(actions.companyAll()).then(() => {
                dispatch(actions.transportAssignmentAll()).then(() => {
                    setIsLoading(true);
                });
            });
        });

        return () => {};
    }, []);

    useEffect(() => {
        setDelivery(delivery?.arr);
        setIsLoading(true);
        return () => {};
    }, [delivery]);

    const handleInProgressDelivery = (delivery_id) => {
        const confirm = window.confirm('ยืนยันการเริ่มดำเนินการรายการเดินรถ');
        const payload = {
            delivery_status: CONST_CONFIG.DELIVERY_STATUS[1],
        };
        if (confirm)
            dispatch(
                actions.deliveryPut(delivery_id, payload)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.deliveryGetProcessingStatus());
            });
    };
    const handleSuccessDelivery = (delivery) => {
        const confirm = window.confirm('ยืนยันรายการเดินรถ');
        delivery.delivery_status = CONST_CONFIG.DELIVERY_STATUS[2];
        // const payload = {
        //     delivery_status: CONST_CONFIG.DELIVERY_STATUS[2],
        // };
        if (confirm)
            dispatch(
                actions.deliveryPutStatusSuccessAndCancle(delivery.delivery_id, delivery)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.deliveryGetProcessingStatus());
            });
    };

    const handleDeleteDelivery = (delivery) => {
        const confirm = window.confirm('ยืนยันการยกเลิกรายการเดินรถ');
        delivery.delivery_status = CONST_CONFIG.DELIVERY_STATUS[3];
        // const payload = {
        //     delivery_status: CONST_CONFIG.DELIVERY_STATUS[3],
        // };
        if (confirm)
            dispatch(
                actions.deliveryPutStatusSuccessAndCancle(delivery.delivery_id, delivery)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.deliveryGetProcessingStatus());
            });
    };

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    จัดรายการเดินรถ
                </h2>
            </div>
            <div className="md:flex py-4 justify-between overflow-x-auto">
                <div>
                </div>
            </div>

            <div className="py-4 ">
                {_.size(delivery_data) ? (
                    <div>
                        <DeliveryListContainer
                            handleInProgressDelivery={handleInProgressDelivery}
                            handleSuccessDelivery={handleSuccessDelivery}
                            handleDeleteDelivery={handleDeleteDelivery}
                            delivery={delivery_data}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
