import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import _ from 'lodash';
import axios from 'axios';

export default function CreatePath() {
    const { register, handleSubmit, errors, reset } = useForm();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        return () => { };
    }, []);

    const onSubmit = (data) => {

        if (data?.origin_expected && data?.destination_expected) {
            const payload = {
                origins: [data?.origin_expected],
                destinations: [data?.destination_expected],
            }
            //ค้นหาเส้นทาง
            axios
                .post(
                    process.env.REACT_APP_API_URL + '/other/find-distance',
                    payload
                )
                .then((res) => {
                    if (res.data.status === 'OK') {
                        data.origin_actual = res.data.origin_addresses[0];
                        data.destination_actual = res.data.destination_addresses[0];
                        data.duration = res.data.rows[0].elements[0].duration.text;
                        data.distance = res.data.rows[0].elements[0].distance.text;
                        data.route_id = id;

                        const confirm = window.confirm(
                            'ยืนยันการเลือกตำแหน่งจาก ' +
                            data.origin_actual +
                            ' ไปยัง ' +
                            data.destination_actual
                        );
                        if (confirm) {
                            dispatch(actions.pathPost(data)).then(() => {
                                history.goBack();
                            })
                        }
                    }
                })
        }
    }

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    เพิ่มเส้นทาง
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-4 justify-start">
                    <Button
                        onClick={() => history.goBack()}
                        variant="outline"
                    >กลับ
                    </Button>
                </div>
                <div className="p-4">
                    <div className="flex content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3 flex flex-wrap">
                                <div className="flex flex-row w-full">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            ลำดับ
                                            <label className="text-red-600 text-xs">
                                                {' '}
                                                *
                                            </label>
                                        </label>
                                        <input
                                            name="index"
                                            ref={register}
                                            placeholder="ลำดับ"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            ราคาน้ำมัน
                                        </label>
                                        <input
                                            name="gasoline"
                                            ref={register}
                                            placeholder="น้ำมัน/km"
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </div>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        เริ่มต้น
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                        <input
                                            name="origin_expected"
                                            ref={register}
                                            placeholder="เริ่มต้น"
                                            className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </label>
                                </div>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ปลายทาง
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                        <input
                                            name="destination_expected"
                                            ref={register}
                                            placeholder="ปลายทาง"
                                            className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </label>
                                </div>
                            </div>
                            <div className="text-center mt-6 ">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    )
}