import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';

export default function EditCategory() {
    const { register, handleSubmit, errors, reset } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const category = useSelector((state) => state.category_vehicle);

    useEffect(() => {
        dispatch(actions.category_vehicleGet(id)).then(() => {
            setIsLoading(true);
        });
        return () => {};
    }, []);

    const onSubmit = (data, e) => {
        dispatch(actions.category_vehiclePut(id, data));
        history.push('/fms/category/detail/' + id);
    };

    const isDisable = id == 1 || id == 2 || id == 3;
    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    แก้ไขหมวดหมู่รถ ตู้คอนเทนเนอร์
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4 justify-between">
                    <div>
                        <Link to="/fms/categories">
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                    {/* <div>
                        <Button type="submit" colorScheme="blue">
                            บันทึก
                        </Button>
                    </div> */}
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    ชื่อหมวดหมู่{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="category_name"
                                    required
                                    ref={register}
                                    placeholder="ชื่อหมวดหมู่"
                                    defaultValue={category.category_name}
                                    disabled={isDisable}
                                    className={`px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 ${
                                        isDisable
                                            ? 'cursor-not-allowed bg-gray-200 text-gray-200'
                                            : ''
                                    }`}
                                ></input>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    รายละเอียด
                                </label>
                                <textarea
                                    name="category_detail"
                                    required
                                    ref={register}
                                    placeholder="รายละเอียดหมวดหมู่"
                                    defaultValue={category.category_detail}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></textarea>
                                <div className="text-center mt-6">
                                    <Button
                                        isFullWidth
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <>
            <SpinnerLoading />
        </>
    );
}
