import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

export default function CardVehicle({
    vehicle_image,
    category_name,
    sub_category_name,
    serial_number,
    vehicle_load_weight,
    vehicle_detail,
    vehicle_note,
    vehicle_status,
    mileage,
    brand,
    generation,
    buy_date,
    price,
    insurance_start,
    insurance_end,
    car_keeper,
    insurance_company,
    insurance_type,
    invoice_date,
}) {
    console.log(insurance_start);
    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-xl mt-2">
                <div className=" flex flex-wrap p-4">
                    <div className="w-full lg:w-4/12">
                        <div className="w-full px-4 flex justify-center mt-16">
                            {vehicle_image ? (
                                <>
                                    {' '}
                                    <img alt="..." src={vehicle_image} />
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <img
                                        alt="..."
                                        src={require('assets/img/truck.png')}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 ">
                        <div className="ml-4 mt-2 mb-5 ml-8 mr-2">
                            <div className="text-2xl leading-normal mt-0 mb-2 text-gray-900 font-bold uppercase">
                                {'ป้ายทะเบียนรถ : ' + serial_number}
                            </div>
                            <div className="mb-2 text-xl text-gray-700 ">
                                {'ประเภท : ' +
                                    category_name +
                                    ' , ' +
                                    sub_category_name}
                            </div>
                            <div className="mb-2 text-xl text-gray-700 ">
                                {'ผู้ดูแล : ' +
                                    `${car_keeper ? car_keeper : 'ไม่ระบุ'}`}
                            </div>

                            <div className="mb-2 mt-8 text-gray-700 ">
                                {'รายละเอียด : ' +
                                    `${
                                        vehicle_detail
                                            ? vehicle_detail
                                            : 'ไม่ระบุ'
                                    }`}
                            </div>

                            <div className="w-full flex">
                                <div className="w-full  lg:w-6/12">
                                    <div className="mb-2 text-gray-700 ">
                                        {'น้ำหนักที่รองรับ : ' +
                                            vehicle_load_weight}
                                    </div>
                                    <div className="mb-2 text-gray-700 ">
                                        {'ยี่ห้อ : ' +
                                            `${brand ? brand : 'ไม่ระบุ'}`}
                                    </div>

                                    {buy_date === '-' ||
                                    buy_date === '1970-01-01T00:00:00.000Z' ||
                                    !buy_date ? (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่ซื้อ : ไม่ระบุ'}
                                        </div>
                                    ) : (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่ซื้อ : ' +
                                                new Date(
                                                    buy_date
                                                ).toLocaleDateString('th-TH')}
                                        </div>
                                    )}
                                </div>
                                <div className="w-full  lg:w-6/12">
                                    <div className="mb-2 text-gray-700 ">
                                        {'เลขไมล์ : ' +
                                            `${mileage ? mileage : 'ไม่ระบุ'}`}
                                    </div>
                                    <div className="mb-2 text-gray-700 ">
                                        {'รุ่น : ' +
                                            `${
                                                generation
                                                    ? generation
                                                    : 'ไม่ระบุ'
                                            }`}
                                    </div>
                                    <div className="mb-2 text-gray-700 ">
                                        {'ราคา : ' +
                                            `${price ? price : 'ไม่ระบุ'}`}
                                    </div>
                                    <div className="mb-2 text-gray-700 "> </div>
                                </div>
                            </div>

                            <div className="mb-2 text-gray-700 ">
                                {'บริษัทประกัน : ' +
                                    `${
                                        insurance_company
                                            ? insurance_company
                                            : 'ไม่ระบุ'
                                    }`}
                            </div>

                            <div className="w-full flex">
                                <div className="w-full lg:w-6/12">
                                    {insurance_start === '-' ||
                                    insurance_start ===
                                        '1970-01-01T00:00:00.000Z' ||
                                    !insurance_start ? (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่เริ่มทำประกัน : ไม่ระบุ'}
                                        </div>
                                    ) : (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่เริ่มทำประกัน : ' +
                                                new Date(
                                                    insurance_start
                                                ).toLocaleDateString('th-TH')}
                                        </div>
                                    )}
                                    <div className="mb-2 text-gray-700 ">
                                        {'ประเภทประกัน : ' +
                                            `${
                                                insurance_type
                                                    ? insurance_type
                                                    : 'ไม่ระบุ'
                                            }`}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12">
                                    {insurance_end === '-' ||
                                    insurance_end ===
                                        '1970-01-01T00:00:00.000Z' ||
                                    !insurance_end ? (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่สิ้นสุดทำประกัน : ไม่ระบุ'}
                                        </div>
                                    ) : (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันที่สิ้นสุดทำประกัน : ' +
                                                new Date(
                                                    insurance_end
                                                ).toLocaleDateString('th-TH')}
                                        </div>
                                    )}
                                    {invoice_date === '-' ||
                                    invoice_date ===
                                        '1970-01-01T00:00:00.000Z' ||
                                    !invoice_date ? (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันหมดอายุภาษี : ไม่ระบุ'}
                                        </div>
                                    ) : (
                                        <div className="mb-2 text-gray-700 ">
                                            {'วันหมดอายุภาษี : ' +
                                                new Date(
                                                    invoice_date
                                                ).toLocaleDateString('th-TH')}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="mb-2 mt-8 text-gray-700 ">
                                {'หมายเหตุ : ' +
                                    `${
                                        vehicle_note ? vehicle_note : 'ไม่ระบุ'
                                    }`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

CardVehicle.defaultProps = {
    vehicle_image: null,
    category_name: 'หมวดหมู่รถ',
    sub_category_name: 'หมวดหมู่รถย่อย',
    serial_number: 'ป้ายทะเบียน',
    vehicle_load_weight: 'น้ำหนักที่รองรับ',
    vehicle_detail: 'รายละเอียด',
    vehicle_note: 'หมายเหตุ',
    vehicle_status: 'สถานะ',
    mileage: 'เลขไมล์',
    brand: 'ไม่ระบุ',
    generation: 'ไม่ระบุ',
    buy_date: '-',
    price: 'ไม่ระบุ',
    insurance_start: '-',
    insurance_end: '-',
    car_keeper: 'ไม่ระบุ',
    insurance_company: 'ไม่ระบุ',
    insurance_type: 'ไม่ระบุ',
    invoice_date: '-',
};

CardVehicle.propTypes = {
    vehicle_image: PropTypes.string,
    category_name: PropTypes.string,
    sub_category_name: PropTypes.string,
    serial_number: PropTypes.string,
    vehicle_load_weight: PropTypes.string,
    vehicle_detail: PropTypes.string,
    vehicle_note: PropTypes.string,
    vehicle_status: PropTypes.string,
    mileage: PropTypes.string,
    brand: PropTypes.string,
    generation: PropTypes.string,
    buy_date: PropTypes.string,
    price: PropTypes.string,
    insurance_start: PropTypes.string,
    insurance_end: PropTypes.string,
    car_keeper: PropTypes.string,
    insurance_company: PropTypes.string,
    insurance_type: PropTypes.string,
    invoice_date: PropTypes.string,
};
