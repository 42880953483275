import React from "react";
import PropTypes from "prop-types";

// CardInfo with Backward Color
export default function CardInfoBackward({
  title,
  subtitle,
  icon,
  flat = false,
}) {
  return (
    <div
      className={`flex items-center ${
        flat === true ? "mt-2" : "p-4"
      } bg-gray-100 rounded-lg  shadow-xs dark:bg-gray-800`}
    >
      <div className='p-3 mr-4 text-gray-500 rounded-full '>
        <i className={icon}></i>
      </div>
      <div>
        <p
          className={` ${
            flat ? "mb-0 text-base" : "mb-2 text-lg"
          }  font-medium text-gray-700 `}
        >
          {title}
        </p>
        <p className={` text-sm text-gray-600`}>{subtitle}</p>
      </div>
    </div>
  );
}

CardInfoBackward.defaultProps = {
  title: "หัวข้อหลัก",
  subtitle: "หัวข้อรอง",
  icon: "fa fa-building",
};

CardInfoBackward.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
};
