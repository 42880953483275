import {
    REPAIR_ALL_VEHICLE,
    REPAIR_ALL_WHEEL,
    REPAIR_ALL_VEHICLE_BY_STATUS,
    REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE,
    REPAIR_ALL_WHEEL_BY_STATUS,
    REPAIR_GET_VEHICLE,
    REPAIR_GET_WHEEL,
    REPAIR_POST_VEHICLE,
    REPAIR_POST_WHEEL,
    REPAIR_PUT,
    REPAIR_DEL
} from "../../actions/types";

const initialState = {
    repair: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case REPAIR_ALL_VEHICLE:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_ALL_WHEEL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_ALL_VEHICLE_BY_STATUS:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_ALL_WHEEL_BY_STATUS:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_GET_VEHICLE:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_GET_WHEEL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_POST_VEHICLE:
            return action.payload;
        case REPAIR_POST_WHEEL:
            return action.payload;
        case REPAIR_PUT:
            return action.payload;
        case REPAIR_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}