import { Button } from '@chakra-ui/react';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function ImageUpload(props) {
    const { images, setImages, preview_size, maxNumber } = props;
    const onChange = (imageList) => {
        setImages(imageList);
    };
    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                // write your building UI
                <div>
                    <div className="flex justify-center my-1 ">
                        <Button
                            colorScheme="teal"
                            variant="outline"
                            style={isDragging ? { color: 'red' } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            อัพโหลดรูปภาพ
                        </Button>
                    </div>
                    <div className="flex flex-wrap justify-center rounded px-1 max-h-96 overflow-y-scroll">
                        {imageList.map((image, index) => (
                            <div key={index} className="my-4 mx-1 ">
                                <div className="flex justify-end my-1">
                                    <i
                                        className="fas fa-edit cursor-pointer mx-1  text-gray-500"
                                        aria-hidden="true"
                                        onClick={() => onImageUpdate(index)}
                                    ></i>
                                    <i
                                        className="fas fa-window-close cursor-pointer text-red-500"
                                        aria-hidden="true"
                                        onClick={() => onImageRemove(index)}
                                    ></i>
                                </div>

                                <img
                                    className="rounded"
                                    src={image.data_url}
                                    alt=""
                                    width={preview_size}
                                />
                            </div>
                        ))}
                    </div>
                    {!_.isEmpty(imageList) && (
                        <div className="flex justify-center  my-1">
                            <Button
                                colorScheme="red"
                                variant="outline"
                                onClick={onImageRemoveAll}
                                size="xs"
                            >
                                ลบทั้งหมด
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </ImageUploading>
    );
}

ImageUpload.defaultProps = {
    preview_size: '250',
    maxNumber: 2,
};

ImageUpload.propTypes = {
    preview_size: PropTypes.string,
    images: PropTypes.array.isRequired,
    setImages: PropTypes.func.isRequired,
    maxNumber: PropTypes.number,
};
