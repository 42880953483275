import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";

import { useForm } from "react-hook-form";
import CardContact from "components/common/Cards/CardContact";
import { Button } from "@chakra-ui/react";

import _ from "lodash";

export default function CustomerContact({ contacts }) {
  const { register, handleSubmit, reset, errors } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    return () => { };
  }, []);

  const genCustomerContactList = () =>
    _.map(contacts, (_contact) => {
      return (
        <div key={_contact.contact_id} className="my-2">
          <CardContact
            className="shadow-md"
            contact_id={_contact.contact_id}
            contact_name={_contact.contact_name}
            contact_phone_number={_contact.contact_phone_number}
            contact_email={_contact.contact_email}
            onDelete={onDelete}
          />
        </div>
      );
    });

  const onDelete = (contact_id) => {
    dispatch(actions.contactDelete(contact_id)).then(() => {
      dispatch(actions.customerGet(id));
      alert("ลบบันทึกสำเร็จ");
    });
  };

  return (
    <div className="w-full  px-2 ">
      <div className="shadow-md rounded-lg bg-white border-0 p-4 mb-2 h-full">

        <div className="flex flex-wrap justify-between w-full">
          <div className="w-3/6 text-gray-500">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-4 "
              htmlFor="grid-password"
            >
              รายชื่อผู้ติดต่อ
            </label>
          </div>
          <div className="flex justify-end">

            <Button colorScheme="blue" size="sm"
              disabled={contacts.length === 3}
              onClick={() => {
                history.push("/crm/" + id + "/contact/create");
              }}>
              เพิ่มผู้ติดต่อ
            </Button>

          </div>
        </div>


        <div className="mt-6">
          {_.size(contacts) ? (
            <div>
              {genCustomerContactList()}
            </div>
          ) : (
            <div className="flex justify-center ">
              <div className="text-xs m-4">ไม่มีรายชื่อผู้ติดต่อ</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
