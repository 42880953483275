import moment from 'moment';
import _ from 'lodash';

const genetateCarVehicleList = (
    containerVehicles,
    showSubVehicalType,
    showDescriptionWord
) => {
    const containerWord = _.find(containerVehicles, (eachVechicle) =>
        _.includes(
            [
                'ตู้คอนเทนเนอร์',
                'คอนเทนเนอ',
                'คอนเทนเนอร์',
                'ตู้',
                'container',
                'Container',
            ],
            eachVechicle?.category_name
        )
    );
    const containerCategory = _.find(
        containerVehicles,
        (eachVechicle) =>
            eachVechicle?.category_id == 3
    );


    const container = containerCategory || containerWord;
    if (container) {
        const vehicleList = [container];
        return _.map(
            vehicleList,
            (eachVechicle) =>
                ' ' +
                containerWord?.category_name +
                ' ' +
                (showSubVehicalType
                    ? container
                        ?.sub_category_name + ' '
                    : ' ') +
                (showDescriptionWord ? '| เลขทะเบียน ' : '') +
                containerVehicles?.serial_number

        );
    } else {
        return _.map(
            containerVehicles,
            (eachVechicle) =>
                ' ' +
                containerWord?.category_name +
                ' ' +
                (showSubVehicalType
                    ? container
                        ?.sub_category_name + ' '
                    : ' ') +
                (showDescriptionWord ? '| เลขทะเบียน ' : '') +
                containerVehicles?.serial_number

        );
    }
};

export const AssignmentToEvent = (assignments,
    showDeliveryType = true,
    showSubVehicalType = true,
    showDescriptionWord = false) => {
    return _.map(assignments, (assignment, index) => {
        const returnedObject = {
            type: assignment.assignment_type,
            id: assignment.assignment_id,
            title: `${showDeliveryType
                ? assignment.assignment_type + ' |'
                : assignment.assignment_type === 'ส่งออก'
                    ? '📄'
                    : '📄'
                }
                ${assignment?.consignee?.customer_company}
                ${assignment?.assignment_status}
                ${genetateCarVehicleList(
                    assignment?.vehicle,
                    showSubVehicalType,
                    showDescriptionWord
                )}`,
            allDay: true,
            status: assignment.assignment_status,
            start: moment(new Date(assignment.pickup_date)).startOf('day'),
            end: moment(new Date(assignment.delivery_date)).endOf('day'),
        };
        return returnedObject;
    });
};