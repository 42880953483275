import React from "react";
import { Checkbox } from "@chakra-ui/react";
import _ from "lodash";

export default function RepairTypeLists({
    selectRepairType,
    setSelectRepairType,
    repairTypeData
}) {

    const genRepairType = () => {
        return _.map(repairTypeData, (repair_type) => (
            <div key={repair_type.repair_type_id} className="py-1 px-2">
                <Checkbox
                    name={"repair_type_id"}
                    value={repair_type?.repair_type_id}
                    onChange={(e) => {
                        if (_.size(selectRepairType)) {
                            setSelectRepairType([
                                ...selectRepairType,
                                { repair_type_id: repair_type?.repair_type_id },
                            ]);
                        } else {
                            setSelectRepairType([
                                { repair_type_id: repair_type?.repair_type_id }
                            ]);
                        }
                    }}
                ></Checkbox> {" "}
                {repair_type.repair_detail}
            </div>
        ))
    }

    return (
        <div className="flex-auto bg-white p-2 rounded shadow">
            <div className="relative ">
                {genRepairType()}
            </div>
        </div>
    )
}