import React from 'react';
import { Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
export default function CustomerSearch({ data, setData }) {
    const handleSearch = (word) => {
        const res = data.filter(
            (customer) =>
                customer.customer_company.includes(word) ||
                customer.customer_phone_number.includes(word)
        );
        setData(res);
    };
    return (
        <div>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={
                        <i className="fa fa-search" aria-hidden="true"></i>
                    }
                />
                <Input
                    className="shadow-md"
                    colorScheme="black"
                    placeholder="ค้นหา"
                    onChange={(e) => {
                        data && handleSearch(e.target.value);
                    }}
                />
            </InputGroup>
        </div>
    );
}
