import _ from "lodash";
export default function checkRoles(roleLists, pathname) {
  if (roleLists === undefined) {
    return true;
  } else {
    for (let role of roleLists) {
      console.log("pathname : ", pathname);
      const result = _.startsWith(pathname, role.module_list.module_name_en, 1);
      if (result) return true;
    }
    return false;
  }
}
