import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions/";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { Box, Button, HStack } from "@chakra-ui/react";
import _ from "lodash";

export default function SubCategoryByCategoryLists({ sub_category }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const getTd = () =>
    _.map(sub_category, (_sub_category) => (
      <Tr key={_sub_category.sub_category_id}>
        <Td>{_sub_category.sub_category_name}</Td>
        <Td>
          {" "}
          <HStack spacing={2}>
            <Box>
              <Link to={"/fms/category/" + _sub_category.sub_category_id + "/subcategory/edit"}>
                {" "}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>

            <Button
              colorScheme="red"
              onClick={() => {
                const confirm = window.confirm("ยืนยันกันลบหมวดหมู่ย่อย");
                if (confirm) handleDelete(_sub_category.sub_category_id);
              }}
            >
              ลบ
            </Button>
          </HStack>
        </Td>
      </Tr>
    ));
  const handleDelete = (sub_category_id) => {
    dispatch(actions.sub_category_vehicleDelete(sub_category_id)).then(() => {
      dispatch(actions.sub_category_vehicleAllByCategory(id)).then(() => {
        // alert("ลบหมวดหมู่ย่อยสำเร็จ");
      });
    });
  };

  return (
    <div className=" rounded rounded-t-lg bg-white p-4">
      <Table variant="striped" size="lg">
        <TableCaption>ข้อมูลหมวดหมู่ย่อย</TableCaption>
        <Thead>
          <Tr>
            <Th>ชื่อ</Th>
            <Th>ดำเนินการ</Th>
          </Tr>
        </Thead>
        <Tbody>{getTd()}</Tbody>
      </Table>
    </div>
  );
}
