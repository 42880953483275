import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    Textarea,
    Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import hash from 'object-hash';

import * as actions from '../../../redux/actions';
import { REPAIR_STATUS } from '../../../config/constant/RepairStatus';
import ImageUpload from '../../../components/ImageUpload/ImageMultiUpload';
import { uploadImageFile } from '../../../util/image.functions';
import { WORD_TYPE_IMAGE_REPAIR } from '../../../config/constant/TypeImageRepair';

export default function ModalRepairInfoRecord({
    openStatus,
    closeHandle,
    repair,
    successfulCloseHandle,
}) {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const [image, setImage] = useState([]);

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const renderImageMultiUpload = () => {
        return (
            <div className="relative w-full mb-3 ">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mt-3 mb-3 "
                    htmlFor="grid-password"
                >
                    รูปภาพ
                </label>
                <div className="bg-white rounded shadow  p-4">
                    <ImageUpload
                        images={image}
                        setImages={setImage}
                        preview_size={'150'}
                        maxNumber={20}
                    />
                </div>
            </div>
        );
    };

    const onSubmit = async (data) => {
        // data.repair_status = REPAIR_STATUS[2];
        repair.repair_status = REPAIR_STATUS[2];
        repair.cause = data.cause;
        repair.repair_modify = data.repair_modify;
        repair.end_date = data.end_date;

        let imagesURL = [];

        const confirm = window.confirm('ยืนยันการบันทึกการแจ้งซ่อม');
        if (confirm) {
            if (!_.isEmpty(image)) {
                for await (const img of image.map((image) =>
                    handleUploadImage(image)
                )) {
                    imagesURL.push({
                        img,
                        type: WORD_TYPE_IMAGE_REPAIR.AFTER_REPAIR,
                    });
                }
                repair.repair_imgs = [...repair.repair_imgs, ...imagesURL];
            }

            dispatch(actions.repairLogPost(repair)).then(() => {
                dispatch(actions.repairPut(repair?.repair_id, repair)).then(
                    () => {
                        alert('สำเร็จ');
                        // window.location.reload();
                        dispatch(actions.repairAllVehicle({ size: 10 }));
                        successfulCloseHandle();
                    }
                );
            });
        }
    };

    return (
        <div>
            <Modal isOpen={openStatus} onClose={closeHandle} size="4xl">
                <ModalOverlay />
                <ModalContent className="font-sans">
                    <ModalHeader>
                        บันทึกการแจ้งซ่อม <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-4">
                                <div className="w-full ">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            สาเหตุการแจ้งซ่อม
                                        </label>
                                        <Textarea
                                            name="cause"
                                            defaultValue={repair?.cause}
                                            ref={register}
                                            placeholder="สาเหตุ"
                                            rows={3}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            วิธีแก้ไข
                                        </label>
                                        <Textarea
                                            name="repair_modify"
                                            defaultValue={repair?.repair_modify}
                                            ref={register}
                                            placeholder="วิธีการแก้ไข"
                                            rows={3}
                                        />
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            วันที่สิ้นสุดการแจ้งซ่อม
                                        </label>
                                        <Input
                                            name="end_date"
                                            defaultValue={repair?.end_date}
                                            type="date"
                                            ref={register}
                                            placeholder="วันที่สิ้นสุดการแจ้งซ่อม"
                                        />
                                    </div>
                                    <div>{renderImageMultiUpload()}</div>
                                </div>
                                <div className="w-full text-center flex justify-end gap-2 ">
                                    <Button
                                        colorScheme="green"
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        colorScheme="gray"
                                        type="button"
                                        onClick={() => closeHandle()}
                                    >
                                        ยกเลิก
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
