import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _, { isEmpty } from 'lodash';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';
import { TYPE_REPAIR } from '../../../config/constant/TypeRepair';
import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import RepairTypeLists from './components/RepairTypeLists';
import ImageUpload from '../../../components/ImageUpload/ImageMultiUpload';
import { WORD_TYPE_IMAGE_REPAIR } from '../../../config/constant/TypeImageRepair';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';

export default function CreateRepairVehicle() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const car_owner = useSelector((state) => state.car_owner);
    const mileage = useSelector((state) => state.mileage);
    const vehicles = useSelector((state) => state.vehicle);
    const repair_type = useSelector((state) => state.repair_type);
    const [vehicle_data, setVehicle] = useState([]);
    const [selectRepairType, setSelectRepairType] = useState([]);
    const [repairTypeData, setRepairTypeData] = useState([]);
    const [mileage_data, setMileageData] = useState('0');

    const [subCategoryVehicle, setSubCategoryVehicle] = useState(
        SUB_CATEGORY_REPAIR[0]
    );
    const [image, setImage] = useState([]);

    useEffect(() => {
        dispatch(actions.meGet()).then(() => {
            dispatch(actions.carOwnerGetByDriver(me.employee_id)).then(() => {
                dispatch(actions.vehicleAll()).then(() => {
                    dispatch(actions.repairTypeAll()).then(() => {
                        setIsLoading(true);
                    });
                });
            });
        });

        return () => {};
    }, []);

    useEffect(() => {
        dispatch(actions.mileageGetLatest(car_owner.vehicle?.vehicle_id)).then(
            () => {
                setVehicle(vehicles?.arr);
                setRepairTypeData(repair_type?.arr);
                setIsLoading(true);
            }
        );
        return () => {};
    }, [car_owner, vehicles, repair_type]);

    useEffect(() => {
        if (mileage.mileage) {
            setMileageData(mileage.mileage);
        }
        return () => {};
    }, [mileage]);

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const onSubmit = async (data) => {
        data.employee_id = me?.employee_id;
        data.problem = selectRepairType;
        data.type = TYPE_REPAIR[0];

        if (data.sub_vehicle == SUB_CATEGORY_REPAIR[0]) {
            data.vehicle_tail_id = '-';
            data.vehicle_id = car_owner?.vehicle?.vehicle_id;
            data.driver_id = me?.employee_id;
        } else if (data.sub_vehicle == SUB_CATEGORY_REPAIR[1]) {
            data.vehicle_id = '-';
        } else if (data.sub_vehicle == SUB_CATEGORY_REPAIR[2]) {
            data.vehicle_id = car_owner?.vehicle?.vehicle_id;
            data.driver_id = me?.employee_id;
        }

        let imagesURL = [];

        if (!isEmpty(image)) {
            for await (const img of image.map((image) =>
                handleUploadImage(image)
            )) {
                imagesURL.push({
                    img,
                    type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                });
            }
            data.repair_img = imagesURL;
        }

        dispatch(actions.repairPostVehicle(data)).then(() => {
            alert('สำเร็จ');
            window.location.reload();
        });

        dispatch(actions.repairPostVehicle(data)).then(() => {
            alert('สำเร็จ');
            window.location.reload();
        });

        console.log('data', data);
    };

    const genSubCategoryVehicle = () => {
        return _.map(SUB_CATEGORY_REPAIR, (sub_repair) => (
            <option>{sub_repair}</option>
        ));
    };

    const showVehicleData = (data) => {
        return (
            <>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ทะเบียนรถ{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <input
                        name="vehicle_id"
                        ref={register}
                        placeholder="ทะเบียนรถ"
                        defaultValue={car_owner.vehicle?.serial_number}
                        disabled
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    ></input>
                </div>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        เลขไมล์{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <input
                        name="mileage"
                        required
                        ref={register}
                        placeholder="เลขไมล์"
                        disabled={subCategoryVehicle == SUB_CATEGORY_REPAIR[1]}
                        value={mileage_data}
                        onChange={(e) => {
                            setMileageData(e.target.value);
                        }}
                        // disabled
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    ></input>
                </div>
            </>
        );
    };

    const renderImageMultiUpload = () => {
        return (
            <div className="relative w-full mb-3 ">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mt-3 mb-3 "
                    htmlFor="grid-password"
                >
                    รูปภาพ
                </label>
                <div className="bg-white rounded shadow  p-4">
                    <ImageUpload
                        images={image}
                        setImages={setImage}
                        preview_size={'150'}
                        maxNumber={4}
                    />
                </div>
            </div>
        );
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                เพิ่มรายการแจ้งซ่อมรถ
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap p-4  justify-around  h-full">
                    <div className="w-full lg:w-6/12 ">
                        <h6 className="text-gray-700 text-xs mt-3 mb-3 font-bold uppercase">
                            ข้อมูลรถ
                        </h6>
                        <div className="bg-white shadow rounded px-4  p-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ชื่อผู้แจ้ง{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="employee_id"
                                    required
                                    ref={register}
                                    placeholder="ชื่อพนักงานขับ"
                                    defaultValue={
                                        me.employee_first_name +
                                        ' ' +
                                        me.employee_last_name
                                    }
                                    disabled
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                            </div>
                            <div className="relative w-full mb-3">
                                <select
                                    name="sub_vehicle"
                                    type="text"
                                    ref={register}
                                    hidden
                                    defaultValue={SUB_CATEGORY_REPAIR[0]}
                                    onChange={(e) => {
                                        setSubCategoryVehicle(e.target.value);
                                    }}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือก
                                    </option>
                                    {genSubCategoryVehicle()}
                                </select>
                            </div>

                            {showVehicleData(subCategoryVehicle)}

                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    หมายเหตุ
                                </label>
                                <textarea
                                    name="repair_detail"
                                    type="textfield"
                                    rows={3}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="หมายเหตุ"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 p-4">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ประเภทการซ่อม{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            {_.size(repairTypeData) ? (
                                <div>
                                    <RepairTypeLists
                                        selectRepairType={selectRepairType}
                                        setSelectRepairType={
                                            setSelectRepairType
                                        }
                                        repairTypeData={repairTypeData}
                                    />
                                </div>
                            ) : (
                                <div className="justify-center">
                                    ไม่มีข้อมูล
                                </div>
                            )}
                        </div>
                        <div>{renderImageMultiUpload()}</div>
                    </div>
                </div>
                <div className="text-center mt-3 px-8">
                    <Button isFullWidth colorScheme="blue" type="submit">
                        บันทึก
                    </Button>
                </div>
                <br />
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
