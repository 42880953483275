import axios from "axios";
import {
  SYSTEM_INFO_ALL,
  SYSTEM_INFO_GET,
  SYSTEM_INFO_PUT,
  SYSTEM_INFO_DEL,
} from "../types";

export const systemInfoAll = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/system_info")
      .then((res) => {
        console.log("Request Server to Get All Companys");
        dispatch({ type: SYSTEM_INFO_ALL, payload: res.data });
      });
  };
};

export const systemInfoGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/system_info/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Companys");
        if (res.data) {
          dispatch({ type: SYSTEM_INFO_GET, payload: res.data });
        } else {
          dispatch({ type: SYSTEM_INFO_GET, payload: null });
        }
      });
  };
};

export const systemInfoPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/system_info/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Companys");
        dispatch({ type: SYSTEM_INFO_PUT, payload: null });
        dispatch(systemInfoAll());
      });
  };
};
export const systemInfoDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/system_info/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Companys");
        dispatch({ type: SYSTEM_INFO_DEL, payload: null });
      });
  };
};
