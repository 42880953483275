import {
  DEPARTMENT_ALL,
  DEPARTMENT_GET,
  DEPARTMENT_PUT,
  DEPARTMENT_DEL,
  DEPARTMENT_POST,
} from "../../actions/types";
export default function (state = null, action) {
  switch (action.type) {
    case DEPARTMENT_ALL:
      return action.payload;
    case DEPARTMENT_POST:
      return action.payload;
    case DEPARTMENT_GET:
      return action.payload;
    case DEPARTMENT_PUT:
      return action.payload;
    case DEPARTMENT_DEL:
      return action.payload;
    default:
      return state;
  }
}
