import React from 'react'
import PropTypes from "prop-types";

export default function CardCustomer({
    company,
    address,
    customer_phone_number,
    postcode,
    taxes_id,
    fax,
    logo,
    customer_business_type,
    customer_type,
}) {
    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
                <div className="text-xs leading-normal mt-0 mb-2 text-gray-500 font-bold ml-4 mt-2">
                    ข้อมูลธุรกิจ
                </div>
                <div className="flex flex-wrap justify-center">
                    <div className="w-full px-4 flex justify-center">
                        <div className="relative ">
                            {logo ? (
                                <>
                                    {" "}
                                    <img
                                        alt="..."
                                        src={logo}
                                        className="h-48 mt-2"
                                    />
                                </>
                            ) : (
                                <>
                                    {" "}
                                    <img
                                        alt="..."
                                        src={require("assets/img/user-profile.png")}
                                        className="h-48 mt-2"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <div className="text-2xl leading-normal mt-0 mb-2 text-gray-900 font-bold uppercase">
                            {company}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="ml-4 mt-2 mb-5 ml-8 mr-2 ">
                        <div className="mb-2 text-gray-700 ">
                            {"ที่อยู่บริษัท : " + address + " " + postcode}
                        </div>
                        <div className="mb-2 text-gray-700 ">
                            {"ประเภทธุรกิจ : " + customer_business_type + " , " + customer_type}
                        </div>
                        <div className="mb-2 text-gray-700 ">
                            {"เลขที่ผู้เสียภาษี : " + taxes_id}
                        </div>
                        <div className="mb-2 text-gray-700 ">
                            {"เบอร์สำนักงาน : " + customer_phone_number}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

CardCustomer.defaultProps = {
    company: "ชื่อบริษัท",
    address: "ที่อยู่บริษัท",
    customer_phone_number: "เบอร์สำนักงาน",
    postcode: "รหัสไปรษณีย์",
    taxes_id: "เลขที่ผู้เสียภาษี",
    fax: "แฟกซ์",
    logo: null,
    customer_business_type: "ประเภทธุรกิจ",
    customer_type: "ประเภทลูกค้า",
};

CardCustomer.propTypes = {
    company: PropTypes.string,
    address: PropTypes.string,
    customer_phone_number: PropTypes.string,
    postcode: PropTypes.string,
    taxes_id: PropTypes.string,
    fax: PropTypes.string,
    logo: PropTypes.string,
    customer_business_type: PropTypes.string,
    customer_type: PropTypes.string,
}
