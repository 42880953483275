import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/";
import { Link } from "react-router-dom";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import CategoryLists from "./components/CategoryLists";

export default function Categories() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [categories_data, setCategories] = useState([]);
    const categories = useSelector((state) => state.category_vehicle);

    useEffect(() => {
        dispatch(actions.category_vehicleAll()).then((res) => {
            setIsLoading(true);
        })
        return () => { }
    }, [])

    useEffect(() => {
        setCategories(categories?.arr);
        return () => {}
    }, [categories])

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">หมวดหมู่รถ</h2>
            </div>
            <div className="py-4">
                <Link to="/fms/category/create">
                    <Button colorScheme="blue">เพิ่ม</Button>
                </Link>
            </div>
            <div className="py-4">
                {_.size(categories_data) ? (
                    <div>
                        {" "}
                        <CategoryLists categories={categories_data} />
                    </div>
                ) : (
                        <div className="flex justify-center p-4">
                            <div>ไม่มีข้อมูล</div>
                        </div>
                    )}
            </div>
        </div>
    ) : (
            <SpinnerLoading />
        );

}