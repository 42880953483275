import {
    PATH_ALL,
    PATH_GET,
    PATH_POST,
    PATH_PUT,
    PATH_DEL,
} from '../../actions/types';

const initialState = {
    path: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PATH_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case PATH_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case PATH_POST:
            return action.payload;
        case PATH_PUT:
            return action.payload;
        case PATH_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}