import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalFooter,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import DisplayMode from './DisplayMode';
import EditMode from './EditorMode';
import * as actions from '../../../redux/actions';
import { ASSIGNMENT_STATUS } from '../../../config/constant/AssignmentStatus';
import CONST_CONFIG from '../../../config/constant';
import { TRANSPORT_SERVICE } from 'config/constant/TransportService';
import CreateImportDelivery from '../../Modal/CreateDeliveryModal/CreateImportDeliveryModal';
import CreateExportDelivery from '../../Modal/CreateDeliveryModal/CreateExportDeliveryModal';
import CreateDomesticDelivery from '../../Modal/CreateDeliveryModal/CreateDomesticDeliveryModal';

export default function TransportAssignmentModal({
    selectedAssignment,
    openStatus,
    closeHandle,
    isEditorMode,
    setIsEditorMode
}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const customer = useSelector((state) => state.customer);
    const vehicle = useSelector((state) => state.vehicle);
    const { register, handleSubmit } = useForm();

    const [vehicle_container_data, setVehicleContainerData] = useState([]);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenDeliveryModal, setIsOpenDeliveryModal] = useState(false);

    const handleOpenEditModal = () => {
        setIsOpenModal(!isOpenModal)
    }

    const handleOpenDeliveryModal = () => {
        setIsOpenDeliveryModal(!isOpenDeliveryModal)
    }

    useEffect(() => {
        dispatch(actions.customerAll()).then(() => {
            dispatch(actions.vehicleAll()).then(() => {
                setIsLoading(true);
            });
        });

        return () => { };
    }, []);

    useEffect(() => {
        vehicle.arr && setVehicleContainerData(filterVehicleContainerData());

        return () => { };
    }, [vehicle]);

    const filterVehicleContainerData = () => {

        const containerResult = _.filter(vehicle.arr, (_vehicle) => {
            return _vehicle?.category_id === '3';
        });

        const result = _.map(containerResult, (container) => {

            return {
                value: container?.vehicle_id,
                label:
                    container?.category_vehicle?.category_name +
                    '/' +
                    container?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    container?.serial_number,
                data: container,
            };
        });
        return result;
    };

    const handleReadyAssignment = (assignment) => {
        const confirm = window.confirm('ยืนยันความพร้อมรายการการจัดส่ง');
        assignment.assignment_status = CONST_CONFIG.ASSIGNMENT_STATUS[4];

        if (confirm)
            dispatch(
                actions.transportAssignmentPut(assignment?.assignment_id, assignment)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.transportAssignmentGetProcessingStatus());
            });
    };

    const handleDeleteAssignment = (assignment) => {
        const confirm = window.confirm('ยืนยันการยกเลิกรายการการจัดส่ง');
        assignment.assignment_status = CONST_CONFIG.ASSIGNMENT_STATUS[3];

        if (confirm)
            dispatch(
                actions.transportAssignmentPut(assignment?.assignment_id, assignment)
            ).then(() => {
                dispatch(actions.transportAssignmentGetProcessingStatus());
                closeHandle()

            });
    };

    if (selectedAssignment) {
        return (
            <div>
                <Modal isOpen={openStatus} onClose={closeHandle} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="font-sans text-base">
                            <div className="flex gap-2 ">
                                {' '}
                                <div>
                                    รายการจัดส่งสินค้า
                                </div>
                                <div className="self-center">
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[0] && (
                                            <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[1] && (
                                            <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[2] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[3] && (
                                            <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[4] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                </div>
                                <div>
                                    {(!isEditorMode) ? (
                                        <Button
                                            onClick={() =>
                                                handleOpenEditModal()
                                            }
                                            colorScheme="yellow"
                                            size="sm"
                                        >
                                            {/* <i className="fas fa-pencil-alt " /> */}
                                            <div className="text-white">แก้ไข</div>
                                        </Button>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody className="p-4 m-4 mt-0 pb-6">
                            {isEditorMode ? (
                                <EditMode
                                    selectedAssignment={selectedAssignment}
                                    allCustomer={customer.arr}
                                    container={vehicle_container_data}
                                    handleCancleEditing={() =>
                                        setIsEditorMode(!isEditorMode)
                                    }
                                    handleExitModal={closeHandle}
                                    isEditorMode={isEditorMode}
                                />
                            ) : (
                                <DisplayMode
                                    selectedAssignment={selectedAssignment}
                                    handleToEditorMode={() => {
                                        setIsEditorMode(true);
                                    }}
                                />
                            )}
                        </ModalBody>
                        <ModalFooter>
                            <div className="flex flex-wrap gap-2">
                                <div>
                                    {(!isEditorMode && (selectedAssignment.assignment_status === ASSIGNMENT_STATUS[0] || selectedAssignment.assignment_status === ASSIGNMENT_STATUS[4])) ? (
                                        <Button
                                            onClick={() =>
                                                handleReadyAssignment(
                                                    selectedAssignment
                                                )
                                            }
                                            colorScheme="green"
                                            size="md"
                                            disabled={selectedAssignment.assignment_status === ASSIGNMENT_STATUS[4]}
                                        >
                                            <div>พร้อม</div>
                                        </Button>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                                <div>
                                    {(!isEditorMode) && (selectedAssignment.assignment_status === ASSIGNMENT_STATUS[4]) ? (
                                        <Button
                                            onClick={() =>
                                                handleOpenDeliveryModal()
                                            }
                                            colorScheme="blue"
                                            size="md"

                                        >
                                            <div>จัดรถ</div>
                                        </Button>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                                <div>
                                    {(!isEditorMode) && (selectedAssignment.assignment_status === ASSIGNMENT_STATUS[0] || selectedAssignment.assignment_status === ASSIGNMENT_STATUS[4]) ? (
                                        <Button
                                            onClick={() =>
                                                handleDeleteAssignment(
                                                    selectedAssignment
                                                )
                                            }
                                            colorScheme="red"
                                            size="md"

                                        >
                                            <div>ยกเลิก</div>
                                        </Button>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            </div>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenModal} onClose={setIsOpenModal} size="5xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="font-sans text-base">
                            <div className="flex gap-2 ">
                                {' '}
                                <div>
                                    รายการจัดส่งสินค้า
                                </div>
                                <div className="self-center">
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[0] && (
                                            <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[1] && (
                                            <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[2] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[3] && (
                                            <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                    {selectedAssignment?.assignment_status ===
                                        ASSIGNMENT_STATUS[4] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedAssignment?.assignment_status
                                                }
                                            </div>
                                        )}
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody className="p-4 m-4 mt-0 pb-6">

                            <EditMode
                                selectedAssignment={selectedAssignment}
                                allCustomer={customer.arr}
                                container={vehicle_container_data}
                                handleCancleEditing={() =>
                                    setIsOpenModal(!isOpenModal)
                                }
                                handleExitModal={() => {
                                    setIsOpenModal(false)
                                    closeHandle()
                                }
                                }
                                isEditorMode={isEditorMode}
                            />

                        </ModalBody>
                    </ModalContent>
                </Modal>

                <Modal isOpen={isOpenDeliveryModal} onClose={setIsOpenDeliveryModal} size="2xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="font-sans text-base">
                            <div className="flex gap-2 ">
                                {' '}
                                <div>
                                    รายการจัดส่งสินค้า{selectedAssignment?.assignment_type}
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody className="p-4 m-4 mt-0 pb-6">
                            {(selectedAssignment?.assignment_type === TRANSPORT_SERVICE[0]) ? (
                                <CreateImportDelivery />
                            ) : (
                                <></>
                            )}
                            {(selectedAssignment?.assignment_type === TRANSPORT_SERVICE[1]) ? (
                                <CreateExportDelivery />
                            ) : (
                                <></>
                            )}
                            {(selectedAssignment?.assignment_type === TRANSPORT_SERVICE[2]) ? (
                                <CreateDomesticDelivery />
                            ) : (
                                <></>
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        );
    } else {
        return <></>;
    }
}
