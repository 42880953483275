import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { MonthDataWheelPrintPDF } from '../../report/MonthDataWheelPrintPDF';

export default function RepairWheelLists({ repair_data, company }) {

    const genTd = () => {
        return _.map(repair_data, (data, index) => (
            <Tr key={data.month_id}>
                <Td>{index + 1}</Td>
                <Td>
                    <Link to={`/cmms/history/repair/wheel/${data.month_id}`}>
                        <div>
                            <b>{moment(data.month).format("MM/YYYY")}</b>
                        </div>
                        <div>
                            <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center">
                                รายละเอียด
                            </span>
                        </div>
                    </Link>
                </Td>
                <Td>
                    <div className="">
                        <Button
                            size="xs"
                            onClick={() => {
                                MonthDataWheelPrintPDF(data, company)
                            }}
                        >
                            <i className="fas fa-print cursor-pointer mr-1"
                            ></i>{" "}พิมพ์
                        </Button>
                    </div>
                </Td>
                <Td>{_.size(data.repair_logs) + " รายการ"}</Td>
            </Tr>
        ))
    }

    // console.log("repair_data", repair_data);
    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>เดือน</Th>
                        <Th>ใบแจ้งซ่อมยาง</Th>
                        <Th>รายการแจ้งซ่อมทั้งหมด</Th>
                    </Tr>
                </Thead>
                <Tbody >{genTd()}</Tbody>
            </Table>
        </div>
    )
}