import React, { useEffect, useState } from "react";
import * as actions from "../../../redux/actions/";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import EditSystemInfo from "./components/EditSystemInfo";

export default function SystemInfo() {
  const [isLoading, setIsLoading] = useState(false);
  const system_info = useSelector((state) => state.system_info);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.systemInfoAll()).then(() => {
      setIsLoading(true);
    });
    return () => {};
  }, []);

  return isLoading ? (
    <div>
      <div>
        <h2 className="text-gray-800 text-xl font-semibold">ข้อมูลระบบ</h2>
      </div>
      <div className=" p-4 w-full">
        <EditSystemInfo system_info={system_info} />
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
