import {
  POSITION_ALL,
  POSITION_GET,
  POSITION_PUT,
  POSITION_DEL,
  POSITION_POST,
  POSITION_ALL_BY_DEPARTMENT,
  POSITION_DEL_BY_DEPARTMENT,
} from "../../actions/types";
export default function (state = null, action) {
  switch (action.type) {
    case POSITION_ALL:
      return action.payload;
    case POSITION_ALL_BY_DEPARTMENT:
      return action.payload;
    case POSITION_GET:
      return action.payload;
    case POSITION_POST:
      return action.payload;
    case POSITION_PUT:
      return action.payload;
    case POSITION_DEL:
      return action.payload;
    case POSITION_DEL_BY_DEPARTMENT:
      return action.payload;
    default:
      return state;
  }
}
