import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import "moment/locale/th";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Button,
} from '@chakra-ui/react';

import * as actions from "../../../redux/actions";
import SpinnerLoading from "components/Loading/SpinnerLoading";

import DeliveryMonthLists from "./components/DeliveryMonthLists";
import { TRANSPORT_SERVICE } from "../../../config/constant/TransportService";
import { MonthDeliveryReport } from './components/report/MonthDeliveryReport';

export default function DetailDeliveryHistory() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const delivery_month = useSelector((state) => state.delivery_month);
    const company = useSelector((state) => state.company);
    const [deliveryType, setDeliveryType] = useState(id);
    const [month, setMonth] = useState();
    const [selectedCategory, setSelectedCategory] = useState('ทั้งหมด');

    useEffect(() => {
        if (deliveryType === id) {
            dispatch(actions.deliveryMonthGetAllType(id)).then(() => {
                dispatch(actions.companyAll())
            })
        } else {
            dispatch(actions.deliveryMonthGet(id, deliveryType)).then(() => {
                dispatch(actions.companyAll())
            })
        }

        return () => { }
    }, [deliveryType])

    useEffect(() => {
        setMonth(delivery_month);
        setIsLoading(true)
        return () => { }
    }, [delivery_month])



    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                ประวัติรายการจัดส่งสินค้าประจำเดือน {" "}
                {moment(month?.month).format("MMMM")}/
                {moment(month?.month).format("YYYY")}
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Button
                        onClick={() => history.goBack()}
                        variant="outline"
                        className="mr-4"
                    >
                        กลับ
                    </Button>
                    <Menu isLazy>
                        <MenuButton
                            as={Button}
                            variant="outline"
                            className="bg-white"
                        >
                            {selectedCategory}
                            <i className="fas fa-sort-down px-2"></i>
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => {
                                    setDeliveryType(id);
                                    setSelectedCategory('ทั้งหมด');
                                }}
                            >
                                ทั้งหมด
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                onClick={() => {
                                    setDeliveryType(TRANSPORT_SERVICE[0]);
                                    setSelectedCategory('นำเข้า');
                                }}
                            >
                                นำเข้า
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setDeliveryType(TRANSPORT_SERVICE[1]);
                                    setSelectedCategory('ส่งออก');
                                }}
                            >
                                ส่งออก
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setDeliveryType(TRANSPORT_SERVICE[2]);
                                    setSelectedCategory('ภายใน');
                                }}
                            >
                                ภายใน
                            </MenuItem>

                        </MenuList>
                    </Menu>
                </div>
                <div>
                    <Button
                        colorScheme="blue"
                        className="ml-16"
                        onClick={() => {
                            MonthDeliveryReport(month, company)
                        }}
                    >
                        พิมพ์
                    </Button>
                </div>
            </div>

            <div className="p-4">
                {_.size(month?.delivery_logs) ? (
                    <div className="flex-auto overflow-x-auto border-black border-2 border-opacity-10 rounded">
                        <DeliveryMonthLists delivery_log={month?.delivery_logs} company={company} id={id} />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}