import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// components
import Navbar from 'components/common/Navbars/AuthNavbar.js';
import FooterSmall from 'components/common/Footers/FooterSmall.js';

// views
import Login from 'views/auth/Signin.js';
// import Register from "views/auth/Register.js";
import ForgottenPassword from 'views/auth/ForgottenPassword';

export default function Auth() {
    return (
        <>
            <Navbar transparent />
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div
                        className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
                        style={{
                            backgroundImage:
                                'url(' +
                                require('assets/img/register_bg_2.png') +
                                ')',
                        }}
                    ></div>

                    <Switch>
                        {window.localStorage.token ? (
                            <>
                                <Redirect from="/" to="/" />
                            </>
                        ) : (
                            <>
                                <Route
                                    path="/auth/login"
                                    exact
                                    component={Login}
                                />
                                <Route
                                    path="/auth/forgotten-password"
                                    exact
                                    component={ForgottenPassword}
                                />
                            </>
                        )}
                    </Switch>
                    <FooterSmall absolute />
                </section>
            </main>
        </>
    );
}
