import React from "react";
import PropTypes from "prop-types";

export default function CardCompany({
    logo,
    name,
    address,
    email,
    phone_number,
    fax,
    taxes_id
}) {
    return (
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
            <div className="flex flex-wrap justify-center">
                <div className="w-full px-4 flex justify-center">
                    <div className="relative ">
                        {logo ? (
                            <>
                                {" "}
                                <img
                                    alt="..."
                                    src={logo}
                                    className="h-48 mt-4 rounded-xl"
                                />
                            </>
                        ) : (
                            <>
                                {" "}
                                <img
                                    alt="..."
                                    src={require("assets/img/user-profile.png")}
                                    className="h-48 mt-4 rounded-xl"
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="text-center mt-2">
                    <div className="text-2xl leading-normal mt-0 mb-2 text-gray-900 font-bold uppercase">
                        {name}
                    </div>
                </div>
            </div>
            <div className="justify-center">
                <div className="ml-4 mt-2 mb-5 ml-8 mr-2 ">
                    <div className="mb-2 text-gray-700 ">
                        {"ที่อยู่ : " + address}
                    </div>
                    <div className="mb-2 text-gray-700 ">
                        {"เบอร์โทรศัพท์ : " + phone_number}
                    </div>

                    <div className="mb-2 text-gray-700 ">
                        {"แฟกซ์ : " + fax}
                    </div>
                    <div className="mb-2 text-gray-700 ">
                        {"อีเมล : " + email}
                    </div>
                    <div className="mb-2 text-gray-700 ">
                        {"เลขที่ผู้เสียภาษี : " +taxes_id}
                    </div>
                </div>
            </div>

        </div>
    );
}

CardCompany.defaultProps = {
    logo: null,
    name: "ชื่อบริษัท",
    address: "ที่อยู่",
    email: "อีเมล",
    phone_number: "เบอร์โทรศัพท์",
    fax: "แฟกซ์",
    taxes_id: "เลขที่ผู้เสียภาษี",
};

CardCompany.propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    fax: PropTypes.string,
    taxes_id: PropTypes.string,
};