import React from 'react';
import { WORD_REPAIR_STATUS } from '../../config/constant/RepairStatus';

export default function RepairStatusBadge({ status }) {
    return (
        <div>
            {status === WORD_REPAIR_STATUS.statusNew && (
                <div className="text-xs text-center  px-4  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                    {status}
                </div>
            )}
            {status === WORD_REPAIR_STATUS.statusAllow && (
                <div className="text-xs text-center  px-4 bg-green-100 hover:bg-green-200 rounded whitespace-nowrap">
                    {status}
                </div>
            )}
            {status === WORD_REPAIR_STATUS.statusFinish && (
                <div className="text-xs text-center  px-4 bg-yellow-100 hover:bg-yellow-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            )}
            {status === WORD_REPAIR_STATUS.statusCancle && (
                <div className="text-xs text-center  px-4 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                    {status}
                </div>
            )}
        </div>
    );
}
