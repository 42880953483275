import React from "react";
import CardInfo from "components/common/Cards/CardInfo";
import { Link } from "react-router-dom";
import _ from "lodash";

export default function CategoryLists({ categories }) {
    const genCardCategories = () => {
        return _.map(categories, (category) => (
            <div key={category.category_id} className="w-full lg:w-3/12 p-2" >
                <Link to={"/fms/category/detail/" + category.category_id}>
                    <CardInfo
                        title={category.category_name}
                        subtitle={category.category_detail}
                        icon="fa fa-list-alt"
                    />
                </Link>
            </div>
        ));
    }
    return (
        <div className=" flex-auto">
            <div className="relative flex flex-wrap">{genCardCategories()}</div>
        </div>
    )
}