import React from "react";
import CardInfo from "components/common/Cards/CardInfo";
import { Link } from "react-router-dom";
import _ from "lodash";
export default function DepartmentLists({ department }) {
  const genCardDepartment = () => {
    return _.map(department, (_department) => (
      <div
        key={_department.department_id}
        className="w-full lg:w-3/12 p-2"
      >
        <Link to={"/hrms/department/details/" + _department.department_id}>
          <CardInfo title={_department.department_name} subtitle={_department.department_detail} icon="fa fa-building" />
        </Link>
      </div>
    ));
  };
  return (
    <div className=" flex-auto">
      <div className="relative flex flex-wrap">{genCardDepartment()}</div>
    </div>
  );
}
