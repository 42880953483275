import {
  TRANSPORT_ASSIGNMENT_GET,
  TRANSPORT_ASSIGNMENT_ALL,
  TRANSPORT_ASSIGNMENT_POST,
  TRANSPORT_ASSIGNMENT_PUT,
  TRANSPORT_ASSIGNMENT_DEL,
  TRANSPORT_ASSIGNMENT_GET_BY_STATUS,
  TRANSPORT_ASSIGNMENT_GET_BY_PROCESSING_STATUS,
  TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE,
} from "../../actions/types";
const initialState = {
  assignment: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSPORT_ASSIGNMENT_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case TRANSPORT_ASSIGNMENT_GET_BY_STATUS:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case TRANSPORT_ASSIGNMENT_GET_BY_PROCESSING_STATUS:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case TRANSPORT_ASSIGNMENT_GET:
      return action.payload;
    case TRANSPORT_ASSIGNMENT_POST:
      return action.payload;
    case TRANSPORT_ASSIGNMENT_PUT:
      return action.payload;
    case TRANSPORT_ASSIGNMENT_DEL:
      return action.payload;
    default:
      return state;
  }
}
