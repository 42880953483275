import axios from "axios";
import {
    PATH_ALL,
    PATH_GET,
    PATH_POST,
    PATH_PUT,
    PATH_DEL,
} from '../types';

export const pathAll = () => {
    return async (dispatch) => {
        await axios .get(process.env.REACT_APP_API_URL + '/path')
        .then((res) => {
            console.log("Request Server to Get All path");
            dispatch({ type: PATH_ALL, payload: res.data });
        });
    };
};

export const pathGet = (id) => {
    return async (dispatch) => {
        await axios .get(process.env.REACT_APP_API_URL + '/path/' + id)
        .then((res) => {
            console.log("Request Server to Get path");
            dispatch({ type: PATH_GET, payload: res.data });
        });
    };
};

export const pathPost = (payload) => {
    return async (dispatch) => {
        await axios .post(process.env.REACT_APP_API_URL + '/path', payload)
        .then((res) => {
            console.log("Request Server to post path");
            dispatch({ type: PATH_POST, payload: null });
        });
    };
};

export const pathPut = (id, payload) => {
    return async (dispatch) => {
        await axios .put(process.env.REACT_APP_API_URL + '/path/' + id, payload)
        .then((res) => {
            console.log("Request Server to put path");
            dispatch({ type: PATH_PUT, payload: null });
        });
    };
};

export const pathDel = (id) => {
    return async (dispatch) => {
        await axios .delete(process.env.REACT_APP_API_URL + '/path/' + id)
        .then((res) => {
            console.log("Request Server to delete path");
            dispatch({ type: PATH_DEL, payload: null });
        });
    };
};