import { storage } from "config/firebase/firebase-client";
export const uploadImageFile = (fileName, file) => {
  return new Promise((resolve, reject) => {
    const uploadTask = storage
      .ref(`${process.env.REACT_APP_NAME}/${fileName}`)
      .putString(file, "data_url"); // data_url

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(percent + "% done");
        // progressVisible(percent);
      },
      function (error) {
        console.log(error);
        reject(error);
      },
      function (complete) {
        console.log("upload file complete!");
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      }
    );
  });
};

export const getBase64ImageFromURL = (url) => {
  console.log("URL : ", url);
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};
