import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@chakra-ui/react';
import _ from 'lodash';
import * as actions from '../../../../redux/actions';
import { green } from 'tailwindcss/colors';
import { TYPE_REPAIR } from 'config/constant/TypeRepair';

export default function RepairTypeLists({
    repairDetail,
    price,
    setPrice,
    type,
}) {
    const dispatch = useDispatch();
    const repair_type = useSelector((state) => state.repair_type);
    const [repairTypeData, setRepairTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [problem, setProblem] = useState(false);

    useEffect(() => {
        dispatch(actions.repairTypeAll({})).then(() => {
            setIsLoading(true);
        });
        return () => {};
    }, []);

    useEffect(() => {
        repair_type?.rows && setRepairTypeData(filterRepairType());
        return () => {};
    }, [repair_type, problem, repairDetail]);

    const filterRepairType = () => {
        const result = _.filter(repair_type?.rows, (_type) => {
            return _type?.type == type;
        });
        return result;
    };

    const checkProblem = (id) => {
        const findProblem = _.find(repairDetail, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });

        if (findProblem === undefined) {
            return false;
        } else {
            return true;
        }
    };

    const checkPrice = (id) => {
        const findProblem = _.find(repairDetail, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });

        if (findProblem === undefined) {
            return 0;
        } else {
            repairDetail[repairDetail.indexOf(findProblem)].price =
                findProblem.price;
            price[repairDetail.indexOf(findProblem)] =
                repairDetail[repairDetail.indexOf(findProblem)];
            return findProblem.price;
        }
    };

    const handleOnChange = (id, value) => {
        const findProblem = _.find(repairDetail, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });

        if (findProblem != undefined) {
            repairDetail[repairDetail.indexOf(findProblem)].price = value;
            price[repairDetail.indexOf(findProblem)] =
                repairDetail[repairDetail.indexOf(findProblem)];
        }
    };

    const showInput = (id) => {
        const findProblem = _.find(repairDetail, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });

        if (findProblem === undefined) {
            return (
                <input
                    name="price"
                    defaultValue={checkPrice(id)}
                    onChange={(e) => {
                        handleOnChange(id, e.target.value);
                    }}
                    disabled
                    placeholder={checkPrice(id)}
                    className="pl-2 placeholder-gray-200 bg-gray-300 text-gray-700 rounded-sm shadow  focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-center"
                />
            );
        } else {
            return (
                <input
                    name="price"
                    defaultValue={checkPrice(id)}
                    onChange={(e) => {
                        handleOnChange(id, e.target.value);
                    }}
                    placeholder={checkPrice(id)}
                    className="pl-2 placeholder-gray-200 bg-white text-gray-700 rounded-sm shadow  focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-center"
                />
            );
        }
    };

    const genRepairType = () => {
        return _.map(repairTypeData, (repair_type, index) => (
            <div
                key={repair_type.repair_type_id}
                className="py-1 px-2 flex justify-between"
            >
                <div className="flex justify-start">
                    <div className="pr-2">
                        <Checkbox
                            defaultChecked={checkProblem(
                                repair_type?.repair_type_id
                            )}
                            onChange={() => {
                                const findProblem = _.find(
                                    repairDetail,
                                    function (repair_type_id) {
                                        return (
                                            repair_type_id.repair_type_id ===
                                            repair_type.repair_type_id
                                        );
                                    }
                                );
                                if (findProblem === undefined) {
                                    const selectProblem = {
                                        repair_type_id:
                                            repair_type.repair_type_id,
                                        price: 0,
                                    };
                                    repairDetail.push(selectProblem);
                                    setPrice(repairDetail);
                                } else {
                                    repairDetail.splice(
                                        repairDetail.indexOf(findProblem),
                                        1
                                    );
                                    setPrice(repairDetail);
                                }
                                setProblem(!problem);
                            }}
                        ></Checkbox>
                    </div>
                    <div className="pr-2">{repair_type?.repair_detail}</div>
                </div>

                <div className=" flex justify-end border box-border w-40">
                    <div className="">
                        <label className="pt-1 px-2 bg-gray-300 block uppercase text-gray-700 text-sm font-bold rounded-sm shadow">
                            ราคา
                        </label>
                    </div>
                    {isLoading && showInput(repair_type?.repair_type_id)}
                </div>
            </div>
        ));
    };
    if (isLoading)
        return (
            <div className="bg-scroll max-h-36 overflow-auto">
                <div className="bg-white p-2 rounded shadow">
                    {genRepairType()}
                </div>
            </div>
        );
    else {
        return <></>;
    }
}
