import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import * as actions from '../../../../../redux/actions';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import { SUB_CATEGORY_REPAIR } from 'config/constant/SubCategoryRepair';
import { HeadVehiclePrintPDF } from '../../report/HeadVehiclePrintPDF';
import { TailVehiclePrintPDF } from '../../report/TailVehiclePrintPDF';
import { HeadAndTailVehiclePrintPDF } from '../../report/HeadAndTailVehiclePrintPDF';
import { TYPE_REPAIR } from 'config/constant/TypeRepair';
import currencyFormatterTH from '../../../../../util/functions/currencyFormat';

export default function RepairMonthVehicleLists({ repair_log, company, id }) {
    // const [logNew, setLogNew] = useState([]);

    // const checkLogStatus = () => {
    //     _.map(repair_log, (log) => {
    //         if (log?.repair_status === REPAIR_STATUS[2]) {
    //             // setLogNew(...logNew, { log });
    //             logNew.push(log);
    //         }
    //     });
    // };

    // useEffect(() => {
    //     checkLogStatus();
    //     return () => {};
    // }, []);

    // const dispatch = useDispatch();
    // const me = useSelector((state) => state.me);

    // useEffect(() => {
    //     dispatch(actions.meGet());
    //     return () => {};
    // }, []);

    // console.log('me', me);

    const showStatus = (status) => {
        if (status === REPAIR_STATUS[2]) {
            return (
                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        } else {
            return (
                <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        }
    };

    const genRepairType = (data) => {
        return _.map(data, (_data) => (
            <div key={_data?.repair_type_id} className="pt-2">
                {'- ' + _data?.repair_type?.repair_detail}
            </div>
        ));
    };

    const genPrice = (data) => {
        let sum = 0;
        for (let i = 0; i < _.size(data); i++) {
            sum = parseFloat(sum) + parseFloat(data[i].price);
        }
        return currencyFormatterTH.format(sum) + ' บาท';
    };

    const typeColor = (data) => {
        if (data === TYPE_REPAIR[0]) {
            return (
                <div className="font-medium text-sm text-center bg-blue-200 hover:bg-blue-300">
                    {data}
                </div>
            );
        } else if (data === TYPE_REPAIR[1]) {
            return (
                <div className="font-medium text-sm text-center bg-purple-200 hover:bg-purple-300">
                    {data}
                </div>
            );
        } else if (data === TYPE_REPAIR[2]) {
            return (
                <div className="font-medium text-sm text-center bg-green-200 hover:bg-green-300">
                    {data}
                </div>
            );
        }
    };

    // const renderHidden = () => {
    //     if (
    //         me?.employee_email !== 'erp@iarc.com' ||
    //         me?.employee_email !== 'admin@4polabear.com'
    //     ) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };

    const genTd = () => {
        return _.map(repair_log, (log, index) => (
            <Tr key={log.repair_log_id}>
                {log?.repair_status === REPAIR_STATUS[2] ? (
                    <>
                        {/* <Td>{index + 1}</Td> */}
                        <Td>{moment(log?.start_date).format('L')}</Td>
                        <Td>{moment(log?.end_date).format('L')}</Td>
                        {/* <Td>{log?.typeData?.detail}</Td> */}
                        <Td>{log?.data?.detail}</Td>
                        <Td>
                            {log?.mileage == null ? (
                                <div className=""> - </div>
                            ) : (
                                <div>
                                    {currencyFormatterTH.format(
                                        log?.mileage?.mileage
                                    )}
                                </div>
                            )}
                        </Td>
                        <Td>
                            {log?.vehicle == null ? (
                                <div className=""> - </div>
                            ) : (
                                <div className="">
                                    {log?.vehicle?.serial_number}
                                </div>
                            )}
                        </Td>
                        <Td>
                            {log?.driver == null ? (
                                <div className=""> - </div>
                            ) : (
                                <div className="">
                                    {log?.driver?.employee_first_name +
                                        ' ' +
                                        log?.driver?.employee_last_name}
                                </div>
                            )}
                        </Td>
                        <Td>{genRepairType(log?.problem_logs)}</Td>
                        <Td>{genPrice(log?.problem_logs)}</Td>
                        <Td>
                            {log?.cause == null ? (
                                <div className=""> - </div>
                            ) : (
                                <div className="">{log?.cause}</div>
                            )}
                        </Td>
                        <Td>
                            {log?.repair_modify == null ? (
                                <div className=""> - </div>
                            ) : (
                                <div className="">{log?.repair_modify}</div>
                            )}
                        </Td>
                        <Td>
                            <div className="">
                                <Button
                                    size="xs"
                                    onClick={() => {
                                        if (
                                            log?.sub_vehicle ===
                                            SUB_CATEGORY_REPAIR[0]
                                        ) {
                                            HeadVehiclePrintPDF(log, company);
                                        } else if (
                                            log?.sub_vehicle ===
                                            SUB_CATEGORY_REPAIR[1]
                                        ) {
                                            TailVehiclePrintPDF(log, company);
                                        } else if (
                                            log?.sub_vehicle ===
                                            SUB_CATEGORY_REPAIR[2]
                                        ) {
                                            HeadAndTailVehiclePrintPDF(
                                                log,
                                                company
                                            );
                                        }
                                    }}
                                >
                                    <i className="fas fa-print cursor-pointer mr-1"></i>{' '}
                                    พิมพ์
                                </Button>
                            </div>
                            <div>
                                <Link
                                    to={`/cmms/history/repair/vehicle/${id}/detail/${log.repair_log_id}`}
                                >
                                    <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center">
                                        รายละเอียด
                                    </span>
                                </Link>
                            </div>
                        </Td>
                        <Td>
                            <div>{showStatus(log?.repair_status)}</div>
                            {/* <div className="mt-2 justify-center">
                                <Button
                                    size="xs"
                                    variant="outline"
                                    colorScheme="yellow"
                                    hidden={renderHidden()}
                                    disabled
                                >
                                    แก้ไข
                                </Button>
                            </div> */}
                        </Td>
                    </>
                ) : (
                    <>
                        {/* <div className="text-center mt-4">ไม่มีข้อมูล</div> */}
                    </>
                )}
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <colgroup>
                    <col width="8%" />
                    <col width="8%" />
                    <col width="10%" />
                    <col width="7%" />
                    <col width="7%" />
                    <col width="10%" />
                    <col width="13%" />
                    <col width="7%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="5%" />
                    <col width="5%" />
                </colgroup>
                <Thead>
                    <Tr>
                        {/* <Th>ลำดับ</Th> */}
                        <Th>วันที่แจ้งซ่อม</Th>
                        <Th>วันที่ซ่อมสำเร็จ</Th>
                        <Th>ประเภท</Th>
                        <Th>เลขไมล์</Th>
                        <Th>ทะเบียนรถ</Th>
                        <Th>พนักงานขับรถ</Th>
                        <Th>รายละเอียด</Th>
                        <Th>ราคารวม</Th>
                        <Th>สาเหตุ</Th>
                        <Th>วิธีแก้ไข</Th>
                        <Th>ใบแจ้งซ่อม</Th>
                        <Th>สถานะ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
