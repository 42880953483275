import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import * as actions from '../../../../redux/actions';
import _ from 'lodash';

export default function TypeLists({ type, repair_type }) {
    const dispatch = useDispatch();

    const onDelete = (id) => {
        dispatch(actions.typeDelete(id)).then(() => {
            dispatch(actions.typeAll());
        });
    };

    const findRepairType = (type_id) => {
        const res = _.filter(repair_type, (_repair) => {
            return _repair?.type == type_id;
        });

        if (_.isEmpty(res)) {
            return false;
        } else {
            return true;
        }
    };

    const genTd = () => {
        return _.map(type, (_type, index) => (
            <Tr key={_type?.type_id}>
                <Td>{index + 1}</Td>
                <Td>{_type?.detail} </Td>
                <Td>
                    <div className="flex">
                        <div className="">
                            <Link
                                to={`/cmms/master/type/edit/${_type?.type_id}`}
                            >
                                <Button colorScheme="yellow" size="sm">
                                    แก้ไข
                                </Button>
                            </Link>
                        </div>
                        <div className=" px-2">
                            <Button
                                colorScheme="red"
                                size="sm"
                                disabled={findRepairType(_type?.type_id)}
                                onClick={() => {
                                    const confirm =
                                        window.confirm('ยืนยันการลบ');
                                    if (confirm) {
                                        onDelete(_type?.type_id);
                                    }
                                }}
                            >
                                ลบ
                            </Button>
                        </div>
                    </div>
                </Td>
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <colgroup>
                    <col width="10%" />
                    <col width="30%" />
                    <col width="20%" />
                </colgroup>
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>ประเภทการแจ้งซ่อม</Th>
                        <Th>ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
