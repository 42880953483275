import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import VehicleDriverLists from "./components/VehicleDriverLists";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";

export default function VehicleDriverManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const car_owner = useSelector((state) => state.car_owner);
    const [car_owner_data, setCarOwner] = useState([]);

    useEffect(() => {
        dispatch(actions.carOwnerAll());
        return () => {}
    }, [])

    useEffect(() => {
        setCarOwner(car_owner?.arr);
        setIsLoading(true)
        return () => {}
    }, [car_owner])

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    จัดการรถและคนขับ
                </h2>
                <div className="py-4">
                    <Link to={"/fms/vehicle/match/driver"}>
                        <Button colorScheme="blue">เพิ่ม</Button>
                    </Link>
                </div>
                <div className="p-4">
                    {_.size(car_owner_data) ? (
                        <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                            <VehicleDriverLists car_owner={car_owner_data} />
                        </div>
                    ) : (
                        <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                            ไม่มีข้อมูล
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}