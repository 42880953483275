import _ from 'lodash';

export default function CheckDriver(employees) {
    return _.filter(
        employees,
        (eachEmployee) =>
            eachEmployee?.position?.department?.department_id ===
            'f7d12253-5132-4351-9f32-a26da5f9ac16' ||
            eachEmployee?.position?.department?.department_name ===
            'พนักงานขับรถ' ||
            eachEmployee?.position?.department?.department_name ===
            'แผนกพนักงานขับรถ' ||
            eachEmployee?.position?.department?.department_name === 'คนขับรถ' ||
            eachEmployee?.position?.department?.department_name === 'ขับรถ' ||
            eachEmployee?.position?.department?.department_name === 'เดินรถ' ||
            eachEmployee?.position?.department?.department_name === 'โชเฟอร์' ||
            eachEmployee?.position?.department?.department_name === 'นายหัว' ||
            eachEmployee?.position?.department?.department_name === 'คนขับ' ||
            eachEmployee?.position?.department?.department_name === 'Driver' ||
            eachEmployee?.position?.department?.department_name === 'driver' ||
            eachEmployee?.position?.department?.department_name === 'ขับ'
    );
}
