import {
  DELIVERY_ALL,
  DELIVERY_GET,
  DELIVERY_GET_BY_DRIVER,
  DELIVERY_GET_BY_STATUS,
  DELIVERY_GET_BY_PROCESSING_STATUS,
  DELIVERY_GET_INVERT_TYPE,
  DELIVERY_PUT,
  DELIVERY_PUT_STATUS,
  DELIVERY_DEL,
  DELIVERY_POST,
} from "../../actions/types";

const initialState = {
  delivery: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case DELIVERY_GET_BY_STATUS:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case DELIVERY_GET_BY_PROCESSING_STATUS:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case DELIVERY_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case DELIVERY_GET_BY_DRIVER:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case DELIVERY_GET_INVERT_TYPE:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case DELIVERY_POST:
      return action.payload;
    case DELIVERY_PUT:
      return action.payload;
    case DELIVERY_PUT_STATUS:
      return action.payload;
    case DELIVERY_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
