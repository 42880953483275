import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import { Link, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";

export default function EditVehicleDriver() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        return () => { }
    }, [])

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                แก้ไขรถและคนขับ
            </h2>
            <div className="flex mt-4 justify-between">
                <div>
                    <Link to={"/fms/vehicle/driver"}>
                        <Button variant="outline">กลับ</Button>
                    </Link>
                </div>
                <div>
                    <Button type="submit" colorScheme="blue">
                        บันทึก
                    </Button>
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}