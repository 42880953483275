import React, { useState, useEffect } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';

// components
import Sidebar from 'components/common/Sidebar/Sidebar-CMMS';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views
import OrderManagement from 'views/cmms/order-management/OrderManagement';
import RepairTypeManagement from 'views/cmms/repairType-management/RepairTypeManagement';
import CreateType from 'views/cmms/createType/CreateType';
import RepairVehicleManagement from 'views/cmms/repairVehicle-management/RepairVehicleManagement';
import RepairWheelManagement from 'views/cmms/repairWheel-management/RepairWheelManagement';
import CreateRepairVehicle from 'views/cmms/createRepairVehicle/CreateRepairVehicle';
import CreateRepairVehicleAdmin from 'views/cmms/createRepairVehicle/CreateRepairVehicleAdmin';
import CreateRepairWheel from 'views/cmms/createRepairWheel/CreateRepairWheel';
import CreateRepairWheelAdmin from 'views/cmms/createRepairWheel/CreateRepairWheelAdmin';
import DetailRepairVehicle from 'views/cmms/detailRepairVehicle/DetailRepairVehicle';
import DetailRepairWheel from 'views/cmms/detailRepairWheel/DetailRepairWheel';
import DetailRepairType from 'views/cmms/detailRepairType/DetailRepairType';
import EditRepairType from 'views/cmms/editRepairType/EditRepairType';
import ReportVehicle from 'views/cmms/report/ReportVehicle';
import ReportWheel from 'views/cmms/report/ReportWheel';
import RepairRecordVehicle from 'views/cmms/repairVehicle-management/components/RepairRecordVehicle';
import RepairRecordWheel from 'views/cmms/repairWheel-management/components/RepairRecordWheel';
import HistoryRepairVehicle from 'views/cmms/history/historyVehicle/HistoryRepairVehicle';
import HistoryRepairWheel from 'views/cmms/history/historyWheel/HistoryRepairWheel';
import DetailHistoryRepairVehicle from 'views/cmms/history/historyVehicle/DetailHistoryRepairVehicle';
import DetailHistoryRepairWheel from 'views/cmms/history/historyWheel/DetailHistoryRepairWheel';
import DetailRepairLogVehicle from 'views/cmms/history/historyVehicle/DetailRepairLog';
import DetailRepairLogWheel from 'views/cmms/history/historyWheel/DetailRepairLog';
import NotifyManagement from 'views/cmms/notify/NotifyManagement';
import TypeManagement from 'views/cmms/type-management/TypeManagement';
import CreateTypeMaster from 'views/cmms/createTypeMaster/CreateTypeMaster';
import EditTypeMaster from 'views/cmms/editTypeMaster/EditTypeMaster';
import EditRepairLog from 'views/cmms/history/historyVehicle/EditRepairLog';

//components loading
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import NotFound from 'components/NotFound/NotFound';

//const
import { PERMISSION_LEVEL } from '../config/constant/PermissionLevel';

//functions
import checkRole from '../util/checkroles';

export default function CMMS() {
    const location = useLocation();
    const history = useHistory();
    const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
    const system_info = useSelector((state) => state.system_info);
    const [isLoading, setIsLoading] = useState(false);
    const [permissionLevel, setPermissionLevel] = useState('');

    useEffect(() => {
        console.log('TMS Layout : ACTIVE');

        dispatch(actions.systemInfoAll()).then(() => {
            dispatch(actions.meGet(storage_remember)).then(() => {});
        });
        return () => {
            dispatch(actions.meReset());
        };
    }, []);

    useEffect(() => {
        if (me && system_info && system_info.isLoading === true) {
            const isAccess = checkRole(
                me?.position?.department?.department_roles,
                location.pathname
            );
            console.log(isAccess);

            //หากไม่มี position และ position_id = 1จะเป็นบัญชีเริ่มต้น
            if (me.position === null && me.position_id === '1') {
                setPermissionLevel(PERMISSION_LEVEL[2]);
            } else {
                setPermissionLevel(me?.position?.permission_level);
            }

            if (isAccess) {
                setIsLoading(true);
                console.log('ได้รับการอนุญาตเข้าถึง');
            } else {
                console.log('ไม่ได้รับอนุญาต');
                alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้');
                history.push('/');
            }
        }
        return () => {};
    }, [me]);
    return isLoading ? (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-gray-200">
                <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
                    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
                        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full max-w-full flex-grow flex-1">
                                    <h6 className="uppercase text-gray-500 text-xs font-semibold">
                                        บริหารจัดการการแจ้งซ่อม
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="px-4">
                            <Switch>
                                {permissionLevel === PERMISSION_LEVEL[0] ? (
                                    <Redirect
                                        exact
                                        from="/cmms"
                                        to="/cmms/repair/vehicle/create"
                                    />
                                ) : (
                                    <Redirect
                                        exact
                                        from="/cmms"
                                        to="/cmms/repair/vehicle"
                                    />
                                )}

                                {permissionLevel === PERMISSION_LEVEL[2] ||
                                permissionLevel === PERMISSION_LEVEL[1] ? (
                                    <Route
                                        path="/cmms"
                                        exact
                                        component={RepairVehicleManagement}
                                    />
                                ) : (
                                    <Route
                                        path="/cmms"
                                        exact
                                        component={CreateRepairVehicle}
                                    />
                                )}

                                {/* staff route */}

                                {permissionLevel === PERMISSION_LEVEL[0] && (
                                    <Route
                                        path="/cmms/repair/vehicle/create"
                                        exact
                                        component={CreateRepairVehicle}
                                    />
                                )}

                                {permissionLevel === PERMISSION_LEVEL[0] && (
                                    <Route
                                        path="/cmms/repair/wheel/create"
                                        exact
                                        component={CreateRepairWheel}
                                    />
                                )}

                                {/* managemnet and president route */}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/vehicle/create/admin"
                                        exact
                                        component={CreateRepairVehicleAdmin}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/wheel/create/admin"
                                        exact
                                        component={CreateRepairWheelAdmin}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/vehicle"
                                        exact
                                        component={RepairVehicleManagement}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/orders"
                                        exact
                                        component={OrderManagement}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/type"
                                        exact
                                        component={RepairTypeManagement}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/master/type"
                                        exact
                                        component={TypeManagement}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/master/type/create"
                                        exact
                                        component={CreateTypeMaster}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/master/type/edit/:id"
                                        exact
                                        component={EditTypeMaster}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/type/create"
                                        exact
                                        component={CreateType}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/wheel"
                                        exact
                                        component={RepairWheelManagement}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/vehicle/detail/:id"
                                        exact
                                        component={DetailRepairVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/wheel/detail/:id"
                                        exact
                                        component={DetailRepairWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/type/detail/:id"
                                        exact
                                        component={DetailRepairType}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/type/edit/:id"
                                        exact
                                        component={EditRepairType}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/vehicle/report/:id"
                                        exact
                                        component={ReportVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/wheel/report/:id"
                                        exact
                                        component={ReportWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/vehicle/record/:id"
                                        exact
                                        component={RepairRecordVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/repair/wheel/record/:id"
                                        exact
                                        component={RepairRecordWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/vehicle"
                                        exact
                                        component={HistoryRepairVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/vehicle/:id"
                                        exact
                                        component={DetailHistoryRepairVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/wheel"
                                        exact
                                        component={HistoryRepairWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/wheel/:id"
                                        exact
                                        component={DetailHistoryRepairWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/vehicle/:id/edit/detail/:logId"
                                        exact
                                        component={EditRepairLog}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/vehicle/:id/detail/:logId"
                                        exact
                                        component={DetailRepairLogVehicle}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/history/repair/wheel/:id/detail/:logId"
                                        exact
                                        component={DetailRepairLogWheel}
                                    />
                                )}

                                {(permissionLevel === PERMISSION_LEVEL[2] ||
                                    permissionLevel ===
                                        PERMISSION_LEVEL[1]) && (
                                    <Route
                                        path="/cmms/notify"
                                        exact
                                        component={NotifyManagement}
                                    />
                                )}

                                <Route component={NotFound} />
                            </Switch>
                        </div>
                        <div />
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </>
    ) : (
        <SpinnerLoading />
    );
}
