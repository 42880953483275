import React from "react";
import PropTypes from "prop-types";

export default function CardCustomerDashbroad({
  title,
  customer_phone_number,
  logo,
  customer_type,
}) {
  return (
    <>
      <div className="h-48 m-auto bg-white rounded-xl relative text-white shadow-xl border transition-transform transform hover:scale-110"
        style={{ backgroundImage: "url(https://image.freepik.com/free-vector/minimalist-white-abstract-background_23-2148816119.jpg)" }}
      >
        <div className="flex w-full h-full">
          <div className="w-2/4 flex items-center justify-center">
            <div className="items-center justify-center mt-2">

              {logo ? (
                <div>
                  <img
                    alt="..."
                    className="rounded max-h-24 max-w-24"
                    src={logo}
                  />

                </div>
              ) : (
                <>
                  {" "}
                  <img
                    alt="..."
                    className="rounded max-h-24 max-w-24"
                    src={require("assets/img/user-profile.png")}
                  />
                </>
              )}
            </div>


          </div>

          <div className="w-2/4 flex items-center justify-center mr-2">
            <div className="text-center text-black text-sm bold font-sans mt-2">
              <p className="lg:text-text-base">{title}</p>
              <p className="mt-2">ประเภท : {customer_type}</p>
              <p className="mt-2"><i className="fa fa-phone mr-2 text-sm text-gray-900"></i>{customer_phone_number}</p>
            </div>


          </div>
        </div>


      </div>
    </>
  );
}

CardCustomerDashbroad.defaultProps = {
  title: "ชื่อบริษัท",
  customer_phone_number: "เบอร์สำนักงาน",
  logo: null,
  customer_type: "ประเภทลูกค้า",
};

CardCustomerDashbroad.propTypes = {
  title: PropTypes.string,
  customer_phone_number: PropTypes.string,
  logo: PropTypes.string,
  customer_type: PropTypes.string,
};
