import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import Select from 'react-select';

import * as actions from '../../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';

import RepairMonthWheelLists from './components/RepairMonthWheelLists';

import { MonthDataWheelPrintPDF } from '../report/MonthDataWheelPrintPDF';

export default function DetailHistoryRepairWheel() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const repair_month = useSelector((state) => state.repair_month);
    const vehicle = useSelector((state) => state.vehicle);
    const company = useSelector((state) => state.company);

    const [month, setMonth] = useState();
    const [vehicle_data, setVehicleData] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    useEffect(() => {
        dispatch(actions.repairMonthGetWheel(id)).then(() => {
            dispatch(actions.vehicleAll()).then(() => {
                dispatch(actions.companyAll());
            });
        });
        return () => {};
    }, []);

    useEffect(() => {
        setMonth(repair_month[0]);
        setIsLoading(true);
        return () => {};
    }, [repair_month[0]]);

    useEffect(() => {
        vehicle?.arr && setVehicleData(filterVehicleData());

        return () => {};
    }, [vehicle]);

    useEffect(() => {
        if (selectedVehicle != null) {
            dispatch(
                actions.repairMonthGetWheelByHeadAndTail(
                    id,
                    selectedVehicle?.value,
                    null
                )
            );
        }
        return () => {};
    }, [selectedVehicle]);

    const filterVehicleData = () => {
        // const vehicleResult = _.filter(vehicle?.arr, (_vehicle) => {
        //     return _vehicle?.category_id === '1';
        // });

        const result = _.map(vehicle?.arr, (vehicle) => {
            return {
                value: vehicle?.vehicle_id,
                label:
                    vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle?.serial_number,
                data: vehicle,
            };
        });
        return result;
    };

    const handleChangeSelectedVehicle = (option) => {
        setSelectedVehicle(option);
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                ประวัติการแจ้งซ่อมยาง {moment(month?.month).format('MMMM')}/
                {moment(month?.month).format('YYYY')}
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Button onClick={() => history.goBack()} variant="outline">
                        กลับ
                    </Button>
                </div>
                <div>
                    <Button
                        colorScheme="blue"
                        onClick={() => {
                            MonthDataWheelPrintPDF(month, company);
                        }}
                    >
                        พิมพ์
                    </Button>
                </div>
            </div>
            <div className="relative flex px-4 mt-4 justify-between ">
                <div className="w-5/12">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ค้นหาหัวรถ{' '}
                    </label>
                    <Select
                        placeholder={'ค้นหาหัวรถ'}
                        onChange={handleChangeSelectedVehicle}
                        options={vehicle_data}
                    />
                </div>
            </div>
            <div className="p-4">
                {_.size(month?.repair_logs) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        {' '}
                        <RepairMonthWheelLists
                            repair_log={month?.repair_logs}
                            company={company}
                            id={id}
                        />
                    </div>
                ) : (
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
