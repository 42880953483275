import axios from "axios";
import {
  SUB_CATEGORY_VEHICLE_ALL,
  SUB_CATEGORY_VEHICLE_GET,
  SUB_CATEGORY_VEHICLE_PUT,
  SUB_CATEGORY_VEHICLE_DEL,
  SUB_CATEGORY_VEHICLE_POST,
  SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY,
} from "../types";

export const sub_category_vehicleAll = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/sub_category_vehicle")
      .then((res) => {
        console.log("Request Server to Get All sub_category_vehicles");
        dispatch({ type: SUB_CATEGORY_VEHICLE_ALL, payload: res.data });
      });
  };
};

export const sub_category_vehicleGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/sub_category_vehicle/" + payload)
      .then((res) => {
        console.log("Request Server to Get an sub_category_vehicles");
        if (res.data) {
          dispatch({ type: SUB_CATEGORY_VEHICLE_GET, payload: res.data });
        } else {
          dispatch({ type: SUB_CATEGORY_VEHICLE_GET, payload: null });
        }
      });
  };
};

export const sub_category_vehicleAllByCategory = (payload) => {
  console.log(process.env.REACT_APP_API_URL + "/sub_category_vehicle/category_vehicle/" + payload);
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/sub_category_vehicle/category_vehicle/" + payload)
      .then((res) => {
        console.log("Request Server to All sub_category_vehicle By Category");
        if (res.data) {
          console.log(res.data);
          dispatch({ type: SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY, payload: res.data });
        }
      });
  };
};

export const sub_category_vehiclePost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/sub_category_vehicle", payload)
      .then((res) => {
        console.log("Request Server to post an sub_category_vehicle");
        dispatch({ type: SUB_CATEGORY_VEHICLE_POST, payload: null });
      });
  };
};

export const sub_category_vehiclePut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/sub_category_vehicle/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an sub_category_vehicles");
        dispatch({ type: SUB_CATEGORY_VEHICLE_PUT, payload: null });
      });
  };
};
export const sub_category_vehicleDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/sub_category_vehicle/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an sub_category_vehicles");
        dispatch({ type: SUB_CATEGORY_VEHICLE_DEL, payload: null });
      });
  };
};
