import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";

export default function OrderManagement() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        return () => { }
    }, [])

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">การแจ้งซ่อม</h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-16 w-full md:w-6/12 items-center flex flex-wrap mx-auto">
                <div className="my-4  px-4">
                    <Link to={"/cmms/repair/vehicle"}>
                        <div className="bg-pink-700  shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                            <i className="fas fa-truck fa-3x text-white"></i>
                            <p className="text-sm text-white mt-4 ">
                                แจ้งซ่อมรถ
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="my-4  px-4">
                    <Link to={"/cmms/repair/wheel"}>
                        <div className="bg-purple-700  shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                            <i className="fas fa-truck fa-3x text-white"></i>
                            <p className="text-sm text-white mt-4 ">
                                แจ้งซ่อมยาง
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}