import {
  COMPANY_ALL,
  COMPANY_GET,
  COMPANY_PUT,
  COMPANY_DEL,
  COMPANY_POST,
} from "../../actions/types";

const initialState = {
  company: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case COMPANY_ALL:
      return {
        ...action.payload,
        isLoading: true,
      };
    case COMPANY_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case COMPANY_POST:
      return action.payload;
    case COMPANY_PUT:
      return action.payload;
    case COMPANY_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
