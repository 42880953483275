import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _, { isEmpty, set } from 'lodash';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';
import { TYPE_REPAIR } from '../../../config/constant/TypeRepair';
import { VEHICLE_STATUS } from '../../../config/constant/VehicleStatus';
import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import RepairTypeLists from './components/RepairTypeLists';
import ImageUpload from '../../../components/ImageUpload/ImageMultiUpload';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';
import { WORD_TYPE_IMAGE_REPAIR } from '../../../config/constant/TypeImageRepair';
import { EMPLOYEE_STATUS } from '../../../config/constant/EmployeeStatus';
import CheckDriver from '../../../util/CheckDriver';

export default function CreateRepairVehicleAdmin() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const car_owner = useSelector((state) => state.car_owner);
    const mileage = useSelector((state) => state.mileage);
    const vehicles = useSelector((state) => state.vehicle);
    const repair_type = useSelector((state) => state.repair_type);
    const employee = useSelector((state) => state.employee);
    const type = useSelector((state) => state.type);
    const [vehicle_data, setVehicleData] = useState([]);
    const [selectRepairType, setSelectRepairType] = useState([]);
    const [repairTypeData, setRepairTypeData] = useState([]);
    const [driver_data, setDriver] = useState([]);

    const [selectType, setSelectType] = useState();
    const [selectVehicle, setSelectVehicle] = useState();
    const [selectDriver, setSelectDriver] = useState();
    const [mileage_data, setMileageData] = useState('0');
    const [vehicle, setVehicle] = useState([]);
    const [subCategoryVehicle, setSubCategoryVehicle] = useState(
        CATEGORY_VEHICLE[0]
    );
    const [image, setImage] = useState([]);

    useEffect(() => {
        dispatch(actions.meGet()).then(() => {
            dispatch(actions.employeeGetByStatus(EMPLOYEE_STATUS[0])).then(
                () => {
                    dispatch(actions.vehicleAll()).then(() => {
                        dispatch(actions.repairTypeAll({})).then(() => {
                            dispatch(actions.typeAll());
                            setIsLoading(true);
                        });
                    });
                }
            );
        });

        return () => {};
    }, []);

    useEffect(() => {
        setVehicleData(vehicles?.arr);
        setDriver(CheckDriver(employee?.arr));
        vehicles?.arr && setVehicle(vehicles?.arr);
        setIsLoading(true);
        return () => {};
    }, [car_owner, vehicles]);

    useEffect(() => {
        if (selectVehicle) {
            dispatch(actions.mileageGetLatest(selectVehicle));
        }
        return () => {};
    }, [selectVehicle]);

    useEffect(() => {
        if (mileage.mileage) {
            setMileageData(mileage.mileage);
        }
        return () => {};
    }, [mileage]);

    useEffect(() => {
        console.log('result',filterRepairType() );
        repair_type?.rows && setRepairTypeData(filterRepairType());
        return () => {};
    }, [selectType, repair_type]);

    console.log('repair_type', repair_type);

    const filterRepairType = () => {
        const result = _.filter(repair_type?.rows, (_type) => {
            return _type?.type == selectType;
        });
        return result;
    };

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const onSubmit = async (data) => {
        data.employee_id = me?.employee_id;
        data.problem = selectRepairType;
        data.sub_vehicle = SUB_CATEGORY_REPAIR[0];

        let imagesURL = [];
        if (!isEmpty(image)) {
            for await (const img of image.map((image) =>
                handleUploadImage(image)
            )) {
                imagesURL.push({
                    img,
                    type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                });
            }
            data.repair_img = imagesURL;
        }

        console.log('DATA', data);

        dispatch(actions.repairPostVehicle(data)).then(() => {
            alert('สำเร็จ');
            window.location.reload();
        });
    };

    const genVehicleList = () => {
        return _.map(vehicle, (_vehicle) => (
            <option key={_vehicle.vehicle_id} value={_vehicle.vehicle_id}>
                {_vehicle?.category_vehicle?.category_name +
                    '/' +
                    _vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    _vehicle.serial_number}
            </option>
        ));
    };

    const genDriverList = () => {
        return _.map(driver_data, (driver) => (
            <option key={driver.employee_id} value={driver.employee_id}>
                {driver?.employee_first_name + ' ' + driver?.employee_last_name}
            </option>
        ));
    };

    const genTypeRepair = () => {
        return _.map(type?.arr, (_type) => (
            <option key={_type?.type_id} value={_type?.type_id}>
                {_type?.detail}
            </option>
        ));
    };

    const showVehicleData = (data) => {
        return (
            <>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ทะเบียนรถ{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <select
                        name="vehicle_id"
                        type="text"
                        ref={register}
                        disabled={subCategoryVehicle === SUB_CATEGORY_REPAIR[1]}
                        onChange={(e) => {
                            setSelectVehicle(e.target.value);
                        }}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                        <option value="" disabled selected>
                            กรุณาเลือก
                        </option>
                        {genVehicleList()}
                    </select>
                </div>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        เลขไมล์{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <input
                        name="mileage"
                        required
                        ref={register}
                        placeholder="เลขไมล์"
                        disabled={subCategoryVehicle === SUB_CATEGORY_REPAIR[1]}
                        value={mileage_data}
                        onChange={(e) => {
                            setMileageData(e.target.value);
                        }}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 disabled:bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    ></input>
                </div>
            </>
        );
    };

    const renderImageMultiUpload = () => {
        return (
            <div className="relative w-full mb-3 ">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mt-3 mb-3 "
                    htmlFor="grid-password"
                >
                    รูปภาพ
                </label>
                <div className="bg-white rounded shadow  p-4">
                    <ImageUpload
                        images={image}
                        setImages={setImage}
                        preview_size={'150'}
                        maxNumber={20}
                    />
                </div>
            </div>
        );
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                เพิ่มรายการแจ้งซ่อม
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex ml-4 mt-4 justify-between">
                    <div>
                        <Button
                            onClick={() => history.goBack()}
                            variant="outline"
                        >
                            กลับ
                        </Button>
                    </div>
                </div>
                <div className="flex flex-wrap p-4  justify-around  h-full">
                    <div className="w-full lg:w-6/12 ">
                        <h6 className="text-gray-700 text-xs mt-3 mb-3 font-bold uppercase">
                            ข้อมูลรถ
                        </h6>
                        <div className="bg-white shadow rounded px-4  p-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ประเภท{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="type"
                                    type="text"
                                    ref={register}
                                    onChange={(e) => {
                                        setSelectType(e.target.value);
                                    }}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือก
                                    </option>
                                    {genTypeRepair()}
                                </select>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    พนักงานขับรถ{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="driver_id"
                                    type="text"
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือก
                                    </option>
                                    {genDriverList()}
                                </select>
                            </div>

                            {showVehicleData(subCategoryVehicle)}

                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    รายละเอียด
                                </label>
                                <textarea
                                    name="repair_detail"
                                    type="textfield"
                                    rows={3}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="รายละเอียด"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 p-4">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รายละเอียดการซ่อม{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            {_.size(repairTypeData) ? (
                                <div>
                                    <RepairTypeLists
                                        selectRepairType={selectRepairType}
                                        setSelectRepairType={
                                            setSelectRepairType
                                        }
                                        repairTypeData={repairTypeData}
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center bg-white rounded p-4 shadow">
                                    ไม่มีข้อมูล
                                </div>
                            )}
                        </div>
                        <div>{renderImageMultiUpload()}</div>
                    </div>
                </div>
                <div className="text-center mt-3 px-4">
                    <Button isFullWidth colorScheme="blue" type="submit">
                        บันทึก
                    </Button>
                </div>
                <br />
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
