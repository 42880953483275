import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import TransportAssignmentLists from './components/TransportAssignmentLists';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import AssignmentModal from '../../../components/Modal/AssignmentModal/TransportAssignmentModal';
import _ from 'lodash';
import CONST_CONFIG from '../../../config/constant';

export default function ImportAssignmentManagement() {
    const dispatch = useDispatch();
    const transport_assignment = useSelector(
        (state) => state.transport_assignment
    );
    const [isLoading, setIsLoading] = useState(false);
    const [transport_assignment_data, setTransportAssignmentData] = useState(
        []
    );
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [selectedAssignment,setSelectedAssignment] = useState()
    
    const [isEditorMode, setIsEditorMode] = useState(true);

    useEffect(() => {
        dispatch(actions.transportAssignmentGetProcessingStatus());
        return () => {};
    }, []);

    useEffect(() => {
        setTransportAssignmentData(transport_assignment?.arr);
        setIsLoading(true);
        return () => {};
    }, [transport_assignment]);

    const assignmentModalCloseHanlde = () => {
        setIsAssignmentModalOpen(false);
        setSelectedAssignment();
    };

    const handleModalOpen = (assignment) => {
     setSelectedAssignment(assignment)
        setIsAssignmentModalOpen(true);
    };

    const handleDeleteAssignment = (assignment) => {
        // console.log("assignment", assignment);
        // console.log("NEW assignment", assignment);
        const confirm = window.confirm('ยืนยันการยกเลิกรายการการจัดส่ง');
        assignment.assignment_status = CONST_CONFIG.ASSIGNMENT_STATUS[3];
        // const payload = {
        //     assignment_status: CONST_CONFIG.ASSIGNMENT_STATUS[3],
        // };
        if (confirm)
            dispatch(
                actions.transportAssignmentPut(assignment.assignment_id, assignment)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.transportAssignmentGetProcessingStatus());
            });
    };

    const handleReadyAssignment = (assignmentId) => {
        const confirm = window.confirm('ยืนยันความพร้อมรายการการจัดส่ง');
        const payload = {
            assignment_status: CONST_CONFIG.ASSIGNMENT_STATUS[4],
        };
        if (confirm)
            dispatch(
                actions.transportAssignmentPut(assignmentId, payload)
            ).then(() => {
                setIsLoading(false);
                dispatch(actions.transportAssignmentGetProcessingStatus());
            });
    };

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    รายการจัดส่งสินค้า
                    {CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport}
                </h2>
            </div>
            <div className="py-4">
                <Link to="/tms/management/import/assignment/create">
                    <Button colorScheme="blue">เพิ่ม</Button>
                </Link>
            </div>
            <div className="py-4">
                <AssignmentModal
                selectedAssignment={selectedAssignment}
                    openStatus={isAssignmentModalOpen}
                    closeHandle={assignmentModalCloseHanlde}
                    isEditorMode={isEditorMode}
                    setIsEditorMode={setIsEditorMode}
                />
                {/* ตารางนำเข้า */}
                {_.size(
                    _.filter(
                        transport_assignment_data,
                        (assignment) =>
                            assignment.assignment_type ===
                                CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport &&
                            assignment.assignment_status ===
                                CONST_CONFIG.ASSIGNMENT_STATUS[0]
                    )
                ) ? (
                    <div>
                        <h2 className="text-xl font-bold text-center mb-2">
                            {' '}
                            รอดำเนินการ
                            {CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport}
                        </h2>
                        <div className="overflow-x-auto border-black border-2  border-opacity-10 rounded">
                            <TransportAssignmentLists
                                transport_assignment={_.filter(
                                    transport_assignment_data,
                                    (assignment) =>
                                        assignment.assignment_type ===
                                            CONST_CONFIG.WORD_TRANSPORT_SERVICE
                                                .intImport &&
                                        assignment.assignment_status ===
                                            CONST_CONFIG.ASSIGNMENT_STATUS[0]
                                )}
                                handleOpenAssignmentModal={handleModalOpen}
                                handleReadyAssignment={handleReadyAssignment}
                                handleDeleteAssignment={handleDeleteAssignment}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-xl font-bold text-center mb-2">
                            รอดำเนินการ
                            {CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport}
                        </h2>
                        <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                            <div>ไม่มีข้อมูล</div>
                        </div>
                    </div>
                )}
                <br />
                {_.size(
                    _.filter(
                        transport_assignment_data,
                        (assignment) =>
                            assignment.assignment_type ===
                                CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport &&
                            (assignment.assignment_status ===
                                CONST_CONFIG.ASSIGNMENT_STATUS[4] ||
                                assignment.assignment_status ===
                                    CONST_CONFIG.ASSIGNMENT_STATUS[1])
                    )
                ) ? (
                    <div>
                        <h2 className="text-xl font-bold text-center mb-2">
                            {' '}
                            พร้อมดำเนินการ
                            {CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport}
                        </h2>
                        <div className="overflow-x-auto border-black border-2  border-opacity-10 rounded">
                            <TransportAssignmentLists
                                transport_assignment={_.filter(
                                    transport_assignment_data,
                                    (assignment) =>
                                        assignment.assignment_type ===
                                            CONST_CONFIG.WORD_TRANSPORT_SERVICE
                                                .intImport &&
                                        (assignment.assignment_status ===
                                            CONST_CONFIG.ASSIGNMENT_STATUS[4] ||
                                            assignment.assignment_status ===
                                                CONST_CONFIG
                                                    .ASSIGNMENT_STATUS[1])
                                )}
                                handleOpenAssignmentModal={handleModalOpen}
                                handleDeleteAssignment={handleDeleteAssignment}
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-xl font-bold text-center mb-2">
                            พร้อมดำเนินการ
                            {CONST_CONFIG.WORD_TRANSPORT_SERVICE.intImport}
                        </h2>
                        <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                            <div>ไม่มีข้อมูล</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
