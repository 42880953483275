import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Button, HStack } from '@chakra-ui/react';
import * as actions from '../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import CardVehicle from 'components/common/Cards/CardVehicle';
import _ from 'lodash';

export default function DetailVehicle() {
    const { id } = useParams();
    const { vehicle_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const vehicle = useSelector((state) => state.vehicle);
    const mileage = useSelector((state) => state.mileage);
    const [isLoading, setIsLoading] = useState(false);
    const [vehicle_attributes, setVehicle_attributes] = useState();

    useEffect(() => {
        dispatch(actions.vehicleGet(vehicle_id)).then(() => {
            dispatch(actions.mileageGetLatest(vehicle_id)).then(() => {
                setIsLoading(true);
            });
        });

        return () => {};
    }, []);

    useEffect(() => {
        _.map(vehicle?.vehicle_attributes, (attribute) => {
            return setVehicle_attributes(attribute);
        });

        setIsLoading(true);
        return () => {};
    }, [vehicle]);

    // console.log('vehicle', vehicle);
    // console.log('vehicle_attributes', vehicle_attributes);
    // console.log("mileage", mileage);
    // console.log("id", id);
    // console.log("vehicle_id", vehicle_id);
    const onDelete = () => {
        dispatch(actions.vehicleDelete(vehicle_id)).then(() => {
            history.push('/fms/category/' + id + '/vehicle');
        });
    };

    return isLoading ? (
        <div>
            <div className="relative w-full max-w-full flex-grow flex-1">
                <h2 className="text-gray-800 text-xl font-semibold  py-1">
                    ข้อมูลยานพาหนะ
                </h2>
            </div>
            <div className="flex py-6 justify-between">
                <div>
                    <Link to={'/fms/category/' + id + '/vehicle'}>
                        {' '}
                        <Button variant="outline">กลับ</Button>
                    </Link>
                </div>
                <div>
                    <HStack spacing={2}>
                        <Box>
                            <Link
                                to={
                                    '/fms/category/' +
                                    id +
                                    '/vehicle/edit/' +
                                    vehicle_id
                                }
                            >
                                {' '}
                                <Button colorScheme="yellow">แก้ไข</Button>
                            </Link>
                        </Box>

                        <Button
                            colorScheme="red"
                            onClick={() => {
                                const confirm = window.confirm(
                                    'ยืนยันการลบข้อมูลยานพาหนะ'
                                );
                                if (confirm) {
                                    onDelete();
                                }
                            }}
                        >
                            ลบ
                        </Button>
                    </HStack>
                </div>
            </div>
            <div className=" flex flex-wrap p-4 justify-center">
                <div className="w-full">
                    <CardVehicle
                        vehicle_image={vehicle.vehicle_image}
                        category_name={vehicle?.category_vehicle?.category_name}
                        sub_category_name={
                            vehicle?.sub_category_vehicle?.sub_category_name
                        }
                        serial_number={vehicle?.serial_number}
                        vehicle_load_weight={vehicle?.vehicle_load_weight}
                        vehicle_detail={vehicle?.vehicle_detail}
                        vehicle_note={vehicle?.vehicle_note}
                        vehicle_status={vehicle?.vehicle_status}
                        mileage={mileage?.mileage}
                        brand={vehicle_attributes?.brand}
                        generation={vehicle_attributes?.generation}
                        buy_date={vehicle_attributes?.buy_date}
                        price={vehicle_attributes?.price}
                        insurance_start={vehicle_attributes?.insurance_start}
                        insurance_end={vehicle_attributes?.insurance_end}
                        car_keeper={vehicle_attributes?.car_keeper}
                        insurance_company={
                            vehicle_attributes?.insurance_company
                        }
                        insurance_type={vehicle_attributes?.insurance_type}
                        invoice_date={vehicle_attributes?.invoice_date}
                    />
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
