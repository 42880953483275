import {
    //ดึงข้อมูลการแจ้งซ่อมทั้งหมดตามเดือน
    REPAIR_MONTH_ALL_VEHICLE,
    REPAIR_MONTH_GET_VEHICLE,
    REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL,
    REPAIR_MONTH_ALL_WHEEL,
    REPAIR_MONTH_GET_WHEEL,
    REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL,
} from "../../actions/types";

const initialState = {
    repair_month: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REPAIR_MONTH_ALL_VEHICLE:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_MONTH_GET_VEHICLE:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_MONTH_ALL_WHEEL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_MONTH_GET_WHEEL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL:
            return {
                ...action.payload,
                isLoading: true,
            };
        default:
            return state;
    }
}