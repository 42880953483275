import React, { useState, useEffect } from 'react';
import {
    Badge,
    Modal,
    Button,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
} from '@chakra-ui/react';
import _ from 'lodash';
import PropType from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import * as actions from '../../../redux/actions';
import hash from 'object-hash';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../util/image.functions';
import { CATEGORY_VEHICLE } from 'config/constant/CategoryVehicle';

export default function AddHeadVechicleModal({
    openStatus,
    closeHandle,
}) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm();
    const [image, setImage] = useState([]);
    const sub_category = useSelector((state) => state.sub_category_vehicle);
    const [sub_category_data, setSubCategoryData] = useState([]);

    useEffect(() => {
        dispatch(actions.sub_category_vehicleAllByCategory(CATEGORY_VEHICLE[0]));
        return () => { };
    }, []);
    useEffect(() => {
        setSubCategoryData(sub_category.arr);
        setIsLoading(true);
        return () => { };
    }, [sub_category]);

    const onSubmit = (data, e) => {
        data.attribute = {
            brand: data.brand,
            generation: data.generation,
            buy_date: data.buy_date,
            price: data.price,
            insurance_start: data.insurance_start,
            insurance_end: data.insurance_end,
        };

        if (_.size(image)) {
            const filename = hash({ image, date: new Date() });
            data.category_id = CATEGORY_VEHICLE[0];
            uploadImageFile(filename, image[0].data_url).then((url) => {
                console.log('URL : ' + url);
                data.vehicle_image = url;
                dispatch(actions.vehiclePost(data)).then(() => {
                    reset();
                    setImage([]);
                    alert('บันทึกยานพาหนะสำเร็จ');
                });
                window.location.reload();
            });
        } else {
            data.category_id = CATEGORY_VEHICLE[0];
            console.log("data",data);
            dispatch(actions.vehiclePost(data)).then(() => {
                reset();
                setImage([]);
                alert('บันทึกยานพาหนะสำเร็จ');
            });
            window.location.reload();

        }
    };
    const genSubcategoryList = () =>
        _.map(sub_category_data, (sub_category) => (
            <option
                key={sub_category.sub_category_id}
                value={sub_category.sub_category_id}
            >
                {sub_category.sub_category_name}
            </option>
        ));
    return isLoading ? (
        <div>
            <Modal isOpen={openStatus} onClose={closeHandle} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="font-sans text-base">
                        เพิ่มหัวรถ
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-2">
                                <div className="flex flex-wrap  justify-center h-full">
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                ประเภท{' '}
                                                <label className="text-red-600 text-xs">
                                                    {' '}
                                        *
                                            </label>
                                            </label>
                                            <select
                                                name="sub_category_id"
                                                type="text"
                                                required
                                                ref={register}
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            >
                                                <option value="" disabled selected>
                                                    กรุณาเลือกประเภท
                                    </option>
                                                {genSubcategoryList()}
                                            </select>
                                        </div>
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                ป้ายทะเบียน{' '}
                                                <label className="text-red-600 text-xs">
                                                    {' '}
                                        *
                                            </label>
                                            </label>
                                            <input
                                                name="serial_number"
                                                required
                                                ref={register}
                                                placeholder="ป้ายทะเบียน"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            ></input>
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                รายละเอียด
                                            </label>
                                            <textarea
                                                name="vehicle_detail"
                                                type="textfield"
                                                ref={register}
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 h-24"
                                                placeholder="รายละเอียด"
                                            />
                                        </div>

                                        <div className="w-full ">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                รูปภาพยานพาหนะ
                                            </label>
                                            <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                                                <ImageUpload
                                                    images={image}
                                                    setImages={setImage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="flex flex-wrap w-full "></div>
                                        <div className="flex flex-row ">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    น้ำหนักที่รองรับได้{' '}
                                                    <label className="text-red-600 text-xs">
                                                        {' '}
                                            *
                                                </label>
                                                </label>
                                                <input
                                                    name="vehicle_load_weight"
                                                    type="number"
                                                    required
                                                    ref={register}
                                                    placeholder="น้ำหนัก"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    เลขไมล์{' '}
                                                    <label className="text-red-600 text-xs">
                                                        {' '}
                                            *
                                                 </label>
                                                </label>
                                                <input
                                                    name="mileage"
                                                    type="number"
                                                    required
                                                    ref={register}
                                                    placeholder="เลขไมล์"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                        </div>

                                        <div className="flex flex-row ">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    ยี่ห้อ
                                                </label>
                                                <input
                                                    name="brand"
                                                    ref={register}
                                                    placeholder="ยี่ห้อ"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    รุ่น
                                                </label>
                                                <input
                                                    name="generation"
                                                    ref={register}
                                                    placeholder="รุ่น"
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="flex flex-row ">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    วันที่ซื้อ
                                                </label>
                                                <input
                                                    name="buy_date"
                                                    type="date"
                                                    defaultValue={"1970-01-01"}
                                                    ref={register}
                                                    placeholder="วันที่ซื้อ"
                                                    className="px-1 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    ราคา
                                                </label>
                                                <input
                                                    name="price"
                                                    type="number"
                                                    ref={register}
                                                    placeholder="ราคา"
                                                    className="py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                        </div>

                                        <div className="flex flex-row ">
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    วันที่เริ่มทำประกัน
                                                </label>
                                                <input
                                                    name="insurance_start"
                                                    type="date"
                                                    defaultValue={"1970-01-01"}
                                                    ref={register}
                                                    placeholder="วันที่เริ่มทำประกัน"
                                                    className="py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                            <div className="relative w-full mb-3">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    วันที่สิ้นสุดทำประกัน
                                                </label>
                                                <input
                                                    name="insurance_end"
                                                    type="date"
                                                    defaultValue={"1970-01-01"}
                                                    ref={register}
                                                    placeholder="วันที่สิ้นสุดทำประกัน"
                                                    className="py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                ></input>
                                            </div>
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                หมายเหตุ
                                            </label>
                                            <textarea
                                                name="vehicle_note"
                                                type="textfield"
                                                ref={register}
                                                className="px-3 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                placeholder="หมายเหตุ"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-6 px-4">
                                    <Button isFullWidth colorScheme="blue" type="submit">
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    ) : (
        <>
        </>
    )

}
