import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";

// components
import Sidebar from "components/common/Sidebar/Sidebar-Me";
import FooterAdmin from "components/common/Footers/FooterAdmin.js";

// views
import MyProfile from "views/me/dashboard/MyProfile";

export default function ME() {
  const history = useHistory();
  const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
  const system_info = useSelector((state) => state.system_info);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("ME Layout : ACTIVE");
    dispatch(actions.systemInfoAll()).then(() => {
      dispatch(actions.meGet(storage_remember)).then(() => {});
    });
    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me) {
      if (me) {
        setIsLoading(true);
        console.log("ได้รับการอนุญาตเข้าถึง");
      } else {
        console.log("ไม่ได้รับอนุญาต");
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้");
        history.push("/");
      }
    }
    return () => {};
  }, [me]);

  return (
    isLoading && (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-gray-200">
          <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
            <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
              <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h6 className="uppercase text-gray-500 text-xs font-semibold">
                      ข้อมูลของฉัน
                    </h6>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <Switch>
                  <Route path="/data/dashboard" exact component={ MyProfile} />
                  <Redirect from="/data" to="/data/dashboard" />
                </Switch>
              </div>
              <div />
            </div>
          </div>
          <FooterAdmin />
        </div>
      </>
    )
  );
}
