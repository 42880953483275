import {
    NOTIFY_ALL,
    NOTIFY_ALL_ACTIVE,
    NOTIFY_ALL_DELAY,
    NOTIFY_GET,
    NOTIFY_POST,
    NOTIFY_PUT,
    NOTIFY_DEL,
    NOTIFY_BY_EMPLOYEE,
    NOTIFY_BY_VEHICLE,
} from '../../actions/types';

const initialState = {
    notify: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NOTIFY_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case NOTIFY_ALL_ACTIVE:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case NOTIFY_ALL_DELAY:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case NOTIFY_BY_EMPLOYEE:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case NOTIFY_BY_VEHICLE:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case NOTIFY_GET:
            return {
                ...action.payload,
                isLoading: true,
            };

        case NOTIFY_POST:
            return action.payload;
        case NOTIFY_PUT:
            return action.payload;
        case NOTIFY_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}
