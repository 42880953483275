import React from "react";
import CardInfo from "components/common/Cards/CardInfo";
import { Link } from "react-router-dom";
import _ from "lodash";

export default function RouteLists({ route }) {

    const genCardRoute = () => {
        return _.map(route, (_route) => (
            <div key={_route?.route_id} className="w-full lg:w-3/12 p-2">
                <Link to={`/fms/route/detail/${_route?.route_id}`}>
                    <CardInfo
                        title={_route?.title}
                        subtitle={_route?.title}
                        icon="fa fa-list-alt"
                    />
                </Link>
            </div>
        ))
    }

    return (
        <div className=" flex-auto">
            <div className="relative flex flex-wrap">
                {genCardRoute()}
            </div>
        </div>
    )
}