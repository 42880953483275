import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Input,
    Button,
    Checkbox,
    FormControl,
    Select,
    Textarea,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import SelectComponent from 'react-select';

import 'flatpickr/dist/themes/airbnb.css';
import * as actions from '../../redux/actions/';

/**
 * @function NotifyEditBox
 * Modal สามารถเรียกได้ 2 แห่งคือ จากหน้าแจ้งเตือน ไม่มี VehicleId กับตอนสำเร็จการแจ้งซ่อม
 * ตรงนี้จะมี Vehicle Id อยู่แล้ว  */

export default function NotifyEditBox({
    notifyData,
    handleClose,
    defaultVehicle = null,
    handleParentClose,
}) {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const vehicle = useSelector((state) => state.vehicle);

    const [isUseMileage, setIsUseMileage] = useState(
        notifyData?.goal_mileage !== '-'
    );
    const [isUseDate, setIsUseDate] = useState(notifyData?.goal_date !== '-');
    const [selectedVehicle, setSelectedVehicle] = useState();

    const [notifyDate, setNotifyDate] = useState(notifyData?.goal_date);
    const [notifyMileage, setNotifyMileage] = useState(
        notifyData?.goal_mileage
    );

    const addDateToCurrentDay = (days) => {
        const currentDay = notifyDate;
        const resultDay = moment(currentDay).add(days, 'days');
        setNotifyDate(resultDay.toDate());
    };

    const handleEditNotify = (data) => {
        // Decorate with checkbox
        if (!isUseMileage) {
            data.goal_mileage = '-';
        }
        if (!isUseDate) {
            data.goal_date = '-';
        }
        console.log('Put Existing Notification');
        console.log('data', data);
        console.log('Notify Data', notifyData);
        dispatch(actions.notifyPut(notifyData?.notify_id, data)).then(() => {
            dispatch(actions.vehicleAllByHeadAndTail());
        });

        handleClose();
        handleParentClose();
    };

    return (
        <div className="rounded-md ">
            <form>
                <h4>แก้ไข</h4>
                <input name="reminder" hidden value={5} ref={register} />
                <input type="hidden" name="type_id" value=" " ref={register} />

                <div>
                    {/** แบ่ง Case ถ้าถูกเรียกเข้ามาโดยไม่มี vehicle Id เราจำให้แสดง AutoComplete ให้เลือกรถ
                     * แต่ถ้ามีเราจะยัด Vehicle Id ไว้ใน Form และ Hidden เอาไว้   แต่สุดท้ายทั้งคู่เมื่อ submit ฟอร์มนี้
                     * มันจะไปเป็น value ของ key vehicle_id เหมือนกัน
                     */}
                    {defaultVehicle ? (
                        <FormControl className="my-1">
                            <input
                                name="vehicle_id"
                                hidden
                                value={defaultVehicle?.vehicle_id}
                                ref={register}
                            />
                            <SelectComponent
                                value={{
                                    value: defaultVehicle?.vehicle_id,
                                    label:
                                        defaultVehicle?.category_vehicle
                                            ?.category_name +
                                        ' ' +
                                        defaultVehicle?.sub_category_vehicle
                                            ?.sub_category_name +
                                        ' ' +
                                        defaultVehicle?.serial_number,
                                }}
                            />
                        </FormControl>
                    ) : (
                        <FormControl className="my-1">
                            <label className="text-sm my-1">
                                รถที่ต้องการแจ้งซ่อม
                            </label>
                            <input
                                name="vehicle_id"
                                hidden
                                value={selectedVehicle?.value}
                                ref={register}
                            />
                            <SelectComponent
                                name="vehicle_id"
                                ref={register}
                                placeholder={'กรุณารถที่ต้องการแจ้ง'}
                                onChange={setSelectedVehicle}
                                value={selectedVehicle}
                                defaultValue={
                                    defaultVehicle
                                        ? {
                                              value: defaultVehicle?.vehicle_id,
                                              label:
                                                  defaultVehicle
                                                      ?.category_vehicle
                                                      ?.category_name +
                                                  ' ' +
                                                  defaultVehicle
                                                      ?.sub_category_vehicle
                                                      ?.sub_category_name +
                                                  ' ' +
                                                  defaultVehicle?.serial_number,
                                          }
                                        : ''
                                }
                                options={_.map(vehicle?.arr, (eachVehicle) => ({
                                    value: eachVehicle?.vehicle_id,
                                    label:
                                        eachVehicle?.category_vehicle
                                            ?.category_name +
                                        ' ' +
                                        eachVehicle?.sub_category_vehicle
                                            ?.sub_category_name +
                                        ' ' +
                                        eachVehicle?.serial_number,
                                }))}
                            />
                        </FormControl>
                    )}

                    <FormControl className="my-2">
                        <label className="text-sm">หัวข้อนัดหมาย</label>
                        <Textarea
                            ref={register}
                            type="text"
                            size="sm"
                            rows={3}
                            name="title"
                            defaultValue={notifyData?.title}
                        />
                    </FormControl>
                </div>

                <div className=" p-2 rounded-md  flex gap-2 my-2">
                    <Checkbox
                        isChecked={isUseMileage}
                        onChange={(event) => {
                            setIsUseMileage(event.target.checked);
                        }}
                    />
                    <i className="fas fa-tachometer-alt text-lg  self-center" />

                    <span className="mx-4 self-center whitespace-nowrap">
                        เลขไมล์
                    </span>

                    <Input
                        type="number"
                        size="sm"
                        name="goal_mileage"
                        className="mx-2"
                        ref={register}
                        value={notifyMileage}
                        onChange={(event) =>
                            setNotifyMileage(event.target.value)
                        }
                    />

                    <span className=" self-center"> km.</span>
                </div>

                <div className="flex  p-2 rounded-md  gap-2">
                    <Checkbox
                        isChecked={isUseDate}
                        onChange={(event) => {
                            setIsUseDate(event.target.checked);
                        }}
                    />
                    <i className="fas fa-calendar text-lg self-center" />
                    <span className="self-center mx-4"> วันที่</span>
                    <div>
                        <Flatpickr
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            value={notifyDate}
                            onChange={(date) => {
                                setNotifyDate(date[0]);
                            }}
                        />
                        <input
                            type="text"
                            hidden
                            ref={register}
                            name="goal_date"
                            value={notifyDate}
                        />
                    </div>
                </div>
                <div className="flex my-1 gap-2 ml-2">
                    <Button size="xs" onClick={() => addDateToCurrentDay(15)}>
                        + 15 วัน
                    </Button>
                    <Button size="xs" onClick={() => addDateToCurrentDay(30)}>
                        + 1 เดือน
                    </Button>
                    <Button size="xs" onClick={() => addDateToCurrentDay(90)}>
                        + 3 เดือน
                    </Button>
                </div>
                <div className="flex justify-end gap-2">
                    <Button
                        colorScheme="blue"
                        type="button"
                        onClick={handleSubmit(handleEditNotify)}
                    >
                        บันทึก
                    </Button>
                    <Button
                        colorScheme="gray"
                        type="button"
                        onClick={() => handleClose()}
                    >
                        ยกเลิก
                    </Button>
                </div>
            </form>
        </div>
    );
}
