import axios from "axios";
import {
    CONTACT_ALL,
    CONTACT_ALL_BY_CUSTOMER,
    CONTACT_GET,
    CONTACT_POST,
    CONTACT_PUT,
    CONTACT_DEL,
} from "../types";

export const contactAll = () => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + "/contact").then((res) => {
            console.log("Request Server to Get All Contacts");
            dispatch({ type: CONTACT_ALL, payload: res.data });
        });
    };
};

export const contactAllByCustomer = (id) => {
    return async (dispatch) => {
        await axios
        .get(process.env.REACT_APP_API_URL + "/contact/by/customer/" + id)
        .then((res) => {
            console.log("Request Server to Get All Contacts by customer_id");
            dispatch({ type: CONTACT_ALL_BY_CUSTOMER, payload: res.data });
        });
    };
};

export const contactGet = (id) => {
    return async (dispatch) => {
        await axios
        .get(process.env.REACT_APP_API_URL + "/contact/" + id)
        .then((res) => {
            console.log("Request Server to Get All Contacts by customer_id");
            if (res.data) {
                dispatch({ type: CONTACT_GET, payload: res.data });
            } else {
                dispatch({ type: CONTACT_GET, payload: null });
            }
        });
    };
};

export const contactPost = (payload) => {
    return async (dispatch) => {
        await axios
        .post(process.env.REACT_APP_API_URL + "/contact" , payload)
        .then((res) => {
            console.log("Request Server to create Contacts.");
            dispatch({ type: CONTACT_POST, payload: res.data });
        });
    };
};

export const contactPut = (id, payload) => {
    return async (dispatch) => {
        await axios
        .put(process.env.REACT_APP_API_URL + "/contact/" + id , payload)
        .then((res) => {
            console.log("Request Server to update Contacts.");
            dispatch({ type: CONTACT_PUT, payload: res.data });
        });
    };
};

export const contactDelete = (id) => {
    return async (dispatch) => {
        await axios
        .delete(process.env.REACT_APP_API_URL + "/contact/" + id)
        .then((res) => {
            console.log("Request Server to Delete an Contact.");
            if (res.data) {
                dispatch({ type: CONTACT_DEL, payload: null });
            }
        });
    };
};


