import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import currencyFormatter from 'currency-formatter';
import currencyFormatterTH from 'util/functions/currencyFormat';
import _ from 'lodash';
import moment from 'moment';
import THBText from 'thai-baht-text';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf',
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf',
    },
};

const sumPrice = (problems) => {
    let sum = 0;
    for (let i = 0; i < _.size(problems); i++) {
        sum = parseFloat(sum) + parseFloat(problems[i].price);
        // console.log('price', problems[i].price);
        // console.log('sum', parseFloat(sum));
    }
    return sum;
};

const genRepairType = (problems) => {
    return _.map(problems, (_problems, index) => [
        {
            text: index + 1,
            border: [true, false, true, false],
            colSpan: 1,
            alignment: 'center',
        },
        {
            text: _problems?.repair_type?.repair_detail,
            border: [true, false, true, false],
            colSpan: 1,
        },
        {
            text: currencyFormatter.format(`${_problems?.price}`, {
                code: '',
            }),
            border: [true, false, true, false],
            colSpan: 1,
            alignment: 'center',
        },
    ]);
};

export const HeadVehiclePrintPDF = (log, company) => {
    const repair_type = genRepairType(log?.problem_logs);
    const sumTotal = sumPrice(log?.problem_logs);

    var docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [40, 40, 40, 40],
        defaultStyle: {
            font: 'THSarabunNew',
            fontSize: '16',
        },
        info: {
            title: 'ใบแจ้งซ่อมรถ',
        },
        content: [
            {
                alignment: 'justify',
                columns: [
                    {
                        image: 'company_logo',
                        width: 100,
                        margin: [0, 10, 0, 10],
                    },
                    [
                        {
                            text: company?.company_name,
                            fontSize: '18',
                            bold: true,
                            margin: [10, 2],
                        },

                        {
                            text: `${company?.company_address}`,
                            fontSize: '18',
                            margin: [10, 2],
                        },
                        {
                            text: `โทร ${company?.company_phone_number} โทรสาร ${company?.company_fax} `,
                            fontSize: '18',
                            margin: [10, 2],
                        },
                        {
                            text: `อีเมล ${company?.company_email} เลขประจำตัวผู้เสียภาษี ${company?.company_taxes_id}`,
                            fontSize: '18',
                            margin: [10, 2],
                        },
                    ],
                ],
            },
            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0,
                        y1: 0,
                        x2: 515,
                        y2: 0,
                        lineWidth: 1,
                    },
                ],
            },
            {
                //หัวข้อ
                alignment: 'center',
                margin: [0, 20, 0, 20],
                text: `ใบ${log?.typeData?.detail}`,
                fontSize: '23',
                bold: true,
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `วัน-เดือน-ปี ${moment(log?.start_date).format(
                            'DD/MM/YYYY'
                        )}`,
                        fontSize: '16',
                        width: 180,
                    },

                    {
                        text: `ประเภทรถ ${log?.vehicle?.sub_category_vehicle?.sub_category_name}`,
                        fontSize: '16',
                        width: 180,
                    },

                    {
                        text: `เลขไมล์ ${currencyFormatterTH.format(
                            log?.mileage?.mileage
                        )}`,
                        fontSize: '16',
                        width: 180,
                    },
                ],
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `ทะเบียนหัว ${log?.vehicle?.serial_number}`,
                        fontSize: '16',
                        width: 180,
                    },

                    {
                        text: `ชื่อคนขับ ${log?.driver?.employee_first_name} ${log?.driver?.employee_last_name}`,
                        fontSize: '16',
                        width: 180,
                    },
                ],
            },
            {
                margin: [0, 20, 0, 20],
                style: 'tableExample',
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0 ? '#c2dec2' : null;
                    },
                },
                table: {
                    widths: ['*', '70%', '*'],
                    heights: [10, 10, 10, 10, 30, 10, 25],
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: 'ลำดับ',
                                colSpan: 1,
                                fontSize: 16,
                                alignment: 'center',
                            },
                            {
                                text: 'รายละเอียดการซ่อม',
                                colSpan: 1,
                                fontSize: 16,
                            },
                            {
                                text: 'ราคา',
                                colSpan: 1,
                                fontSize: 16,
                                alignment: 'center',
                            },
                        ],
                        ...repair_type,
                        [
                            {
                                text: ` ( ${THBText(sumTotal)} )`,
                                colSpan: 2,
                                fontSize: 16,
                                alignment: 'center',
                            },
                            {},
                            {
                                text: currencyFormatter.format(`${sumTotal}`, {
                                    code: '',
                                }),
                                colSpan: 1,
                                fontSize: 16,
                                alignment: 'center',
                            },
                        ],
                    ],
                },
            },
            {
                margin: [0, 20, 0, 20],
                fontSize: 16,
                columns: [
                    [
                        {
                            text: `รายละเอียด :  ${log?.repair_detail}`,
                        },
                      
                    ],
                ],
            },
            {
                alignment: 'justify',
                margin: [0, 90, 0, 90],
                columns: [
                    [
                        {
                            text: `ลงชื่อ ..........................................ผู้แจ้ง`,
                            fontSize: '16',
                            alignment: 'center',
                        },
                        {
                            text: `(.......................................................)`,
                            fontSize: '16',
                            alignment: 'center',
                        },
                    ],
                    [
                        {
                            text: `ลงชื่อ ..........................................ผู้อนุมัติ`,
                            fontSize: '16',
                            alignment: 'center',
                        },
                        {
                            text: `(.......................................................)`,
                            fontSize: '16',
                            alignment: 'center',
                        },
                    ],
                ],
            },
        ],
        images: {
            company_logo: company?.company_logo,
        },
    };
    pdfMake.createPdf(docDefinition).open();
};
