import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
// import { useForm } from "react-hook-form";

export default function CardWheel({
    index_wheel,
    wheel_detail,
    setWheelDetail,
    setCH,
    CH
}) {
    const [collapse, setCollapse] = useState(false);

    // console.log(CH);
    // console.log("wheel_detail", wheel_detail);
    return (
        <>
            <div >
                    <div className="flex">
                        <div className="">
                            <Button
                                colorScheme="teal"
                                height="60px"
                                width="60px"
                                onClick={() => {
                                    setCollapse(!collapse);
                                }}
                            >
                                {index_wheel}
                            </Button>
                        </div>
                        <div className="">
                            {collapse &&
                                <div className="pl-1">
                                    <textarea
                                        rows={2}
                                        cols={10}
                                        onChange={(e) => {
                                            wheel_detail[index_wheel-1] = { wheel_detail: e.target.value, index_wheel: index_wheel[0] }

                                        }}
                                        placeholder="รายละเอียด"
                                        className="p-2 placeholder-gray-400 rounded text-sm shadow"
                                    >

                                    </textarea>
                                </div>
                            }
                        </div>
                    </div>

            </div>
        </>
    )
}

CardWheel.defaultProps = {
    index_wheel: "index"
}

CardWheel.propTypes = {
    index_wheel: PropTypes.string,
}