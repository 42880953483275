import {
    CATEGORY_VEHICLE_ALL,
    CATEGORY_VEHICLE_GET,
    CATEGORY_VEHICLE_POST,
    CATEGORY_VEHICLE_PUT,
    CATEGORY_VEHICLE_DEL
} from "../../actions/types";

const initialState = {
    category_vehicle: null,
    isLoading: false,
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case CATEGORY_VEHICLE_ALL:
        return {
          arr: [...action.payload],
          isLoading: true,
        };
      case CATEGORY_VEHICLE_GET:
        return {
          ...action.payload,
          isLoading: true,
        };
      case CATEGORY_VEHICLE_POST:
        return action.payload;
      case CATEGORY_VEHICLE_PUT:
        return action.payload;
      case CATEGORY_VEHICLE_DEL:
        return { isLoading: true };
      default:
        return state;
    }
  }