import React from "react";
import { Checkbox } from "@chakra-ui/react";
import _ from "lodash";

export default function RepairTypeLists({ 
    repairTypeData,
    price
}) {
    // console.log(repairTypeData);
    const genRepairType = () => {
        return _.map(repairTypeData, (repair_type, index) => (
            <div key={repair_type.problem_id} className="py-1 px-2 flex justify-between">
                <div className="flex justify-start">
                    <div className="pr-2">
                        <Checkbox isDisabled defaultIsChecked></Checkbox>
                    </div>
                    <div className="pr-2">
                        {repair_type.repair_type?.repair_detail}
                    </div>
                </div>

                <div className=" flex justify-end border box-border w-40">
                    <div className="">
                        <label className="pt-1 px-2 bg-gray-300 block uppercase text-gray-700 text-sm font-bold rounded-sm shadow">
                            ราคา
                    </label>
                    </div>
                    <div className="">
                        <input
                            name="price"
                            defaultValue={repair_type?.price}
                            onChange={(e) => {
                                price[index] = { problem_id: repair_type.problem_id, price: e.target.value }
                            }}
                            className="pl-2 placeholder-gray-400 text-gray-700 rounded-sm shadow  focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                        </input>
                    </div>
                </div>
            </div>
        ))
    }
    return (
        <div>
            <div className="flex-auto bg-white p-2 rounded shadow">
                <div className="relative ">
                    {genRepairType()}
                </div>
            </div>
        </div>
    )
}