import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import _ from 'lodash';

import { DeliveryToEvent } from '../../util/DeliveryToEvent';
import { AssignmentToEvent } from '../../util/AssignmentToEvent';
import DeliveryModal from '../Modal/DeliveryModal/DeliveryModal';
import CalendarSetting from './Components/CalendarSetting';
import TransportAssignmentModal from '../Modal/AssignmentModal/TransportAssignmentModal';

import { WORD_TRANSPORT_SERVICE } from '../../config/constant/TransportService';
import { WORD_DELIVERY_STATUS } from '../../config/constant/DeliveryStatus';
import { ASSIGNMENT_STATUS } from '../../config/constant/AssignmentStatus';

const DnDCalendar = Calendar;
const localizer = momentLocalizer(moment);

export default function DeliveryCalendar({
    deliveryArray,
    assignmentArray,
    isShowEditCalendar = false,
}) {
    const [events, setEvents] = useState();
    const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
    const [popoverDeliveryId, setPopoverDeliveryId] = useState('');
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [rerendered, setRerendered] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState()
    const [isEditorMode, setIsEditorMode] = useState(false);

    const filterAssignment = () => {
        const result = _.filter(
            assignmentArray,
            (_assignment) => {
                return (_assignment?.assignment_status === ASSIGNMENT_STATUS[0]
                    || _assignment?.assignment_status === ASSIGNMENT_STATUS[4]
                )
            })

        return result
    }
    const [assignmentData, setAssignmentData] = useState(filterAssignment());

    // Setting User wanted Data in Local Storage
    const defaultDeliveryTypeShow = localStorage.getItem(
        'erp_delivery_type_show'
    );
    if (!defaultDeliveryTypeShow) {
        localStorage.setItem('erp_delivery_type_show', true);
    }

    const defaultSubVehicalTypeShow = localStorage.getItem(
        'erp_sub_vechical_type_show'
    );
    if (!defaultSubVehicalTypeShow) {
        localStorage.setItem('erp_sub_vechical_type_show', true);
    }

    const defaultDescriptionShow = localStorage.getItem('erp_description_show');
    if (!defaultDescriptionShow) {
        localStorage.setItem('erp_description_show', true);
    }

    const defaultTebleLongShow = localStorage.getItem('erp_table_long_show');
    if (!defaultTebleLongShow) {
        localStorage.setItem('erp_table_long_show', true);
    }

    // Setting Description Inside

    const [isDeliveryTypeShow, setIsDeliveryTypeShow] = useState(
        defaultDeliveryTypeShow === 'false' ? false : true
    );
    const [isSubVehicalTypeShow, setIsSubVehicalTypeShow] = useState(
        defaultSubVehicalTypeShow === 'false' ? false : true
    );
    const [isDescriptionShow, setIsDescriptionShow] = useState(
        defaultDescriptionShow === 'false' ? false : true
    );
    const [isTableLongShow, setIsTableLongShow] = useState(
        defaultTebleLongShow === 'false' ? false : true
    );

    useEffect(() => {

        assignmentArray && setAssignmentData(filterAssignment())
        const datatoEvent = [[...DeliveryToEvent(
            deliveryArray,
            isDeliveryTypeShow,
            isSubVehicalTypeShow,
            isDescriptionShow
        )], AssignmentToEvent(assignmentData,
            isDeliveryTypeShow,
            isSubVehicalTypeShow,
            isDescriptionShow)]

        setEvents([...datatoEvent[0], ...datatoEvent[1]]);

        return () => { };
    }, [
        deliveryArray,
        assignmentArray,
        isDeliveryTypeShow,
        isSubVehicalTypeShow,
        isDescriptionShow,
    ]);

    const eventStyleGetter = (event, start, end, isSelected) => {
        if (event.type === WORD_TRANSPORT_SERVICE.intExport) {
            if (event.status === ASSIGNMENT_STATUS[0] || event.status === ASSIGNMENT_STATUS[4]) {
                var backgroundColor = '#F1D44C';
            } else {
                var backgroundColor = '#F59E0B';
            }
        }
        else if (event.type === WORD_TRANSPORT_SERVICE.domestic) {
            if (event.status === ASSIGNMENT_STATUS[0] || event.status === ASSIGNMENT_STATUS[4]) {
                var backgroundColor = '#FF8E9B';
            } else {
                var backgroundColor = '#d94773';
            }
        }

        else if (event.type === WORD_TRANSPORT_SERVICE.intImport) {
            if (event.status === ASSIGNMENT_STATUS[0] || event.status === ASSIGNMENT_STATUS[4]) {
                var backgroundColor = '#61bee7';
            } else {
                var backgroundColor = '#0286e7';
            }
        }



        else if (event.status === WORD_DELIVERY_STATUS.cancle) {
            if (event.status === ASSIGNMENT_STATUS[0] || event.status === ASSIGNMENT_STATUS[4]) {
                var backgroundColor = '#D6EAF8';
            } else {
                var backgroundColor = '#FF0000';
            }
        }
        else if (event.status === WORD_DELIVERY_STATUS.success) {
            if (event.status === ASSIGNMENT_STATUS[0] || event.status === ASSIGNMENT_STATUS[4]) {
                var backgroundColor = '#D6EAF8';
            } else {
                var backgroundColor = '#32CD32';
            }
        }
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        return {
            style: style,
        };
    };

    const onSelectedEvent = (event) => {
        setPopoverDeliveryId(event?.id);
        setIsDeliveryModalOpen(true);

        const result = _.find(
            assignmentData,
            (_assignment) => _assignment.assignment_id === event.id
        );
        setSelectedAssignment(result);
        setIsAssignmentModalOpen(true);
    };

    const handleCloseDeliveryModal = () => {
        setIsDeliveryModalOpen(false);
        setPopoverDeliveryId('');
    };

    const assignmentModalCloseHandle = () => {
        setIsAssignmentModalOpen(false);
        setSelectedAssignment();
    };

    if (events) {
        return (
            <div>
                <DeliveryModal
                    deliveryId={popoverDeliveryId}
                    openStatus={isDeliveryModalOpen}
                    closeHandle={handleCloseDeliveryModal}
                    allDelivery={deliveryArray}
                    allAssignments={assignmentArray}
                />

                <TransportAssignmentModal
                    selectedAssignment={selectedAssignment}
                    openStatus={isAssignmentModalOpen}
                    closeHandle={assignmentModalCloseHandle}
                    isEditorMode={isEditorMode}
                    setIsEditorMode={setIsEditorMode}
                />

                {/* <Collapse in={!isShowEditCalendar}> */}
                <CalendarSetting
                    isDeliveryTypeShow={isDeliveryTypeShow}
                    isDescriptionShow={isDeliveryTypeShow}
                    isSubVechicalShow={isSubVehicalTypeShow}
                    isTableLongShow={isTableLongShow}
                    setIsDeliveryTypeShow={setIsDeliveryTypeShow}
                    setIsDescriptionShow={setIsDescriptionShow}
                    setIsSubVechicleShow={setIsSubVehicalTypeShow}
                    setIsTableLongShow={setIsTableLongShow}
                    rendered={rerendered}
                    setRendered={setRerendered}
                />
                {/* </Collapse> */}
                <div style={{ height: isTableLongShow ? '200vh' : '100%' }}>
                    <DnDCalendar
                        culture={'th'}
                        defaultDate={moment().toDate()}
                        defaultView={Views.MONTH}
                        views={{ month: true }}
                        events={events}
                        localizer={localizer}
                        eventPropGetter={eventStyleGetter}
                        selectable={true}
                        onSelectEvent={onSelectedEvent}
                        dayLayoutAlgorithm="no-overlap"
                        resizable
                        popup={true}
                        style={{
                            height: isTableLongShow ? 'block' : 'contents',
                        }}
                    />
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
