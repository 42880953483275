import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import RepairWheelLists from './components/RepairWheelLists';

import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import ModalWheelRepair from '../../../components/Modal/WheelRepairModal/BodyWheelRepair';
import ModalRepairInfoRecord from '../../../components/Modal/RepairInfoRecordModal/ModalRepairInfoRecord';
import NotifyModal from '../../../components/Modal/NotifyModal/NotifyModal';

export default function RepairWheelManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const repair = useSelector((state) => state.repair);
    const company = useSelector((state) => state.company);

    const [headVehicle, setHeadVehicle] = useState([]);

    const [isModalRepairOpen, setIsModalRepairOpen] = useState(false);
    const [selectedRepairData, setSelectedRepairData] = useState();

    const [isRecordModalOpen, setIsRecordModalOpen] = useState(false);
    const [isModalNotifyOpen, setIsModalNotifyOpen] = useState(false);

    useEffect(() => {
        dispatch(actions.repairAllWheelByStatus()).then(() => {
            dispatch(actions.repairTypeAll());
            dispatch(actions.companyAll());
        });

        return () => {};
    }, []);

    useEffect(() => {
        repair?.arr && setHeadVehicle(repair?.arr);

        setIsLoading(true);
        return () => {};
    }, [repair, company]);

    const handleModalWheelRepairClose = () => {
        setIsModalRepairOpen(false);
        setSelectedRepairData({});
    };

    const handleCloseRecordModal = () => {
        setSelectedRepairData({});
        setIsRecordModalOpen(false);
    };

    const handleSuccessfulClose = () => {
        setIsRecordModalOpen(false);
        setIsModalNotifyOpen(true);
    };

    const handleCloseNotifyModal = () => {
        setIsModalNotifyOpen(false);
        setSelectedRepairData({});
    };

    const showRepairWheelLists = () => {
        return (
            <>
                <div>
                    {_.size(headVehicle) ? (
                        <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                            <RepairWheelLists
                                repair={headVehicle}
                                sub_vehicle={SUB_CATEGORY_REPAIR[0]}
                                company={company}
                                handleOpenRepairModal={() =>
                                    setIsModalRepairOpen(true)
                                }
                                setIsSelectedRepairData={setSelectedRepairData}
                                handleRecordModalOn={() =>
                                    setIsRecordModalOpen(true)
                                }
                            />
                        </div>
                    ) : (
                        <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                            ไม่มีข้อมูล
                        </div>
                    )}
                </div>
            </>
        );
    };

    return isLoading ? (
        <div>
            <ModalWheelRepair
                closeHandle={handleModalWheelRepairClose}
                openStatus={isModalRepairOpen}
                repairDetail={selectedRepairData}
            />
            <ModalRepairInfoRecord
                closeHandle={handleCloseRecordModal}
                openStatus={isRecordModalOpen}
                repair={selectedRepairData}
                successfulCloseHandle={handleSuccessfulClose}
            />

            {/* <NotifyModal
                closeHandle={handleCloseNotifyModal}
                currentMileage={selectedRepairData?.mileage?.mileage}
                openStatus={isModalNotifyOpen}
                vehicle={selectedRepairData?.vehicle}
            /> */}

            <h2 className="text-gray-800 text-xl font-semibold">
                รายการแจ้งซ่อมยาง
            </h2>
            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Link to={'/cmms/repair/wheel/create/admin'}>
                        <Button colorScheme="blue">เพิ่ม</Button>
                    </Link>
                </div>
            </div>
            <div className="p-4">{showRepairWheelLists()}</div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
