// import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import currencyFormatter from "currency-formatter";
import _ from "lodash";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
};

const genRepairWheel = (repair_wheels) => {
    return _.map(repair_wheels, (_repair_wheel) =>
        [

            {
                margin : [30,2],
                ul: [

                    {
                        columns: [
                            `ล้อที่ ${_repair_wheel?.index_wheel} ${_repair_wheel?.wheel_detail}`,
                            {
                                stack: [
                                    `ราคา ${_repair_wheel?.price} บาท`,

                                ]
                                , alignment: "left"
                            },

                        ]
                    },

                ]
            }

        ]
    )
}

export const HeadAndTailWheelPrintPDF = (repair, company) => {

    const repair_wheel = genRepairWheel(repair?.repair_wheels);

    var docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [40, 40, 40, 40],
        defaultStyle: {
            font: "THSarabunNew",
            fontSize: "16",
        },
        info: {
            title: "ใบแจ้งยาง"
        },
        content: [
            {
                alignment: "justify",
                columns: [
                    {
                        image: "company_logo",
                        width: 100,
                        margin: [0, 10, 0, 10],
                    },
                    [
                        {
                            text: company?.company_name,
                            fontSize: "18",
                            bold: true,
                            margin: [10, 2]
                        },

                        {
                            text: `${company?.company_address}`,
                            fontSize: "18",
                            margin: [10, 2]
                        },
                        {
                            text: `โทร ${company?.company_phone_number} โทรสาร ${company?.company_fax} `,
                            fontSize: "18",
                            margin: [10, 2]
                        },
                        {
                            text: `อีเมล ${company?.company_email} เลขประจำตัวผู้เสียภาษี ${company?.company_taxes_id}`,
                            fontSize: "18",
                            margin: [10, 2]
                        },
                    ]
                ]

            },
            {
                canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }]
            },
            {
                //หัวข้อ
                alignment: "center",
                margin: [0, 20, 0, 20],
                text: "ใบแจ้งยาง",
                fontSize: "23",
                bold: true,
            },
            {
                alignment: "justify",
                columns: [

                    {
                        text: `วัน-เดือน-ปี ${moment(repair.createdAt).format("DD/MM/YYYY")}`,
                        fontSize: "16",
                        width: 180,

                    },

                    {
                        text: `ประเภทรถ ${repair?.vehicle?.sub_category_vehicle?.sub_category_name}`,
                        fontSize: "16",
                        width: 180,

                    },

                    {
                        text: `เลขไมล์ ${repair?.mileage?.mileage}`,
                        fontSize: "16",
                        width: 180,

                    },

                ]

            },
            {
                alignment: "justify",
                columns: [

                    {
                        text: `ทะเบียนหัว ${repair?.vehicle?.serial_number}`,
                        fontSize: "16",
                        width: 180,

                    },

                    {
                        text: `ทะเบียนหาง ${repair?.vehicle_tail?.serial_number}`,
                        fontSize: "16",
                        width: 180,

                    },

                    {
                        text: `ชื่อคนขับ ${repair?.driver?.employee_first_name} ${repair?.driver?.employee_last_name}`,
                        fontSize: "16",
                        width: 180,

                    }
                ]
            },
            {
                alignment: "center",
                image: "repair_wheel",
                width: 200,
                margin: [0, 20, 0, 20],
            },
            {
                fontSize: 16,
                columns: [
                    {
                        text: `รายละเอียดที่ต้องการเปลี่ยน\n\n`,
                        bold: true,
                    },
                ],
            },
            {
                fontSize: 16,
                columns: [
                    {
                        ul: [
                            ...repair_wheel,
                        ]
                    },

                ]
            },
            {
                alignment: "justify",
                margin: [0, 80, 0, 80],
                columns: [
                    [
                        {
                            text: `ลงชื่อ ..........................................ผู้แจ้ง`,
                            fontSize: "16",
                            alignment: "center",
                        },
                        {
                            text: `(.......................................................)`,
                            fontSize: "16",
                            alignment: "center",
                        },
                    ],
                    [
                        {
                            text: `ลงชื่อ ..........................................ผู้อนุมัติ`,
                            fontSize: "16",
                            alignment: "center",
                        },
                        {
                            text: `(.......................................................)`,
                            fontSize: "16",
                            alignment: "center",
                        },
                    ],
                ],
            },
        ],
        images: {
            company_logo: company?.company_logo,
            repair_wheel: window.location.origin + require("../../../assets/img/repair_wheel.jpg")
        },
    };
    pdfMake.createPdf(docDefinition).open();
}