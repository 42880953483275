import React from 'react'
import { useSelector } from 'react-redux'
import Info from '../../../config/VersionInfo.json'

export default function FooterSmall(props) {
  const system = useSelector((state) => state.system_info)

  if (system && system.isLoading === true)
    return (
      <>
        <footer
          className={
            (props.absolute
              ? 'absolute w-full bottom-0 bg-gray-900'
              : 'relative') + ' pb-6'
          }
        >
          <div className="container mx-auto px-4">
            <hr className="mb-6 border-b-1 border-gray-700" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-4/12 px-4">
                <div className="text-sm text-gray-600 font-normal py-1 text-center md:text-left">
                  Copyright © {new Date().getFullYear()}{' '}
                  <a
                    href={system.system_owner_url}
                    className="text-white hover:text-gray-400 text-sm font-normal py-1"
                  >
                    {system.system_owner}
                  </a>
                  ,
                  <br />
                  <a
                    href={system.system_sponsor_url}
                    className="text-white hover:text-gray-400 text-sm font-normal py-1"
                  >
                    {system.system_sponsor}
                  </a>{' '}
                  and Powered by{' '}
                  <a
                    href="https://iarc.psu.ac.th/"
                    className="text-white hover:text-gray-400 text-sm font-normal py-1"
                  >
                    IARC PSU
                  </a>{' '}
                  Ver {Info.version}
                </div>
              </div>
              <div className="w-full md:w-8/12 px-4">
                <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                  <li>
                    <a
                      href="https://iarc.psu.ac.th/"
                      className="text-white hover:text-gray-400 text-sm font-normal block py-1 px-3"
                    >
                      IARC PSU
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://iarc.psu.ac.th/about/"
                      className="text-white hover:text-gray-400 text-sm font-normal block py-1 px-3"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/iarc.psu/"
                      className="text-white hover:text-gray-400 text-sm font-normal block py-1 px-3"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  else {
    return <></>
  }
}