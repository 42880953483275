import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";
import _ from "lodash";

export default function Pagination({ currPage, lastPage, setPage }) {

    // console.log("currPage", currPage);
    // console.log("lastPage", lastPage);
    return (
        <>
            <div className=" flex mt-4 justify-center">
                <div className="mr-4">
                    <Button
                        size="sm"
                        colorScheme="facebook"
                        disabled={!(currPage > 1)}
                        onClick={() => {
                            if (currPage > 1) {
                                setPage(currPage - 1)
                            }
                        }}
                    >
                        <i className="fas fa-arrow-left" />
                    </Button>
                </div>
                <div className="mr-4">
                    {currPage} จาก {lastPage}
                </div>
                <div>
                    <Button
                        size="sm"
                        colorScheme="facebook"
                        disabled={!(currPage < lastPage)}
                        onClick={() => {
                            if (currPage < lastPage) {
                                setPage(currPage + 1)
                            }
                        }}
                    >
                        <i className="fas fa-arrow-right" />
                    </Button>
                </div>
            </div>
        </>
    )
}

Pagination.defaultProps = {
    currPage: "",
    lastPage: "",
    setPage: ""
}

Pagination.propTypes = {
    currPage: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired
}