import React from 'react';
import _ from 'lodash';

import DeliveryList from './DeliveryLists';
import Config from '../../../../config/constant';

export default function DeliveryListContainer({ delivery }) {
    return (
        <div>
            {_.size(
                _.filter(
                    delivery,
                    (eachDelivery) =>
                        eachDelivery.assignment_type ===
                        Config.WORD_TRANSPORT_SERVICE.intImport
                )
            ) ? (
                <div>
                    {' '}
                    <h2 className="text-xl font-bold text-center mb-2">
                        {Config.WORD_TRANSPORT_SERVICE.intImport}
                    </h2>
                    <DeliveryList
                        delivery={_.filter(
                            delivery,
                            (eachDelivery) =>
                                eachDelivery.assignment_type ===
                                Config.WORD_TRANSPORT_SERVICE.intImport
                        )}
                    />
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-center mb-2">
                        {Config.WORD_TRANSPORT_SERVICE.intImport}
                    </h2>
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                </div>
            )}

            <br />

            {_.size(
                _.filter(
                    delivery,
                    (eachDelivery) =>
                        eachDelivery.assignment_type ===
                        Config.WORD_TRANSPORT_SERVICE.intExport
                )
            ) ? (
                <div>
                    {' '}
                    <h2 className="text-xl font-bold text-center mb-2">
                        {' '}
                     {Config.WORD_TRANSPORT_SERVICE.intExport}
                    </h2>
                    <DeliveryList
                        delivery={_.filter(
                            delivery,
                            (eachDelivery) =>
                                eachDelivery.assignment_type ===
                                Config.WORD_TRANSPORT_SERVICE.intExport
                        )}
                    />
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-center mb-2">
                        {Config.WORD_TRANSPORT_SERVICE.intExport}
                    </h2>
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                </div>
            )}

            <br />

            {_.size(
                _.filter(
                    delivery,
                    (eachDelivery) =>
                        eachDelivery.assignment_type ===
                        Config.WORD_TRANSPORT_SERVICE.domestic
                )
            ) ? (
                <div>
                    {' '}
                    <h2 className="text-xl font-bold text-center mb-2">
                        {Config.WORD_TRANSPORT_SERVICE.domestic}
                    </h2>
                    <DeliveryList
                        delivery={_.filter(
                            delivery,
                            (eachDelivery) =>
                                eachDelivery.assignment_type ===
                                Config.WORD_TRANSPORT_SERVICE.domestic
                        )}
                    />
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-center mb-2">
                        {Config.WORD_TRANSPORT_SERVICE.domestic}
                    </h2>
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                </div>
            )}
        </div>
    );
}
