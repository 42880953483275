import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '@chakra-ui/react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import hash from 'object-hash';
import * as actions from '../../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import ImageUpload from 'components/ImageUpload/ImageMultiUpload';
import { EMPLOYEE_STATUS } from '../../../../config/constant/EmployeeStatus';
import { WORD_TYPE_IMAGE_REPAIR } from 'config/constant/TypeImageRepair';
import CheckDriver from '../../../../util/CheckDriver';
import { uploadImageFile } from '../../../../util/image.functions';

export default function EditRepairLog() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { logId } = useParams();

    const repair_log = useSelector((state) => state.repair_log);
    const type = useSelector((state) => state.type);
    const repair_type = useSelector((state) => state.repair_type);
    const vehicles = useSelector((state) => state.vehicle);
    const employee = useSelector((state) => state.employee);

    const [driver_data, setDriver] = useState([]);
    const [repairTypeData, setRepairTypeData] = useState([]);
    const [problemData, setProblemData] = useState([]);
    const [price, setPrice] = useState([]);
    const [imageBefore, setImageBefore] = useState([]);
    const [imageAfter, setImageAfter] = useState([]);
    const [problem, setProblem] = useState(false);
    const [isEditBefore, setIsEditBefore] = useState(false);
    const [isEditAfter, setIsEditAfter] = useState(false);
    const [imgBeforeRepair, setImgBeforRepair] = useState(null);
    const [imgAfterRepair, setImgAfterRepair] = useState(null);

    useEffect(() => {
        dispatch(actions.repairLogGetVehicle(logId));
        dispatch(actions.vehicleAll());
        dispatch(actions.repairTypeAll({}));
        dispatch(actions.typeAll());
        dispatch(actions.employeeGetByStatus(EMPLOYEE_STATUS[0]));
        setIsLoading(true);
        return () => {};
    }, []);

    useEffect(() => {
        setDriver(CheckDriver(employee?.arr));
        repair_type?.rows && setRepairTypeData(filterRepairType());
        return () => {};
    }, [employee, repair_type, problem]);

    useEffect(() => {
        setProblemData(repair_log?.problem_logs);
        setImgBeforRepair(filterImgBefore());
        setImgAfterRepair(filterImgAfter());
        return () => {};
    }, [repair_log]);

    const filterRepairType = () => {
        const result = _.filter(repair_type?.rows, (_type) => {
            return _type?.type == repair_log?.type;
        });
        return result;
    };

    const filterImgBefore = () => {
        const result = _.filter(repair_log?.repair_img_logs, (repair_imgs) => {
            return repair_imgs?.type === WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR;
        });
        return result;
    };

    const filterImgAfter = () => {
        const result = _.filter(repair_log?.repair_img_logs, (repair_imgs) => {
            return repair_imgs?.type === WORD_TYPE_IMAGE_REPAIR.AFTER_REPAIR;
        });
        return result;
    };

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const onSubmit = async (data) => {
        console.log('price', price);
        console.log('imageBefore', imageBefore);
        console.log('imageAfter', imageAfter);
        let imagesURL = [];
        if (_.size(imageBefore) || _.size(imageAfter)) {
            if (_.size(imageBefore)) {
                console.log('รูปภาพก่อนการแจ้งซ่อม');
                for await (const img of imageBefore.map((imageBefore) =>
                    handleUploadImage(imageBefore)
                )) {
                    imagesURL.push({
                        img,
                        type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                    });
                }
            }

            if (_.size(imageAfter)) {
                console.log('รูปภาพหลังการแจ้งซ่อม');
                for await (const img of imageAfter.map((imageAfter) =>
                    handleUploadImage(imageAfter)
                )) {
                    imagesURL.push({
                        img,
                        type: WORD_TYPE_IMAGE_REPAIR.AFTER_REPAIR,
                    });
                }
            }
            data.repair_img_logs = imagesURL;
        }

        if (!data.driver_id) {
            data.driver_id = repair_log.driver_id;
        }

        data.problem_logs = price;

        // console.log('DATA ', data);

        dispatch(actions.repairLogPut(logId, data)).then(() => {
            alert('สำเร็จ');
            window.location.reload();
        });
    };

    const genDriverList = () => {
        return _.map(driver_data, (driver) => (
            <option key={driver.employee_id} value={driver.employee_id}>
                {driver?.employee_first_name + ' ' + driver?.employee_last_name}
            </option>
        ));
    };

    const checkProblem = (id) => {
        const findProblem = _.find(problemData, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });
        if (findProblem === undefined) {
            return false;
        } else {
            return true;
        }
    };

    const checkPrice = (id) => {
        const findProblem = _.find(problemData, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });
        if (findProblem === undefined) {
            return 0;
        } else {
            problemData[problemData.indexOf(findProblem)].price =
                findProblem.price;
            price[problemData.indexOf(findProblem)] =
                problemData[problemData.indexOf(findProblem)];
            return findProblem.price;
        }
    };

    const handleOnChange = (id, value) => {
        const findProblem = _.find(problemData, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });
        if (findProblem != undefined) {
            problemData[problemData.indexOf(findProblem)].price = value;
            price[problemData.indexOf(findProblem)] =
                problemData[problemData.indexOf(findProblem)];
        }
    };

    const showInput = (id) => {
        const findProblem = _.find(problemData, function (repair_type_id) {
            return repair_type_id.repair_type_id === id;
        });

        if (findProblem === undefined) {
            return (
                <input
                    name="price"
                    defaultValue={checkPrice(id)}
                    onChange={(e) => {
                        handleOnChange(id, e.target.value);
                    }}
                    disabled
                    placeholder={checkPrice(id)}
                    className="pl-2 placeholder-gray-200 bg-gray-300 text-gray-700 rounded-sm shadow  focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-center"
                />
            );
        } else {
            return (
                <input
                    name="price"
                    defaultValue={checkPrice(id)}
                    onChange={(e) => {
                        handleOnChange(id, e.target.value);
                    }}
                    placeholder={checkPrice(id)}
                    className="pl-2 placeholder-gray-200 bg-white text-gray-700 rounded-sm shadow  focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 text-center"
                />
            );
        }
    };

    const renderRepairType = () => {
        return _.map(repairTypeData, (repairType) => (
            <div
                key={repairType?.repair_type_id}
                className="py-1 px-2 flex justify-between "
            >
                <div className="flex justify-start">
                    <div className="pr-2">
                        <Checkbox
                            defaultChecked={checkProblem(
                                repairType?.repair_type_id
                            )}
                            onChange={() => {
                                const findProblem = _.find(
                                    problemData,
                                    function (repair_type_id) {
                                        return (
                                            repair_type_id.repair_type_id ===
                                            repairType.repair_type_id
                                        );
                                    }
                                );
                                if (findProblem === undefined) {
                                    const selectProblem = {
                                        repair_type_id:
                                            repairType.repair_type_id,
                                        price: 0,
                                    };
                                    problemData.push(selectProblem);
                                    setPrice(problemData);
                                } else {
                                    problemData.splice(
                                        problemData.indexOf(findProblem),
                                        1
                                    );
                                    setPrice(problemData);
                                }
                                setProblem(!problem);
                            }}
                        />
                    </div>
                    <div className="pr-2">{repairType?.repair_detail}</div>
                </div>
                <div className=" flex justify-end border box-border w-40">
                    <div className="">
                        <label className="pt-1 px-2 bg-gray-300 block uppercase text-gray-700 text-sm font-bold rounded-sm shadow">
                            ราคา
                        </label>
                    </div>
                    {isLoading && showInput(repairType?.repair_type_id)}
                </div>
            </div>
        ));
    };

    const renderImgBeforRepair = () => {
        return (
            <div className="content-center text-right bg-white shadow rounded px-4  p-4">
                {isEditBefore || !_.size(imgBeforeRepair) ? (
                    <>
                        <ImageUpload
                            images={imageBefore}
                            setImages={setImageBefore}
                            maxNumber={20}
                        />
                        {!_.isEmpty(imgBeforeRepair) && (
                            <Button
                                size="xs"
                                variant="outline"
                                onClick={() => {
                                    setIsEditBefore(false);
                                }}
                            >
                                ยกเลิก
                            </Button>
                        )}
                    </>
                ) : (
                    <div>
                        <div className="flex flex-wrap justify-center  rounded px-1 mb-2 max-h-96 overflow-y-scroll">
                            {_.map(imgBeforeRepair, (img, index) => (
                                <div key={index} className="my-4 mx-1 ">
                                    <div className="flex justify-end"></div>
                                    <img
                                        className=" shadow-md max-w-150-px"
                                        src={img.img}
                                    ></img>
                                </div>
                            ))}
                        </div>
                        <Button
                            size="xs"
                            variant="outline"
                            colorScheme="yellow"
                            onClick={() => {
                                setIsEditBefore(true);
                            }}
                        >
                            แก้ไข
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const renderImgAfterRepair = () => {
        return (
            <div className="content-center text-right bg-white shadow rounded px-4  p-4">
                {isEditAfter || !_.size(imgAfterRepair) ? (
                    <>
                        <ImageUpload
                            images={imageAfter}
                            setImages={setImageAfter}
                            maxNumber={20}
                        />
                        {!_.isEmpty(imgAfterRepair) && (
                            <Button
                                size="xs"
                                variant="outline"
                                onClick={() => {
                                    setIsEditAfter(false);
                                }}
                            >
                                ยกเลิก
                            </Button>
                        )}
                    </>
                ) : (
                    <div>
                        <div className="flex flex-wrap justify-center  rounded px-1 mb-2 max-h-96 overflow-y-scroll">
                            {_.map(imgAfterRepair, (img, index) => (
                                <div key={index} className="my-4 mx-1 ">
                                    <div className="flex justify-end"></div>
                                    <img
                                        className=" shadow-md max-w-150-px"
                                        src={img.img}
                                    ></img>
                                </div>
                            ))}
                        </div>
                        <Button
                            size="xs"
                            variant="outline"
                            colorScheme="yellow"
                            onClick={() => {
                                setIsEditAfter(true);
                            }}
                        >
                            แก้ไข
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    return isLoading ? (
        <div>
            <div className="flex flex-wrap">
                <h2 className="text-gray-800 text-xl font-semibold">
                    แก้ไขประวัติการแจ้งซ่อม
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex px-4 mt-4 justify-between">
                    <div>
                        <Button
                            onClick={() => history.goBack()}
                            variant="outline"
                        >
                            กลับ
                        </Button>
                    </div>
                    <div>
                        <Button
                            variant="solid"
                            colorScheme="blue"
                            type="submit"
                        >
                            บันทึก
                        </Button>
                    </div>
                </div>
                <div className="flex flex-wrap px-4 py-2 mt-2 justify-around  h-full">
                    <div className="w-full lg:w-6/12 p-2">
                        <h6 className="text-gray-700 text-xs mb-2 font-bold uppercase">
                            ข้อมูลรถ
                        </h6>
                        <div className="bg-white shadow rounded px-4  p-4">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ประเภท{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    disabled
                                    value={repair_log?.typeData?.detail}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 disabled:bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                            </div>
                            <div className="relative w-full mb-3 flex flex-wrap">
                                <div className="w-full lg:w-3/6 ">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        ทะเบียนรถ{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        disabled
                                        value={
                                            repair_log?.vehicle?.serial_number
                                        }
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 disabled:bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                                <div className="w-full lg:w-3/6">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        เลขไมล์{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        disabled
                                        value={repair_log?.mileage?.mileage}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 disabled:bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    ></input>
                                </div>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    พนักงานขับรถ{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <select
                                    name="driver_id"
                                    type="text"
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        {repair_log?.driver
                                            ?.employee_first_name +
                                            ' ' +
                                            repair_log?.driver
                                                ?.employee_last_name}
                                    </option>
                                    {genDriverList()}
                                </select>
                            </div>
                            <div className="relative w-full mb-1">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold"
                                    htmlFor="grid-password"
                                >
                                    รายละเอียด
                                </label>
                                <textarea
                                    name="repair_detail"
                                    type="textfield"
                                    rows={3}
                                    defaultValue={repair_log?.repair_detail}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="รายละเอียด"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 p-2">
                        <div className="relative w-full mb-3 flex flex-wrap ">
                            <div className="w-full lg:w-3/6 ">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    สาเหตุ{' '}
                                </label>
                                <textarea
                                    name="cause"
                                    type="textfield"
                                    rows={3}
                                    defaultValue={repair_log?.cause}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="สาเหตุ"
                                />
                            </div>
                            <div className="w-full lg:w-3/6">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    วิธีการแก้ไข{' '}
                                </label>
                                <textarea
                                    name="repair_modify"
                                    type="textfield"
                                    rows={3}
                                    defaultValue={repair_log?.repair_modify}
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="วิธีการแก้ไข"
                                />
                            </div>
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รายละเอียดการซ่อม{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <div className="bg-white shadow rounded px-4  p-4 max-h-64 overflow-y-scroll">
                                {renderRepairType()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap px-4 py-2  justify-around  h-full">
                    <div className="w-full lg:w-6/12 px-2">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รูปภาพก่อนการแจ้งซ่อม
                            </label>
                            {renderImgBeforRepair()}
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-2">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รูปภาพหลังการแจ้งซ่อม
                            </label>
                            {renderImgAfterRepair()}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
