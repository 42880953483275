import axios from "axios";
import {
  CATEGORY_VEHICLE_ALL,
  CATEGORY_VEHICLE_GET,
  CATEGORY_VEHICLE_POST,
  CATEGORY_VEHICLE_PUT,
  CATEGORY_VEHICLE_DEL,
} from "../types";

export const category_vehicleAll = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/category_vehicle")
      .then((res) => {
        console.log("Request Server to Get All Categorys");
        dispatch({ type: CATEGORY_VEHICLE_ALL, payload: res.data });
      });
  };
};

export const category_vehicleGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/category_vehicle/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Categorys vehicle");
        if (res.data) {
          dispatch({ type: CATEGORY_VEHICLE_GET, payload: res.data });
        } else {
          dispatch({ type: CATEGORY_VEHICLE_GET, payload: null });
        }
      });
  };
};

export const category_vehiclePost = (payload) => {
  console.log(payload);
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/category_vehicle", payload)
      .then((res) => {
        console.log("Request Server to post an category vehicle");
        dispatch({ type: CATEGORY_VEHICLE_POST, payload: null });
      });
  };
};

export const category_vehiclePut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/category_vehicle/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Categorys vehicle");
        dispatch({ type: CATEGORY_VEHICLE_PUT, payload: null });
      });
  };
};
export const category_vehicleDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/category_vehicle/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Categorys vehicle");
        dispatch({ type: CATEGORY_VEHICLE_DEL, payload: null });
      });
  };
};
