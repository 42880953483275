import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
import { firebaseAuth } from "contexts/AuthContext";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box, Button, HStack } from "@chakra-ui/react";
import CardProfile from "components/common/Cards/CardProfile";
import SpinnerLoading from "components/Loading/SpinnerLoading";

export default function EmployeeProfile() {
  const { handleDeleteUser } = useContext(firebaseAuth);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  useEffect(() => {
    console.log(id);
    dispatch(actions.employeeGet(id));
    return () => {};
  }, []);
  return employee.isLoading ? (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">
        ข้อมูลพนักงาน
      </h2>

      <div className="flex py-6 justify-between">
        <div>
          <Link to="/hrms/dashboard">
            {" "}
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div>
   
        </div>
      </div>
      <div className="p-4">
        <CardProfile
          first_name={employee.employee_first_name}
          last_name={employee.employee_last_name}
          department={employee.position?.department.department_name}
          position={employee.position?.position_name}
          email={employee.employee_email}
          phone_number={employee.employee_phone_number}
          address={employee.employee_address}
          postcode={employee.employee_postcode}
          image={employee.employee_image}
          gender={employee.employee_gender}
          driving_license={employee?.driving_license}
        />
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
