import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import RepairVehicleLists from './components/RepairVehicleLists';
import RepairVehicleQuarterLists from './components/RepairVehicleQuarterLists';
import RepairVehicleYearLists from './components/RepairVehicleYearLists';
import Pagination from 'components/common/Pagination/Pagination';
import { HISTORY_DATA } from '../../../../config/constant/HistoryData';

export default function HistoryRepairVehicle() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const repair_month = useSelector((state) => state.repair_month);
    const company = useSelector((state) => state.company);
    const [repair_data, setRepairData] = useState([]);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(HISTORY_DATA[0]);

    useEffect(() => {
        dispatch(actions.repairMonthAllVehicle({ size: 12, page: page })).then(
            () => {
                dispatch(actions.companyAll());
            }
        );
        return () => {};
    }, [page]);

    useEffect(() => {
        setRepairData(repair_month?.rows);
        setIsLoading(true);
        return () => {};
    }, [repair_month]);

    const showRepairLists = (data) => {
        if (data === HISTORY_DATA[0]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                                {' '}
                                <RepairVehicleLists
                                    month={repair_data}
                                    company={company}
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            );
        } else if (data === HISTORY_DATA[1]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                                {' '}
                                <RepairVehicleQuarterLists
                                    repair_data={repair_data}
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            );
        } else if (data === HISTORY_DATA[2]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                                {' '}
                                <RepairVehicleYearLists
                                    repair_data={repair_data}
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            );
        }
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                ประวัติการแจ้งซ่อม {data}
            </h2>
            <div className="p-4 mt-4">{showRepairLists(data)}</div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination
                    currPage={repair_month?.currPage}
                    lastPage={repair_month?.lastPage}
                    setPage={setPage}
                />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
