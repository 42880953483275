import axios from "axios";
import {
    ROUTE_ALL,
    ROUTE_GET,
    ROUTE_POST,
    ROUTE_PUT,
    ROUTE_DEL,
} from '../types';

export const routeAll = () => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + '/route')
            .then((res) => {
                console.log("Request Server to Get All Route");
                dispatch({ type: ROUTE_ALL, payload: res.data });
            });
    };
};

export const routeGet = (id) => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + '/route/' + id)
            .then((res) => {
                console.log("Request Server to Get Route");
                dispatch({ type: ROUTE_GET, payload: res.data });
            });
    };
};

export const routePost = (payload) => {
    return async (dispatch) => {
        await axios.post(process.env.REACT_APP_API_URL + '/route', payload)
            .then((res) => {
                console.log("Request Server to post Route");
                dispatch({ type: ROUTE_POST, payload: res.data });
            });
    };
};

export const routePut = (id, payload) => {
    return async (dispatch) => {
        await axios.put(process.env.REACT_APP_API_URL + '/route/' + id, payload)
            .then((res) => {
                console.log("Request Server to put Route");
                dispatch({ type: ROUTE_PUT, payload: null });
            });
    };
};

export const routeDel = (id) => {
    return async (dispatch) => {
        await axios.delete(process.env.REACT_APP_API_URL + '/route/' + id)
            .then((res) => {
                console.log("Request Server to delete Route");
                dispatch({ type: ROUTE_DEL, payload: null });
            });
    };
};

