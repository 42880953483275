import React, { useState } from 'react';
import _ from 'lodash';

import NotifyComponent from './NotifyComponent';
import NotifyModal from '../../../../components/Modal/NotifyModal/NotifyModal';

const NotifyPerVehicle = ({
    vehicle,
    notifyList,
    mileage,
    setModalNotifyOpen,
    setSelectedNotify,
    setShowedOnModalMileage,
    setSelectedVehicle,
}) => {
    const [isNotifyModalOpen, setIsModalNotifyOpen] = useState(false);
    // For Latest Notify Stack
    const generateLatestNotifyStack = () => {
        if (_.size(notifyList)) {
            const notify = _.first(notifyList);

            return (
                <NotifyComponent
                    notify={notify}
                    currentMileage={mileage}
                    handleModalOpen={setModalNotifyOpen}
                    setSelectedNotify={setSelectedNotify}
                    setShowedOnModalMileage={setShowedOnModalMileage}
                    selectedVehicle={vehicle}
                    setSelectedVehicle={setSelectedVehicle}
                />
            );
        } else {
            return (
                <div>
                    <div
                        className="p-1 shadow-sm mb-1 hover:shadow-md  align-middle  cursor-pointer flex  py-3 hover:translate-y-12 h-24 border border-gray-200"
                        onClick={() => {
                            setIsModalNotifyOpen(true);
                        }}
                    >
                        <div className="flex w-full justify-center  ">
                            <p className="text-center self-center ">
                                ยังไม่มีการนัดหมาย
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div>
            <NotifyModal
                closeHandle={() => {
                    setIsModalNotifyOpen(false);
                }}
                openStatus={isNotifyModalOpen}
                vehicle={vehicle}
                currentMileage={mileage}
            />
            {generateLatestNotifyStack()}
        </div>
    );
};

export default NotifyPerVehicle;
