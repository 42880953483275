import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardDetailWheel from '../../../common/Cards/CardDetailWheel';
import { FormControl, Input, Textarea, Button } from '@chakra-ui/react';
import _ from 'lodash';
import { SUB_CATEGORY_REPAIR } from "../../../../config/constant/SubCategoryRepair";
import ModalAddDetailWheel from '../../../common/Modals/ModalAddDetailWheel';

const RepairType = ({ repairDetail }) => {

    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenAddModal = () => {
        setIsOpenModal(!isOpenModal)

    }
    const handleCloseAddModal = () => {
        setIsOpenModal(!isOpenModal)

    }
    const genCardDetailWheel = () => {

        if (repairDetail?.sub_vehicle === SUB_CATEGORY_REPAIR[0]) {
            return (
                <>
                    <div className="p-2">
                        <CardDetailWheel
                            repair_wheel={repairDetail?.repair_wheels}
                        />
                    </div>
                </>
            )
        } else if (repairDetail?.sub_vehicle === SUB_CATEGORY_REPAIR[1]) {
            return (
                <>
                    <div className="p-2">
                        <CardDetailWheel
                            repair_wheel={repairDetail?.repair_wheels}
                        />
                    </div>
                </>
            )
        } else if (repairDetail?.sub_vehicle === SUB_CATEGORY_REPAIR[2]) {
            return (
                <>
                    <div className="p-2">
                        <CardDetailWheel
                            repair_wheel={repairDetail?.repair_wheels}
                        />
                    </div>
                </>
            )
        }

    };

    return (
        <div className="relative w-full mb-3 px-4">
            <ModalAddDetailWheel
                openHandle={isOpenModal}
                setIsOpenModal={setIsOpenModal}
                repair_wheel={repairDetail?.repair_wheels}
            />
            <div className="flex flex-wrap">
                <label className="block uppercase text-gray-700 text-sm font-bold mb-2 mr-2">
                    รายการซ่อมยาง
                </label>
                <Button
                    size="xs"
                    colorScheme="blue"
                    onClick={() => {
                        handleOpenAddModal()
                    }}
                >
                    เพิ่ม
                </Button>


            </div>
            {genCardDetailWheel()}
        </div>
    );
};

export default RepairType;
