/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import _ from 'lodash';
import {
    Badge,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { PERMISSION_LEVEL } from '../../../config/constant/PermissionLevel';
export default function Sidebar() {
    const [collapseShow, setCollapseShow] = useState('hidden');
    const system_info = useSelector((state) => state.system_info);
    const { handleSignout } = useContext(firebaseAuth);
    const [permissionLevel, setPermissionLevel] = useState('');
    const category_vehicle = useSelector((state) => state.category_vehicle);
    const me = useSelector((state) => state.me);
    const [isLoading, setIsLoading] = useState(false);
    const [category_vehicle_data, setCategoryVehicleData] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (me.position === null && me.position_id === '1') {
            setPermissionLevel(PERMISSION_LEVEL[2]);
        } else {
            setPermissionLevel(me?.position?.permission_level);
        }
        return () => { };
    }, [me]);

    useEffect(() => {
        dispatch(actions.category_vehicleAll()).then(() => { });
        return () => { };
    }, []);
    useEffect(() => {
        if (category_vehicle?.arr) {
            setCategoryVehicleData(category_vehicle?.arr);
            setIsLoading(true);
        }
        return () => { };
    }, [category_vehicle]);
    const onSignOut = async () => {
        await handleSignout();
        history.push('/login');
    };
    const genCategoryVehicleList = () =>
        _.map(category_vehicle_data, (category, index) => (
            <Link
                key={index}
                className={
                    'text-xs uppercase py-3 font-bold block items-center ml-6 ' +
                    (window.location.href.indexOf(
                        '/fms/category/detail/' + category.category_id
                    ) !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                }
                to={'/fms/category/detail/' + category.category_id}
            >
                {'- จัด' + category.category_name}
            </Link>
        ));

    const genCategoryVehicleListToVehicle = () =>
        _.map(category_vehicle_data, (category, index) => (
            <Link
                key={index}
                className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                        '/fms/category/' + category.category_id + '/vehicle'
                    ) !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                }
                to={'/fms/category/' + category.category_id + '/vehicle'}
            >
                <i
                    className={
                        'fas fa-truck mr-2 text-sm ' +
                        (window.location.href.indexOf(
                            '/fms/category/' + category.category_id + '/vehicle'
                        ) !== -1
                            ? 'opacity-75'
                            : 'text-gray-400')
                    }
                ></i>{' '}
                {category.category_name}
            </Link>
        ));
    return system_info && system_info.isLoading === true ? (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() =>
                            setCollapseShow('bg-white m-2 py-3 px-6')
                        }
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <Link
                        className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                        to="/"
                    >
                        <span className="self-center">
                            {system_info.system_name}
                        </span>
                    </Link>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        {/* <li className="inline-block relative">
              <NotificationDropdown />
            </li> */}

                        {/* <li className="inline-block relative">
              <UserDropdown />
            </li> */}
                    </ul>
                    {/* Collapse */}
                    <div
                        className={
                            'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
                            collapseShow
                        }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                                        to="/"
                                    >
                                        <span className="self-center">
                                            {system_info.system_name}
                                        </span>
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() =>
                                            setCollapseShow('hidden')
                                        }
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Heading */}
                        <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            บริหารจัดการรถ
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600 '
                                    }
                                    to="/"
                                >
                                    <i
                                        className={
                                            'fas fa-home mr-2 text-sm text-gray-400 '
                                        }
                                    ></i>{' '}
                                    หน้าแรก
                                </Link>
                            </li>
                            <li className="items-center">
                                <Accordion allowToggle allowMultiple>
                                    <AccordionItem border="none">
                                        <Link
                                            className={
                                                'text-xs uppercase  font-bold block ' +
                                                ((window.location.href.indexOf(
                                                    '/fms/categories'
                                                ) ||
                                                    window.location.href.indexOf(
                                                        '/fms/category'
                                                    )) !== -1
                                                    ? 'text-blue-500 hover:text-blue-600'
                                                    : 'text-gray-800 hover:text-gray-600')
                                            }
                                            to="/fms/categories"
                                        >
                                            <AccordionButton pl={0}>
                                                <i
                                                    className={
                                                        'fas fa-folder mr-2 text-sm ' +
                                                        ((window.location.href.indexOf(
                                                            '/fms/categories'
                                                        ) ||
                                                            window.location.href.indexOf(
                                                                '/fms/category'
                                                            )) !== -1
                                                            ? 'opacity-75'
                                                            : 'text-gray-400')
                                                    }
                                                ></i>{' '}
                                                <span className="text-xs uppercase  font-bold block">
                                                    จัดการหมวดหมู่
                                                </span>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Link>
                                        {window.location.href.indexOf(
                                            '/fms/categories'
                                        ) ||
                                            window.location.href.indexOf(
                                                '/fms/category'
                                            ) !== -1 ? (
                                            <AccordionPanel pl={0}>
                                                {genCategoryVehicleList()}
                                            </AccordionPanel>
                                        ) : (
                                            <div></div>
                                        )}
                                    </AccordionItem>
                                </Accordion>
                            </li>
                        </ul>

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                {genCategoryVehicleListToVehicle()}
                            </li>
                        </ul>

                        {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/fms/vehicle/driver'
                                        ) !== -1
                                            ? 'text-blue-500 hover:text-blue-600'
                                            : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to="/fms/vehicle/driver"
                                >
                                    <i
                                        className={
                                            'fas fa-users-cog mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/fms/vehicle/driver'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-gray-400')
                                        }
                                    ></i>{' '}
                                    จัดการรถและคนขับ
                                </Link>
                            </li>
                        </ul> */}

                        {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    className={
                                        'text-xs uppercase py-3 font-bold block ' +
                                        (window.location.href.indexOf(
                                            '/fms/route/management'
                                        ) !== -1
                                            ? 'text-blue-500 hover:text-blue-600'
                                            : 'text-gray-800 hover:text-gray-600')
                                    }
                                    to="/fms/route/management"
                                >
                                    <i
                                        className={
                                            'fas fa-road mr-2 text-sm ' +
                                            (window.location.href.indexOf(
                                                '/fms/route/management'
                                            ) !== -1
                                                ? 'opacity-75'
                                                : 'text-gray-400')
                                        }
                                    ></i>{' '}
                                    จัดการเส้นทางการเดินรถ
                                </Link>
                            </li>
                        </ul> */}

                        <hr className="my-4 md:min-w-full" />
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block ">
                                    <i className="fas fa-user text-gray-500 mr-2 text-sm"></i>{' '}
                                    {me.employee_first_name +
                                        ' ' +
                                        me.employee_last_name}{' '}
                                    <Badge colorScheme="gray" className="mb-1">
                                        {/* {me.role} */}
                                    </Badge>
                                </div>
                            </li>

                            <li className="items-center">
                                <div
                                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                                    onClick={() => {
                                        onSignOut();
                                    }}
                                >
                                    <i className="fas fa-sign-out-alt text-gray-500 mr-2 text-sm"></i>{' '}
                                    ออกจากระบบ
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    ) : (
        <div></div>
    );
}
