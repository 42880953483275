import {
    MILEAGE_ALL,
    MILEAGE_ALL_BY_VEHICLE,
    MILEAGE_GET,
    MILEAGE_GET_LATEST,
    MILEAGE_ALL_LATEST,
    MILEAGE_POST,
    MILEAGE_PUT,
    MILEAGE_DEL,
} from '../../actions/types';

const initialState = {
    mileage: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case MILEAGE_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case MILEAGE_ALL_BY_VEHICLE:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case MILEAGE_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case MILEAGE_GET_LATEST:
            return {
                ...action.payload,
                isLoading: true,
            };
        case MILEAGE_ALL_LATEST:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case MILEAGE_POST:
            return action.payload;
        case MILEAGE_PUT:
            return action.payload;
        case MILEAGE_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}
