import {
  CUSTOMER_ALL,
  CUSTOMER_GET,
  CUSTOMER_PUT,
  CUSTOMER_DEL,
  CUSTOMER_POST,
} from "../../actions/types";

const initialState = {
  customer: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case CUSTOMER_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case CUSTOMER_POST:
      return action.payload;
    case CUSTOMER_PUT:
      return action.payload;
    case CUSTOMER_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
