import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAcc0TVtf4nQHL3Bg5Wk5WoWtHOsOFFSNU",
  authDomain: "erp-iarc.firebaseapp.com",
  projectId: "erp-iarc",
  storageBucket: "erp-iarc.appspot.com",
  messagingSenderId: "16498816574",
  appId: "1:16498816574:web:6d2fb62799414a9984c36f",
  measurementId: "G-NS1S4V9D8F",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const app = firebase.app();

export const auth = firebase.auth();
export const storage = firebase.storage();
export const signInAuthProvider = [
  {
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  },
  {
    provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    scopes: ["public_profile", "email"],
  },
];

console.log(app.name ? "Firebase Activated!" : "Firebase not working :(");
