import React, { useState, useEffect } from 'react';
import * as actions from '../../../redux/actions/index';
import CardInfo from 'components/common/Cards/CardInfo';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import SubCategoryByCategoryLists from './components/SubCategoryByCategoryLists';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Button, HStack } from '@chakra-ui/react';
import _ from 'lodash';
import { idText } from 'typescript';

export default function DetailCategory() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const category = useSelector((state) => state.category_vehicle);
    const sub_category = useSelector((state) => state.sub_category_vehicle);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(actions.category_vehicleGet(id)).then(() => {
            dispatch(actions.sub_category_vehicleAllByCategory(id)).then(() => {
                setIsLoading(true);
            });
        });
        return () => {};
    }, [id]);

    const onDelete = () => {
        dispatch(actions.category_vehicleDelete(id)).then(() => {
            console.log('ลบหมวดหมู่ กำลังลบ');
            dispatch(actions.sub_category_vehicleDelete(id)).then(() => {
                console.log('ลบหมวดหมู่ย่อยสำเร็จ');
                history.push('/fms/categories');
            });
        });
    };

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    {' '}
                    รายละเอียดหมวดหมู่
                </h2>
            </div>
            <div className="flex py-4 justify-between">
                <div>
                    <Link to="/fms/categories">
                        <Button variant="outline">กลับ</Button>
                    </Link>
                </div>
                <div>
                    <HStack spacing={2}>
                        <Box>
                            <Link to={'/fms/category/edit/' + id}>
                                {' '}
                                <Button colorScheme="yellow">แก้ไข</Button>
                            </Link>
                        </Box>

                        <Button
                            colorScheme="red"
                            onClick={() => {
                                const confirm = window.confirm(
                                    'ยืนยันการลบหมวดหมู่'
                                );
                                if (confirm) {
                                    onDelete();
                                }
                            }}
                        >
                            ลบ
                        </Button>
                    </HStack>
                </div>
            </div>
            <div className="p-4">
                <div className="w-full lg:w-6/12">
                    <CardInfo
                        title={category?.category_name}
                        subtitle={category?.category_detail}
                        icon="fas fa-list-alt"
                    />
                </div>
                <div className="my-6">
                    <div className="my-4">
                        <Link to={`/fms/category/${id}/subcategory/create`}>
                            <Button colorScheme="blue">
                                เพิ่มหมวดหมู่ย่อย
                            </Button>
                        </Link>
                    </div>
                    <div className="shadow-lg">
                        {_.size(sub_category.arr) ? (
                            <SubCategoryByCategoryLists
                                sub_category={sub_category?.arr}
                            />
                        ) : (
                            <div className="flex content-center items-center justify-center  rounded rounded-t-lg bg-white p-4">
                                ไม่มีข้อมูลหมวดหมู่ย่อย
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
