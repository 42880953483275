import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import { SUB_CATEGORY_REPAIR } from 'config/constant/SubCategoryRepair';
import { HeadAndTailWheelPrintPDF } from '../../report/HeadAndTailWheelPrintPDF';
import { HeadWheelPrintPDF } from '../../report/HeadWheelPrintPDF';
import { TailWheelPrintPDF } from '../../report/TailWheelPrintPDF';

import RepairStatusBadge from '../../../../components/Badge/RepairStatusBadge';

export default function RepairWheelLists({
    repair,
    sub_vehicle,
    company,
    handleOpenRepairModal,
    handleRecordModalOn,
    setIsSelectedRepairData,
}) {
    const dispatch = useDispatch();

    const changeStatus = (id, status) => {
        const payload = {
            repair_status: status,
        };
        dispatch(actions.repairPut(id, payload)).then(() => {
            alert('สำเร็จ');
            dispatch(actions.repairAllWheelByStatus());
        });
    };

    const postLog = (data) => {
        console.log('data', data);
        data.repair_status = REPAIR_STATUS[3];

        dispatch(actions.repairPut(data.repair_id, data)).then(() => {
            dispatch(actions.repairLogPost(data)).then(() => {
                alert('สำเร็จ');
                dispatch(actions.repairAllWheelByStatus());
            });
        });
    };

    const showStatus = (status) => {
        if (status === REPAIR_STATUS[0]) {
            return (
                <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                    {status}{' '}
                </div>
            );
        } else if (status === REPAIR_STATUS[1]) {
            return (
                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        } else if (status === REPAIR_STATUS[2]) {
            return (
                <div className="text-xs text-center px-2 bg-Indigo-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        } else {
            return (
                <div className="text-xs text-center px-2 bg-red-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        }
    };

    const genTd = () => {
        return _.map(repair, (_repair, index) => (
            <Tr key={_repair.repair_id}>
                <Td>
                    <div className="text-center">{index + 1}</div>
                </Td>
                <Td>
                    {_repair?.employee?.employee_first_name +
                        ' ' +
                        _repair?.employee?.employee_last_name}
                </Td>

                <Td>
                    {_repair?.vehicle == null ? (
                        <div className="text-center"> - </div>
                    ) : (
                        <div className="text-center">
                            {
                                _repair?.vehicle?.sub_category_vehicle
                                    ?.sub_category_name
                            }
                        </div>
                    )}
                </Td>
                <Td>
                    {_repair?.mileage == null ? (
                        <div className="text-center"> - </div>
                    ) : (
                        <div className="text-center">
                            {_repair?.mileage?.mileage}
                        </div>
                    )}
                </Td>
                <Td>
                    {_repair?.vehicle == null ? (
                        <div className="text-center"> - </div>
                    ) : (
                        <div className="text-center">
                            {_repair?.vehicle?.serial_number}
                        </div>
                    )}
                </Td>

                <Td>
                    <div className="flex justify-center">
                        <Button
                            size="xs"
                            onClick={() => {
                                if (sub_vehicle === SUB_CATEGORY_REPAIR[0]) {
                                    HeadWheelPrintPDF(_repair, company);
                                } else if (
                                    sub_vehicle === SUB_CATEGORY_REPAIR[1]
                                ) {
                                    TailWheelPrintPDF(_repair, company);
                                } else if (
                                    sub_vehicle === SUB_CATEGORY_REPAIR[2]
                                ) {
                                    HeadAndTailWheelPrintPDF(_repair, company);
                                }
                            }}
                        >
                            <i className="fas fa-print cursor-pointer mr-1"></i>{' '}
                            พิมพ์
                        </Button>
                    </div>
                    <div
                        className="text-center"
                        onClick={() => {
                            setIsSelectedRepairData(_repair);
                            handleOpenRepairModal();
                        }}
                    >
                        <span className="text-xs text-blue-500 hover:text-blue-700 cursor-pointer text-center whitespace-nowrap">
                            รายละเอียด
                        </span>
                    </div>
                </Td>
                <Td>{showStatus(_repair?.repair_status)}</Td>
                <Td>
                    {
                        <div className="flex justify-center">
                            <div>
                                <Button
                                    colorScheme="yellow"
                                    size="sm"
                                    disabled={
                                        _repair?.repair_status ===
                                        REPAIR_STATUS[1]
                                    }
                                    onClick={() => {
                                        setIsSelectedRepairData(_repair);
                                        handleOpenRepairModal();
                                    }}
                                >
                                    <div className="text-white">พิจารณา</div>
                                </Button>
                            </div>
                            <div className="px-2">
                                <Button
                                    colorScheme="blue"
                                    size="sm"
                                    onClick={() => {
                                        changeStatus(
                                            _repair.repair_id,
                                            REPAIR_STATUS[1]
                                        );
                                    }}
                                    disabled={
                                        _repair?.repair_status ===
                                        REPAIR_STATUS[1]
                                    }
                                >
                                    อนุมัติ
                                </Button>
                            </div>
                            <div className="">
                                <Button
                                    colorScheme="green"
                                    size="sm"
                                    onClick={() => {
                                        setIsSelectedRepairData(_repair);
                                        handleRecordModalOn();
                                    }}
                                >
                                    บันทึกการซ่อม
                                </Button>
                            </div>
                            <div className="px-2">
                                <Button
                                    colorScheme="red"
                                    size="sm"
                                    onClick={() => {
                                        postLog(_repair);
                                    }}
                                >
                                    ยกเลิก
                                </Button>
                            </div>
                        </div>
                    }
                </Td>
            </Tr>
        ));
    };
    return (
        <div className="flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>
                            <div className="text-center">ลำดับ</div>
                        </Th>
                        <Th>ผู้แจ้ง</Th>
                        <Th>
                            <div className="text-center">ประเภท</div>
                        </Th>
                        <Th>
                            <div className="text-center">เลขไมล์</div>
                        </Th>
                        <Th>
                            <div className="text-center">ทะเบียนรถ</div>
                        </Th>

                        <Th>
                            <div className="text-center">ใบแจ้งยาง</div>
                        </Th>
                        <Th>
                            <div className="text-center">สถานะ</div>
                        </Th>
                        <Th>
                            <div className="text-center">ดำเนินการ</div>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
