import axios from "axios";
import {
  NOTE_GET,
  NOTE_PUT,
  NOTE_DEL,
  NOTE_POST,
  NOTE_DEL_BY_CUSTOMER,
  NOTE_ALL_BY_CUSTOMER,
} from "../types";

export const noteAllByCustomer = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/note/customer/" + payload)
      .then((res) => {
        console.log("Request Server to All Note By Customer");
        if (res.data) {
          dispatch({ type: NOTE_ALL_BY_CUSTOMER, payload: res.data });
        }
      });
  };
};

export const noteGet = (payload) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/note/" + payload)
      .then((res) => {
        console.log("Request Server to Get a Note");
        if (res.data) {
          dispatch({ type: NOTE_GET, payload: res.data });
        } else {
          dispatch({ type: NOTE_GET, payload: null });
        }
      });
  };
};

export const notePost = (payload) => {

  return async (dispatch) => {
      console.log(payload);
    await axios
      .post(process.env.REACT_APP_API_URL + "/note", payload)
      .then((res) => {
        console.log("Request Server to Post a Note");
        dispatch({ type: NOTE_POST, payload: res.data });
      });
  };
};

export const noteDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/note/" + payload)
      .then((res) => {
        console.log("Request Server to Delete Note by ID");
        if (res.data) {
          dispatch({ type: NOTE_DEL, payload: null });
        }
      });
  };
};

export const noteDeleteByCustomer = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/note/customer/" + payload)
      .then((res) => {
        console.log("Request Server to Delete Note by CUSTOMER");
        if (res.data) {
          dispatch({ type: NOTE_DEL_BY_CUSTOMER, payload: null });
        }
      });
  };
};
