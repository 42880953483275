import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import Flatpickr from 'react-flatpickr';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import Select from 'react-select';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import AddContainerModal from '../../../components/Modal/AddContainerModal/AddContainerModal';

import { TRANSPORT_SERVICE } from '../../../config/constant/TransportService';

export default function CreateExportAssignment() {
    const { register, handleSubmit, setValue, errors } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const customer = useSelector((state) => state.customer);
    const vehicle = useSelector((state) => state.vehicle);
    const [vehicle_container_data, setVehicleContainerData] = useState([]);
    const [customer_data, setCustomer] = useState([]);
    const [selectedShipper, setSelectedShipper] = useState(); //ลูกค้าที่ถูกเลือก (ผู้ส่ง)
    const [selectedConsignee, setSelectedConsignee] = useState(); //ลูกค้าที่ถูกเลือก (ผู้รับ)
    const [selectedVehicleContainer, setSelectedVehicleContainer] = useState();
    const [pickup_date, setPickupDate] = useState(new Date());
    const [delivery_date, setDeliveryDate] = useState(new Date());
    const [addContainerModalOpen, setAddContainerModalOpen] = useState(false);
    const [selectedContainerSize, setSelectedContainerSize] = useState();

    useEffect(() => {
        dispatch(actions.customerAll()).then(() => {
            dispatch(actions.vehicleAll()).then(() => {
                dispatch(actions.companyAll()).then(() => {
                    dispatch(actions.meGet()).then(() => {
                        dispatch(actions.companyAll()).then(() => {
                            setIsLoading(true);
                        });
                    });
                });
            });
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (selectedShipper) {
            setValue(
                'shipper_address',
                selectedShipper?.data?.customer_address
            );
            setValue(
                'shipper_postcode',
                selectedShipper?.data?.customer_postcode
            );
        }

        if (selectedConsignee) {
            setValue(
                'consignee_address',
                selectedConsignee?.data?.customer_address
            );
            setValue(
                'consignee_postcode',
                selectedConsignee?.data?.customer_postcode
            );
        }
    }, [selectedShipper, selectedConsignee]);

    useEffect(() => {
        vehicle.arr && setVehicleContainerData(filterVehicleContainerData());
        customer.arr && setCustomer(genCustomerLists());
        setIsLoading(true);
        return () => {};
    }, [customer, selectedShipper, selectedConsignee, vehicle]);

    useEffect(() => {
        vehicle.arr && setVehicleContainerData(filterVehicleContainerData());
        return () => {};
    }, [vehicle]);

    const onSubmit = (data) => {
        const payload = {
            origins: [data.shipper_address + data.shipper_postcode],
            destinations: [data.consignee_address + data.consignee_postcode],
        };

        data.pickup_date = moment(pickup_date).startOf('day');
        data.delivery_date = moment(delivery_date).startOf('day');
        //ค้นหาเส้นทาง
        axios
            .post(
                process.env.REACT_APP_API_URL + '/other/find-distance',
                payload
            )
            .then((res) => {
                if (res.data.status === 'OK') {
                    data.assignment_type = TRANSPORT_SERVICE[1];
                    data.shipper_id = selectedShipper?.value;
                    data.consignee_id = selectedConsignee?.value;
                    data.assignment_origin = res.data.origin_addresses[0];
                    data.assignment_destination =
                        res.data.destination_addresses[0];
                    data.assignment_duration =
                        res.data.rows[0].elements[0].duration.text;
                    data.assignment_distance =
                        res.data.rows[0].elements[0].distance.text;

                    if (!data.load_weight) {
                        data.load_weight = 0;
                    }

                    if (!data.number_of_packages) {
                        data.number_of_packages = 0;
                    }

                    if (selectedVehicleContainer) {
                        data.vehicle_container_id =
                            selectedVehicleContainer?.value;
                        data.container_size = selectedContainerSize;
                    }

                    if (!selectedShipper || !selectedConsignee) {
                        alert('ข้อมูลไม่ถูกต้อง');
                        return;
                    }
                    const confirm = window.confirm(
                        'ยืนยันการเลือกตำแหน่งจาก ' +
                            data.assignment_origin +
                            ' ไปยัง ' +
                            data.assignment_destination
                    );
                    if (confirm) {
                        // console.log(data);
                        dispatch(actions.transportAssignmentPost(data)).then(
                            () => {
                                dispatch(actions.transportAssignmentAll()).then(
                                    () => {
                                        alert('สำเร็จ');
                                        history.goBack();
                                    }
                                );
                            }
                        );
                    }
                } else {
                    alert('ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ');
                }
            })
            .catch(() => {
                alert('ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ');
            });
    };

    const genCustomerLists = () => {
        const result = _.map(customer?.arr, (customer) => {
            return {
                value: customer?.customer_id,
                label: customer?.customer_company,
                data: customer,
            };
        });
        return result;
    };

    const handleSelectdShipper = (option) => {
        setSelectedContainerSize(
            option?.data?.sub_category_vehicle?.sub_category_name
        );
        setSelectedShipper(option);
    };

    const handleSelectdConsignee = (option) => {
        setSelectedConsignee(option);
    };

    const filterVehicleContainerData = () => {
        const containerResult = _.filter(vehicle.arr, (_vehicle) => {
            return _vehicle?.category_id === '3';
        });

        const result = _.map(containerResult, (container) => {
            return {
                value: container?.vehicle_id,
                label:
                    container?.category_vehicle?.category_name +
                    '/' +
                    container?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    container?.serial_number,
                data: container,
            };
        });
        return result;
    };

    const handleChangeSelectedContainer = (option) => {
        setSelectedContainerSize(
            option?.data?.sub_category_vehicle?.sub_category_name
        );
        setSelectedVehicleContainer(option);
    };

    const handleAddContainer = () => {
        setAddContainerModalOpen(!addContainerModalOpen);
    };

    return isLoading ? (
        <div>
            <AddContainerModal
                openStatus={addContainerModalOpen}
                closeHandle={handleAddContainer}
            />
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    สร้างรายการจัดส่งสินค้า{TRANSPORT_SERVICE[1]}
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex  mt-4 justify-between">
                    <div>
                        <Button
                            variant="outline"
                            onClick={() => history.goBack()}
                        >
                            กลับ
                        </Button>
                    </div>
                </div>
                <div className="flex flex-wrap p-4  justify-center  h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                ลูกค้าต้นทาง{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <Select
                                name="shiper_id"
                                value={selectedShipper}
                                placeholder={'กรุณาเลือกข้อมูลลูกค้าต้นทาง'}
                                onChange={handleSelectdShipper}
                                options={customer_data}
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                สถานที่รับสินค้า{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <textarea
                                name="shipper_address"
                                type="textfield"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="สถานที่รับสินค้า"
                            />
                            {errors?.shipper_address && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รหัสไปรษณีย์{' '}
                            </label>
                            <input
                                name="shipper_postcode"
                                ref={register({
                                    required: false,
                                    maxLength: 10,
                                })}
                                placeholder="รหัสไปรษณีย์"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            />
                            {errors?.shipper_postcode && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                ลูกค้าปลายทาง{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <Select
                                name="consignee_id"
                                value={selectedConsignee}
                                placeholder={'กรุณาเลือกข้อมูลลูกค้าปลายทาง'}
                                onChange={handleSelectdConsignee}
                                options={customer_data}
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                สถานที่ส่งสินค้า{' '}
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <textarea
                                name="consignee_address"
                                type="textfield"
                                ref={register({
                                    required: true,
                                })}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="สถานที่ส่งสินค้า"
                            />
                            {errors?.consignee_address && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                รหัสไปรษณีย์{' '}
                            </label>
                            <input
                                name="consignee_postcode"
                                type="number"
                                ref={register({
                                    required: false,
                                })}
                                placeholder="รหัสไปรษณีย์"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            />
                            {errors?.consignee_postcode && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                เลขที่ใบแจ้งหนี้{' '}
                            </label>
                            <input
                                name="invoice_number"
                                ref={register({
                                    required: false,
                                })}
                                placeholder="เลขที่ใบแจ้งหนี้"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            />
                            {errors?.invoice_number && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>
                        <div className="flex flex-row">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    วันที่รับสินค้า{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    //   data-enable-time
                                    value={pickup_date}
                                    onChange={(date) => {
                                        setPickupDate(date[0]);
                                    }}
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    วันที่ส่งสินค้า{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    //   data-enable-time
                                    value={delivery_date}
                                    onChange={(date) => {
                                        setDeliveryDate(date[0]);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="relative w-full mb-3 ">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ตู้คอนเทนเนอร์{' '}
                            </label>
                            <div className="flex flex-wrap">
                                <div className="w-11/12">
                                    <Select
                                        name="vehicle_container_id"
                                        value={selectedVehicleContainer}
                                        placeholder={
                                            'กรุณาเลือกข้อมูลตู้คอนเทนเนอร์'
                                        }
                                        onChange={handleChangeSelectedContainer}
                                        options={vehicle_container_data}
                                        // required
                                    />
                                </div>
                                <div className="w-1/12 p-1">
                                    <Button
                                        colorScheme="blue"
                                        size="sm"
                                        onClick={() => handleAddContainer()}
                                    >
                                        เพิ่ม
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ขนาดตู้คอนเทนเนอร์{' '}
                                </label>
                                <input
                                    name="container_size"
                                    ref={register({
                                        required: false,
                                    })}
                                    defaultValue={selectedContainerSize}
                                    placeholder="ขนาดตู้คอนเทนเนอร์"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                />
                                {errors?.container_size && (
                                    <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                        กรุณาระบุข้อมูลให้ถูกต้อง
                                    </p>
                                )}
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    จำนวนหีบห่อหรือพาเลท{' '}
                                </label>
                                <input
                                    name="number_of_packages"
                                    ref={register({
                                        required: false,
                                    })}
                                    placeholder="จำนวนหีบห่อหรือพาเลท"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                                {errors?.number_of_packages && (
                                    <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                        กรุณาระบุข้อมูลให้ถูกต้อง
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                น้ำหนักรวม (กิโลกรัม){' '}
                            </label>
                            <input
                                name="load_weight"
                                ref={register({
                                    required: false,
                                })}
                                placeholder="น้ำหนักรวม"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                            {errors?.load_weight && (
                                <p className="text-sm text-red-500 px-3 opacity-70 hover:opacity-100">
                                    กรุณาระบุข้อมูลให้ถูกต้อง
                                </p>
                            )}
                        </div>

                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                หมายเหตุ
                            </label>
                            <textarea
                                name="assignment_note"
                                type="textfield"
                                rows={6}
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="หมายเหตุ"
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-2 px-4">
                    <Button isFullWidth colorScheme="blue" type="submit">
                        บันทึก
                    </Button>
                </div>
                <br />
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
