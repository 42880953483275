import React from "react";
import PropTypes from "prop-types";
export default function CardInfo({ title, subtitle, icon}) {
  return (
    <div className="flex items-center p-4 bg-white rounded-lg  shadow-xl dark:bg-gray-800">
      <div className="p-3 mr-4 text-gray-500  rounded-full ">
        <i className={icon}></i>
      </div>
      <div>
        <p className="mb-2 text-xl font-medium text-gray-700 ">{title}</p>
        <p className="text-sm  text-gray-500 ">รายละเอียด : {subtitle}</p>
      </div>
    </div>
  );
}

CardInfo.defaultProps = {
  title: "หัวข้อหลัก",
  subtitle: "หัวข้อรอง",
  icon: "fa fa-building",
};

CardInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
};
