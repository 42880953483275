import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import DeliveryList from './DeliveryLists';
import Config from '../../../../config/constant';

export default function ImportDeliveryListContainer({
    delivery,
    handleInProgressDelivery,
    handleSuccessDelivery,
    handleDeleteDelivery,
}) {
    useEffect(() => {
        return () => {};
    }, [delivery]);
    return (
        <div>
            {_.size(
                _.filter(
                    delivery,
                    (eachDelivery) =>
                        eachDelivery.assignment_type ===
                        Config.WORD_TRANSPORT_SERVICE.intImport
                )
            ) ? (
                <div>
                    {' '}
                    <h2 className="text-xl font-bold text-center mb-2">
                        รายการเดินรถ{Config.WORD_TRANSPORT_SERVICE.intImport}
                    </h2>
                    <div className="overflow-x-auto  overflow-y-auto  max-h-70-screen">
                        <DeliveryList
                            handleInProgressDelivery={handleInProgressDelivery}
                            handleSuccessDelivery={handleSuccessDelivery}
                            handleDeleteDelivery={handleDeleteDelivery}
                            delivery={_.filter(
                                delivery,
                                (eachDelivery) =>
                                    eachDelivery.assignment_type ===
                                    Config.WORD_TRANSPORT_SERVICE.intImport
                            )}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <h2 className="text-xl font-bold text-center mb-2">
                        รายการเดินรถ{Config.WORD_TRANSPORT_SERVICE.intImport}
                    </h2>
                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                </div>
            )}
        </div>
    );
}
