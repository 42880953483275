import React from 'react'
import { useSelector } from 'react-redux'
import Info from '../../../config/VersionInfo.json'
export default function Footer() {

  const system = useSelector((state) => state.system_info)

  if (system && system.isLoading === true)
    return (
      <>
        <footer className=" bg-gray-300 pt-4 pb-4">
          <div className="container mx-auto px-4">
         
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                <div className="text-sm text-gray-600 font-normal py-1">
                  Copyright © {new Date().getFullYear()} {system.system_owner}
                  <br />
                  <a
                    href={system.system_sponsor_url}
                    className="text-gray-600 hover:text-gray-900"
                  >
                    {system.systemSponsor}
                  </a>{' '}
                  and powered by{' '}
                  <a
                    href="https://iarc.psu.ac.th/"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    IARC PSU
                  </a>
                  . Version {Info.version}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  else {
    return <></>
  }
}