import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/";
import { useForm } from "react-hook-form";
import { Button, Checkbox } from "@chakra-ui/react";
import _ from "lodash";
import axios from "axios";
export default function CreateDepartment() {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [module_lists, setModuleLists] = useState([]);
  const onSubmit = (data, e) => {
    console.log(data);
    dispatch(actions.departmentPost(data)).then(() => {
      console.log("เพิ่มแผนกสำเร็จ");
      history.push("/hrms/department");
    });
  };
  useEffect(() => {
    findAllModuleLists();
    return () => {};
  }, []);
  const findAllModuleLists = () => {
    axios.get(process.env.REACT_APP_API_URL + "/module_lists/").then((res) => {
      console.log("Request Server to Get all module_lists");
      setModuleLists(res.data);
    });
  };
  const genModuleListsCheckBox = () =>
    _.map(module_lists, (module) => {
      return (
        <div key={module.module_id}>
          <Checkbox name="module_lists" value={module.module_id} ref={register}>
            {module.module_name_th}
          </Checkbox>
        </div>
      );
    });
  return (
    <div>
      <h2 className="text-gray-800 text-xl font-semibold py-1">เพิ่มแผนก</h2>

      <div className="py-6">
        <Link to="/hrms/department">
          <Button variant="outline">กลับ</Button>
        </Link>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อแผนก <label className="text-red-600 text-xs"> *</label>
                </label>
                <input
                  name="department_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อแผนก"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายละเอียด <label className="text-red-600 text-xs"> *</label>
                </label>
                <textarea
                  name="department_detail"
                  type="textfield"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="รายละเอียด"
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  จัดการโมดูล
                </label>
                {genModuleListsCheckBox()}
              </div>

              <div className="text-center mt-6">
                <Button isFullWidth colorScheme="blue" type="submit">
                  เพิ่ม
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
