import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box, Button, HStack } from "@chakra-ui/react";
import CardCustomer from "components/common/Cards/CardCustomer";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import CustomerNote from "./components/CustomerNote";
import CustomerContact from "./components/CustomerContact";

export default function CustomerProfile() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const note = useSelector((state) => state.note);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(actions.customerGet(id)).then(() => {
      dispatch(actions.noteAllByCustomer(id)).then(() => {
        setIsLoading(true);
      });
    });
    return () => { };
  }, []);
  const handleDelEmployee = async () => {
    const confirm = window.confirm("ยืนยันการลบข้อมูลลูกค้า");
    if (confirm) {
      dispatch(actions.customerDelete(id)).then(async () => {
        alert("ลบสำเร็จ");
        history.push("/crm/customers");
      });
    }
  };
  return isLoading ? (
    <div>
      <div className="relative w-full max-w-full flex-grow flex-1">
        <h2 className="text-gray-800 text-xl font-semibold  py-1">
          ข้อมูลลูกค้า
        </h2>
      </div>
      <div className="flex py-6 justify-between">
        <div>
          <Link to="/crm/customer">
            {" "}
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
        <div>
          <HStack spacing={2}>
            <Box>
              <Link to={"/crm/customer/edit/" + id}>
                {" "}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>

            <Button
              colorScheme="red"
              onClick={() => {
                handleDelEmployee();
              }}
            >
              ลบ
            </Button>
          </HStack>
        </div>
      </div>
      <div className="flex flex-wrap p-4">
        <div className="w-full lg:w-6/12 px-2">
          <CardCustomer
            address={customer.customer_address}
            company={customer.customer_company}
            customer_phone_number={customer.customer_phone_number}
            postcode={customer.customer_postcode}
            taxes_id={customer.customer_taxes_id}
            fax={customer.customer_fax}
            logo={customer.customer_logo}
            customer_business_type={customer.customer_business_type}
            customer_type={customer.customer_type}
          />
          <CustomerContact contacts={customer.contacts} />
        </div>
        <div className="w-full lg:w-6/12">
          <CustomerNote note={note} />
        </div>
      </div>
    </div>
  ) : (
    <SpinnerLoading />
  );
}
