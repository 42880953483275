import React from "react";
import _ from "lodash";
import CardCustomer from "components/common/Cards/CardCustomerDashbroad";
import { Link } from "react-router-dom";

export default function CustomerLists({ customer }) {
  console.log(customer);
  const genCardCustomer = () => {
    return _.map(customer, (_customer) => (
      <div
        key={_customer.customer_id}
        className="w-full lg:w-4/12 p-2 cursor-pointer"
      >
        <Link to={"/crm/customer/profile/" + _customer.customer_id}>
          {" "}
          <CardCustomer
            logo={_customer.customer_logo}
            title={_customer.customer_company}
            customer_phone_number = {_customer.customer_phone_number}
            customer_type={_customer.customer_type}
          />
        </Link>
      </div>
    ));
  };
  return (
    <>
      <div className="flex-auto">
        <div className="relative flex flex-wrap">{genCardCustomer()}</div>
      </div>
    </>
  );
}
