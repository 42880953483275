import React from 'react';
import _ from 'lodash';

export default function ContactPerson({ contactPeopleData }) {
    return _.map(contactPeopleData, (contactPerson, index) => (
        <div key={index}>
            <div className="flex">
                <div className="w-1/5 flex place-content-center">
                    <i className="fas fa-user mt-2 ml-2" />
                </div>
                <div className="w-4/5">
                    <h5 className="text-sm"> {contactPerson?.contact_name} </h5>
                    <p className="text-sm">
                        {contactPerson?.contact_phone_number}
                    </p>
                </div>
            </div>
        </div>
    ));
}
