import axios from "axios";
import {
  POSITION_ALL,
  POSITION_GET,
  POSITION_PUT,
  POSITION_DEL,
  POSITION_POST,
  POSITION_ALL_BY_DEPARTMENT,
  POSITION_DEL_BY_DEPARTMENT,
} from "../types";

export const positionAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/position").then((res) => {
      console.log("Request Server to Get All Positions");
      dispatch({ type: POSITION_ALL, payload: res.data });
    });
  };
};

export const positionGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/position/" + payload)
      .then((res) => {
        console.log("Request Server to Get a Position");
        if (res.data) {
          dispatch({ type: POSITION_GET, payload: res.data });
        } else {
          dispatch({ type: POSITION_GET, payload: null });
        }
      });
  };
};

export const positionAllByDepartment = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/position/department/" + payload)
      .then((res) => {
        console.log("Request Server to All Position By Department");
        if (res.data) {
          dispatch({ type: POSITION_ALL_BY_DEPARTMENT, payload: res.data });
        }
      });
  };
};

export const positionPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/position/", payload)
      .then((res) => {
        console.log("Request Server to Post a Position");
        dispatch({ type: POSITION_POST, payload: res.data });
        dispatch(positionAll());
      });
  };
};

export const positionPut = (payload) => {
  return async (dispatch) => {
    await axios
      .put(
        process.env.REACT_APP_API_URL + "/position/" + payload.position_id,
        payload
      )
      .then((res) => {
        console.log("Request Server to Put a Position");
        dispatch({ type: POSITION_PUT, payload: res.data });
        dispatch(positionAll());
      });
  };
};

export const positionDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/position/" + payload)
      .then((res) => {
        console.log("Request Server to Delete Position by ID");
        if (res.data) {
          dispatch({ type: POSITION_DEL, payload: null });
        }
      });
  };
};

export const positionDeleteByDepartment = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/position/department/" + payload)
      .then((res) => {
        console.log("Request Server to Delete Position by Deparment");
        if (res.data) {
          dispatch({ type: POSITION_DEL_BY_DEPARTMENT, payload: null });
        }
      });
  };
};
