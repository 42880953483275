import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CardInfo from "components/common/Cards/CardInfo";
import _ from "lodash";
import { Button, HStack } from "@chakra-ui/react";
import * as actions from 'redux/actions';

export default function PathLists({ path }) {

    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    const onDelete = (data) => {
        dispatch(actions.pathDel(data)).then(() => {
            dispatch(actions.routeGet(id))
        })
    }

    const genCardPath = () => {

        return _.map(path, (_path) => (
            <div className=" w-1/3  p-1">
                <div className="bg-gray-200 p-4 shadow-md mb-3 rounded-lg">
                    <div className="flex flex-wrap justify-end">
                        <HStack spacing={2}>
                            <Button
                                size="xs"
                                colorScheme="yellow"
                                onClick={() => {
                                    history.push(`/fms/route/detail/${id}/edit/path/${_path?.path_id}`)
                                }}
                            >
                                แก้ไข
                            </Button>
                            <Button
                                size="xs"
                                colorScheme="red"
                                onClick={() => {
                                    const confirm = window.confirm(
                                        `ยืนยันการลบข้อมูล`
                                    );
                                    if (confirm) {
                                        onDelete(_path?.path_id);
                                    }
                                }}
                            >
                                ลบ
                            </Button>
                        </HStack>
                    </div>
                    <div className="flex flex-row">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ลำดับ
                            </label>
                            <input
                                disabled
                                defaultValue={_path?.index}
                                placeholder="ลำดับ"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ราคาน้ำมัน
                            </label>
                            <input
                                disabled
                                defaultValue={_path?.gasoline}
                                placeholder="น้ำมัน/km"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            เริ่มต้น
                            <input
                                disabled
                                defaultValue={_path?.origin_expected}
                                placeholder="เริ่มต้น"
                                className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </label>
                    </div>
                    <div className="relative w-full mb-3">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            ปลายทาง
                            <input
                                disabled
                                defaultValue={_path?.destination_expected}
                                placeholder="ปลายทาง"
                                className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </label>
                    </div>
                    <div className="flex flex-row">
                        <div className="relative w-full">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ระยะทาง
                            </label>
                            <input
                                disabled
                                defaultValue={_path?.distance}
                                placeholder="ระยะทาง"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                        <div className="relative w-full">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                เวลา
                            </label>
                            <input
                                disabled
                                defaultValue={_path?.duration}
                                placeholder="เวลา"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                    </div>
                </div>
            </div>
        ))

    }

    return (
        <div className=" flex-auto">
            <div className="relative flex flex-wrap">
                {genCardPath()}
            </div>
        </div>
    )
}