import {
    CONTACT_ALL,
    CONTACT_ALL_BY_CUSTOMER,
    CONTACT_GET,
    CONTACT_POST,
    CONTACT_PUT,
    CONTACT_DEL,
} from "../../actions/types";

const initialState = {
    contact: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CONTACT_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case CONTACT_ALL_BY_CUSTOMER:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case CONTACT_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case CONTACT_POST:
            return action.payload;
        case CONTACT_PUT:
            return action.payload;
        case CONTACT_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}