import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import moment from 'moment';
import { CATEGORY_VEHICLE } from '../../../../config/constant/CategoryVehicle';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf',
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf',
    },
};

const findHeadAndTail = (vehicles) => {
    // you can apply any logic and return any valid pdfmake element
    const head_result = _.find(vehicles, (eachVehicle) => {
        return eachVehicle?.vehicle.category_id === CATEGORY_VEHICLE[0];
    });

    const tail_result = _.find(vehicles, (eachVehicle) => {
        return eachVehicle?.vehicle.category_id === CATEGORY_VEHICLE[1];
    });

    return (
        head_result?.vehicle?.serial_number +
        '/' +
        tail_result?.vehicle?.serial_number
    );
};

const findContainer = (vehicles) => {
    // you can apply any logic and return any valid pdfmake element
    const result = _.find(vehicles, (eachVehicle) => {
        return eachVehicle?.vehicle.category_id === CATEGORY_VEHICLE[2];
    });

    return result?.vehicle?.serial_number;
};

export const printPDF = (assignment, delivery, company) => {
    //   const history = historyList(consumption);
    var docDefinition = {
        header: {
            columns: [
                {
                    text: `${moment(new Date()).format('ll')}`,
                    alignment: 'right',
                    margin: [10, 10, 10, 10],
                    fontSize: '12',
                },
            ],
        },
        footer: function (currentPage, pageCount) {
            // you can apply any logic and return any valid pdfmake element

            return [
                {
                    text: currentPage.toString() + ' of ' + pageCount,
                    alignment: 'right',
                    margin: [10, 10, 10, 10],
                    fontSize: '12',
                },
            ];
        },

        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [40, 40, 40, 40],
        defaultStyle: {
            font: 'THSarabunNew',
            fontSize: '16',
        },
        info: {
            title: `ใบรายการส่งสินค้า ${moment(delivery).format(
                'MMMM'
            )}/${parseInt(moment(new Date()).format('YYYY'))}`,
        },
        content: [
            {
                alignment: 'justify',
                columns: [
                    {
                        image: 'company_logo',
                        width: 60,
                        margin: [0, 0, 30, 0],
                        opacity: 1,
                    },
                    {
                        margin: [10, 10, 10, 10],
                        text: [
                            {
                                text: company?.company_name,
                                fontSize: '18',
                                bold: true,
                            },
                            '\n',

                            {
                                text: `เลขที่ผู้เสียภาษี : ${company?.company_taxes_id}`,
                                fontSize: '14',
                            },
                            '\n',
                            {
                                text: `${company?.company_address}`,
                                fontSize: '12',
                            },
                        ],
                    },
                ],
            },
            {
                //หัวข้อใบ DELIVERY ORDER
                alignment: 'center',
                margin: [0, 0, 0, 5],
                text: `DELIVERY ORDER`,
                fontSize: '23',
                bold: true,
            },
            {
                style: 'tableExample',
                // layout: {
                //     fillColor: function (rowIndex, node, columnIndex) {
                //         return rowIndex === 0 ? '#c2dec2' : null;
                //     },
                // },
                table: {
                    widths: [
                        '15%',
                        '15%',
                        '10%',
                        '10%',
                        '15%',
                        '15%',
                        '10%',
                        '10%',
                    ],
                    heights: [10, 10, 10, 10, 30, 10, 10, 10],
                    // headerRows: 1,
                    body: [
                        [
                            {
                                text: [
                                    {
                                        text: 'CONSIGNMENT / INVOICE NO.\n',
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: `${assignment?.invoice_number}`, //เลขที่ใบแจ้งหนี้
                                        fontSize: 12,
                                    },
                                ],

                                colSpan: 2,
                            },
                            {},
                            {
                                text: [
                                    {
                                        text: 'DATE / TIME \n',
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: `${moment(
                                            delivery?.createdAt
                                        ).format('ll')}`,
                                        fontSize: 12,
                                    },
                                ],
                                colSpan: 2,
                            },
                            {},
                            {
                                text: [
                                    {
                                        text: 'REFERENCE NO \n',
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: '-',
                                        fontSize: 12,
                                    },
                                ],
                                colSpan: 2,
                            },
                            {},
                            {
                                text: [
                                    {
                                        text: 'JOB NO : \n',
                                        fontSize: 14,
                                        bold: true,
                                    },
                                ],
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: `${assignment?.assignment_serial_number}`,
                                colSpan: 1,
                                fontSize: 12,
                            },
                        ],
                        [
                            {
                                text: 'CONTAINER NO.',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: findContainer(delivery?.delivery_vehicles),
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'SIZE : ',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: `${assignment?.container_size}`,
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'TRUCK NO.',
                                colSpan: 2,
                                fontSize: 14,
                                bold: true,
                            },
                            {},
                            {
                                text: `${findHeadAndTail(
                                    delivery?.delivery_vehicles
                                )}`,
                                colSpan: 2,
                                fontSize: 14,
                            },
                            {},
                        ],
                        [
                            {
                                text: 'FACTORY SEAL NO.',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: `${delivery?.seal_no}`,
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'WEIGHT',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: `${assignment?.load_weight} MT`,
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: '',
                                colSpan: 4,
                                fontSize: 14,
                            },
                            {},
                            {},
                            {},
                        ],

                        [
                            {
                                text: 'SHIPPER',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: [
                                    {
                                        text: `${delivery?.shipper?.customer_company} \n`,
                                        colSpan: 3,
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: `${delivery?.shipper?.customer_address} ${delivery?.shipper?.customer_postcode}  `,
                                        colSpan: 3,
                                        fontSize: 14,
                                    },
                                ],
                                colSpan: 3,
                            },
                            {},
                            {},
                            {
                                text: 'CONSIGNEE',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },

                            {
                                text: [
                                    {
                                        text: `${delivery?.consignee?.customer_company} \n`,
                                        colSpan: 3,
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: `${delivery?.consignee?.customer_address} ${delivery?.consignee?.customer_postcode}  `,
                                        colSpan: 3,
                                        fontSize: 14,
                                    },
                                ],
                                colSpan: 3,
                            },
                            {},
                            {},
                        ],
                        [
                            {
                                text: 'SPECIAL INSTRUCTION',
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },
                            {
                                text: `${assignment?.number_of_packages} PCS`,
                                colSpan: 3,
                                fontSize: 14,
                            },
                            {},
                            {},
                            {
                                text: 'DELIVERY ADDRESS',
                                rowSpan: 1,
                                colSpan: 1,
                                fontSize: 14,
                                bold: true,
                            },

                            {
                                text: [
                                    {
                                        text: `${delivery?.consignee?.customer_company} \n`,
                                        colSpan: 3,
                                        fontSize: 14,
                                        bold: true,
                                    },
                                    {
                                        text: `${assignment?.consignee_address} ${assignment?.consignee_postcode}  `,
                                        colSpan: 3,
                                        fontSize: 14,
                                    },
                                ],
                                rowSpan: 1,
                                colSpan: 3,
                                fontSize: 14,
                            },
                            {},
                            {},
                        ],
                        [
                            {
                                text: 'Remark',
                                colSpan: 2,
                                fontSize: 14,
                                bold: true,
                            },
                            {},
                            {
                                text: `${delivery?.delivery_note}`,
                                colSpan: 6,
                                fontSize: 14,
                            },
                            {},
                            {},

                            {},
                            {},
                            {},
                        ],
                        [
                            {
                                text: 'ISSUED ON BEHALF OF THE CARRIER',
                                colSpan: 4,
                                rowSpan: 2,
                                fontSize: 14,
                                bold: true,
                            },
                            {},
                            {},
                            {},
                            {
                                text: [
                                    {
                                        text: 'We here by acknowledge receipt above \n',
                                        fontSize: 14,
                                    },
                                    {
                                        text: 'SHIPMET in good order & condition \n \n ',
                                        fontSize: 14,
                                    },
                                    {
                                        text: '.................................................................................. \n \n',
                                        fontSize: 10,
                                    },
                                    {
                                        text: 'Date/Name/L.C.No :',
                                        fontSize: 10,
                                    },
                                ],
                                colSpan: 4,
                                rowSpan: 2,
                            },

                            {},
                            {},
                            {},
                        ],
                        [{}, {}, {}, {}, {}, {}, {}, {}],
                    ],
                },
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        width: 'auto',

                        text: [
                            {
                                text: 'TERM & CONDITIONS \n',
                                fontSize: 10,
                            },
                            {
                                text: '1) ALL CONTAINER DELIVERED ARE SUBJECT TO OUR STANDARD TRADING CONDITION \n',
                                fontSize: 10,
                            },
                            {
                                text: "2) ALL goods in transit from point of origin to final destination are at owner ' s risk. \n",
                                fontSize: 10,
                            },
                            {
                                text: '3) Shipper / Consignee should  always insure their goods under Cargo / Marine Inland Insurance policy. \n',
                                fontSize: 10,
                            },
                            {
                                text: '4) We will not responsible for damage or loss due to insufficient or improper packing or any unforeseen situation. \n',
                                fontSize: 10,
                            },
                        ],
                    },
                    {
                        width: 'auto',
                        alignment: 'center',
                        margin: [5, 5, 5, 5],
                        text: [
                            {
                                text: '\n \n',
                                fontSize: 10,
                            },
                            {
                                text: '.................................................................................. \n \n',
                                fontSize: 10,
                            },
                            {
                                text: company?.company_name,
                                fontSize: 14,
                            },
                        ],
                    },
                ],
            },
        ],
        images: {
            company_logo: company?.company_logo,
        },
    };
    pdfMake.createPdf(docDefinition).open();
};
