import {
    PROBLEM_ALL,
    PROBLEM_GET,
    PROBLEM_POST,
    PROBLEM_PUT,
    PROBLEM_DEL,
} from "../../actions/types";

const initialState = {
    problem: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case PROBLEM_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case PROBLEM_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case PROBLEM_POST:
            return action.payload;
        case PROBLEM_PUT:
            return action.payload;
        case PROBLEM_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}