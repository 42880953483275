import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/";
import { useForm } from "react-hook-form";
import ImageUpload from "components/ImageUpload/ImageUpload";
import { uploadImageFile } from "util/image.functions";
import * as UserInfo from "config/UserInfo";
import { Button, Center } from "@chakra-ui/react";
import Flatpickr from "react-flatpickr";
import SpinnerLoading from "components/Loading/SpinnerLoading";

import { BUSINESS_TYPE, CUSTOMER_TYPE } from "../../../config/CustomerConfig";

import _ from "lodash";
import hash from "object-hash";

export default function EditCustomer() {
  const { register, handleSubmit, reset, errors } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const [image, setImage] = useState([]);
  const [isEditImage, setIsEditImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.customerGet(id)).then(() => {
      setIsLoading(true);
    });
    return () => { };
  }, []);

  const onSubmit = async (data, e) => {
    const confirm = window.confirm("ยืนยันการแก้ไขข้อมูลลูกค้า");
    if (confirm) {
      data.customer_id = customer.customer_id;

      if (!isEditImage) {
        dispatch(actions.customerPut(data)).then(() => {
          dispatch(actions.customerAll()).then(() => {
            // alert("แก้ไขสำเร็จ");
            history.push("/crm/customers");
          });
        });
      } else {
        if (_.size(image)) {
          const filename = hash({ image, date: new Date() });
          uploadImageFile(filename, image[0].data_url).then(async (url) => {
            console.log("URL : " + url);
            data.customer_logo = url;
            e.preventDefault();
            // console.log(data);
            dispatch(actions.customerPut(data)).then(async () => {
              dispatch(actions.customerAll()).then(() => {
                alert("แก้ไขสำเร็จ");
                history.push("/crm/customers");
              });
            });
            reset();
            setImage([]);
          });
        } else {
          alert("กรุณาเลือกรูปภาพ");
        }
      }
    }
  };
  const genBusinessType = () =>
    _.map(BUSINESS_TYPE, (type, index) => (
      <option
        key={index}
        value={type}
        selected={customer.customer_business_type === type ? true : false}
      >
        {type}
      </option>
    ));

  const genCustomerType = () =>
    _.map(CUSTOMER_TYPE, (type, index) => (
      <option
        key={index}
        value={type}
        selected={customer.customer_type === type ? true : false}
      >
        {type}
      </option>
    ));
  return isLoading ? (
    <>
      <div>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full ">
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  แก้ไขข้อมูลลูกค้า
                </h2>
              </div>
              <div className="flex py-6 justify-between">
                <Button variant="outline" onClick={() => { history.goBack() }}>กลับ</Button>
              </div>
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <h6 className="text-gray-500 text-xs mt-3 mb-6 font-bold uppercase">
                          ข้อมูลธุรกิจ
                    </h6>
                        <div className="flex flex-wrap">
                          <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ประเภทธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <select
                                name="customer_business_type"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {genBusinessType()}
                              </select>
                            </div>
                          </div>
                          <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mt-6">
                              <select
                                name="customer_type"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {genCustomerType()}
                              </select>
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ชื่อธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <input
                                name="customer_company"
                                type="text"
                                required
                                ref={register}
                                defaultValue={customer?.customer_company}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ชื่อธุรกิจ"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                เลขที่ผู้เสียภาษี
                          </label>
                              <input
                                name="customer_taxes_id"
                                type="text"
                                ref={register}
                                defaultValue={customer?.customer_taxes_id}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เลขที่ผู้เสียภาษี"
                              />
                            </div>
                          </div>


                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                เบอร์ธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <input
                                name="customer_phone_number"
                                type="text"
                                required
                                ref={register}
                                defaultValue={customer?.customer_phone_number}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เบอร์ธุรกิจ"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                ที่อยู่
                              </label>
                              <textarea
                                name="customer_address"
                                ref={register}
                                rows="2"
                                defaultValue={customer?.customer_address}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ที่อยู่"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                รหัสไปรษณีย์
                              </label>
                              <input
                                name="customer_postcode"
                                ref={register}
                                defaultValue={customer?.customer_postcode}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="รหัสไปรษณีย์"
                              />
                            </div>
                          </div>
                          <div className="w-full px-4">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              รูปภาพประจำตัว
                        </label>
                            {isEditImage ? (
                              <>
                                <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right">
                                  <ImageUpload
                                    images={image}
                                    setImages={setImage}
                                    preview_size={"128px"}
                                  />
                                  <Button
                                    size="xs"
                                    variant="outline"
                                    onClick={() => {
                                      setIsEditImage(false);
                                    }}
                                  >
                                    ยกเลิก
                              </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg">
                                  <Center>
                                    {customer?.customer_logo ? (
                                      <img
                                        className="rounded-lg shadow-md max-w-150-px"
                                        src={customer?.customer_logo}
                                      />
                                    ) : (
                                      <div>ไม่มีรูปภาพ</div>
                                    )}
                                  </Center>

                                  <Button
                                    size="xs"
                                    variant="outline"
                                    onClick={() => {
                                      setIsEditImage(true);
                                    }}
                                  >
                                    แก้ไข
                              </Button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="text-center px-4 gap-0 py-3 ">
                          <Button
                            isFullWidth
                            colorScheme="blue"
                            size="sm"
                            variant="solid"
                            type="submit"
                          >
                            บันทึก
                          </Button>
                        </div>
                      </div>

                    </div>{" "}

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <SpinnerLoading />
  );
}
