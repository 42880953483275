import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import CalendarDeliveryModal from '../../../../components/Modal/DeliveryModal/DeliveryModal';
import AssignmentModal from '../../../../components/Modal/AssignmentModal/TransportAssignmentModal';
import { DELIVERY_STATUS } from '../../../../config/constant/DeliveryStatus';
import { printPDF } from '../components/DeliveryOrderReport';

export default function DeliveryLists({
    delivery,
    handleInProgressDelivery,
    handleSuccessDelivery,
    handleDeleteDelivery,
}) {
    const [isDeliveryModalOpen, setIsDeliveryModalOpen] = useState(false);
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
    const [selectedDeliveryId, setSelectedDeliveryId] = useState('');
    const [selectedAssignment, setSelectedAssignment] = useState()


    const company = useSelector((state) => state.company);
    const allAssignments = useSelector((state) => state.transport_assignment);
    const [isEditorMode, setIsEditorMode] = useState(false);

    const deliveryModalCloseHandle = () => {
        setIsDeliveryModalOpen(false);
        setSelectedDeliveryId('');
    };


    const assignmentModalCloseHanlde = () => {
        setIsAssignmentModalOpen(false);
        setSelectedAssignment();
    };

    const genTd = () => {
        // console.log("Delivery", delivery);
        return _.map(delivery, (_delivery, index) => (
            <Tr
                className="cursor-pointer whitespace-nowrap"
                key={_delivery.delivery_id}
            >
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {index + 1}
                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {moment(_delivery?.start_reservation).format('DD/MM/YYYY')}
                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {moment(_delivery?.end_reservation).format('DD/MM/YYYY')}
                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {_delivery?.delivery_status === DELIVERY_STATUS[0] && (
                        <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                            {_delivery?.delivery_status}
                        </div>
                    )}
                    {_delivery?.delivery_status === DELIVERY_STATUS[1] && (
                        <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                            {_delivery?.delivery_status}
                        </div>
                    )}
                    {_delivery?.delivery_status === DELIVERY_STATUS[2] && (
                        <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                            {_delivery?.delivery_status}
                        </div>
                    )}
                    {_delivery?.delivery_status === DELIVERY_STATUS[3] && (
                        <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                            {_delivery?.delivery_status}
                        </div>
                    )}
                    <div className="text-xs text-center px-2 text-blue-500 hover:text-blue-700">
                        รายละเอียด
                    </div>
                    {/* printPDF */}
                </Td>

                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {/* เลขที่ใบแจ้งหนี้ */}
                    <div>
                        {_.map(
                            _delivery?.delivery_assignments,
                            (eachaAssignment, index) => (
                                <div className="text-center" key={index}>
                                    {(eachaAssignment?.assignment?.invoice_number) ? (
                                        <span className="text-xs font-semibold ">
                                            {
                                                eachaAssignment?.assignment
                                                    ?.invoice_number
                                            }
                                        </span>
                                    ) : (
                                        <span className="text-xs font-semibold ">
                                            -
                                        </span>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </Td>

                <Td>
                    <div>
                        {_.map(
                            _delivery?.delivery_assignments,
                            (eachaAssignment, index) => (
                                <div className="text-center" key={index}>
                                    <span
                                        className="text-xs cursor-pointer"
                                        onClick={() => {
                                            printPDF(
                                                eachaAssignment?.assignment,
                                                _delivery,
                                                company
                                            );
                                        }}
                                    >
                                        <span className="text-blue-500 hover:text-blue-700 ">
                                            {'พิมพ์ใบส่งของ'}
                                        </span>

                                        <i className="fas fa-print mx-2 "></i>
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </Td>

                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    <div>
                        <div>
                            {_.map(
                                _.uniqBy(
                                    _.map(
                                        _delivery?.delivery_assignments,
                                        (eachAssignment) =>
                                            _.find(
                                                allAssignments?.arr,
                                                (assignment) =>
                                                    assignment.assignment_id ===
                                                    eachAssignment.assignment_id
                                            )
                                    ),
                                    (selectedAssignment) =>
                                        selectedAssignment?.shipper
                                            ?.customer_company
                                ),
                                (selectedAssignment, index) => (
                                    <div className="mb-2" key={index}>
                                        <div className="font-semibold">
                                            {
                                                selectedAssignment?.shipper
                                                    ?.customer_company
                                            }
                                        </div>
                                        <div className="text-xs whitespace-normal">
                                            {
                                                selectedAssignment?.shipper_address
                                            }
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    <div>
                        <div>
                            {_.map(
                                _.uniqBy(
                                    _.map(
                                        _delivery?.delivery_assignments,
                                        (eachAssignment) =>
                                            _.find(
                                                allAssignments?.arr,
                                                (assignment) =>
                                                    assignment.assignment_id ===
                                                    eachAssignment.assignment_id
                                            )
                                    ),
                                    (selectedAssignment) =>
                                        selectedAssignment?.consignee
                                            ?.customer_company
                                ),
                                (selectedAssignment, index) => (
                                    <div className="mb-2" key={index}>
                                        <div className="font-semibold">
                                            {
                                                selectedAssignment?.consignee
                                                    ?.customer_company
                                            }
                                        </div>
                                        <div className="text-xs whitespace-normal">
                                            {
                                                selectedAssignment?.consignee_address
                                            }
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {' '}
                    {_.map(
                        _delivery?.delivery_assignments,
                        (eachaAssignment, index) => (
                            <div key={index} className="text-center">
                                {(eachaAssignment?.assignment?.container_size) ? (
                                    <span className="text-xs mr-2">
                                        {
                                            eachaAssignment?.assignment
                                                ?.container_size
                                        }
                                    </span>
                                ) : (
                                    <span className="text-xs mr-2">
                                        -
                                    </span>
                                )}
                            </div>
                        )
                    )}
                </Td>

                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    {(_delivery?.delivery_vehicles.length != 0) ? (
                        <div className="flex flex-col">
                            {_.map(
                                _delivery?.delivery_vehicles,
                                (eachVehicle, index) => (
                                    <span key={index}>
                                        <span className="text-xs ">
                                            {
                                                eachVehicle?.vehicle
                                                    ?.category_vehicle
                                                    ?.category_name
                                            }
                                        </span>
                                        {' : '}{' '}
                                        <span className="text-xs font-semibold">
                                            {eachVehicle?.vehicle?.serial_number}
                                        </span>
                                    </span>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="text-xs mr-2 font-semibold">
                            -
                        </div>
                    )}

                </Td>
                <Td
                    onClick={() => {
                        setSelectedDeliveryId(_delivery.delivery_id);
                        setIsDeliveryModalOpen(true);
                    }}
                >
                    <div className="text-xs text-center font-semibold">
                        {_delivery.seal_no ? (
                            <div>{_delivery.seal_no}</div>
                        ) : (
                            <div>-</div>
                        )}
                    </div>
                </Td>
                <Td>
                    <Button
                        className="mr-1"
                        colorScheme={'yellow'}
                        size={'sm'}
                        disabled={
                            _delivery.delivery_status === DELIVERY_STATUS[1]
                        }
                        onClick={() => {
                            handleInProgressDelivery(_delivery.delivery_id);
                        }}
                    >
                        <div className="text-xs text-white  ">ดำเนินการ</div>
                    </Button>

                    <Button
                        className="mr-1"
                        colorScheme={'green'}
                        size={'sm'}
                        onClick={() => {
                            handleSuccessDelivery(_delivery);
                        }}
                    >
                        <div className="text-xs">สำเร็จ</div>
                    </Button>
                    <Button
                        colorScheme={'red'}
                        size={'sm'}
                        onClick={() => {
                            handleDeleteDelivery(_delivery);
                        }}
                    >
                        <div className="text-xs">ยกเลิก</div>
                    </Button>
                </Td>
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto overflow-x-auto border-black border-2 border-opacity-10 rounded">
            <CalendarDeliveryModal
                allDelivery={delivery}
                closeHandle={deliveryModalCloseHandle}
                deliveryId={selectedDeliveryId}
                openStatus={isDeliveryModalOpen}
                allAssignments={allAssignments?.arr}

            />
            <AssignmentModal
                selectedAssignment={setSelectedAssignment}
                openStatus={isAssignmentModalOpen}
                closeHandle={assignmentModalCloseHanlde}
                isEditorMode={isEditorMode}
                setIsEditorMode={setIsEditorMode}
            />
            <Table
                className=" bg-white   break-words rounded-sm"
                variant="simple"
                size="sm"
            >
                <Thead className="whitespace-nowrap">
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>วันรับ</Th>
                        <Th>วันส่ง</Th>
                        <Th>สถานะ</Th>
                        <Th>เลขที่ใบแจ้งหนี้</Th>
                        <Th>ใบส่งของ</Th>
                        <Th>ผู้ส่ง</Th>
                        <Th>ผู้รับ</Th>
                        <Th>ขนาด</Th>
                        <Th>ทะเบียนรถ</Th>
                        <Th>หมายเลขซีล</Th>
                        <Th>ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
