import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import { useForm } from 'react-hook-form';
import { Button, Center } from '@chakra-ui/react';
import * as actions from '../../../redux/actions';
import ImageUploader from '../../../components/ImageUpload/ImageUpload';
import _ from 'lodash';
import hash from 'object-hash';
import { uploadImageFile } from 'util/image.functions';

export default function MyProfile() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const [isEditImage, setIsEditImage] = useState(false);
    const [image, setImage] = useState([]);

    useEffect(() => {
        dispatch(actions.meGet()).then(() => {
            setIsLoading(true);
        });
        return () => {};
    }, []);

    const onSubmit = (data) => {
        data.employee_id = me?.employee_id;
        if (isEditImage) {
            if (_.size(image)) {
                const filename = hash({ picture: image, date: new Date() });
                uploadImageFile(filename, image[0].data_url).then((url) => {
                    console.log('URL : ' + url);
                    data.employee_image = url;
                    dispatch(actions.employeePut(data)).then(() => {
                        dispatch(actions.meGet()).then(() => {
                            setIsEditImage(false);
                            alert('สำเร็จ');
                        });
                    });
                });
            } else {
                alert('กรุณาเลือกรูปภาพก่อนบันทึก');
            }
        } else {
            const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลของฉัน');
            if (confirm) {
                dispatch(actions.employeePut(data)).then(() => {
                    dispatch(actions.meGet()).then(() => {
                        setIsEditImage(false);
                        alert('สำเร็จ');
                    });
                });
            }
        }
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                ข้อมูลของฉัน
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-4 py-8">
                    <div className="flex flex-wrap h-full">
                        <div className="w-full lg:w-4/12 px-4">
                            {' '}
                            <div className="w-full mb-3 ">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    รูปภาพประจำตัว
                                </label>
                                <div className="shadow rounded ">
                                    {!isEditImage ? (
                                        <>
                                            <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg ">
                                                <Center>
                                                    {me.employee_image ? (
                                                        <img
                                                            className="rounded shadow p-4"
                                                            src={
                                                                me?.employee_image
                                                            }
                                                            width="150px"
                                                        />
                                                    ) : (
                                                        <img
                                                            className="rounded shadow p-4"
                                                            src={require('assets/img/user-profile.png')}
                                                            width="150px"
                                                        />
                                                    )}
                                                </Center>

                                                <Button
                                                    size="xs"
                                                    variant="outline"
                                                    onClick={() => {
                                                        setIsEditImage(true);
                                                    }}
                                                >
                                                    แก้ไข
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right ">
                                                <ImageUploader
                                                    images={image}
                                                    setImages={setImage}
                                                    preview_size={'100px'}
                                                />
                                                <Button
                                                    size="xs"
                                                    variant="outline"
                                                    onClick={() => {
                                                        setIsEditImage(false);
                                                    }}
                                                >
                                                    ยกเลิก
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className=" w-full">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    ที่อยู่
                                </label>
                                <textarea
                                    name="employee_address"
                                    defaultValue={me?.employee_address}
                                    ref={register}
                                    rows="5"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                    placeholder="ที่อยู่"
                                />
                            </div>
                            <div className="w-full ">
                                <div className="relative w-full mb-3">
                                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                        รหัสไปรษณีย์{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="employee_postcode"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={me?.employee_postcode}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                        placeholder="รหัสไปรษณีย์"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-8/12">
                            <div className="flex flex-wrap   px-4">
                                <div className="w-full lg:w-6/12 ">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            ชื่อ{' '}
                                            <label className="text-red-600 text-xs">
                                                {' '}
                                                *
                                            </label>
                                        </label>
                                        <input
                                            name="employee_first_name"
                                            type="text"
                                            required
                                            ref={register}
                                            defaultValue={
                                                me?.employee_first_name
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                            placeholder="ชื่อ"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 pl-1">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            นามสกุล{' '}
                                            <label className="text-red-600 text-xs">
                                                {' '}
                                                *
                                            </label>
                                        </label>
                                        <input
                                            name="employee_last_name"
                                            type="text"
                                            required
                                            ref={register}
                                            defaultValue={
                                                me?.employee_last_name
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                            placeholder="นามสกุล"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        อีเมล{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="employee_email"
                                        type="email"
                                        disabled
                                        // ref={register}
                                        defaultValue={me?.employee_email}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray- bg-gray-300 rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                        placeholder="example@email.com"
                                    />
                                </div>
                            </div>
                            <div className="w-full  px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        เบอร์โทรศัพท์{' '}
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                    </label>
                                    <input
                                        name="employee_phone_number"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={me?.employee_phone_number}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                        placeholder="เบอร์โทรศัพท์"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full  px-4 py-4">
                            <Button
                                isFullWidth
                                colorScheme="blue"
                                type="submit"
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
