import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { REPAIR_STATUS } from "config/constant/RepairStatus";

export default function LogRepairVehicle({ log_repair }) {

    const textColor = (data) => {
        if (data == REPAIR_STATUS[2]) {
            return (
                <div className="font-bold text-green-600">
                    {data}
                </div>
            )
        } else {
            return (
                <div className="font-bold text-red-600 ">
                    {data}
                </div>
            )
        }
    }

    const genRepairType = (data) => {
        // console.log(data);
        return _.map(data, (_data) => (
            <div key={_data.repair_type_id} className="pt-2">
                {_data?.repair_type.repair_detail + " , " + "ราคา " + _data?.price}
            </div>
        ))
    }

    const genTd = () => {
        return _.map(log_repair, (_log, index) => (
            <Tr key={_log?.repair_id}>
                <Td>{index + 1}</Td>
                <Td>{moment(_log?.createdAt).format("DD/MM/YYYY")}</Td>
                <Td>{moment(_log?.updatedAt).format("DD/MM/YYYY")}</Td>
                <Td>
                    {_log?.mileage?.mileage == null ? (
                        <div> - </div>
                    ):(
                        <>
                            {_log?.mileage?.mileage}
                        </>
                    )}
                </Td>
                <Td>
                    {_log?.vehicle_tail?.serial_number == null ? (
                        <div> - </div>
                    ):(
                        <>
                            {_log?.vehicle_tail?.serial_number}
                        </>
                    )}
                </Td>
                <Td>{ genRepairType(_log?.problem_logs) }</Td>
                <Td>
                    {
                        textColor(_log?.repair_status)
                    }
                </Td>
            </Tr>
        ))
    }

    return (
        <div className="flex-auto">
            <Table className="bg-white"  size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>วันที่แจ้งซ่อม</Th>
                        <Th>วันที่ซ่อมสำเร็จ</Th>
                        <Th>เลขไมล์ขณะแจ้งซ่อม</Th>
                        <Th>ทะเบียนหาง</Th>
                        <Th>ประเภทการซ่อม</Th>
                        <Th>สถานะ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    )
}