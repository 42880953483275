import { ME_GET, ME_RESET } from "../../actions/types";

const initialState = null

export default function (state = initialState, action) {
  switch (action.type) {
    case ME_GET:
      return action.payload;
    case ME_RESET:
      return action.payload;
    default:
      return state;
  }
}
