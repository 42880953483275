import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import * as actions from '../../../../redux/actions';
import _ from 'lodash';

export default function RepairLists({ repair_type, size, page }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const onDelete = (id) => {
        dispatch(actions.repairTypeDelete(id)).then(() => {
            dispatch(actions.repairTypeAll());
        });
    };

    const genTd = () => {
        return _.map(repair_type, (type, index) => (
            <Tr key={type.repair_type_id}>
                <Td>{(page - 1) * size + index + 1}</Td>
                <Td>{type?.typeData?.detail}</Td>
                <Td>{type.repair_detail}</Td>
                <Td>
                    <div className="flex">
                        <div>
                            <Link to={'/cmms/type/edit/' + type.repair_type_id}>
                                <Button colorScheme="yellow" size="sm">
                                    แก้ไข
                                </Button>
                            </Link>
                        </div>
                        <div className=" px-2">
                            <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => {
                                    const confirm =
                                        window.confirm('ยืนยันการลบ');
                                    if (confirm) {
                                        onDelete(type.repair_type_id);
                                    }
                                }}
                            >
                                ลบ
                            </Button>
                        </div>
                    </div>
                </Td>
            </Tr>
        ));
    };

    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <colgroup>
                    <col width="10%" />
                    <col width="30%" />
                    <col width="40%" />
                    <col width="20%" />
                </colgroup>
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>ประเภทการแจ้งซ่อม</Th>
                        <Th>รายละเอียด</Th>
                        <Th>ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
