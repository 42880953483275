import axios from 'axios';
import {
    NOTIFY_ALL,
    NOTIFY_ALL_ACTIVE,
    NOTIFY_ALL_DELAY,
    NOTIFY_GET,
    NOTIFY_POST,
    NOTIFY_PUT,
    NOTIFY_DEL,
    NOTIFY_BY_EMPLOYEE,
    NOTIFY_BY_VEHICLE,
} from '../types';

// แสดงค่าการแจ้งเตือนที่มีในระบบทั้งหมด
export const notifyAll = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/notify')
            .then((res) => {
                console.log('Request Server to Get All Notification');
                dispatch({ type: NOTIFY_ALL, payload: res.data });
            });
    };
};

// แสดงการแจ้งเตือนที่ยังไม่ถึงเวลา
export const notifyAllActive = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/notify/by/status/active')
            .then((res) => {
                console.log(
                    'Request Server to Get All Notification which is active'
                );
                dispatch({ type: NOTIFY_ALL_ACTIVE, payload: res.data });
            });
    };
};

// แสดงการแจ้งเตือนที่เลยกำหนดแล้ว
export const notifyAllDelayed = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/notify/by/status/delay')
            .then((res) => {
                console.log(
                    'Request Server to Get All Notification which is delayed'
                );
                dispatch({ type: NOTIFY_ALL_DELAY, payload: res.data });
            });
    };
};

// แสดงการแจ้งเตือนทั้งหมดของผู้ใช้คนหนึ่ง
export const notifyAllByEmployee = (employeeId) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/notify/by/employee/' +
                    employeeId
            )
            .then((res) => {
                console.log(
                    'Request Server to Get All Notification which belong to this employee'
                );
                dispatch({ type: NOTIFY_BY_EMPLOYEE, payload: res.data });
            });
    };
};

// แสดงการแจ้งเตือนทั้งหมดของรถคนที่เลือก
export const notifyAllByVehicle = (vehicleId) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/notify/by/vehicle/' +
                    vehicleId
            )
            .then((res) => {
                console.log(
                    'Request Server to Get All Notification which belong to this vehicle'
                );
                dispatch({ type: NOTIFY_BY_VEHICLE, payload: res.data });
            });
    };
};

// รับการแจ้งเตือนของรายการใดรายการหนึ่ง
export const notifyGet = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/notify/' + id)
            .then((res) => {
                console.log('Request Server to Get Selected Notification');
                dispatch({ type: NOTIFY_GET, payload: res.data });
            });
    };
};

// สร้างการแจ้งเตือนใหม่
export const notifyPost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/notify/', payload)
            .then((res) => {
                console.log('Request Server to  Create a new Notification');
                dispatch({ type: NOTIFY_POST, payload: res.data });
            });
    };
};

// แก้ไขการแจ้งเตือน
export const notifyPut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/notify/' + id, payload)
            .then((res) => {
                console.log('Request Server to Edit Notification');
                dispatch({ type: NOTIFY_PUT, payload: res.data });
            });
    };
};

// แก้ไขการแจ้งเตือน
export const notifyDelete = (id) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/notify/' + id)
            .then((res) => {
                console.log('Request Server to Delete Notification');
                dispatch({ type: NOTIFY_DEL, payload: res.data });
            });
    };
};
