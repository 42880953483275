import _ from 'lodash';
import React from 'react';
import moment from 'moment';

export default function NotifyComponent({
    notify,
    currentMileage,
    handleModalOpen,
    setSelectedNotify,
    setShowedOnModalMileage,
    selectedVehicle,
    setSelectedVehicle,
}) {
    const distanceToMileage = notify.goal_mileage - currentMileage;

    const renderDateNotify = () => {
        const dayDuration = moment
            .duration(moment().diff(moment(notify.goal_date)))
            .days();

        if (moment(notify?.goal_date).isBefore(moment()))
            return (
                <div>
                    <div className="ml-2 ">
                        <i className="fas fa-calendar mr-2" />
                        {moment(notify?.goal_date).format('ll')}
                    </div>
                    <div className="text-red-700 text-xs ml-2">
                        เกินกำหนด {Math.abs(dayDuration)}
                        วัน
                    </div>
                </div>
            );
        else
            return (
                <div>
                    <div className="ml-2 ">
                        <i className="fas fa-calendar mr-2" />
                        {moment(notify?.goal_date).format('ll')}
                    </div>
                    <div>
                        {dayDuration <= 5 ? (
                            <div className="text-red-500 text-xs ml-2 ">
                                เหลืออีก {Math.abs(dayDuration)} วัน
                            </div>
                        ) : dayDuration <= 10 ? (
                            <div className="text-yellow-600 text-xs ml-2 ">
                                เหลืออีก {Math.abs(dayDuration)} วัน
                            </div>
                        ) : (
                            <div className="text-green-600 text-xs ml-2 ">
                                เหลืออีก {Math.abs(dayDuration)} วัน
                            </div>
                        )}
                    </div>
                </div>
            );
    };

    const renderMileageNotification = () => {
        return (
            <div>
                <div className="ml-2">
                    <i className="fas fa-tachometer-alt mr-2" />
                    {notify.goal_mileage} km.
                </div>

                {distanceToMileage <= 0 ? (
                    <div className="text-red-700 text-xs">
                        เกินกำหนด {Math.abs(distanceToMileage)} km
                    </div>
                ) : (
                    <div>
                        {Math.abs(distanceToMileage) <= 200 ? (
                            <div className="text-red-500 text-xs ">
                                เหลืออีก {Math.abs(distanceToMileage)} km
                            </div>
                        ) : Math.abs(distanceToMileage) <= 600 ? (
                            <div className="text-yellow-600 text-xs ">
                                เหลืออีก {Math.abs(distanceToMileage)} km
                            </div>
                        ) : (
                            <div className="text-green-600 text-xs ">
                                เหลืออีก {Math.abs(distanceToMileage)} km
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div
                className="p-1 shadow-sm mb-1 text-center  gap-2 hover:shadow-md align-middle cursor-pointer  py-3 hover:translate-y-12  bg-yellow-50 border border-gray-200   "
                onClick={() => {
                    handleModalOpen(true);
                    setSelectedNotify(notify);
                    setShowedOnModalMileage(currentMileage);
                    setSelectedVehicle(selectedVehicle);
                }}
            >
                <div className=" align-middle justify-center flex">
                    {notify?.goal_mileage !== '-' &&
                        renderMileageNotification()}
                    {notify?.goal_date !== '-' && renderDateNotify()}
                </div>
                <div className="my-2 ">{notify.title}</div>
            </div>
        </div>
    );
}
