import React, { useState } from 'react';
import _ from 'lodash';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Button,
} from '@chakra-ui/react';

export default function CategoriesMenu({ data, setData, customer_type }) {
    const [selectedCategory, setSelectedCategory] = useState('ลูกค้าทั้งหมด');
    const genCustomerTypeLists = () =>
        _.map(customer_type, (_customer_type) => (
            <MenuItem
                key={_customer_type}
                onClick={() => {
                    getCustomerByType(_customer_type);
                    setSelectedCategory(_customer_type);
                }}
            >
                {_customer_type}
            </MenuItem>
        ));
    const getCustomerByType = (_customer_type) => {
        const res = _.filter(
            data,
            (_data) => _data.customer_type === _customer_type
        );
        setData(res);
    };
    return (
        <div className="shadow rounded">
            <Menu isLazy>
                <MenuButton as={Button} variant="outline">
                    {selectedCategory}
                    <i className="fas fa-sort-down px-2"></i>
                </MenuButton>
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            setData(data);
                            setSelectedCategory('ลูกค้าทั้งหมด');
                        }}
                    >
                        ลูกค้าทั้งหมด
                    </MenuItem>
                    <MenuDivider />
                    {genCustomerTypeLists()}
                </MenuList>
            </Menu>
        </div>
    );
}
