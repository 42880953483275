import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/';
import { useForm } from 'react-hook-form';
import * as UserInfo from 'config/UserInfo';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';
import _ from 'lodash';
import { Button, Center } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
export default function EditEmployee() {
    const { register, handleSubmit, reset, errors } = useForm();
    const { id } = useParams();
    const [image, setImage] = useState([]);
    const [idCardImage, setIdCardImage] = useState([]);
    const [drivingLiceneImage, setDrivingLiceneImage] = useState([]);
    const [isEditImage, setIsEditImage] = useState(false);
    const [isEditIdCard, setIsEditIdCard] = useState(false);
    const [isEditDrivingLicene, setIsEditDrivingLicene] = useState(false);
    const dispatch = useDispatch();
    const position = useSelector((state) => state.position);
    const employee = useSelector((state) => state.employee);
    const [genderSelect, setGenderSelect] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [employee_date_of_birth, setEmployeeDateOfBirth] = useState(
        new Date()
    );
    const [date_joined, setDateJoined] = useState(new Date());
    const [date_left, setDateLeft] = useState(new Date());
    const [selectedPosition, setSelectedPosition] = useState({});
    const driving_license = moment(employee?.driving_license).format(
        'YYYY-MM-DD'
    );
    const history = useHistory();

    useEffect(() => {
        dispatch(actions.positionAll()).then(() => {
            dispatch(actions.employeeGet(id));
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (employee?.employee_id) {
            setGenderSelect(employee?.employee_gender);
            setEmployeeDateOfBirth(new Date(employee?.employee_date_of_birth));
            setDateJoined(new Date(employee?.date_joined));
            setDateLeft(new Date(employee?.date_left));
            setSelectedPosition(employee?.position);
            setIsLoading(true);
        }

        return () => {};
    }, [employee]);

    const onSubmit = async (data, e) => {
        const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลพนักงาน');
        if (confirm) {
            data.employee_id = employee.employee_id;
            data.employee_date_of_birth = employee_date_of_birth;
            data.date_joined = date_joined;
            data.date_left = date_left;

            // data.driving_license = new Date(data.driving_license);
            if (employee?.position !== null) {
                data.position_id = selectedPosition?.position_id;
                data.department_id = selectedPosition?.department_id;
            }

            const callActions = async (payload) => {
                if (
                    payload.employee_image !== null &&
                    payload.idCard_image !== null &&
                    payload.driving_license_image !== null
                ) {
                    await dispatch(actions.employeePut(payload)).then(
                        async () => {
                            dispatch(actions.employeeAll()).then(() => {
                                alert('แก้ไขสำเร็จ');
                                history.push('/hrms/employee');
                            });
                        }
                    );
                }
            };

            if (
                _.size(image) ||
                _.size(idCardImage) ||
                _.size(drivingLiceneImage)
            ) {
                if (_.size(image)) {
                    console.log('แก้ไขรูปโปรไฟล์');
                    const filename = hash({ image, date: new Date() });
                    data.employee_image = null;
                    uploadImageFile(filename, image[0].data_url).then(
                        async (url) => {
                            console.log('URL : ' + url);
                            data.employee_image = url;
                            e.preventDefault();
                            console.log(data);
                            callActions(data);
                        }
                    );
                }

                if (_.size(idCardImage)) {
                    console.log('แก้ไขรูปบัตรประชาชน');
                    data.idCard_image = null;
                    const filename = hash({ idCardImage, date: new Date() });
                    uploadImageFile(filename, idCardImage[0].data_url).then(
                        async (url) => {
                            console.log('URL : ' + url);
                            data.idCard_image = url;
                            e.preventDefault();
                            console.log(data);
                            callActions(data);
                        }
                    );
                }

                if (_.size(drivingLiceneImage)) {
                    console.log('แก้ไขรูปใบขับขี่');
                    data.driving_license_image = null;
                    const filename = hash({
                        drivingLiceneImage,
                        date: new Date(),
                    });
                    uploadImageFile(
                        filename,
                        drivingLiceneImage[0].data_url
                    ).then(async (url) => {
                        console.log('URL : ' + url);
                        data.driving_license_image = url;
                        e.preventDefault();
                        console.log(data);
                        callActions(data);
                    });
                }

                callActions(data);
                reset();
                setImage([]);
                setIdCardImage([]);
                setDrivingLiceneImage([]);
            } else {
                await dispatch(actions.employeePut(data)).then(async () => {
                    dispatch(actions.employeeAll()).then(() => {
                        alert('แก้ไขสำเร็จ');
                        history.push('/hrms/employee');
                    });
                });
            }
        }
    };

    const handleDelEmployee = async () => {
        if (employee?.position === null && employee.position_id === '1') {
            alert('ไม่สามารถลบบัญชีเริ่มต้นของระบบ');
        } else {
            const confirm = window.confirm('ยืนยันการลบข้อมูลพนักงาน');
            if (confirm) {
                dispatch(actions.employeeDelete(id)).then(async () => {
                    alert('ลบสำเร็จ');
                    history.push('/hrms/employee');
                });
            }
        }
    };

    const findPositionAndDepartment = (position_id) => {
        return _.find(position, (_position) => {
            return _position.position_id === position_id;
        });
    };

    const genPositionOption = () =>
        _.map(position, (_position) => (
            <option key={_position.position_id} value={_position.position_id}>
                {_position.position_name}
            </option>
        ));

    return isLoading ? (
        <>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <h2 className="text-gray-800 text-xl font-semibold py-1">
                                    แก้ไขพนักงาน
                                </h2>
                                <div className="flex justify-between py-6">
                                    <Link to={'/hrms/employee'}>
                                        <Button variant="outline">กลับ</Button>
                                    </Link>
                                    <Button
                                        colorScheme="red"
                                        onClick={() => {
                                            handleDelEmployee();
                                        }}
                                    >
                                        ลบ
                                    </Button>
                                </div>
                            </div>
                            <div className="flex content-center items-center justify-center h-full mb-12">
                                <div className="w-full lg:w-6/12 px-4">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="flex flex-wrap">
                                            <div className="w-full px-4">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    รูปภาพประจำตัว
                                                </label>
                                                {isEditImage ? (
                                                    <>
                                                        <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right">
                                                            <ImageUpload
                                                                images={image}
                                                                setImages={
                                                                    setImage
                                                                }
                                                                preview_size={
                                                                    '128px'
                                                                }
                                                            />
                                                            <Button
                                                                size="xs"
                                                                variant="outline"
                                                                onClick={() => {
                                                                    setIsEditImage(
                                                                        false
                                                                    );
                                                                }}
                                                            >
                                                                ยกเลิก
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg">
                                                            <Center>
                                                                {employee?.employee_image ? (
                                                                    <img
                                                                        className="rounded-lg shadow-md max-w-150-px"
                                                                        src={
                                                                            employee?.employee_image
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <div>
                                                                        ไม่มีรูปภาพ
                                                                    </div>
                                                                )}
                                                            </Center>

                                                            <Button
                                                                size="xs"
                                                                variant="outline"
                                                                onClick={() => {
                                                                    setIsEditImage(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                แก้ไข
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="w-full px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        อีเมล{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_email"
                                                        type="email"
                                                        disabled
                                                        ref={register}
                                                        defaultValue={
                                                            employee?.employee_email
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray-300  rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="example@email.com"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ชื่อ{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_first_name"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        defaultValue={
                                                            employee?.employee_first_name
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="ชื่อ"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        นามสกุล{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_last_name"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        defaultValue={
                                                            employee?.employee_last_name
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="นามสกุล"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ตำแหน่ง{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    {employee?.position ===
                                                        null &&
                                                    employee.position_id ===
                                                        '1' ? (
                                                        <select
                                                            disabled
                                                            name="position_id"
                                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        >
                                                            <option>
                                                                บัญชีเริ่มต้นของระบบ
                                                            </option>
                                                        </select>
                                                    ) : (
                                                        <select
                                                            name="position_id"
                                                            required
                                                            value={
                                                                selectedPosition?.position_id
                                                            }
                                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                            onChange={(e) => {
                                                                console.log(
                                                                    e.target
                                                                        .value
                                                                );
                                                                setSelectedPosition(
                                                                    findPositionAndDepartment(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {genPositionOption()}
                                                        </select>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    เพศ{' '}
                                                    <label className="text-red-600 text-xs">
                                                        {' '}
                                                        *
                                                    </label>
                                                </label>

                                                <select
                                                    name="employee_gender"
                                                    required
                                                    ref={register}
                                                    value={genderSelect}
                                                    onChange={(e) =>
                                                        setGenderSelect(
                                                            e.target.value
                                                        )
                                                    }
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                >
                                                    <option
                                                        value={
                                                            UserInfo.GENDER.MALE
                                                        }
                                                    >
                                                        {UserInfo.GENDER.MALE}
                                                    </option>
                                                    <option
                                                        value={
                                                            UserInfo.GENDER
                                                                .FEMALE
                                                        }
                                                    >
                                                        {UserInfo.GENDER.FEMALE}
                                                    </option>
                                                    <option
                                                        value={
                                                            UserInfo.GENDER
                                                                .OTHERS
                                                        }
                                                    >
                                                        {UserInfo.GENDER.OTHERS}
                                                    </option>
                                                </select>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        เบอร์โทรศัพท์{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_phone_number"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        defaultValue={
                                                            employee?.employee_phone_number
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="เบอร์โทรศัพท์"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        วันหมดอายุใบขับขี่{' '}
                                                        {/* <label className="text-red-600 text-xs"> *</label> */}
                                                    </label>
                                                    <input
                                                        name="driving_license"
                                                        type="date"
                                                        // required
                                                        ref={register}
                                                        defaultValue={
                                                            driving_license
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="วันหมดอายุใบขับขี่"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full  px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ที่อยู่
                                                    </label>
                                                    <textarea
                                                        name="employee_address"
                                                        ref={register}
                                                        rows="5"
                                                        defaultValue={
                                                            employee?.employee_address
                                                        }
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                        placeholder="ที่อยู่"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full  px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    รหัสไปรษณีย์
                                                </label>
                                                <input
                                                    name="employee_postcode"
                                                    type="text"
                                                    ref={register}
                                                    defaultValue={
                                                        employee?.employee_postcode
                                                    }
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow w-full focus:outline-none focus:ring focus:border-blue-300 transition-shadow duration-150"
                                                    placeholder="รหัสไปรษณีย์"
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full px-4">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                รูปภาพบัตรประชาชน
                                            </label>
                                            {isEditIdCard ? (
                                                <>
                                                    <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right">
                                                        <ImageUpload
                                                            images={idCardImage}
                                                            setImages={
                                                                setIdCardImage
                                                            }
                                                            preview_size={
                                                                '128px'
                                                            }
                                                        />
                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            onClick={() => {
                                                                setIsEditIdCard(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg">
                                                        <Center>
                                                            {employee?.idCard_image ? (
                                                                <img
                                                                    className="rounded-lg shadow-md max-w-150-px"
                                                                    src={
                                                                        employee?.idCard_image
                                                                    }
                                                                />
                                                            ) : (
                                                                <div>
                                                                    ไม่มีรูปภาพ
                                                                </div>
                                                            )}
                                                        </Center>

                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            onClick={() => {
                                                                setIsEditIdCard(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            แก้ไข
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="w-full px-4">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                รูปภาพใบขับขี่
                                            </label>
                                            {isEditDrivingLicene ? (
                                                <>
                                                    <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right">
                                                        <ImageUpload
                                                            images={
                                                                drivingLiceneImage
                                                            }
                                                            setImages={
                                                                setDrivingLiceneImage
                                                            }
                                                            preview_size={
                                                                '128px'
                                                            }
                                                        />
                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            onClick={() => {
                                                                setIsEditDrivingLicene(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg">
                                                        <Center>
                                                            {employee?.driving_license_image ? (
                                                                <img
                                                                    className="rounded-lg shadow-md max-w-150-px"
                                                                    src={
                                                                        employee?.driving_license_image
                                                                    }
                                                                />
                                                            ) : (
                                                                <div>
                                                                    ไม่มีรูปภาพ
                                                                </div>
                                                            )}
                                                        </Center>

                                                        <Button
                                                            size="xs"
                                                            variant="outline"
                                                            onClick={() => {
                                                                setIsEditDrivingLicene(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            แก้ไข
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="text-center px-4 gap-0 py-3">
                                            <Button
                                                isFullWidth
                                                colorScheme="blue"
                                                variant="solid"
                                                type="submit"
                                            >
                                                บันทึก
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <SpinnerLoading />
    );
}
