import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import _ from 'lodash';
import axios from 'axios';

export default function CreateRoute() {
    const { register, handleSubmit, errors, reset } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [number_path, setNumberPath] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatch(actions.routeAll());
        setIsLoading(true);
        return () => { };
    }, []);

    useEffect(() => {
        return () => { };
    }, [reload]);

    const onSubmit = (data) => {
        const path = [];
        for (let i = 0; i < _.size(number_path); i++) {
            const payload = {
                origins: [data?.origin_expected[i]],
                destinations: [data?.destination_expected[i]],
            }
            //ค้นหาเส้นทาง
            axios
                .post(
                    process.env.REACT_APP_API_URL + '/other/find-distance',
                    payload
                )
                .then((res) => {
                    if (res.data.status === 'OK') {
                        path.push({
                            index: data?.index[i],
                            gasoline: data?.gasoline[i],
                            origin_expected: data?.origin_expected[i],
                            destination_expected: data?.destination_expected[i],
                            origin_actual: res.data.origin_addresses[0],
                            destination_actual: res.data.destination_addresses[0],
                            duration: res.data.rows[0].elements[0].duration.text,
                            distance: res.data.rows[0].elements[0].distance.text,
                        })
                        // เช็ค path สุดท้าย
                        if (i === (_.size(number_path) - 1)) {
                            console.log("PATH", path);
                            const routeData = {
                                title: data?.title,
                                path: path
                            }
                            console.log("routeData", routeData);

                            dispatch(actions.routePost(routeData)).then(() => {
                                alert('บันทึกสำเร็จ');
                                reset();
                            })
                        }
                    } else {
                        console.log("ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ");
                    }
                })
                .catch(() => {
                    alert('ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ');
                });
        }
    }

    const showPath = () => {
        return _.map(number_path, (path, index) => (
            <>
                <div className="bg-gray-200 p-4 shadow-md mb-3 w-1/3">
                    <div className="flex flex-row">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ลำดับ
                                <label className="text-red-600 text-xs">
                                    {' '}
                                    *
                                </label>
                            </label>
                            <input
                                name={`index[${index}]`}
                                required
                                ref={register}
                                placeholder="ลำดับ"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ราคาน้ำมัน
                            </label>
                            <input
                                name={`gasoline[${index}]`}
                                ref={register}
                                placeholder="น้ำมัน/km"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            เริ่มต้น
                            <label className="text-red-600 text-xs">
                                {' '}
                                *
                            </label>
                            <input
                                name={`origin_expected[${index}]`}
                                required
                                ref={register}
                                placeholder="เริ่มต้น"
                                className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </label>
                    </div>
                    <div className="relative w-full mb-3">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            ปลายทาง
                            <label className="text-red-600 text-xs">
                                {' '}
                                *
                            </label>
                            <input
                                name={`destination_expected[${index}]`}
                                required
                                ref={register}
                                placeholder="ปลายทาง"
                                className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            ></input>
                        </label>
                    </div>
                </div>
            </>
        ))
    }

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    สร้างเส้นทางการเดินรถ
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4 justify-start">
                    <Button
                        onClick={() => history.goBack()}
                        variant="outline"
                    >กลับ
                    </Button>
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3 flex flex-wrap">
                                <div className="w-5/6">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อเส้นทาง
                                        <label className="text-red-600 text-xs">
                                            {' '}
                                            *
                                        </label>
                                        <input
                                            name="title"
                                            required
                                            ref={register}
                                            placeholder="ชื่อเส้นทาง"
                                            className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </label>
                                </div>
                                <div className="w-1/6 text-right mt-6">
                                    <Button
                                        isFullWidth
                                        colorScheme="blue"
                                        disabled={_.size(number_path) === 5}
                                        onClick={() => {
                                            if (_.size(number_path) < 5) {
                                                number_path.push('1')
                                                setReload(!reload)
                                            }
                                        }}
                                    >
                                        เพิ่ม
                                    </Button>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-wrap justify-center w-full">
                            {showPath()}
                        </div>
                        <div className="text-center mt-6 w-1/2">
                            <Button
                                isFullWidth
                                colorScheme="blue"
                                type="submit"
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <>
            <SpinnerLoading />
        </>
    )
}