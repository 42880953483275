import React, { useState } from 'react';
import _ from 'lodash';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Button,
} from '@chakra-ui/react';

export default function TypeMenu({ selectType, setSelectType, type }) {

    const genTypeLists = () =>
        _.map(type, (_type) => (
            <MenuItem
                key={_type?.type_id}
                onClick={(e) => {
                    setSelectType({
                        type_id: _type?.type_id,
                        detail: _type?.detail,
                    });
                }}
            >
                {_type?.detail}
            </MenuItem>
        ));

    return (
        <div className="shadow rounded">
            <Menu isLazy>
                <MenuButton as={Button} variant="outline">
                    {selectType?.detail}
                    <i className="fas fa-sort-down px-2"></i>
                </MenuButton>
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            setSelectType({
                                type_id: '',
                                detail: 'ประเภททั้งหมด',
                            });
                        }}
                    >
                        ประเภททั้งหมด
                    </MenuItem>
                    <MenuDivider />
                    {genTypeLists()}
                </MenuList>
            </Menu>
        </div>
    );
}
