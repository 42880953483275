import {
    ROUTE_ALL,
    ROUTE_GET,
    ROUTE_POST,
    ROUTE_PUT,
    ROUTE_DEL,
} from '../../actions/types';

const initialState = {
    route: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case ROUTE_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case ROUTE_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case ROUTE_POST:
            return action.payload;
        case ROUTE_PUT:
            return action.payload;
        case ROUTE_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}