import React, { useState, useEffect } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';

// components

import Sidebar from 'components/common/Sidebar/Sidebar-TMS';
import FooterAdmin from 'components/common/Footers/FooterAdmin.js';

// views
//dashboard
import DeliveryCelendarDashboard from 'views/tms/delivery-dashboard/DeliveryCalendarDashboard';
import DeliveryTableDashboard from 'views/tms/delivery-dashboard/DeliveryTableDashboard';

//import
import ImportAssignmentDashboard from 'views/tms/transport-assigment-management/ImportAssignmentManagement';
import CreateImportAssignment from 'views/tms/createAssignment/CreateImportAssignment';
import CreateImportDelivery from 'views/tms/createDelivery/CreateImportDelivery';
import ImportDeliveryManagement from 'views/tms/delivery-management/ImportDeliveryManagement';

//export
import ExportAssignmentDashboard from 'views/tms/transport-assigment-management/ExportAssignmentManagement';
import CreateExportAssignment from 'views/tms/createAssignment/CreateExportAssignment';
import CreateExportDelivery from 'views/tms/createDelivery/CreateExportDelivery';
import ExportDeliveryManagement from 'views/tms/delivery-management/ExportDeliveryManagement';

//domestic
import DomesticAssignmentDashboard from 'views/tms/transport-assigment-management/DomesticAssignmentManagement';
import CreateDomesticAssignment from 'views/tms/createAssignment/CreateDomesticAssignment';
import CreateDomesticDelivery from 'views/tms/createDelivery/CreateDomesticDelivery';
import DomesticDeliveryManagement from 'views/tms/delivery-management/DomesticDeliveryManagement';

import DeliveryHistoryManagement from 'views/tms/delivery-history/DeliveryHistoryManagement';
import DetailDeliveryHistory from 'views/tms/delivery-history/DetailDeliveryHistory'
//components loading
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import NotFound from 'components/NotFound/NotFound';

//functions
import checkRole from '../util/checkroles';
import ImportAssignmentManagement from 'views/tms/transport-assigment-management/ImportAssignmentManagement';

export default function TMS() {
    const location = useLocation();
    const history = useHistory();
    const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
    const system_info = useSelector((state) => state.system_info);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log('TMS Layout : ACTIVE');
        dispatch(actions.systemInfoAll()).then(() => {
            dispatch(actions.meGet(storage_remember)).then(() => { });
        });
        return () => {
            dispatch(actions.meReset());
        };
    }, []);

    useEffect(() => {
        if (me && system_info && system_info.isLoading === true) {
            const isAccess = checkRole(
                me?.position?.department?.department_roles,
                location.pathname
            );
            console.log(isAccess);
            if (isAccess) {
                setIsLoading(true);
                console.log('ได้รับการอนุญาตเข้าถึง');
            } else {
                console.log('ไม่ได้รับอนุญาต');
                alert('ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้');
                history.push('/');
            }
        }
        return () => { };
    }, [me]);

    return isLoading ? (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-gray-200">
                <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
                    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
                        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full max-w-full flex-grow flex-1">
                                    <h6 className="uppercase text-gray-500 text-xs font-semibold">
                                        บริหารจัดการการขนส่ง
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="px-4">
                            <Switch>
                                <Redirect
                                    exact
                                    from="/tms"
                                    to="/tms/dashboard/delivery/calendar"
                                />
                                {/* Dashboard */}
                                <Route
                                    exact
                                    path="/tms"
                                    component={DeliveryCelendarDashboard}
                                />

                                <Route
                                    path="/tms/dashboard/delivery/calendar"
                                    exact
                                    component={DeliveryCelendarDashboard}
                                />

                                <Route
                                    path="/tms/dashboard/delivery/table"
                                    exact
                                    component={DeliveryTableDashboard}
                                />

                                <Route
                                    path="/tms/management/import/assignment"
                                    exact
                                    component={ImportAssignmentDashboard}
                                />

                                <Route
                                    path="/tms/management/import/assignment"
                                    exact
                                    component={ImportAssignmentManagement}
                                />

                                <Route
                                    path="/tms/management/import/assignment/create"
                                    exact
                                    component={CreateImportAssignment}
                                />

                                <Route
                                    path="/tms/management/import/create/delivery"
                                    exact
                                    component={CreateImportDelivery}
                                />

                                <Route
                                    path="/tms/management/import/delivery"
                                    exact
                                    component={ImportDeliveryManagement}
                                />

                                {/* export */}
                                <Route
                                    path="/tms/management/export/assignment"
                                    exact
                                    component={ExportAssignmentDashboard}
                                />

                                <Route
                                    path="/tms/management/export/assignment/create"
                                    exact
                                    component={CreateExportAssignment}
                                />

                                <Route
                                    path="/tms/management/export/create/delivery/"
                                    exact
                                    component={CreateExportDelivery}
                                />

                                <Route
                                    path="/tms/management/export/delivery"
                                    exact
                                    component={ExportDeliveryManagement}
                                />


                                {/* domestic */}
                                <Route
                                    path="/tms/management/domestic/assignment"
                                    exact
                                    component={DomesticAssignmentDashboard}
                                />

                                <Route
                                    path="/tms/management/domestic/assignment/create"
                                    exact
                                    component={CreateDomesticAssignment}
                                />

                                <Route
                                    path="/tms/management/domestic/create/delivery/"
                                    exact
                                    component={CreateDomesticDelivery}
                                />

                                <Route
                                    path="/tms/management/domestic/delivery"
                                    exact
                                    component={DomesticDeliveryManagement}
                                />

                                <Route
                                    path="/tms/history/delivery"
                                    exact
                                    component={DeliveryHistoryManagement}
                                />

                                <Route
                                    path="/tms/history/delivery/:id"
                                    exact
                                    component={DetailDeliveryHistory}
                                />

                                <Route component={NotFound} />
                            </Switch>
                        </div>
                        <div />
                    </div>
                </div>
                <FooterAdmin />
            </div>
        </>
    ) : (
        <SpinnerLoading />
    );
}
