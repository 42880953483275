import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import { Link } from 'react-router-dom';

// components
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import CustomerLists from './components/CustomerLists';
import CustomerCategoriesMenu from '../../../components/CategoriesMenu/CustomerCategoriesMenu';
import CustomerSearch from '../../../components/Search/CustomerSearch';
import { CUSTOMER_TYPE } from '../../../config/CustomerConfig';

export default function Customers() {
    const customer = useSelector((state) => state.customer);
    const [customer_data, setCustomer] = useState(customer.arr);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.customerAll()).then(() => { });
        return () => { };
    }, []);

    useEffect(() => {
        setCustomer(customer.arr);
        setIsLoading(true);
        return () => { };
    }, [customer]);
    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold py-1">
                ลูกค้าทั้งหมด
            </h2>
            <div className="flex justify-between py-6">
                <div className="flex flex-wrap">
                    <div className="pr-2 mt-2">
                        <Link to={'/crm/customer/create'}>
                            <Button colorScheme="blue" variant="solid">
                                เพิ่ม
                            </Button>
                        </Link>
                    </div>

                    <div className='mt-2'>
                        <CustomerCategoriesMenu
                            data={customer.arr}
                            setData={setCustomer}
                            customer_type={CUSTOMER_TYPE}
                        />

                    </div>
                </div>
                <div className='mt-2'>
                    <CustomerSearch data={customer.arr} setData={setCustomer} />

                </div>
            </div>
            <div className="p-4 w-full">
                {_.size(customer_data) ? (
                    <div>
                        {' '}
                        <CustomerLists customer={customer_data} />{' '}
                    </div>
                ) : (
                    <div className="flex justify-center p-4">
                        <div>ไม่มีข้อมูล</div>
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
