import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import * as actions from "../../../redux/actions";

export default function CreateSubCategory() {
  const { register, handleSubmit, errors } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.categoryAll());
    return () => {};
  }, []);

  const onSubmit = (data, e) => {
    data.category_id = id;
    dispatch(actions.sub_category_vehiclePost(data)).then(() => {
      console.log("เพิ่มหมวดหมู่ย่อยสำเร็จ");
      history.push("/fms/category/detail/" + id);
    });
  };

  return (
    <div>
      <div>
        <h2 className="text-gray-800 text-xl font-semibold">
          {" "}
          เพิ่มหมวดหมู่ย่อย
        </h2>
      </div>
      <div className="flex py-4 justify-between">
        <div>
          <Link to={`/fms/category/detail/${id}`}>
            <Button variant="outline">กลับ</Button>
          </Link>
        </div>
      </div>
      <div className="p-4">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อหมวดหมู่ย่อย <label className="text-red-600 text-xs"> *</label>
                </label>
                <input
                  name="sub_category_name"
                  type="text"
                  required
                  ref={register}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ชื่อหมวดหมู่ย่อย"
                />
              </div>
              <div className="text-center mt-6">
                <Button isFullWidth colorScheme="blue" type="submit">
                  เพิ่ม
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
