import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

export default function RepairVehicleYearLists({ repair_data }) {
    return (
        <div className=" flex-auto">
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>เดือน</Th>
                        <Th>ใบแจ้งซ่อม</Th>
                        <Th>รายการแจ้งซ่อมทั้งหมด</Th>
                    </Tr>
                </Thead>
                {/* <Tbody >{genTd()}</Tbody> */}
            </Table>
        </div>
    )
}