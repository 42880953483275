import axios from "axios";
import {
  CUSTOMER_ALL,
  CUSTOMER_GET,
  CUSTOMER_PUT,
  CUSTOMER_DEL,
  CUSTOMER_POST,
} from "../types";

export const customerAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/customer").then((res) => {
      console.log("Request Server to Get All Customers");
      dispatch({ type: CUSTOMER_ALL, payload: res.data });
    });
  };
};

export const customerGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/customer/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Customers");
        if (res.data) {
          dispatch({ type: CUSTOMER_GET, payload: res.data });
        } else {
          dispatch({ type: CUSTOMER_GET, payload: null });
        }
      });
  };
};

export const customerPost = (payload) => {
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/customer", payload)
      .then((res) => {
        console.log("Request Server to Post  Customers");
        dispatch({ type: CUSTOMER_POST, payload: res.data });
      });
  };
};

export const customerPut = (payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/customer/"+payload.customer_id, payload)
      .then((res) => {
        console.log("Request Server to Put  Customers");
        dispatch({ type: CUSTOMER_PUT, payload: res.data });
      });
  };
};

export const customerDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/customer/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Customers");
        if (res.data) {
          dispatch({ type: CUSTOMER_DEL, payload: null });
          dispatch(customerAll());
        }
      });
  };
};
