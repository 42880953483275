import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import currencyFormatter from 'currency-formatter';
import _, { values } from 'lodash';
import moment from 'moment';
import THBText from 'thai-baht-text';
import { CATEGORY_VEHICLE } from '../../../../../config/constant/CategoryVehicle';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf',
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf',
    },
};

const genInvoiceNnumber = (delivery) => {
    return _.map(delivery, (_delivery) => [
        {
            text: `${_delivery?.assignment_log?.invoice_number || ' - ' }`,
            colSpan: 1,
            border: [false, false, false, false],
            alignment: 'center',
        },
    ]);
};

const genShipper = (delivery) => {
    return _.map(delivery, (_delivery) => [
        {
            text: [
                {
                    text: `${_delivery?.assignment_log?.shipper?.customer_company} \n`,
                    colSpan: 3,
                    fontSize: 14,
                    bold: true,
                },
                {
                    text: `${_delivery?.assignment_log?.shipper?.customer_address} ${_delivery?.assignment_log?.shipper?.customer_postcode}  `,
                    colSpan: 3,
                    fontSize: 14,
                },
            ],
            rowSpan: 1,
            border: [false, false, false, false],
        },
    ]);
};

const genConsignee = (delivery) => {
    return _.map(delivery, (_delivery) => [
        {
            text: [
                {
                    text: `${_delivery?.assignment_log?.consignee?.customer_company} \n`,
                    colSpan: 3,
                    fontSize: 14,
                    bold: true,
                },
                {
                    text: `${_delivery?.assignment_log?.consignee?.customer_address} ${_delivery?.assignment_log?.consignee?.customer_postcode}  `,
                    colSpan: 3,
                    fontSize: 14,
                },
            ],
            rowSpan: 1,
            border: [false, false, false, false],
        },
    ]);
};

const genContainerSize = (delivery) => {
    return _.map(delivery, (_delivery) => [
        {
            text: `${_delivery?.assignment_log?.container_size || ' - '}`,
            rowSpan: 1,
            border: [false, false, false, false],
        },
    ]);
};

const genVechicle = (delivery) => {

    if (delivery?.length != 0) {
        const head = _.find(delivery, (_delivery) => {
            return _delivery?.vehicle.category_id === CATEGORY_VEHICLE[0];
        });
        const tail = _.find(delivery, (_delivery) => {
            return _delivery?.vehicle.category_id === CATEGORY_VEHICLE[1];
        });
        const container = _.find(delivery, (_delivery) => {
            return _delivery?.vehicle.category_id === CATEGORY_VEHICLE[2];
        });

        return [[
            {
                text: [
                    {
                        text: `หัวรถ : ${head?.vehicle?.serial_number || ' - '} \n`,
                        colSpan: 3,
                        fontSize: 14,
                        bold: true,
                    },
                    {
                        text: `หางรถ : ${tail?.vehicle?.serial_number || ' - '} \n`,
                        colSpan: 3,
                        fontSize: 14,
                        bold: true,
                    },
                    {
                        text: `คอนเทนเนอร์ : ${container?.vehicle?.serial_number || ' - '} \n`,
                        colSpan: 3,
                        fontSize: 14,
                        bold: true,
                        
                    },
                ],
                rowSpan: 1,
                border: [false, false, false, false],

            },
        ]]
    }
    else {
        return [[
            {
                text: "-",
                colSpan: 1,
                border: [false, false, false, false],
            },
        ]]
    }

};

const genData = (delivery_logs) => {

    return _.map(delivery_logs, (_log, index) => [
        {
            text: index + 1,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },
        {
            text: `${moment(_log?.start_reservation).format('DD/MM/YYYY')}`,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },
        {
            text: `${moment(_log?.end_reservation).format('DD/MM/YYYY')}`,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },
        {
            text: `${_log?.assignment_type}`,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },
        {
            text: `${_log?.delivery_status}`,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },
        {
            table: {
                body:
                    genInvoiceNnumber(_log?.delivery_assignment_logs),

            },
        },
        {
            table: {
                body:
                    genShipper(_log?.delivery_assignment_logs),

            },
        },
        {
            table: {
                body:
                    genConsignee(_log?.delivery_assignment_logs),

            },
        },
        {
            table: {
                body:
                    genContainerSize(_log?.delivery_assignment_logs),

            },
        },
        {
            table: {
                body:
                    genVechicle(_log?.delivery_vehicle_logs)
            }
        },
        {
            text: `${_log?.seal_no || "-"}`,
            border: [true, true, true, true],
            colSpan: 1,
            alignment: 'center',
        },


    ])
}

export const MonthDeliveryReport = (log, company) => {

    const data = genData(log?.delivery_logs);

    var docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 30, 40, 20],
        defaultStyle: {
            font: 'THSarabunNew',
            fontSize: '16',
        },
        info: {
            title: 'รายการจัดส่งสินค้า',
        },
        content: [
            {
                alignment: 'justify',
                columns: [
                    {
                        image: 'company_logo',
                        width: 70,
                        margin: [20, 20, 20, 20],
                    },
                    [
                        {
                            text: company?.company_name,
                            fontSize: '18',
                            bold: true,
                            margin: [30, 2],
                        },

                        {
                            text: `${company?.company_address}`,
                            fontSize: '14',
                            margin: [30, 2],
                        },
                        {
                            text: `โทร ${company?.company_phone_number} โทรสาร ${company?.company_fax} `,
                            fontSize: '14',
                            margin: [30, 2],
                        },
                        {
                            text: `อีเมล ${company?.company_email} เลขประจำตัวผู้เสียภาษี ${company?.company_taxes_id}`,
                            fontSize: '14',
                            margin: [30, 2],
                        },
                    ],
                    [
                        {
                            text: `รายการจัดส่งสินค้าประจำเดือน ${moment(log?.month).format("MMMM")}/${moment(log?.month).format("YYYY")}`,
                            fontSize: '18',
                            bold: true,
                            margin: [55, 2],
                        },
                        {
                            text: `รายการทั้งหมด : ${_.size(log?.delivery_logs)} รายการ`,
                            fontSize: '16',
                            margin: [55, 2],
                        },

                    ]
                ],
            },

            {
                margin: [0, 30, 30, 0],
                style: 'tableExample',
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0 ? '#c2dec2' : null;
                    },
                },
                table: {
                    widths: ['4%', '9%', '9%', '5%', '6%', '12%', '17%', '17%', '5%', '18%', '8%'],
                    heights: [10, 10, 10, 10, 30, 10, 10, 10, 10, 10, 10, 10],
                    headerRows: 1,
                    dontBreakRows: true,
                    body: [
                        [
                            {
                                text: 'ลำดับ',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'วันรับ',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'วันส่ง',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'ประเภท',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'สถานะ',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'เลขที่ใบแจ้งหนี้',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'ผู้ส่ง',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'ผู้รับ',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'ขนาด',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'ทะเบียนรถ',
                                colSpan: 1,
                                fontSize: 14,
                            },
                            {
                                text: 'หมายเลขซีล',
                                colSpan: 1,
                                fontSize: 14,
                            },

                        ],
                        ...data,

                    ],
                },
            }
        ],
        images: {
            company_logo: company?.company_logo,
        },
    };
    pdfMake.createPdf(docDefinition).open();

}