import axios from 'axios';
import _ from 'lodash';
import {
    MILEAGE_ALL,
    MILEAGE_ALL_BY_VEHICLE,
    MILEAGE_GET,
    MILEAGE_GET_LATEST,
    MILEAGE_POST,
    MILEAGE_PUT,
    MILEAGE_DEL,
    MILEAGE_ALL_LATEST,
} from '../types';

export const mileageAll = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/mileage')
            .then((res) => {
                console.log('Request Server to Get All Mileage');
                dispatch({ type: MILEAGE_ALL, payload: res.data });
            });
    };
};

export const mileageAllByVehicle = (payload) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL + '/mileage/by/vehicle/' + payload
            )
            .then((res) => {
                console.log('Request Server to Get All Mileage by Vehicle');
                dispatch({ type: MILEAGE_ALL_BY_VEHICLE, payload: res.data });
            });
    };
};

export const mileageGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/mileage/' + payload)
            .then((res) => {
                console.log('Request Server to Get an Mileage');
                if (res.data) {
                    dispatch({ type: MILEAGE_GET, payload: res.data });
                } else {
                    dispatch({ type: MILEAGE_GET, payload: null });
                }
            });
    };
};

export const mileageGetLatest = (payload) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/mileage/latest/by/vehicle/' +
                    payload
            )
            .then((res) => {
                console.log('Request Server to Get latest Mileage');
                if (res.data) {
                    dispatch({ type: MILEAGE_GET_LATEST, payload: res.data });
                } else {
                    dispatch({ type: MILEAGE_GET_LATEST, payload: null });
                }
            });
    };
};

export const mileageGetAllLatest = () => {
    return async (dispatch) => {
        // Get All Vehicle
        const mileagueVehicleMap = [];
        await axios
            .get(process.env.REACT_APP_API_URL + '/vehicle/by/head/and/tail')
            .then(async (vehicleRes) => {
                const vehicleAmount = _.size(vehicleRes.data);
                _.map(vehicleRes.data, async (vehicle, index) => {
                    await axios
                        .get(
                            process.env.REACT_APP_API_URL +
                                '/mileage/latest/by/vehicle/' +
                                vehicle.vehicle_id
                        )
                        .then((mileagueRes) => {
                            const eachVehicleMileague = mileagueRes.data;
                            mileagueVehicleMap.push(eachVehicleMileague);
                            if (index === vehicleAmount - 1) {
                                dispatch({
                                    type: MILEAGE_ALL_LATEST,
                                    payload: mileagueVehicleMap,
                                });
                            }
                        });
                });
            })
            .catch(() => {
                dispatch({
                    type: MILEAGE_ALL_LATEST,
                    payload: null,
                });
            });
    };
};

export const mileagePost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/mileage', payload)
            .then((res) => {
                console.log('Request Server to post an Mileage');
                dispatch({ type: MILEAGE_POST, payload: null });
            });
    };
};

export const mileagePut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/mileage/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an Mileage');
                dispatch({ type: MILEAGE_PUT, payload: null });
            });
    };
};

export const mileageDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/mileage/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an Mileage');
                dispatch({ type: MILEAGE_DEL, payload: null });
            });
    };
};
