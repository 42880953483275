import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ModalFooter,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import DisplayMode from './DisplayMode'
import EditorMode from './EditorMode';
import { DELIVERY_STATUS } from '../../../config/constant/DeliveryStatus';
import CONST_CONFIG from '../../../config/constant';
import * as actions from '../../../redux/actions';

export default function DeliveryModal({
    deliveryId,
    openStatus,
    closeHandle,
    allDelivery,
    allAssignments,
}) {
    const dispatch = useDispatch();

    const selectedDeliveryOrder = _.find(
        allDelivery,
        (eachDelivery) => eachDelivery.delivery_id === deliveryId
    );

    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleOpenEditModal = () => {
        setIsOpenModal(!isOpenModal)
    }

    const handleInProgressDelivery = (delivery_id) => {
        const confirm = window.confirm('ยืนยันการเริ่มดำเนินการรายการเดินรถ');
        const payload = {
            delivery_status: CONST_CONFIG.DELIVERY_STATUS[1],
        };
        if (confirm)
            dispatch(
                actions.deliveryPut(delivery_id, payload)
            ).then(() => {
                dispatch(actions.deliveryGetProcessingStatus());
            });

    };

    const handleSuccessDelivery = (delivery) => {
        const confirm = window.confirm('ยืนยันรายการเดินรถ');
        delivery.delivery_status = CONST_CONFIG.DELIVERY_STATUS[2];
        if (confirm)
            dispatch(
                actions.deliveryPutStatusSuccessAndCancle(delivery.delivery_id, delivery)
            ).then(() => {
                dispatch(actions.deliveryGetProcessingStatus());
            });
    };

    const handleDeleteDelivery = (delivery) => {
        const confirm = window.confirm('ยืนยันการยกเลิกรายการเดินรถ');
        delivery.delivery_status = CONST_CONFIG.DELIVERY_STATUS[3];
        if (confirm)
            dispatch(
                actions.deliveryPutStatusSuccessAndCancle(delivery.delivery_id, delivery)
            ).then(() => {
                dispatch(actions.deliveryGetProcessingStatus());
            });
    };

    if (selectedDeliveryOrder)
        return (
            <div>
                <Modal isOpen={openStatus} onClose={closeHandle} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="font-sans text-base">
                            <div className="flex gap-2">
                                <div>
                                    รายการเดินรถ{selectedDeliveryOrder?.assignment_type}
                                </div>
                                <div className="self-center">

                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[0] && (
                                            <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[1] && (
                                            <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[2] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[3] && (
                                            <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                </div>
                                <div>
                                    {(selectedDeliveryOrder?.delivery_status === DELIVERY_STATUS[0]) ? (
                                        <Button
                                            onClick={() =>
                                                handleOpenEditModal()
                                            }
                                            colorScheme="yellow"
                                            size="sm"
                                        >
                                            <div className="text-white">แก้ไข</div>
                                        </Button>
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody className="p-4 m-4 mt-0 pb-6">
                            <DisplayMode
                                allAssignments={allAssignments}
                                selectedDeliveryOrder={
                                    selectedDeliveryOrder
                                }
                            />
                        </ModalBody>
                        <ModalFooter>
                            <div className="flex flex-wrap gap-2">

                                <Button
                                    colorScheme="yellow"
                                    size="md"
                                    disabled={
                                        selectedDeliveryOrder?.delivery_status === DELIVERY_STATUS[1]
                                    }
                                    onClick={() => {
                                        handleInProgressDelivery(selectedDeliveryOrder?.delivery_id);
                                    }}
                                >
                                    <div className="text-white  ">ดำเนินการ</div>
                                </Button>

                                <Button
                                    colorScheme="green"
                                    size="md"
                                    onClick={() => {
                                        handleSuccessDelivery(selectedDeliveryOrder);
                                    }}
                                >
                                    <div>สำเร็จ</div>
                                </Button>
                                <Button
                                    colorScheme="red"
                                    size="md"
                                    onClick={() => {
                                        handleDeleteDelivery(selectedDeliveryOrder);
                                    }}
                                >
                                    <div>ยกเลิก</div>
                                </Button>
                            </div>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Modal isOpen={isOpenModal} onClose={setIsOpenModal} size="4xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className="font-sans text-base">
                            <div className="flex gap-2">
                                <div>
                                    รายการเดินรถ{selectedDeliveryOrder?.assignment_type}
                                </div>
                                <div className="self-center">

                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[0] && (
                                            <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[1] && (
                                            <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[2] && (
                                            <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                    {selectedDeliveryOrder?.delivery_status ===
                                        DELIVERY_STATUS[3] && (
                                            <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                                {
                                                    selectedDeliveryOrder?.delivery_status
                                                }
                                            </div>
                                        )}
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody className="p-4 m-4 mt-0 pb-6">

                            <EditorMode
                                allAssignments={allAssignments}
                                selectedDeliveryOrder={
                                    selectedDeliveryOrder
                                }
                                handleExitModal={closeHandle}
                            />

                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        );
    else {
        return <></>;
    }
}
