import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import _ from 'lodash';

import TableNotifications from './components/TableNotifications';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import NotifyModal from '../../../components/Modal/NotifyModal/NotifyModal';

export default function NotifyManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const [isNotifyModalOpen, setIsModalNotifyOpen] = useState(false);
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.vehicle);
    const mileage = useSelector((state) => state.mileage);

    useEffect(() => {
        dispatch(actions.vehicleAllByHeadAndTail()).then(() => {
            dispatch(actions.repairTypeAll());
            dispatch(actions.mileageGetAllLatest()).then(() => {
                setIsLoading(true);
            });
        });

        return () => { };
    }, []);

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                จัดการการแจ้งเตือน
            </h2>
            <div className="p-4">
                <Button
                    colorScheme="blue"
                    onClick={() => {
                        setIsModalNotifyOpen(true);
                    }}
                >
                    เพิ่ม
                </Button>
            </div>
            <div className="px-4">
                <div >
                    <NotifyModal
                        closeHandle={() => {
                            setIsModalNotifyOpen(false);
                        }}
                        openStatus={isNotifyModalOpen}
                    />
                </div>
                <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                    <TableNotifications
                        vehicleList={vehicles?.arr}
                        mileagueList={mileage?.arr}
                    />
                </div>

            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
