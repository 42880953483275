import axios from "axios";
import { COMPANY_ALL, COMPANY_GET, COMPANY_PUT, COMPANY_DEL } from "../types";

export const companyAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/company").then((res) => {
      console.log("Request Server to Get All Companys");
      dispatch({ type: COMPANY_ALL, payload: res.data });
    });
  };
};

export const companyGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/company/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Companys");
        if (res.data) {
          dispatch({ type: COMPANY_GET, payload: res.data });
        } else {
          dispatch({ type: COMPANY_GET, payload: null });
        }
      });
  };
};

export const companyPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/company/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Companys");
        dispatch({ type: COMPANY_PUT, payload: null });
      });
  };
};
export const companyDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/company/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Companys");
        dispatch({ type: COMPANY_DEL, payload: null });
      });
  };
};
