import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";
import { Box, Button, HStack } from "@chakra-ui/react";
import _ from "lodash";

export default function PositionByDepartmentLists({ position }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const genTd = () =>
    _.map(position, (_position) => (
      <Tr key={_position.position_id}>
        <Td>{_position.position_name}</Td>
        <Td>{_position.position_salary}</Td>
        <Td>{_position.permission_level}</Td>
        <Td>
          {" "}
          <HStack spacing={2}>
            <Box>
              <Link to={"/hrms/department/" + id + "/position/edit/" + _position.position_id}>
                {" "}
                <Button colorScheme="yellow">แก้ไข</Button>
              </Link>
            </Box>

            <Button
              colorScheme="red"
              onClick={() => {
                handdleDelete(_position.position_id);
              }}
            >
              ลบ
            </Button>
          </HStack>
        </Td>
      </Tr>
    ));
  const handdleDelete = (departmet_id) => {
    dispatch(actions.positionDelete(departmet_id)).then(() => {
      dispatch(actions.positionAllByDepartment(id)).then(() => {
        // alert("ลบตำแหน่งสำเร็จ");
      });
    });
  };
  return (
    <div className=" rounded rounded-t-lg bg-white p-4">
      <Table variant="striped" size="lg">
        <TableCaption>ข้อมูลและรายละเอียดตำแหน่งในแผนก</TableCaption>
        <Thead>
          <Tr>
            <Th>ตำแหน่ง</Th>
            <Th>รายละเอียด</Th>
            <Th>สิทธิการเข้าถึง</Th>
            <Th>ดำเนินการ</Th>
          </Tr>
        </Thead>
        <Tbody>{genTd()}</Tbody>
      </Table>
    </div>
  );
}
