import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/index';
import { useForm } from 'react-hook-form';
import ImageUploader from 'components/ImageUpload/ImageUpload';
import { uploadImageFile } from 'util/image.functions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import { Button, Center } from '@chakra-ui/react';
import _ from 'lodash';
import hash from 'object-hash';
export default function EditCompany() {
    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const company = useSelector((state) => state.company);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState([]);
    const [isEditLogo, setIsEditLogo] = useState(false);
    
  
    const onSubmit = (data, e) => {
        const id = company.company_id;
        if (isEditLogo) {
            if (_.size(image)) {
                const filename = hash({ picture: image, date: new Date() });
                uploadImageFile(filename, image[0].data_url).then((url) => {
                    console.log('URL : ' + url);
                    data.company_logo = url;
                    dispatch(actions.companyPut(id, data)).then(() => {
                        setIsLoading(false);
                        console.log('แก้ไขข้อมูลบริษัทสำเร็จ');
                        history.push('/system/company');
                    });
                });
            } else {
                alert('กรุณาเลือกรูปภาพก่อนบันทึก');
            }
        } else {
            const confirm = window.confirm('ยืนยันการแก้ไขข้อมูลบริษัท');
            if (confirm) {
                dispatch(actions.companyPut(id, data)).then(() => {
                    setIsLoading(false);
                    console.log('แก้ไขข้อมูลบริษัทสำเร็จ');
                    history.push('/system/company');
                });
            }
        }
    };
    useEffect(() => {
        dispatch(actions.companyAll()).then(() => {
            setIsLoading(true);
        });
        return () => {};
    }, []);
    return isLoading ? (
        <div>
            <div className="p-4 ">
             
                    <Button variant="outline" className="inline-flex" onClick={()=>history.goBack()}>
                        กลับ
                    </Button>
              
            </div>
            <div className="p-4">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        {' '}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-full mb-3 ">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    โลโก้บริษัท{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                {!isEditLogo ? (
                                    <>
                                        <div className="content-center text-right bg-white p-3 mt-3 mb-2 rounded-lg">
                                            <Center>
                                                <img
                                                    className="rounded-lg shadow-md max-w-150-px"
                                                    src={company?.company_logo}
                                                ></img>
                                            </Center>

                                            <Button
                                                size="xs"
                                                variant="outline"
                                                onClick={() => {
                                                    setIsEditLogo(true);
                                                }}
                                            >
                                                แก้ไข
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="rounded-lg  bg-white p-4  mt-3 mb-2 text-right">
                                            <ImageUploader
                                                images={image}
                                                setImages={setImage}
                                            />
                                            <Button
                                                size="xs"
                                                variant="outline"
                                                onClick={() => {
                                                    setIsEditLogo(false);
                                                }}
                                            >
                                                ยกเลิก
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    ชื่อบริษัท{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="company_name"
                                    type="text"
                                    defaultValue={company?.company_name}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="ชื่อบริษัท"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    เลขที่ผู้เสียภาษี{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="company_taxes_id"
                                    type="text"
                                    defaultValue={company?.company_taxes_id}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="เลขที่ผู้เสียภาษี"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    Email{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="company_email"
                                    type="email"
                                    defaultValue={company?.company_email}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="Email Company"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    เบอร์โทรศํพท์{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="company_phone_number"
                                    type="text"
                                    defaultValue={company?.company_phone_number}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="Email Company"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    แฟกซ์{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="company_fax"
                                    type="text"
                                    defaultValue={company?.company_fax}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="FAX"
                                />
                            </div>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    ที่อยู่{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <textarea
                                    name="company_address"
                                    type="textfield"
                                    defaultValue={company?.company_address}
                                    required
                                    ref={register}
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    placeholder="ที่อยู่บริษัท"
                                />
                                <div className="text-center mt-6">
                                    <Button
                                        isFullWidth
                                        colorScheme="blue"
                                        type="submit"
                                    >
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <>
            <SpinnerLoading />
        </>
    );
}
