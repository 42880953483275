import { combineReducers } from 'redux';
// Common Module
import MeReducers from './common/MeReducers';
// HRMS Module
import EmployeeReducers from './hrms/EmployeeReducer';
import DepartmentReducers from './hrms/DepartmentReducer';
import PositionReducers from './hrms/PositionReducer';
// CRM Module
import CustomerReducers from './crm/CustomerReducer';
import ContactReducers from './crm/ContactReducer';
import NoteReducers from './crm/NoteReducer';
// CMMS Module
import MileageReducers from './cmms/MileageReducers';
import ProblemReducers from './cmms/ProblemReduces';
import RepairWheelReducers from './cmms/RepairWheelReducers';
import RepairTypeReducers from './cmms/RepairTypeReducers';
import RepairReducers from './cmms/RepairReducers';
import NotifyReducers from './cmms/NotifyReducers';
import TypeReducers from './cmms/TypeReducers';
// FMS Module
import CategoryVehicleReducers from './fms/CategoryVehicleReducers';
import SubCategoryVehicleReducers from './fms/SubCategoryVehicleReducers';
import VehicleReducers from './fms/VehicleReducers';
import CarOwner from './fms/CarOwnerReducers';
import RouteReducers from './fms/RouteReducers';
import PathReducers from './fms/PathReducers';
// Sales Module
import QuotationReducers from './sales/QuotationReducers';
// MRP Module
import Sales_OrderReducers from './mrp/Sales_OrderReducers';
import ProductReducers from './mrp/ProductReducers';
import ProcessProductReducers from './mrp/ProcessProductReducers';
import CategoryReducers from './mrp/CategoryReducers';
import SubCategoryReducers from './mrp/Sub_CategoryReducers';
// TMS Module
import TransportAssignmentReducers from './tms/TransportAssignmentReducer';
import DeliveryReducers from './tms/DeliveryReducers';
// System  Module
import SystemInfoReducers from './system/SystemInfoReducers';
import CompanyReducers from './system/CompanyReducers';
// Log
import RepairLogReducers from './log/RepairLogReducers';
import RepairLogMonthReducers from './log/RepairLogMonthReducers';
import DeliveryMonthReducers from './log/DeliveryMonthReducers';

const rootRuducer = combineReducers({
    me: MeReducers,
    // HRMS
    employee: EmployeeReducers,
    department: DepartmentReducers,
    position: PositionReducers,
    // assignment: AssignmentReducers,
    // CRM
    customer: CustomerReducers,
    contact: ContactReducers,
    note: NoteReducers,
    // CMMS
    mileage: MileageReducers,
    problem: ProblemReducers,
    repair_wheel: RepairWheelReducers,
    repair_type: RepairTypeReducers,
    repair: RepairReducers,
    notify: NotifyReducers,
    type: TypeReducers,
    // FMS
    category_vehicle: CategoryVehicleReducers,
    sub_category_vehicle: SubCategoryVehicleReducers,
    vehicle: VehicleReducers,
    car_owner: CarOwner,
    route: RouteReducers,
    path: PathReducers,
    // SALES
    quotation: QuotationReducers,
    // MRP
    sales_order: Sales_OrderReducers,
    product: ProductReducers,
    process: ProcessProductReducers,
    category: CategoryReducers,
    sub_category: SubCategoryReducers,

    // TMS
    transport_assignment: TransportAssignmentReducers,
    delivery: DeliveryReducers,
    // Log
    notify: NotifyReducers,
    repair_log: RepairLogReducers,
    repair_month: RepairLogMonthReducers,
    delivery_month: DeliveryMonthReducers,
    // SYSTEM
    system_info: SystemInfoReducers,
    company: CompanyReducers,
});
export default rootRuducer;
