import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import { useForm } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import * as actions from "redux/actions";

export default function EditRoute() {
    const { register, handleSubmit, errors } = useForm();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const route = useSelector((state) => state.route);

    useEffect(() => {
        dispatch(actions.routeGet(id))
        setIsLoading(true);
        return () => {}
    }, [])

    const onSubmit = (data) => {
        dispatch(actions.routePut(id, data)).then(() => {
            history.goBack()
        })
    }

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    แก้ไขเส้นทางการเดินรถ
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4 justify-start">
                    <Button
                        onClick={() => history.goBack()}
                        variant="outline"
                    >กลับ
                    </Button>
                </div>
                <div className="p-4">
                    <div className="flex content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3 flex flex-wrap">
                                <div className="w-full">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อเส้นทาง
                                        <input
                                            name="title"
                                            defaultValue={route?.title}
                                            ref={register}
                                            placeholder="ชื่อเส้นทาง"
                                            className="px-3 py-3 mt-2 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        ></input>
                                    </label>
                                </div>
                            </div>
                            <div className="text-center mt-6 ">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    )
}