import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/actions';
import DeliveryCalendarCompanent from '../../../../components/Calendar/DeliveryCalendar';

export default function Dashboard({ isShowEditCalendar = false }) {
    const delivery = useSelector((state) => state.delivery);
    const transportAssignment = useSelector(
        (state) => state.transport_assignment
    );

    return (
        <div>
            <DeliveryCalendarCompanent
                deliveryArray={delivery?.arr}
                assignmentArray={transportAssignment?.arr}
                isShowEditCalendar={isShowEditCalendar}
            />
        </div>
    );
}
