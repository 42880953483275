import axios from "axios";
import {
    REPAIR_WHEEL_ALL,
    REPAIR_WHEEL_GET,
    REPAIR_WHEEL_POST,
    REPAIR_WHEEL_PUT,
    REPAIR_WHEEL_DEL
} from "../types";

export const repairWheelAll = () => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + "/repair_wheel").then((res) => {
            console.log("Request Server to Get All Repair_wheel");
            dispatch({ type: REPAIR_WHEEL_ALL, payload: res.data });
        });
    };
}

export const repairWheelGet = (payload) => {
    return async (dispatch) => {
        await axios
        .get(process.env.REACT_APP_API_URL + "/repair_wheel/" + payload)
        .then((res) => {
            console.log("Request Server to Get an Repair_wheel");
            if (res.data) {
                dispatch({ type: REPAIR_WHEEL_GET, payload: res.data })
            } else {
                dispatch({ type: REPAIR_WHEEL_GET, payload: null });
            }
        });
    };
};

export const repairWheelPost = (payload) => {
    return async (dispatch) => {
        await axios
        .post(process.env.REACT_APP_API_URL + "/repair_wheel", payload)
        .then((res) => {
            console.log("Request Server to post an Repair_wheel");
            dispatch({ type: REPAIR_WHEEL_POST, payload: null });
        });
    };
};

export const repairWheelPut = (id, payload) => {
    console.log("id", id);
    console.log("payload", payload);
    return async (dispatch) => {
        await axios
        .put(process.env.REACT_APP_API_URL + "/repair_wheel/" + id, payload)
        .then((res) => {
            console.log("Request Server to put an Repair_wheel");
            dispatch({ type: REPAIR_WHEEL_PUT, payload: null });
        });
    };
};

export const repairWheelDelete = (payload) => {
    return async (dispatch) => {
        await axios
        .delete(process.env.REACT_APP_API_URL + "/repair_wheel/" + payload)
        .then((res) => {
            console.log("Request Server to Delete an Repair_wheel");
            dispatch({ type: REPAIR_WHEEL_DEL, payload: null });
        });
    };
};