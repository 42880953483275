import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
import DeliveryCalendar from './components/DeliveryCalendar';
import DeliveryListContainer from './components/DeliveryListContainer';

export default function DeliveryTableDashboard() {
    const [isLoading, setIsLoading] = useState(false);
    const [isShownCalendar, setIsShownCalendar] = useState(false);
    const delivery = useSelector((state) => state.delivery);
    const [delivery_data, setDelivery] = useState([]);
    const [enableCalendarSetting, setEnableCalendarSetting] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.vehicleAll());
        dispatch(actions.deliveryAll()).then(() => {
            dispatch(actions.transportAssignmentAll()).then(() => {
                setIsLoading(true);
            });
        });

        return () => {};
    }, []);

    useEffect(() => {
        setDelivery(delivery?.arr);
        return () => {};
    }, [delivery]);

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    แดชบอร์ด
                </h2>
            </div>
            {isShownCalendar ? (
                <div className="py-4 ">
                    <DeliveryCalendar
                        isShowEditCalendar={enableCalendarSetting}
                    />
                </div>
            ) : (
                <>
                    {' '}
                    <div className="py-4 ">
                        {_.size(delivery_data) ? (
                            <div>
                                <DeliveryListContainer
                                    delivery={delivery_data}
                                />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                <div>ไม่มีข้อมูล</div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    ) : (
        <SpinnerLoading />
    );
}
