import {
  NOTE_ALL_BY_CUSTOMER,
  NOTE_GET,
  NOTE_PUT,
  NOTE_DEL,
  NOTE_POST,
  NOTE_DEL_BY_CUSTOMER,
} from "../../actions/types";
export default function (state = null, action) {
  switch (action.type) {
    case NOTE_ALL_BY_CUSTOMER:
      return action.payload;
    case NOTE_GET:
      return action.payload;
    case NOTE_POST:
      return action.payload;
    case NOTE_PUT:
      return action.payload;
    case NOTE_DEL:
      return action.payload;
    case NOTE_DEL_BY_CUSTOMER:
      return action.payload;
    default:
      return state;
  }
}
