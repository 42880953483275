import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Input, FormControl, Textarea, Button } from '@chakra-ui/react';
import Flatpickr from 'react-flatpickr';
import SelectComponent from 'react-select';
import 'flatpickr/dist/themes/airbnb.css';
import { useForm } from 'react-hook-form';
import * as actions from '../../../redux/actions';
import Select from 'react-select';
import axios from 'axios';
import { ASSIGNMENT_STATUS } from '../../../config/constant/AssignmentStatus';

export default function EditMode({
    selectedAssignment,
    allCustomer,
    container,
    handleCancleEditing,
    handleExitModal,
    isEditorMode
}) {
    const dispatch = useDispatch();
    const { register, handleSubmit, setValue } = useForm();
    const [pickupDate, setPickupDate] = useState(
        selectedAssignment?.pickup_date
    );
    const [delivery_date, setDeliveryDate] = useState(
        selectedAssignment?.delivery_date
    );
    const [selectedVehicleContainer, setSelectedVehicleContainer] = useState(
        () =>
            container.find(
                (option) =>
                    option.value == selectedAssignment?.vehicle_container_id
            )
    );
    const [selectedShipper, setSelectedShipper] = useState(); //ลูกค้าที่ถูกเลือก (ผู้ส่ง)
    const [selectedConsignee, setSelectedConsignee] = useState(); //ลูกค้าที่ถูกเลือก (ผู้รับ)
    const [selectedContainerSize, setSelectedContainerSize] = useState(selectedAssignment?.container_size);

    useEffect(() => {
        if (selectedShipper) {
            setValue(
                'shipper_address',
                selectedShipper?.data?.customer_address
            );
            setValue(
                'shipper_postcode',
                selectedShipper?.data?.customer_postcode
            );
        }

        if (selectedConsignee) {
            setValue(
                'consignee_address',
                selectedConsignee?.data?.customer_address
            );
            setValue(
                'consignee_postcode',
                selectedConsignee?.data?.customer_postcode
            );
        }

    }, [selectedShipper, selectedConsignee]);

    const genCustomerLists = () => {
        const result = _.map(allCustomer, (customer) => {
            return {
                value: customer?.customer_id,
                label: customer?.customer_company,
                data: customer,
            };
        });
        return result;
    };

    const handleSelectdShipper = (option) => {
        setSelectedShipper(option);
    };

    const handleSelectdConsignee = (option) => {
        setSelectedConsignee(option);
    };

    const handleChangeSelectedContainer = (option) => {
        setSelectedContainerSize(option?.data?.sub_category_vehicle?.sub_category_name)
        setSelectedVehicleContainer(option);
    };

    const handleUpdateAssignment = (data) => {

        const payload = {
            origins: [data.shipper_address + data.shipper_postcode],
            destinations: [data.consignee_address + data.consignee_postcode],
        };

        data.pickup_date = pickupDate;
        data.delivery_date = delivery_date;

        axios
            .post(
                process.env.REACT_APP_API_URL + '/other/find-distance',
                payload
            )
            .then((res) => {
                if (res.data.status === 'OK') {
                    data.shipper_id = selectedShipper?.value;
                    data.consignee_id = selectedConsignee?.value;
                    data.assignment_origin = res.data.origin_addresses[0];
                    data.assignment_destination =
                        res.data.destination_addresses[0];
                    data.assignment_duration =
                        res.data.rows[0].elements[0].duration.text;
                    data.assignment_distance =
                        res.data.rows[0].elements[0].distance.text;

                    if (!data.load_weight) {
                        data.load_weight = 0;
                    }

                    if (!data.number_of_packages) {
                        data.number_of_packages = 0;
                    }

                    if (selectedVehicleContainer) {
                        data.vehicle_container_id = selectedVehicleContainer?.value;
                        data.container_size = selectedContainerSize;
                    }


                    const confirm = window.confirm(
                        'ยืนยันการเลือกตำแหน่งจาก ' +
                        data.assignment_origin +
                        ' ไปยัง ' +
                        data.assignment_destination
                    );
                    if (confirm) {

                        dispatch(
                            actions.transportAssignmentPut(
                                selectedAssignment.assignment_id, data
                            )
                        ).then(() => {
                            if (!isEditorMode) {

                                dispatch(actions.deliveryAll()).then(() => {
                                    dispatch(actions.transportAssignmentGetByStatus(ASSIGNMENT_STATUS[4])).then(() => {
                                        handleCancleEditing();
                                        handleExitModal();
                                    });
                                });
                            } else {

                                dispatch(actions.deliveryAll()).then(() => {
                                    dispatch(actions.transportAssignmentAll()).then(() => {
                                        handleCancleEditing();
                                        handleExitModal();
                                    });
                                });
                            }

                        });
                    }
                } else {
                    alert('ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ');
                }
            })
            .catch(() => {
                alert('ไม่สามารถค้นหาเส้นทาง กรุณาเลือกตำแหน่งอื่นๆ');
            });
    };

    return (
        <div>
            <form action={handleSubmit(handleUpdateAssignment)}>
                <div
                    className="hover:bg-gray-100 p-3 rounded-md cursor-text border"
                >
                    <div className="flex gap-2 font-sans flex-wrap justify-center">
                        <div className="w-2/6">
                            <h5 className="block uppercase text-gray-700 text-xs font-bold my-1">ผู้ส่ง</h5>
                            <Select
                                name="shipper_id"
                                defaultValue={{ value: selectedAssignment?.shipper_id, label: selectedAssignment?.shipper?.customer_company }}
                                ref={register}
                                onChange={handleSelectdShipper}
                                options={genCustomerLists()}
                                required
                                placeholder={'กรุณาเลือกข้อมูลลูกค้าต้นทาง'}
                            />

                            <FormControl>
                                <label className="block uppercase text-gray-700 text-xs font-bold my-1">
                                    สถานที่ส่งสินค้า
                                </label>
                                <Textarea
                                    size="sm"
                                    name="shipper_address"
                                    ref={register}
                                    placeholder="สถานที่ส่งสินค้า"
                                    defaultValue={
                                        selectedAssignment?.shipper_address
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <label className="block uppercase text-gray-700 text-xs font-bold my-1">
                                    รหัสไปรษณีย์
                                </label>
                                <Input
                                    size="sm"
                                    name="shipper_postcode"
                                    ref={register}
                                    placeholder="รหัสไปรษณีย์"
                                    defaultValue={
                                        selectedAssignment?.shipper_postcode
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className="w-1/6  inset-0 flex items-center justify-center">
                            <i className="fas fa-arrow-right" />
                        </div>

                        <div className="w-2/6">
                            <h5 className="block uppercase text-gray-700 text-xs font-bold my-1">ผู้รับ</h5>
                            <Select
                                name="consignee_id"
                                ref={register}
                                defaultValue={{ value: selectedAssignment?.consignee_id, label: selectedAssignment?.consignee?.customer_company }}
                                onChange={handleSelectdConsignee}
                                options={genCustomerLists()}
                                required
                                placeholder={'กรุณาเลือกข้อมูลลูกค้าปลายทาง'}
                            />

                            <FormControl>
                                <label className="block uppercase text-gray-700 text-xs font-bold my-1">
                                    สถานที่รับสินค้า
                                </label>
                                <Textarea
                                    size="sm"
                                    name="consignee_address"
                                    ref={register}
                                    placeholder="สถานที่รับสินค้า"
                                    defaultValue={
                                        selectedAssignment?.consignee_address
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <label className="block uppercase text-gray-700 text-xs font-bold my-1">รหัสไปรษณีย์</label>
                                <Input
                                    size="sm"
                                    name="consignee_postcode"
                                    ref={register}
                                    placeholder="รหัสไปรษณีย์"
                                    defaultValue={
                                        selectedAssignment?.consignee_postcode
                                    }
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>

                <div className="mt-2 hover:bg-gray-100 p-3 rounded-md cursor-text border">
                    <h5 className="block uppercase text-gray-700 text-xs">
                        <b>ระยะทาง</b> {selectedAssignment?.assignment_distance}
                    </h5>
                    <h5 className="block uppercase text-gray-700 text-xs">
                        <b>ระยะเวลาในการเดินทาง</b> {selectedAssignment?.assignment_duration}
                    </h5>
                </div>

                <div className="mt-2 hover:bg-gray-100 p-3 rounded-md cursor-text border">

                    <div className="flex flex-wrap gap-2 mx-4">
                        <FormControl className="my-2">
                            <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                เลขที่ใบแจ้งหนี้
                            </h3>

                            <Input
                                name="invoice_number"
                                ref={register}
                                placeholder="เลขที่ใบแจ้งหนี้"
                                defaultValue={
                                    selectedAssignment?.invoice_number
                                }
                            />
                        </FormControl>
                    </div>

                    <div className="flex flex-row mx-4">
                        <div className="relative w-full mb-3">
                            <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                วันที่รับสินค้า
                            </h3>
                            <Flatpickr
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                value={pickupDate}
                                onChange={(date) => {
                                    setPickupDate(date[0]);
                                }}
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                วันที่ส่งสินค้า
                            </h3>
                            <Flatpickr
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                value={delivery_date}
                                onChange={(date) => {
                                    setDeliveryDate(date[0]);
                                }}
                            />
                        </div>
                    </div>
                    <div className="relative mb-3 mx-4 ">
                        <FormControl>
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ตู้คอนเทนเนอร์{' '}
                            </label>
                            <SelectComponent
                                name="vehicle_container_id"
                                defaultValue={container.filter(
                                    (option) =>
                                        option.value ==
                                        selectedAssignment?.vehicle_container_id
                                )}
                                placeholder={'กรุณาเลือกข้อมูลตู้คอนเทนเนอร์'}
                                onChange={handleChangeSelectedContainer}
                                value={selectedVehicleContainer}
                                options={container}
                            />
                        </FormControl>
                    </div>

                    <div className="flex flex-row gap-2 mx-4">
                        <FormControl className="mb-2">
                            <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                ขนาดคอนเทนเนอร์
                            </h3>

                            <Input
                                type="text"
                                name="container_size"
                                ref={register}
                                placeholder="ขนาดคอนเทนเนอร์"
                                disabled
                                value={selectedContainerSize}
                            />
                        </FormControl>
                        <FormControl className="mb-2">
                            <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                จำนวนหีบห่อหรือพาเลท
                            </h3>

                            <Input
                                type="number"
                                name="number_of_packages"
                                ref={register}
                                placeholder="จำนวนหีบห่อหรือพาเลท"
                                defaultValue={
                                    selectedAssignment?.number_of_packages
                                }
                            />
                        </FormControl>
                    </div>

                    <div className="flex flex-wrap gap-2 mx-4">
                        <div className="w-full ">
                            <div className="flex gap-2 flex-wrap lg:flex-nowrap ">

                                <FormControl className="mb-2">
                                    <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                                        น้ำหนักรวม (กิโลกรัม)
                                    </h3>

                                    <Input
                                        type="number"
                                        name="load_weight"
                                        ref={register}
                                        placeholder="น้ำหนักรวม"
                                        defaultValue={
                                            selectedAssignment?.load_weight
                                        }
                                    />
                                </FormControl>{' '}

                            </div>

                        </div>
                    </div>

                    <div className="mt-1 mx-4">
                        <h3 className="block uppercase text-gray-700 text-xs font-bold mb-1">
                            หมายเหตุ
                        </h3>
                        <FormControl>
                            <Textarea
                                name="assignment_note"
                                ref={register}
                                defaultValue={selectedAssignment?.assignment_note}
                            />
                        </FormControl>
                    </div>

                </div>
                <div className="flex justify-end font-sans gap-2 mt-2">
                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit(handleUpdateAssignment)}
                    >
                        บันทึก
                    </Button>
                    <Button
                        colorScheme="gray"
                        type="button"
                        onClick={() => handleExitModal()}
                    >
                        ยกเลิก
                    </Button>
                </div>
            </form>
        </div>
    );
}
