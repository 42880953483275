import axios from "axios";
import { CATEGORY_ALL, CATEGORY_GET, CATEGORY_PUT, CATEGORY_DEL, CATEGORY_POST } from "../types";

export const categoryAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/category").then((res) => {
      console.log("Request Server to Get All Categorys");
      dispatch({ type: CATEGORY_ALL, payload: res.data });
    });
  };
};

export const categoryGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/category/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Categorys");
        if (res.data) {
          dispatch({ type: CATEGORY_GET, payload: res.data });
        } else {
          dispatch({ type: CATEGORY_GET, payload: null });
        }
      });
  };
};

export const categoryPost = (payload) => {
  console.log(payload);
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/category", payload)
      .then((res) => {
        console.log("Request Server to post an category");
        dispatch({ type: CATEGORY_POST, payload: null });
      });
  };
};

export const categoryPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/category/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an Categorys");
        dispatch({ type: CATEGORY_PUT, payload: null });
      });
  };
};
export const categoryDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/category/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an Categorys");
        dispatch({ type: CATEGORY_DEL, payload: null });
      });
  };
};
