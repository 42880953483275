import axios from "axios";
import {
    DELIVERY_MONTH_ALL,
    DELIVERY_MONTH_GET,
    DELIVERY_MONTH_GET_ALL_TYPE,
} from '../types';

const defaultSize = 5
const defaultPage = 1

export const deliveryMonthAll = ({ size = defaultSize, page = defaultPage }) => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + `/delivery_month?size=${size || defaultSize}&page=${page || defaultPage}`).then((res) => {
            console.log("RES", res.data);
            console.log("Request Server to Get All delivery month");
            dispatch({ type: DELIVERY_MONTH_ALL, payload: res.data });
        })
    }
}

export const deliveryMonthGet = (id, type) => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + `/delivery_month/by/${id}/and/type/${type}`)
        .then((res) => {
            console.log("RES", res.data);
            console.log("Request Server to Get delivery month");
            dispatch({ type: DELIVERY_MONTH_GET, payload: res.data });
        })
    }
}

export const deliveryMonthGetAllType = (id) => {
    return async (dispatch) => {
        await axios.get(process.env.REACT_APP_API_URL + `/delivery_month/${id}`)
        .then((res) => {
            console.log("RES", res.data);
            console.log("Request Server to Get all type delivery month");
            dispatch({ type: DELIVERY_MONTH_GET_ALL_TYPE, payload: res.data });
        })
    }
}