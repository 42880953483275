import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { Button } from "@chakra-ui/react";


export default function CreateContact() {
  const { register, handleSubmit, reset, errors } = useForm();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const onSubmit = async (data, e) => {
    data.customer_id = id;
    dispatch(actions.contactPost(data)).then(() => {
      alert("เพิ่มรายชื่อผู้ติดต่อสำเร็จ");
      history.push("/crm/customer/profile/" + id);
    });
  };

  return (
    <>
      <div>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full ">
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  เพิ่มรายชื่อผู้ติดต่อ
                </h2>
              </div>
              <div className="flex py-6 justify-between">
                <Button variant="outline" onClick={() => { history.goBack() }}>กลับ</Button>
              </div>
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap ">
                      <div className="w-full px-4">
                        <h6 className="text-gray-500 text-xs mt-3 mb-6 font-bold uppercase">
                          ข้อมูลผู้ติดต่อ
                        </h6>
                        <div className="flex flex-wrap">


                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ชื่อผู้ติดต่อ <label className="text-red-600 text-xs"> *</label>
                              </label>
                              <input
                                name="contact_name"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ชื่อผู้ติดต่อ"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                เบอร์ผู้ติดต่อ <label className="text-red-600 text-xs"> *</label>
                              </label>
                              <input
                                name="contact_phone_number"
                                type="number"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เบอร์ผู้ติดต่อ"
                              />
                            </div>
                          </div>

                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                อีเมล <label className="text-red-600 text-xs"> *</label>
                              </label>
                              <input
                                name="contact_email"
                                type="email"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="อีเมล"
                              />
                            </div>
                          </div>

                        </div>
                        <div className="text-center px-4 gap-0 py-3">
                          <Button
                            isFullWidth
                            colorScheme="blue"
                            size="sm"
                            variant="solid"
                            type="submit"
                          >
                            บันทึก
                          </Button>
                        </div>
                      </div>
                    </div>{" "}

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
