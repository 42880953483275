import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
} from '@chakra-ui/react';
import _ from 'lodash';

import NotifyAddBox from '../../Notify/AddBox';
import NotifyEditBox from '../../Notify/EditBox';

/**
 * @function NotifyModal
 *  @params {"create|edit"} modalMode Mode that assign to modal
 * @params {Object} notifyData Notify Object use in Edit Mode Only
 */
export default function NotifyModal({
    openStatus,
    closeHandle,
    currentMileage = 0,
    vehicle = null,
    modalMode = 'create',
    notifyData = null,
    handleParentClose,
}) {
    return (
        <div>
            <Modal isOpen={openStatus} onClose={closeHandle} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader className="font-sans text-base">
                        การนัดหมาย
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className="p-4 m-4 mt-0 pb-6 font-sans">
                        {modalMode === 'create' ? (
                            <NotifyAddBox
                                currentMileage={currentMileage}
                                defaultVehicle={vehicle}
                                handleClose={closeHandle}
                            />
                        ) : (
                            <NotifyEditBox
                                handleClose={closeHandle}
                                defaultVehicle={vehicle}
                                notifyData={notifyData}
                                handleParentClose={handleParentClose}
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
