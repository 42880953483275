import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';

import RepairStatusBadge from '../../Badge/RepairStatusBadge';
import * as actions from '../../../redux/actions';

import Detail from './components/Detail';
import RepairType from './components/RepairType';
import History from './components/History';
import ImageUpload from '../../../components/ImageUpload/ImageMultiUpload';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';
import { WORD_TYPE_IMAGE_REPAIR } from '../../../config/constant/TypeImageRepair';
import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import { REPAIR_STATUS } from '../../../config/constant/RepairStatus';

export default function ModalVehicleRepair({
    openStatus,
    closeHandle,
    repairDetail,
}) {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, reset } = useForm();
    const [price, setPrice] = useState([]);
    const [image, setImage] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const onSubmit = async (data) => {
        if (repairDetail?.mileage?.mileage != data?.mileage?.mileage) {
            data.problems = price;
            let imagesURL = [];
            if (!_.isEmpty(image)) {
                for await (const img of image.map((image) =>
                    handleUploadImage(image)
                )) {
                    imagesURL.push({
                        img,
                        type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                    });
                }
                data.repair_imgs = imagesURL;
            }

            const sendData = {
                problems: price,
                repair_detail: data.repair_detail,
                vehicle_id: repairDetail.vehicle_id,
                mileage: data.mileage.mileage,
                repair_imgs: data.repair_imgs,
            };

            dispatch(actions.repairPut(repairDetail.repair_id, sendData)).then(
                () => {
                    dispatch(actions.repairAllVehicle({ size: 10 }));
                }
            );

            alert('สำเร็จ');
            window.location.reload();
            closeHandle();
        } else {
            let imagesURL = [];
            if (!_.isEmpty(image)) {
                for await (const img of image.map((image) =>
                    handleUploadImage(image)
                )) {
                    imagesURL.push({
                        img,
                        type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                    });
                }
                data.repair_imgs = imagesURL;
            }
            // console.log(repairDetail);
            if (repairDetail?.sub_vehicle === SUB_CATEGORY_REPAIR[1]) {
                const sendData = {
                    problems: price,
                    repair_detail: data.repair_detail,
                    vehicle_id: repairDetail.vehicle_id,
                    repair_imgs: data.repair_imgs,
                };

                dispatch(actions.repairPut(repairDetail.repair_id, sendData)).then(
                    () => {
                        dispatch(actions.repairAllVehicle({ size: 10 }));
                    }
                );
                alert('สำเร็จ');
                window.localStorage.setItem("currentVehiclePage", repairDetail?.sub_vehicle);
                window.location.reload();
                closeHandle();
            } else {
                const sendData = {
                    problems: price,
                    repair_detail: data.repair_detail,
                    vehicle_id: repairDetail.vehicle_id,
                    mileage: data.mileage.mileage,
                    repair_imgs: data.repair_imgs,
                };

                dispatch(actions.repairPut(repairDetail.repair_id, sendData)).then(
                    () => {
                        dispatch(actions.repairAllVehicle({ size: 10 }));
                    }
                );
                alert('สำเร็จ');
                window.localStorage.setItem("currentVehiclePage", repairDetail?.sub_vehicle);
                window.location.reload();

                closeHandle();
            }

        }
    };

    const renderRepairImagePanel = () => {
        return (
            <div className="content-center text-right bg-white  px-4">
                <label className="block uppercase  text-left text-gray-700 text-sm font-bold ">
                    รูปภาพ
                </label>
                {isEdit || !_.size(repairDetail.repair_imgs) ? (
                    <>
                        <ImageUpload images={image} setImages={setImage} maxNumber={20}
                        />
                        {!_.isEmpty(repairDetail.repair_imgs) && (
                            <Button
                                size="xs"
                                variant="outline"
                                onClick={() => {
                                    setIsEdit(false);
                                }}
                            >
                                ยกเลิก
                            </Button>
                        )}
                    </>
                ) : (
                    <div>
                        <div>
                            <div className="flex flex-wrap justify-center  rounded px-1 mb-2 max-h-96 overflow-y-scroll">
                                {_.map(
                                    repairDetail.repair_imgs,
                                    (img, index) => (
                                        <div key={index} className="my-4 mx-1 ">
                                            <div className="flex justify-end"></div>
                                            <img
                                                className=" shadow-md max-w-150-px"
                                                src={img.img}
                                            ></img>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <Button
                            size="xs"
                            variant="outline"
                            onClick={() => {
                                setIsEdit(true);
                            }}
                        >
                            แก้ไข
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const handleColseModal = () => {

        // dispatch(actions.repairAllVehicle({ size: 10 }));
        closeHandle();

    }

    return (
        <Modal isOpen={openStatus} onClose={handleColseModal} size="6xl">
            <ModalOverlay />
            <ModalContent className="font-sans">
                <ModalHeader>
                    <div className="flex gap-2">
                        <div>พิจารณาการแจ้งซ่อมรถ </div>
                        <div className="flex align-middle self-center">
                            <RepairStatusBadge
                                status={repairDetail.repair_status}
                            />
                        </div>
                    </div>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Detail
                            repairDetail={repairDetail}
                            register={register}
                        />
                        {renderRepairImagePanel()}

                        <RepairType
                            repairDetail={repairDetail}
                            price={price}
                            setPrice={setPrice}
                        />

                        <History repairDetail={repairDetail} />
                        <div className="flex justify-end gap-2 mb-4 pb-2">
                            <Button
                                disabled={repairDetail?.repair_status != REPAIR_STATUS[0]}
                                colorScheme="blue"
                                type="submit">
                                บันทึก
                            </Button>
                            <Button
                                colorScheme="gray"
                                type="button"
                                onClick={handleColseModal}
                            >
                                ยกเลิก
                            </Button>
                        </div>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
