import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions";
import { Link, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import SpinnerLoading from "components/Loading/SpinnerLoading";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import currencyFormatter from "currency-formatter";
import THBText from "thai-baht-text";
import _ from "lodash";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
};

export default function ReportWheel() {
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const repair = useSelector((state) => state.repair);
    const company = useSelector((state) => state.company);
    const [repair_wheel, setRepairWheel] = useState([]);
    const [sumTotal, setSum] = useState(0);

    useEffect(() => {
        dispatch(actions.repairGetWheel(id)).then(() => {
            dispatch(actions.companyAll())
        })
        return () => { }
    }, [])

    useEffect(() => {
        setRepairWheel(genRepairWheel());
        sumPrice();
        setIsLoading(true)
        return () => { }
    }, [repair, company])

    console.log("repair: ", repair);
    console.log("company", company);

    const sumPrice = () => {
        let sum = 0;
        for (let i = 0; i < _.size(repair?.repair_wheels); i++) {
            sum =
                parseInt(sum) + parseInt(repair?.repair_wheels[i].price);
            console.log("price", repair?.repair_wheels[i].price);
            console.log("sum", sum);
        }
        setSum(sum);
    }

    const printPDF = () => {
        var docDefinition = {
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [40, 40, 40, 40],
            defaultStyle: {
                font: "THSarabunNew",
                fontSize: "16",
            },
            info: {
                title: "ใบแจ้งยาง"
            },
            content: [
                {
                    alignment: "justify",
                    columns: [
                        {
                            image: "company_logo",
                            width: 60,
                            margin: [0, 10, 0, 10],
                        },
                        [
                            {
                                text: company.company_name,
                                fontSize: "18",
                                bold: true,
                            },

                            {
                                text: `${company.company_address} โทร: ${company.company_phone_number}`,
                                fontSize: "14",
                            },
                            {
                                text: "อีเมล : " + company.company_email,
                                fontSize: "14",
                            },
                        ],
                    ],
                },
                {
                    canvas: [{ type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }]
                },
                {
                    //หัวข้อ
                    alignment: "center",
                    margin: [0, 20, 0, 20],
                    text: "ใบแจ้งยาง",
                    fontSize: "23",
                    bold: true,
                },
                {
                    alignment: "justify",
                    columns: [
                        [
                            {
                                text: `วัน-เดือน-ปี ......... ${moment(repair.createdAt).format("DD/MM/YYYY")} ..........`,
                                fontSize: "16",
                            },
                        ],
                        [
                            {
                                text: `ประเภทรถ ......... ${repair?.vehicle?.sub_category_vehicle.sub_category_name} ...........`,
                                fontSize: "16",
                                alignment: "center",
                            },
                        ],
                        [
                            {
                                text: `เลขไมล์ ........... ${repair?.mileage.mileage} ........... `,
                                fontSize: "16",
                                alignment: "center",
                            }
                        ]
                    ]
                },
                {
                    alignment: "justify",
                    columns: [
                        [
                            {
                                text: `ทะเบียนหัว ......... ${repair?.vehicle?.serial_number} ..........`,
                                fontSize: "16",
                            },
                        ],
                        [
                            {
                                text: `ทะเบียนหาง ......... ${repair?.vehicle_tail?.serial_number} ...........`,
                                fontSize: "16",
                                alignment: "center",
                            },
                        ],
                        [
                            {
                                text: `ชื่อคนขับ ..... ${repair?.employee.employee_first_name} ${repair?.employee.employee_last_name} .... `,
                                fontSize: "16",
                                alignment: "center",
                            }
                        ]
                    ]
                },
                {
                    margin: [0, 20, 0, 20],
                    style: "tableExample",
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return rowIndex === 0 ? "#c2dec2" : null;
                        },
                    },
                    table: {
                        widths: ["*", "70%", "*"],
                        heights: [10, 10, 10, 10, 30, 10, 25],
                        headerRows: 1,
                        body: [
                            [
                                {
                                    text: "ล้อที่",
                                    colSpan: 1,
                                    fontSize: 14,
                                    alignment: "center",
                                },
                                {
                                    text: "รายละเอียด",
                                    colSpan: 1,
                                    fontSize: 14,
                                },
                                {
                                    text: "ราคา",
                                    colSpan: 1,
                                    fontSize: 14,
                                    alignment: "center",
                                }
                            ],
                            ...repair_wheel,
                            [
                                {
                                },
                                {
                                    text: `ราคาทั้งหมด`,
                                    colSpan: 1,
                                    fontSize: 14,
                                },
                                {
                                    text: `${sumTotal}`,
                                    colSpan: 1,
                                    fontSize: 14,
                                    alignment: "center",
                                },
                            ],
                        ],
                    },
                },
                {
                    margin: [0, 20, 0, 20],
                    fontSize: 14,
                    columns: [
                        [
                            {
                                text: `หมายเหตุ :  ..............................................................................................................................................................................................................`,
                            },
                            {
                                text: `..................................................................................................................................................................................................................................`,
                            },
                            {
                                text: `..................................................................................................................................................................................................................................`,
                            },
                            {
                                text: `..................................................................................................................................................................................................................................`,
                            }
                        ]
                    ]
                },
                {
                    alignment: "justify",
                    margin: [0, 100, 0, 100],
                    columns: [
                        [
                            {
                                text: `ลงชื่อ ..........................................ผู้แจ้ง`,
                                fontSize: "16",
                                alignment: "center",
                            },
                            {
                                text: `(.......................................................)`,
                                fontSize: "16",
                                alignment: "center",
                            },
                        ],
                        [
                            {
                                text: `ลงชื่อ ..........................................ผู้อนุมัติ`,
                                fontSize: "16",
                                alignment: "center",
                            },
                            {
                                text: `(.......................................................)`,
                                fontSize: "16",
                                alignment: "center",
                            },
                        ],
                    ],
                },
            ],
            images: {
                company_logo: company.company_logo,
            },
        };
        pdfMake.createPdf(docDefinition).open();
    };

    const genRepairWheel = () => {
        return _.map(repair?.repair_wheels, (_repair_wheel) => [
            {
                text: _repair_wheel.index_wheel,
                border: [true, false, true, false],
                colSpan: 1,
                alignment: "center",
            },
            {
                text: _repair_wheel.wheel_detail,
                border: [true, false, true, false],
                colSpan: 1,
            },
            {
                text: _repair_wheel.price,
                border: [true, false, true, false],
                colSpan: 1,
                alignment: "center",
            }
        ])
    }

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                รายละเอียดใบแจ้งยาง
            </h2>
            <div className="py-4">
                <Button
                    colorScheme="blue"
                    onClick={() => {
                        printPDF();
                    }}
                >
                    พิมพ์
                </Button>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}
