import axios from 'axios';
import {
    VEHICLE_ALL,
    VEHICLE_ALL_BY_DELIVERY,
    VEHICLE_ALL_BY_STATUS,
    VEHICLE_ALL_BY_HEAD_AND_TAIL,
    VEHICLE_GET,
    VEHICLE_PUT,
    VEHICLE_DEL,
    VEHICLE_POST,
    VEHICLE_ALL_BY_CATEGORY,
} from '../types';

export const vehicleAll = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/vehicle')
            .then((res) => {
                console.log('Request Server to Get All Vehicles');
                dispatch({ type: VEHICLE_ALL, payload: res.data });
            });
    };
};

export const vehicleAllByCategory = (payload) => {
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/vehicle/by/category_vehicle/' +
                    payload
            )
            .then((res) => {
                console.log('Request Server to Get All By category vehicles');
                dispatch({ type: VEHICLE_ALL_BY_CATEGORY, payload: res.data });
            });
    };
};

export const vehicleAllByDelivery = (payload) => {
    console.log('payload : ', payload);
    return async (dispatch) => {
        await axios
            .post(
                process.env.REACT_APP_API_URL + '/vehicle/by/delivery',
                payload
            )
            .then((res) => {
                console.log('Request Server to Get All By Delivery Vehicles');
                dispatch({ type: VEHICLE_ALL_BY_DELIVERY, payload: res.data });
            });
    };
};

export const vehicleAllByHeadAndTail = () => {
    console.log('vehicleAllByHeadAndTail');
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/vehicle/by/head/and/tail')
            .then((res) => {
                console.log('Request Server to Get All By Head and Tail');
                dispatch({
                    type: VEHICLE_ALL_BY_HEAD_AND_TAIL,
                    payload: res.data,
                });
            });
    };
};

export const vehicleAllByStatus = (id, payload) => {
    console.log('id : ', id);
    console.log('payload : ', payload);
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/vehicle/by/status/' +
                    id +
                    '/' +
                    payload
            )
            .then((res) => {
                console.log('Request Server to Get All vehicles by status');
                dispatch({ type: VEHICLE_ALL_BY_STATUS, payload: res.data });
            });
    };
};

export const vehicleGet = (payload) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/vehicle/' + payload)
            .then((res) => {
                console.log('Request Server to Get an Vehicles');
                if (res.data) {
                    dispatch({ type: VEHICLE_GET, payload: res.data });
                } else {
                    dispatch({ type: VEHICLE_GET, payload: null });
                }
            });
    };
};

export const vehiclePost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/vehicle', payload)
            .then((res) => {
                console.log('Request Server to post an vehicle');
                dispatch({ type: VEHICLE_POST, payload: null });
            });
    };
};

export const vehiclePut = (id, payload) => {
    console.log('payload : ', payload);
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/vehicle/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an Vehicles');
                dispatch({ type: VEHICLE_PUT, payload: res.data });
            });
    };
};

export const vehicleDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/vehicle/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an Vehicles');
                dispatch({ type: VEHICLE_DEL, payload: null });
            });
    };
};
