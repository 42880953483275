import React, { useState } from 'react';
import { Checkbox } from '@chakra-ui/react';

export default function CalendarSetting({
    isDeliveryTypeShow,
    setIsDeliveryTypeShow,
    isSubVechicalShow,
    setIsSubVechicleShow,
    isDescriptionShow,
    setIsDescriptionShow,
    isTableLongShow,
    setIsTableLongShow,
    rendered,
    setRendered,
}) {
    return (
        <div>
            <div className=" border rounded-md px-4 py-2 my-2 border-gray-200">
                <h4 className="text-base font-bold mb-2">ปรับแต่งปฏิทิน</h4>
                <div>
                    <Checkbox
                        className="px-1"
                        defaultChecked={isTableLongShow}
                        onChange={(event) => {
                            setIsTableLongShow(event.target.checked);
                            localStorage.setItem(
                                'erp_table_long_show',
                                event.target.checked
                            );
                            setRendered(!rendered);
                        }}
                    >
                        <h3 className="block uppercase text-gray-700 text-xs font-bold">
                            แสดงปฏิทินแบบเต็ม
                        </h3>
                    </Checkbox>

                    <Checkbox
                        className="px-1"
                        defaultChecked={isDeliveryTypeShow}
                        onChange={(event) => {
                            setIsDeliveryTypeShow(event.target.checked);
                            localStorage.setItem(
                                'erp_delivery_type_show',
                                event.target.checked
                            );
                        }}
                    >
                        <h3 className="block uppercase text-gray-700 text-xs font-bold ">
                            การแสดงสถานะ นำเข้า/ส่งออก
                        </h3>
                    </Checkbox>

                    <Checkbox
                        className="px-1"
                        defaultChecked={isSubVechicalShow}
                        onChange={(event) => {
                            setIsSubVechicleShow(event.target.checked);
                            localStorage.setItem(
                                'erp_sub_vechical_type_show',
                                event.target.checked
                            );
                        }}
                    >
                        <h3 className="block uppercase text-gray-700 text-xs font-bold ">
                            แสดงหมวดหมู่ย่อยของยานพาหนะ
                        </h3>
                    </Checkbox>

                    <Checkbox
                        className="px-1"
                        defaultChecked={isDescriptionShow}
                        onChange={(event) => {
                            setIsDescriptionShow(event.target.checked);
                            localStorage.setItem(
                                'erp_description_show',
                                event.target.checked
                            );
                        }}
                    >
                        <h3 className="block uppercase text-gray-700 text-xs font-bold">
                            แสดงคำบรรยาย
                        </h3>
                    </Checkbox>
                </div>
            </div>
        </div>
    );
}
