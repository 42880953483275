import React, { useState } from 'react';
import _ from 'lodash';

import CardAsssignmentFlatInfo from '../../common/Cards/CardAssignmentFlatInfo';
import AssignmentModal from '../../Modal/AssignmentModal/TransportAssignmentModal';

export default function DeliveryAssignment({
    allSystemAssignments,
    assignments,

}) {

    const [isEditorMode, setIsEditorMode] = useState(false);
    const [isModalAssignmentOpen, setIsModalAssignmentOpen] = useState(false)
    const [selectedAssignment, setSelectedAssignment] = useState()
    const assignmentComponent = (eachAssignment, index) => (
        <div
            onClick={() => {
                setIsModalAssignmentOpen(true)
                setSelectedAssignment(eachAssignment.assignment)
            }
            }
            className='md:w-6/12  cursor-pointer '
            key={index}
        >
            <CardAsssignmentFlatInfo
                eachAssignment={eachAssignment}
                allSystemAssignments={allSystemAssignments}
            />
        </div>
    );

    return (
        <div>
            <AssignmentModal
                selectedAssignment={selectedAssignment}
                openStatus={isModalAssignmentOpen}
                closeHandle={() => { setIsModalAssignmentOpen(!isModalAssignmentOpen) }}
                isEditorMode={isEditorMode}
                setIsEditorMode={setIsEditorMode}
            />
            <div className='flex flex-wrap '>
                {_.map(assignments, (eachAssignment, index) => {
                    return assignmentComponent(eachAssignment, index);
                })}
            </div>
        </div>
    );
}
