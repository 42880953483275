import React from 'react';
import moment from 'moment';
import ContactPerson from './Components/ContactPerson';

export default function DisplayMode({
    selectedAssignment,
    handleToEditorMode,
}) {
    return (
        <div>
            <div
                className="hover:bg-gray-100 p-3 rounded-md cursor-text border"
            >
                <div className="flex gap-2 font-sans flex-wrap justify-center">
                    <div className="w-2/6">
                        <h5 className="text-xs font-bold">ผู้ส่ง</h5>

                        <label className="text-lg font-bold mb-0 ">
                            {selectedAssignment.shipper?.customer_company}{' '}
                        </label>

                        <label className="text-base  mb-0">
                            {selectedAssignment.shipper_address}{' '}
                            {selectedAssignment.shipper_postcode}
                        </label>

                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2 mt-2">
                            ผู้ติดต่อฝั่งผู้ส่ง
                        </label>{' '}
                        <ContactPerson
                            contactPeopleData={
                                selectedAssignment?.shipper?.contacts
                            }
                        />
                    </div>
                    <div className="w-1/6 inset-0 flex items-center justify-center">
                        <label className="text-base font-bold">
                            <i className="fas fa-arrow-right" />
                        </label>
                    </div>
                    <div className="w-2/6">
                        <h5 className="text-xs font-bold">ผู้รับ</h5>
                        <label className="text-lg  font-bold mb-0 ">
                            {selectedAssignment?.consignee?.customer_company}
                        </label>

                        <label className="text-base   mb-0">
                            {selectedAssignment?.consignee_address}{' '}
                            {selectedAssignment?.consignee_postcode}
                        </label>

                        <label className="block uppercase text-gray-700 text-xs font-bold mb-2 mt-2">
                            ผู้ติดต่อฝั่งรับ
                        </label>
                        <ContactPerson
                            contactPeopleData={
                                selectedAssignment?.consignee?.contacts
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap gap-2 font-sans justify-center">
                <div
                    // className="w-full"
                    className="w-full hover:bg-gray-100 p-3 rounded-md cursor-text"
                // onDoubleClick={handleToEditorMode}
                >

                    <label className="block text-gray-700 text-base mb-1 ml-16">
                        <b>เลขที่ใบแจ้งหนี้</b>  {selectedAssignment?.invoice_number}
                    </label>

                    <div className="flex flex-row">
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>วันที่รับสินค้า</b> {moment(selectedAssignment?.pickup_date).format(
                                    'DD/MM/YYYY'
                                )}
                            </label>
                        </div>
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>วันที่ส่งสินค้า</b> {moment(selectedAssignment?.delivery_date).format(
                                    'DD/MM/YYYY'
                                )}
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>หมายเลขคอนเทนเนอร์</b> {selectedAssignment?.vehicle?.serial_number}
                            </label>

                        </div>
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>ขนาดคอนเทนเนอร์</b> {selectedAssignment?.container_size}
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>จำนวนหีบห่อหรือพาเลท</b> {selectedAssignment?.number_of_packages} ชิ้น
                            </label>
                        </div>
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1 ml-16">
                                <b>น้ำหนักรวม</b> {selectedAssignment?.load_weight} กิโลกรัม
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="w-1/2">
                            <label className="block text-gray-700 text-base mb-1 ml-16">
                                <b>ระยะทาง</b> {selectedAssignment?.assignment_distance}
                            </label>
                        </div>
                        <div className="w-1/2">
                            <label className="block text-gray-700 text-base mb-1 ml-16">
                                <b>ระยะเวลาในการเดินทาง</b> {selectedAssignment?.assignment_duration}
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-row">
                    <label className="block uppercase text-gray-700 text-base mb-2 ml-16">
                        <b>หมายเหตุ</b> {selectedAssignment?.assignment_note}
                    </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
