import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import _, { isEmpty } from 'lodash';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';
import { CATEGORY_VEHICLE } from '../../../config/constant/CategoryVehicle';
import { VEHICLE_STATUS } from '../../../config/constant/VehicleStatus';
import { TYPE_REPAIR } from '../../../config/constant/TypeRepair';
import { SUB_CATEGORY_REPAIR } from '../../../config/constant/SubCategoryRepair';
import CardWheel from 'components/common/Cards/CardWheel';
import WheelLists from './components/WheelLists';
import ImageUpload from '../../../components/ImageUpload/ImageMultiUpload';
import { WORD_TYPE_IMAGE_REPAIR } from '../../../config/constant/TypeImageRepair';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';

export default function CreateRepairWheelAdmin() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const car_owner = useSelector((state) => state.car_owner);
    const mileage = useSelector((state) => state.mileage);
    const vehicles = useSelector((state) => state.vehicle);
    const [vehicle_data, setVehicleData] = useState([]);
    const [index_wheel, setIndexWheel] = useState([]);
    const [wheel_detail, setWheelDetail] = useState([]);

    const [selectVehicle, setSelectVehicle] = useState();
    const [mileage_data, setMileageData] = useState('0');
    const [vehicle, setVehicle] = useState([]);
    const [vehicleTail, setVehicleTail] = useState([]);
    const [subCategoryVehicle, setSubCategoryVehicle] = useState(null);
    const [image, setImage] = useState([]);

    useEffect(() => {
        dispatch(actions.meGet()).then(() => {
            dispatch(actions.carOwnerAll()).then(() => {
                dispatch(actions.vehicleAll()).then(() => {
                    setIsLoading(true);
                });
            });
        });

        return () => {};
    }, []);

    useEffect(() => {
        setVehicleData(vehicles?.arr);
        vehicles?.arr && setVehicle(vehicles?.arr); // ยกเลิกการเอารถเฉพาะที่มีเจ้าของแล้วออกไป เอาทุกคัน
        setIsLoading(true);

        return () => {};
    }, [car_owner, vehicles]);

    useEffect(() => {
        if (selectVehicle) {
            dispatch(actions.mileageGetLatest(selectVehicle));
        }
        return () => {};
    }, [selectVehicle]);

    useEffect(() => {
        if (mileage.mileage) {
            setMileageData(mileage.mileage);
        }
        return () => {};
    }, [mileage]);

    const handleUploadImage = async (image) => {
        const filename = hash({ picture: image, date: new Date() });
        const url = await uploadImageFile(filename, image.data_url);
        return url;
    };

    const onSubmit = async (data) => {
        const result = _.filter(car_owner.arr, (owner) => {
            return owner.vehicle_id === selectVehicle;
        });
        console.log('result', result[0]);
        data.employee_id = me?.employee_id;
        data.type = TYPE_REPAIR[1];
        data.repair_wheels = wheel_detail;

        data.vehicle_tail_id = '-';
        data.driver_id = result[0].employee_id;

        let imagesURL = [];

        if (!isEmpty(image)) {
            for await (const img of image.map((image) =>
                handleUploadImage(image)
            )) {
                imagesURL.push({
                    img,
                    type: WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR,
                });
            }
            data.repair_img = imagesURL;
        }

        dispatch(actions.repairPostWheel(data)).then(() => {
            alert('สำเร็จ');
            window.location.reload();
        });
        console.log('data', data);
    };

    // const filterVehicle = () => {
    //     const result = _.filter(vehicle_data, (vehicle) => {
    //         return (
    //             vehicle.category_id === CATEGORY_VEHICLE[0] &&
    //             vehicle?.vehicle_status === VEHICLE_STATUS[1]
    //         );
    //     });
    //     return result;
    // };

    const genVehicleList = () => {
        return _.map(vehicle, (vehicle) => (
            <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                {vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle.serial_number}
            </option>
        ));
    };

    const genVehicleTailList = () => {
        return _.map(vehicleTail, (vehicle) => (
            <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                {vehicle?.category_vehicle?.category_name +
                    '/' +
                    vehicle?.sub_category_vehicle?.sub_category_name +
                    '/' +
                    vehicle.serial_number}
            </option>
        ));
    };

    const genCardWheel = (data) => {
        for (let i = 0; i < 22; i++) {
            index_wheel[i] = [i + 1];
        }

        if (data === SUB_CATEGORY_REPAIR[2]) {
            return (
                <div>
                    <div className="bg-white shadow rounded px-4 p-4">
                        <div className="flex flex-wrap grid grid-cols-4 gap-6">
                            {showHeadWheel(index_wheel)}
                        </div>
                        <div className="flex flex-wrap grid grid-cols-4 gap-6 mt-16">
                            {showTailWheel(index_wheel)}
                        </div>
                    </div>
                    {renderImageMultiUpload()}
                </div>
            );
        } else if (data === SUB_CATEGORY_REPAIR[0]) {
            return (
                <div>
                    <div className="bg-white shadow rounded px-4 p-4">
                        <div className="flex flex-wrap grid grid-cols-4 gap-6">
                            {showHeadWheel(index_wheel)}
                        </div>
                    </div>
                    {renderImageMultiUpload()}
                </div>
            );
        } else if (data === SUB_CATEGORY_REPAIR[1]) {
            return (
                <div>
                    <div className="bg-white shadow rounded px-4 p-4">
                        <div className="flex flex-wrap grid grid-cols-4 gap-6">
                            {showTailWheel(index_wheel)}
                        </div>
                    </div>
                    {renderImageMultiUpload()}
                </div>
            );
        }
    };
    const showHeadWheel = (data) => {
        return data.map((wheel, index) => {
            if (index === 0) {
                return (
                    <>
                        <div></div>
                        <CardWheel
                            index_wheel={wheel}
                            wheel_detail={wheel_detail}
                            setWheelDetail={setWheelDetail}
                        />
                    </>
                );
            }
            if (index === 1) {
                return (
                    <>
                        <CardWheel
                            index_wheel={wheel}
                            wheel_detail={wheel_detail}
                            setWheelDetail={setWheelDetail}
                        />
                        <div></div>
                    </>
                );
            }
            if (index > 1 && index < 10) {
                return (
                    <>
                        <CardWheel
                            index_wheel={wheel}
                            wheel_detail={wheel_detail}
                            setWheelDetail={setWheelDetail}
                        />
                    </>
                );
            }
        });
    };
    const showTailWheel = (data) => {
        return data.map((wheel, index) => {
            if (index >= 10) {
                return (
                    <>
                        <CardWheel
                            index_wheel={wheel}
                            wheel_detail={wheel_detail}
                            setWheelDetail={setWheelDetail}
                        />
                    </>
                );
            }
        });
    };
    const genSubCategoryVehicle = () => {
        return _.map(SUB_CATEGORY_REPAIR, (sub_repair) => (
            <option>{sub_repair}</option>
        ));
    };

    const showWheelData = (data) => {
        return (
            <>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        ทะเบียนรถ{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <select
                        name="vehicle_id"
                        type="text"
                        ref={register}
                        onChange={(e) => {
                            console.log('value', e.target.value);
                            setSelectVehicle(e.target.value);
                        }}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                        <option value="" disabled selected>
                            กรุณาเลือก
                        </option>
                        {genVehicleList()}
                    </select>
                </div>
                <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                        เลขไมล์{' '}
                        <label className="text-red-600 text-xs"> *</label>
                    </label>
                    <input
                        name="mileage"
                        required
                        ref={register}
                        placeholder="เลขไมล์"
                        disabled={subCategoryVehicle === SUB_CATEGORY_REPAIR[1]}
                        value={mileage_data}
                        onChange={(e) => {
                            setMileageData(e.target.value);
                        }}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 disabled:bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    ></input>
                </div>
            </>
        );
    };

    const renderImageMultiUpload = () => {
        return (
            <div className="relative w-full mb-3 ">
                <label
                    className="block uppercase text-gray-700 text-xs font-bold mt-3 mb-3 "
                    htmlFor="grid-password"
                >
                    รูปภาพ
                </label>
                <div className="bg-white rounded shadow  p-4">
                    <ImageUpload
                        images={image}
                        setImages={setImage}
                        preview_size={'150'}
                        maxNumber={4}
                    />
                </div>
            </div>
        );
    };

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">
                เพิ่มรายการแจ้งซ่อมยาง
            </h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex ml-4 mt-4 justify-between">
                    <div>
                        <Link to={'/cmms/repair/wheel'}>
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                </div>
                <div className="flex flex-wrap p-4  justify-around h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                            <h6 className="text-gray-700 text-xs mt-3 mb-3 font-bold uppercase">
                                ข้อมูลรถ
                            </h6>
                            <div className="bg-white shadow rounded px-4  p-4">
                                <select
                                    name="sub_vehicle"
                                    type="text"
                                    required
                                    ref={register}
                                    hidden
                                    defaultValue={CATEGORY_VEHICLE[0]}
                                    onChange={(e) => {
                                        setSubCategoryVehicle(e.target.value);
                                    }}
                                    className="px-3 py-3 mb-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                >
                                    <option value="" disabled selected>
                                        กรุณาเลือก
                                    </option>
                                    {genSubCategoryVehicle()}
                                </select>

                                {showWheelData(subCategoryVehicle)}

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mt-3 mb-3"
                                        htmlFor="grid-password"
                                    >
                                        หมายเหตุ
                                    </label>
                                    <textarea
                                        name="repair_detail"
                                        type="textfield"
                                        rows={3}
                                        ref={register}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="หมายเหตุ"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-8/12 px-4 ">
                        <h6 className="text-gray-700 text-xs mt-3 mb-3 font-bold uppercase">
                            รายละเอียด
                        </h6>
                        {genCardWheel(SUB_CATEGORY_REPAIR[0])}
                    </div>
                </div>
                <div className="text-center mt-3 px-8 ">
                    <Button isFullWidth colorScheme="blue" type="submit">
                        บันทึก
                    </Button>
                </div>
                <br />
            </form>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
