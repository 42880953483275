import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button, Textarea } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import Select from 'react-select';

import { WORD_TYPE_IMAGE_REPAIR } from 'config/constant/TypeImageRepair';
import { REPAIR_STATUS } from 'config/constant/RepairStatus';
import * as actions from '../../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import currencyFormatterTH from 'util/functions/currencyFormat';

export default function DetailRepairLog() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, logId } = useParams();
    const repair_log = useSelector((state) => state.repair_log);
    const [imgBeforeRepair, setImgBeforRepair] = useState(null);
    const [imgAfterRepair, setImgAfterRepair] = useState(null);

    useEffect(() => {
        dispatch(actions.repairLogGetVehicle(logId));
        setIsLoading(true);
        return () => {};
    }, []);

    useEffect(() => {
        setImgBeforRepair(filterImgBefore());
        setImgAfterRepair(filterImgAfter());
        return () => {};
    }, [repair_log]);

    const showStatus = (status) => {
        if (status === REPAIR_STATUS[2]) {
            return (
                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        } else {
            return (
                <div className="text-xs text-center px-2 bg-red-100 hover:bg-green-200 rounded  whitespace-nowrap">
                    {status}
                </div>
            );
        }
    };

    const showImgAfterRepair = (repair_imgs) => {
        return _.map(repair_imgs, (img) => (
            <img
                key={img?.repair_img_log_id}
                className="rounded max-h-32 max-w-32 ml-6 mb-2"
                alt="..."
                src={img?.img}
            />
        ));
    };

    const showRepairType = (repair_type) => {
        return _.map(repair_type, (type) => (
            <div key={type?.problem_log_id} className="flex flex-wrap">
                <div className="w-3/6">
                    {'- ' + type?.repair_type?.repair_detail}
                </div>
                <div className="w-3/6">
                    <b>ราคา</b> {type?.price}
                </div>
            </div>
        ));
    };

    const filterImgBefore = () => {
        const result = _.filter(repair_log?.repair_img_logs, (repair_imgs) => {
            return repair_imgs?.type === WORD_TYPE_IMAGE_REPAIR.BEFORE_REPAIR;
        });
        return result;
    };

    const filterImgAfter = () => {
        const result = _.filter(repair_log?.repair_img_logs, (repair_imgs) => {
            return repair_imgs?.type === WORD_TYPE_IMAGE_REPAIR.AFTER_REPAIR;
        });
        return result;
    };

    return isLoading ? (
        <div>
            <div className="flex flex-wrap">
                <h2 className="text-gray-800 text-xl font-semibold">
                    รายละเอียดการแจ้งซ่อม
                </h2>
                <div className="mt-2 ml-2">
                    {showStatus(repair_log?.repair_status)}
                </div>
            </div>

            <div className="flex px-4 mt-4 justify-between">
                <div>
                    <Button onClick={() => history.goBack()} variant="outline">
                        กลับ
                    </Button>
                </div>
                <div>
                    <Button
                        variant="solid"
                        colorScheme="yellow"
                        onClick={() => {
                            history.push(
                                `/cmms/history/repair/vehicle/${id}/edit/detail/${logId}`
                            );
                        }}
                    >
                        แก้ไข
                    </Button>
                </div>
            </div>
            <div className="flex flex-wrap w-full justify-between">
                <div className="w-6/12 md:w-6/12 px-4 py-2 mb-2 ">
                    <div className="bg-gray-200 shadow-lg rounded md:shadow-lg mb-4 px-4">
                        <div className="flex flex-wrap ">
                            <div className="flex flex-wrap w-3/6">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    วันที่แจ้ง
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {moment(repair_log?.start_date).format('L')}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-3/6">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    วันที่แจ้ง
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {moment(repair_log?.end_date).format('L')}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap ">
                            <div className="flex flex-wrap w-3/6">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    ทะเบียนรถ
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {repair_log?.vehicle ? (
                                        <>
                                            {repair_log?.vehicle?.serial_number}
                                        </>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-3/6">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    เลขไมล์
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {repair_log?.mileage ? (
                                        <>
                                            {currencyFormatterTH.format(
                                                repair_log?.mileage?.mileage
                                            )}
                                        </>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap">
                            <label className="flex flex-wrap text-gray-700 text-sm font-bold mt-4 mr-4">
                                <div>ประเภทการแจ้งซ่อม :</div>
                                <div className="ml-2 text-blue-700">{`${repair_log?.typeData?.detail}`}</div>
                            </label>
                            <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                {repair_log?.problem_logs ? (
                                    <>
                                        {showRepairType(
                                            repair_log?.problem_logs
                                        )}
                                    </>
                                ) : (
                                    <div>-</div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                รายละเอียด
                            </label>
                            <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                {repair_log?.repair_detail ? (
                                    <>{repair_log?.repair_detail}</>
                                ) : (
                                    <div>-</div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex flex-wrap w-3/6 mb-4">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    สาเหตุ
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {repair_log?.cause ? (
                                        <>{repair_log?.cause}</>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-3/6 mb-4">
                                <label className="block  text-gray-700 text-sm font-bold mt-4 mr-4">
                                    วิธีการแก้ไข
                                </label>
                                <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                    {repair_log?.repair_modify ? (
                                        <>{repair_log?.repair_modify}</>
                                    ) : (
                                        <div>-</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-6/12 md:w-6/12 px-4 text-center py-2">
                    <div className="bg-gray-200 shadow-lg rounded py-2 md:shadow-lg mb-4">
                        <div className="mb-2 font-bold">
                            รูปภาพก่อนการแจ้งซ่อม
                        </div>
                        <div>
                            {imgBeforeRepair?.length != 0 ? (
                                <>
                                    <div className="flex flex-wrap justify-center rounded px-1 max-h-96 overflow-y-scroll ">
                                        {showImgAfterRepair(imgBeforeRepair)}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded ">
                                        ไม่มีข้อมูล
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="bg-gray-200 mt-2 shadow-lg rounded py-2 md:shadow-lg">
                        <div className="mb-2 font-bold">
                            รูปภาพหลังการแจ้งซ่อม
                        </div>
                        <div>
                            {imgAfterRepair?.length != 0 ? (
                                <>
                                    <div className="flex flex-wrap justify-center rounded px-1 max-h-96 overflow-y-scroll">
                                        {showImgAfterRepair(imgAfterRepair)}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                        ไม่มีข้อมูล
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    );
}
