import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions";
import { Link, useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import _ from "lodash";
import RepairWheelLists from "./components/RepairWheelLists";
import Pagination from "components/common/Pagination/Pagination";
import { HISTORY_DATA } from '../../../../config/constant/HistoryData';

export default function HistoryRepairWheel() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const repair_month = useSelector((state) => state.repair_month);
    const company = useSelector((state) => state.company);
    const [repair_data, setRepairData] = useState([]);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(HISTORY_DATA[0]);

    useEffect(() => {
        dispatch(actions.repairMonthAllWheel({ size: 12, page: page })).then(() => {
            dispatch(actions.companyAll())
        })
        return () => { }
    }, [page])

    useEffect(() => {
        setRepairData(repair_month?.rows);
        setIsLoading(true)
        return () => { }
    }, [repair_month])

    const showRepairLists = (data) => {
        if (data === HISTORY_DATA[0]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto mt-4 bg-white border-black border-2  border-opacity-10 rounded">
                                {" "}
                                <RepairWheelLists repair_data={repair_data} company={company} />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            )
        } else if (data === HISTORY_DATA[1]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto mt-4 bg-white border-black border-2  border-opacity-10 rounded">
                                {" "}
                                <RepairWheelLists repair_data={repair_data} company={company} />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            )
        } else if (data === HISTORY_DATA[2]) {
            return (
                <>
                    <div>
                        {_.size(repair_data) ? (
                            <div className="overflow-x-auto mt-4 bg-white border-black border-2  border-opacity-10 rounded">
                                {" "}
                                <RepairWheelLists repair_data={repair_data} company={company} />
                            </div>
                        ) : (
                            <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                                ไม่มีข้อมูล
                            </div>
                        )}
                    </div>
                </>
            )
        }
    }

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">ประวัติการแจ้งซ่อมยาง</h2>
            {/* <div className="flex p-4 mt-4 justify-end">
                <div className="justify-between">
                    <Button
                        variant="outline"
                        size="sm"
                        className={
                            data === HISTORY_DATA[0]
                                ? 'bg-white'
                                : 'bg-gray-200'
                        }
                        onClick={() => {
                            setData(HISTORY_DATA[0]);
                        }}
                    >
                        เดือน{' '}
                    </Button>{' '}
                    <Button
                        variant="outline"
                        size="sm"
                        className={
                            data === HISTORY_DATA[1]
                                ? 'bg-white'
                                : 'bg-gray-200'
                        }
                        onClick={() => {
                            setData(HISTORY_DATA[1]);
                        }}
                    >
                        ไตรมาส{' '}
                    </Button>{' '}
                    <Button
                        variant="outline"
                        size="sm"
                        className={
                            data === HISTORY_DATA[2]
                                ? 'bg-white'
                                : 'bg-gray-200'
                        }
                        onClick={() => {
                            setData(HISTORY_DATA[2]);
                        }}
                    >
                        ปี{' '}
                    </Button>
                </div>
            </div> */}
            <div className="px-4">
                {showRepairLists(data)}
            </div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination currPage={repair_month?.currPage} lastPage={repair_month?.lastPage} setPage={setPage} />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}