import axios from 'axios';
import {
    REPAIR_LOG_ALL,
    REPAIR_LOG_ALL_VEHICLE,
    REPAIR_LOG_VEHICLE_BY_ID,
    REPAIR_LOG_ALL_VEHICLE_BY_ID,
    REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL,
    REPAIR_LOG_ALL_WHEEL,
    REPAIR_LOG_WHEEL_BY_ID,
    REPAIR_LOG_ALL_WHEEL_BY_ID,
    REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL,
    REPAIR_LOG_POST,
    REPAIR_LOG_PUT,
    REPAIR_LOG_DEL,
    //ดึงข้อมูลการแจ้งซ่อมทั้งหมดตามเดือน
    REPAIR_MONTH_ALL_VEHICLE,
    REPAIR_MONTH_ALL_WHEEL,
} from '../types';

export const repairLogAll = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + `/repair_log`)
            .then((res) => {
                console.log('Request Server to Get All repair log');
                dispatch({ type: REPAIR_LOG_ALL, payload: res.data });
            });
    };
};

export const repairLogPost = (payload) => {
    return async (dispatch) => {
        await axios
            .post(process.env.REACT_APP_API_URL + '/repair_log', payload)
            .then((res) => {
                console.log('Request Server to post an repair log');
                dispatch({ type: REPAIR_LOG_POST, payload: null });
            });
    };
};

export const repairLogPut = (id, payload) => {
    return async (dispatch) => {
        await axios
            .put(process.env.REACT_APP_API_URL + '/repair_log/' + id, payload)
            .then((res) => {
                console.log('Request Server to put an repair log');
                dispatch({ type: REPAIR_LOG_PUT, payload: null });
            });
    };
};

export const repairLogDelete = (payload) => {
    return async (dispatch) => {
        await axios
            .delete(process.env.REACT_APP_API_URL + '/repair_log/' + payload)
            .then((res) => {
                console.log('Request Server to Delete an repair log');
                dispatch({ type: REPAIR_LOG_DEL, payload: null });
            });
    };
};

// VEHICLE //
export const repairLogAllVehicle = (params = {}) => {
    const {
        size = '',
        page = 1,
        month_id = '',
        repair_status = '',
        type = '',
        driver_id = '',
        vehicle_id = '',
    } = params;
    console.log('repair_status', repair_status);
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    `/repair_log/vehicle?month_id=${month_id}&size=${size}&page=${page}&driver_id=${driver_id}&vehicle_id=${vehicle_id}&type=${type}&repair_status=${repair_status}`
            )
            .then((res) => {
                console.log('Request Server to Get All repair log vehicle');
                dispatch({ type: REPAIR_LOG_ALL_VEHICLE, payload: res.data });
            });
    };
};

export const repairLogGetVehicle = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_log/vehicle/' + id)
            .then((res) => {
                console.log('Request Server to Get All repair log vehicle');
                dispatch({ type: REPAIR_LOG_VEHICLE_BY_ID, payload: res.data });
            });
    };
};

export const repairLogAllVehicleById = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_log/vehicle/by/' + id)
            .then((res) => {
                console.log(
                    'Request Server to Get All repair log vehicle ById'
                );
                dispatch({
                    type: REPAIR_LOG_ALL_VEHICLE_BY_ID,
                    payload: res.data,
                });
            });
    };
};

export const repairLogAllVehicleByHeadAndTail = (headId, tailId) => {
    console.log('headId', headId);
    console.log('tailId', tailId);
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/repair_log/vehicle/by/head/' +
                    headId +
                    '/and/tail/' +
                    tailId
            )
            .then((res) => {
                console.log(
                    'Request Server to Get All repair log vehicle By haed and tail.'
                );
                dispatch({
                    type: REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL,
                    payload: res.data,
                });
            });
    };
};

// export const repairMonthAllVehicle = () => {
//     return async (dispatch) => {
//         await axios.get(process.env.REACT_APP_API_URL + "/repair_month/vehicle/").then((res) => {
//             console.log("Request Server to Get All repair month vehicle");
//             dispatch({ type: REPAIR_MONTH_ALL_VEHICLE, payload: res.data });
//         })
//     }
// }

// WHEEL //
export const repairLogAllWheel = () => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_log/wheel/')
            .then((res) => {
                console.log('Request Server to Get All repair log wheel');
                dispatch({ type: REPAIR_LOG_ALL_WHEEL, payload: res.data });
            });
    };
};

export const repairLogGetWheel = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_log/wheel/' + id)
            .then((res) => {
                console.log('Request Server to Get All repair log wheel');
                dispatch({ type: REPAIR_LOG_WHEEL_BY_ID, payload: res.data });
            });
    };
};

export const repairLogAllWheelById = (id) => {
    return async (dispatch) => {
        await axios
            .get(process.env.REACT_APP_API_URL + '/repair_log/wheel/by/' + id)
            .then((res) => {
                console.log('Request Server to Get All repair log wheel ById');
                dispatch({
                    type: REPAIR_LOG_ALL_WHEEL_BY_ID,
                    payload: res.data,
                });
            });
    };
};

export const repairLogAllWheelByHeadAndTail = (headId, tailId) => {
    console.log('headId', headId);
    console.log('tailId', tailId);
    return async (dispatch) => {
        await axios
            .get(
                process.env.REACT_APP_API_URL +
                    '/repair_log/wheel/by/head/' +
                    headId +
                    '/and/tail/' +
                    tailId
            )
            .then((res) => {
                console.log(
                    'Request Server to Get All repair log wheel By haed and tail.'
                );
                dispatch({
                    type: REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL,
                    payload: res.data,
                });
            });
    };
};

// export const repairMonthAllWheel = () => {
//     return async (dispatch) => {
//         await axios.get(process.env.REACT_APP_API_URL + "/repair_month/wheel/").then((res) => {
//             console.log("Request Server to Get All repair month wheel");
//             dispatch({ type: REPAIR_MONTH_ALL_WHEEL, payload: res.data });
//         })
//     }
// }
