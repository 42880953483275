import React, { useState } from 'react';
import _ from 'lodash';
import { Tr, Td, Table, Thead, Tbody, Th } from '@chakra-ui/react';

import NotifyPerVehicle from './NotifyPerVehicle';
import ModalInfoNotify from '../../../../components/Modal/NotifyModal/InfoNotifyModal';

export default function TableNotifications({ vehicleList, mileagueList }) {
    const [isModalNotifyOpen, setIsModalNotifyOpen] = useState(false);
    const [selectedNotify, setSelectedNotify] = useState();
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [showedOnModalMileage, setShowedOnModalMileage] = useState();

    const vehicleTableElement = (vehicle, index) => {
        const selectedMileage = _.find(
            mileagueList,
            (mileage) => mileage.vehicle_id === vehicle.vehicle_id
        );

        return (
            <Tr key={index}>
                <Td>{index + 1}</Td>
                <Td>
                    <div className="text-xs">
                        {vehicle?.category_vehicle?.category_name}{' '}
                        {vehicle?.sub_category_vehicle?.sub_category_name}{' '}
                    </div>
                </Td>
                <Td>
                    <div className="my-2">
                        <div className="text-base font-bold">
                            {vehicle.serial_number}
                        </div>
                    </div>
                </Td>
                <Td>
                    <div className="my-2">
                        {selectedMileage?.mileage
                            ? selectedMileage?.mileage + ' km.'
                            : '-'}
                    </div>
                </Td>
                <Td>
                    <NotifyPerVehicle
                        mileage={selectedMileage?.mileage}
                        notifyList={vehicle?.notifies}
                        setModalNotifyOpen={setIsModalNotifyOpen}
                        setSelectedNotify={setSelectedNotify}
                        setShowedOnModalMileage={setShowedOnModalMileage}
                        setSelectedVehicle={setSelectedVehicle}
                        vehicle={vehicle}
                    />
                </Td>
            </Tr>
        );
    };

    const handleModalClose = () => {
        setIsModalNotifyOpen(false);
        setSelectedNotify({});
    };

    return (
        <div className=" flex-auto overflow-x-auto">
            <ModalInfoNotify
                closeHandle={handleModalClose}
                notifyData={selectedNotify}
                openStatus={isModalNotifyOpen}
                showedOnModalMileage={showedOnModalMileage}
                selectedVehicle={selectedVehicle}
            />
            <Table className="bg-white" size="sm">
                <Thead>
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>ประเภทรถ</Th>
                        <Th>หมายเลขทะเบียน</Th>
                        <Th>เลขไมล์</Th>
                        <Th>กำหนดการซ่อม</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {_.map(vehicleList, (vehicle, index) =>
                        vehicleTableElement(vehicle, index)
                    )}
                </Tbody>
            </Table>
        </div>
    );
}
