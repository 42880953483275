import React, { useState } from "react";
import _ from "lodash";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
} from "@chakra-ui/react";

export default function CategoriesMenu({ data, setData, department }) {
  const [selectedCategory, setSelectedCategory] = useState("พนักงานทั้งหมด");

  const genDepartmentLists = () =>
    _.map(department, (_department) => (
      <MenuItem
        key={_department.department_id}
        onClick={() => {
          getEmployeeByDepartment(_department.department_id);
          setSelectedCategory(_department.department_name);
        }}
      >
        {_department.department_name}
      </MenuItem>
    ));
  const getEmployeeByDepartment = (id) => {
    const res = _.filter(data, (_data) => _data.department_id === id);
    setData(res);
  };
  return (
    <div className="shadow rounded">
      <Menu isLazy>
        <MenuButton as={Button} variant="outline">
          {selectedCategory}
          <i className="fas fa-sort-down px-2"></i>
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setData(data);
              setSelectedCategory("พนักงานทั้งหมด");
            }}
          >
            พนักงานทั้งหมด
          </MenuItem>
          <MenuDivider />
          {genDepartmentLists()}
        </MenuList>
      </Menu>
    </div>
  );
}
