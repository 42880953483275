import { WORD_REPAIR_STATUS } from './RepairStatus';
import { WORD_TRANSPORT_SERVICE } from './TransportService';
import { ASSIGNMENT_STATUS } from './AssignmentStatus';
import { DELIVERY_STATUS, WORD_DELIVERY_STATUS } from './DeliveryStatus';
import { WORD_TYPE_IMAGE_REPAIR } from './TypeImageRepair';

export default {
    WORD_REPAIR_STATUS,
    WORD_TRANSPORT_SERVICE,
    ASSIGNMENT_STATUS,
    DELIVERY_STATUS,
    WORD_DELIVERY_STATUS,
    WORD_TYPE_IMAGE_REPAIR,
};
