import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import { REPAIR_STATUS } from "../../../config/constant/RepairStatus";
import { SUB_CATEGORY_REPAIR } from "../../../config/constant/SubCategoryRepair";
import _ from "lodash";
import SpinnerLoading from "components/Loading/SpinnerLoading";
import * as actions from "../../../redux/actions";
import RepairTypeLists from "./components/RepairTypeLists";
import LogRepairVehicle from "./components/LogRepairVehicle";

export default function DetailRepairVehicle() {
    const { register, handleSubmit, errors, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const repair = useSelector((state) => state.repair);
    const repair_log = useSelector((state) => state.repair_log);
    const [repair_data, setRepairData] = useState();
    const [log_repair, setLog] = useState([]);
    const [repairLog, setRepairLog] = useState([]);
    const [price, setPrice] = useState([]);

    useEffect(() => {
        dispatch(actions.repairGetVehicle(id))
        return () => { }
    }, [])

    useEffect(() => {
        if (repair?.vehicle_id != "-") {
            console.log("-----vehicle_id------");
            dispatch(actions.repairLogAllVehicleById(repair?.vehicle_id))
        } else if (repair?.vehicle_tail_id != "-") {
            console.log("-----vehicle_tail_id-----");
            dispatch(actions.repairLogAllVehicleById(repair?.vehicle_tail_id))
        }
        setIsLoading(true);
        return () => { }
    }, [repair])

    useEffect(() => {
        setRepairLog(repair_log?.arr);
        setIsLoading(true);
        return () => { }
    }, [repair_log])

    const onSubmit = (data) => {
        console.log("data", data);
        for (let i = 0; i < _.size(price); i++) {
            console.log("price", price[i]);
            if (price[i] != null && price[i] != undefined) {
                const payload = {
                    price: price[i].price
                }
                dispatch(actions.problemPut(price[i].problem_id, payload))
            }
        }
        alert("สำเร็จ");
        history.push("/cmms/repair/vehicle");
    }

    console.log("id: ", id);
    console.log("repair_log", repairLog);
    console.log("repair", repair);

    const showDetailRepairVehicle = (data) => {
        if (data == SUB_CATEGORY_REPAIR[0]) {
            return (
                <>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            เลขไมล์
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.mileage?.mileage}
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ประเภท
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle?.sub_category_vehicle.sub_category_name}
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ทะเบียนหัว
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle?.serial_number}
                        </div>
                    </div>
                </>
            )
        } else if (data == SUB_CATEGORY_REPAIR[1]) {
            return (
                <>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ทะเบียนหาง
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle_tail?.serial_number}
                        </div>
                    </div>
                </>
            )
        } else if (data == SUB_CATEGORY_REPAIR[2]) {
            return (
                <>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            เลขไมล์
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.mileage?.mileage}
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ประเภท
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle?.sub_category_vehicle.sub_category_name}
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ทะเบียนหัว
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle?.serial_number}
                        </div>
                    </div>
                    <div className="relative w-full mb-3">
                        <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                            ทะเบียนหาง
                        </label>
                        <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                            {repair?.vehicle_tail?.serial_number}
                        </div>
                    </div>
                </>
            )
        }
    }

    return isLoading ? (
        <div>
            <h2 className="text-gray-800 text-xl font-semibold">รายละเอียดการแจ้งซ่อมรถ</h2>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4 justify-between">
                    <div>
                        <Link to={"/cmms/repair/vehicle"}>
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                    <div>
                        <Button
                            type="submit"
                            colorScheme="blue"
                        // onClick={() => {
                        //     onSubmit();
                        // }}
                        >
                            บันทึก
                    </Button>
                    </div>
                </div>

                <div className="flex flex-wrap p-4  justify-between h-full">
                    <div className="w-full lg:w-4/12 px-4 ">
                        <div className="relative w-full mb-3 ">
                            <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                ชื่อผู้แจ้ง
                        </label>
                            <div className="px-3 py-3 text-gray-700 bg-gray-300 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                {repair?.employee?.employee_first_name + " " + repair?.employee?.employee_last_name}
                            </div>
                        </div>
                        {showDetailRepairVehicle(repair?.sub_vehicle)}
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                รายละเอียด
                        </label>
                            <div className="px-3 py-3 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                                {repair?.repair_detail}
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-5/12 px-4 ">
                        <div className="relative w-full mb-3">
                            <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                                ประเภทการซ่อม
                        </label>
                            {_.size(repair?.problems) ? (
                                <div>
                                    <RepairTypeLists
                                        repairTypeData={repair?.problems}
                                        price={price}
                                    />
                                </div>
                            ) : (
                                <div className="justify-center">ไม่มีข้อมูล</div>
                            )}
                        </div>
                  
                    </div>
                </div>
            </form>

            <div className="p-4 pr-4">
                <label className="block uppercase text-gray-700 text-sm font-bold mb-2">
                    {repair?.vehicle_id != "-" ? (
                        <>
                            {/* ประวัติการซ่อม ทะเบียนหาง : {" " + repair?.vehicle_tail?.serial_number} */}
                            ประวัติการซ่อม ทะเบียนหัว : {" " + repair?.vehicle?.serial_number}
                        </>
                    ) : (
                        <>
                            {/* ประวัติการซ่อม ทะเบียนหัว : {" " + repair?.vehicle?.serial_number} */}
                            ประวัติการซ่อม ทะเบียนหาง : {" " + repair?.vehicle_tail?.serial_number}
                        </>
                    )}
                </label>
                {/* {filterRepairByVehicleId(repair_data[0])} */}
                {_.size(repairLog) ? (
                    <div className="overflow-x-auto bg-white border-black border-2  border-opacity-10 rounded">
                        <LogRepairVehicle log_repair={repairLog} />
                    </div>
                ) : (
                    <div className="flex justify-center p-4">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}