import {
    TYPE_ALL,
    TYPE_GET,
    TYPE_POST,
    TYPE_PUT,
    TYPE_DEL,
} from '../../actions/types';

const initialState = {
    type: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case TYPE_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case TYPE_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case TYPE_POST:
            return action.payload;
        case TYPE_PUT:
            return action.payload;
        case TYPE_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}
