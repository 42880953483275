import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import * as actions from '../../../redux/actions';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import Pagination from "components/common/Pagination/Pagination";
import DeliveryHistoryList from './components/DeliveryHistoryLists';

export default function DeliveryHistoryManagement() {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const delivery_month = useSelector((state) => state.delivery_month);
    const [delivery_data, setDeliveryData] = useState([]);
    const [page, setPage] = useState(1);
    const company = useSelector((state) => state.company);

    useEffect(() => {
        dispatch(actions.deliveryMonthAll({ size: 12, page: page })).then(() => {
            dispatch(actions.companyAll())
        })
        return () => { }
    }, [page])

    useEffect(() => {
        setDeliveryData(delivery_month?.rows);
        setIsLoading(true)
        return () => { }
    }, [delivery_month])

    const showDeliveryLists = () => {
        return (
            <>
                <div>
                    {_.size(delivery_data) ? (
                        <div className="overflow-x-auto mt-4 bg-white border-black border-2  border-opacity-10 rounded">
                            {" "}
                            <DeliveryHistoryList month={delivery_data} company={company} />
                        </div>
                    ) : (
                        <div className="flex justify-center p-4 bg-white border-black border-2  border-opacity-10 rounded">
                            ไม่มีข้อมูล
                        </div>
                    )}
                </div>
            </>
        )

    }
   
    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    ประวัติรายการจัดส่งสินค้า
                </h2>
            </div>

           
            <div className="px-4">
                {showDeliveryLists()}
            </div>
            <div className="pl-4 pr-4 mt-2">
                <Pagination currPage={delivery_month?.currPage} lastPage={delivery_month?.lastPage} setPage={setPage} />
            </div>
        </div>
    ) : (
        <SpinnerLoading />
    )
}
