import React from 'react';
import { Input, InputLeftElement, InputGroup } from '@chakra-ui/react';
export default function VehicleSearch({ data, setData }) {
    const handleSearch = (word) => {
        // console.log(data);
        const res = data.filter(
            (vehicle) =>
                vehicle?.category_vehicle?.category_name?.includes(word) ||
                vehicle?.category_vehicle?.category_detail?.includes(word) ||
                vehicle?.sub_category_vehicle?.sub_category_name?.includes(word)||
                vehicle?.vehicle_detail?.includes(word)||
                vehicle?.vehicle_note?.includes(word)||
                vehicle?.serial_number?.includes(word)
        );
        setData(res);
    };
    return (
        <div>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={
                        <i className="fa fa-search" aria-hidden="true"></i>
                    }
                />
                <Input
                    className="shadow-md"
                    colorScheme="black"
                    placeholder="ค้นหา"
                    onChange={(e) => {
                        data && handleSearch(e.target.value);
                    }}
                />
            </InputGroup>
        </div>
    );
}
