import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import currencyFormatter from 'currency-formatter';
import _ from 'lodash';
import moment from 'moment';
import THBText from 'thai-baht-text';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf',
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf',
    },
};

const genPrice = (data) => {
    let sum = 0;
    for (let i = 0; i < _.size(data); i++) {
        let sub_sum = 0;
        for (let j = 0; j < _.size(data[i]?.repair_wheel_logs); j++) {
            sub_sum = parseFloat(sub_sum) + parseFloat(data[i]?.repair_wheel_logs[j].price);
        }
        sum = sum + sub_sum;
    }
    return sum;
}

const sumPrice = (data) => {
    let sum = 0;
    for (let i = 0; i < _.size(data); i++) {
        sum = parseFloat(sum) + parseFloat(data[i].price);
    }
    return sum;
}

const genData = (repair_logs) => {
    return _.map(repair_logs, (_log, index) => [
        {
            text: index + 1,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: `${moment(_log?.start_date).format('DD/MM/YYYY')}`,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: `${moment(_log?.end_date).format('DD/MM/YYYY')}`,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: `${_log?.mileage?.mileage || '-'} km`,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: `${_log?.vehicle?.serial_number || `-`}/${_log?.vehicle_tail?.serial_number || `-`}`,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: `${_.map(_log?.repair_wheel_logs, (wheel) => ' ล้อที่ ' + wheel?.index_wheel + ' ' + wheel?.wheel_detail + '\n')}`,
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
        {
            text: currencyFormatter.format(`${sumPrice(_log?.repair_wheel_logs)}`, {
                code: '',
            }),
            border: [true, true, true, false],
            colSpan: 1,
            // alignment: 'center',
        },
    ])
}

export const MonthDataWheelPrintPDF = (log, company) => {

    const data = genData(log?.repair_logs);

    var docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 40, 40, 20],
        defaultStyle: {
            font: 'THSarabunNew',
            fontSize: '16',
        },
        info: {
            title: 'ใบแจ้งซ่อมรถ',
        },
        dontBreakRows: true,
        content: [
            {
                alignment: 'justify',
                columns: [
                    {
                        image: 'company_logo',
                        width: 100,
                        margin: [0, 0, 0, 0],
                    },
                    [
                        {
                            text: company?.company_name,
                            fontSize: '18',
                            bold: true,
                            margin: [5, 2],
                        },

                        {
                            text: `${company?.company_address}`,
                            fontSize: '14',
                            margin: [5, 2],
                        },
                        {
                            text: `โทร ${company?.company_phone_number} โทรสาร ${company?.company_fax} `,
                            fontSize: '14',
                            margin: [5, 2],
                        },
                        {
                            text: `อีเมล ${company?.company_email} เลขประจำตัวผู้เสียภาษี ${company?.company_taxes_id}`,
                            fontSize: '14',
                            margin: [5, 2],
                        },
                    ],
                    [
                        {
                            text: `ใบแจ้งซ่อมรถประจำเดือน ${moment(log?.month).format("MMMM")}/${moment(log?.month).format("YYYY")}`,
                            fontSize: '18',
                            bold: true,
                            margin: [60, 2],
                        },
                        {
                            text: `รายการทั้งหมด : ${_.size(log?.repair_logs)} รายการ`,
                            fontSize: '16',
                            margin: [60, 2],
                        },
                        {
                            text: `ราคาทั้งหมด : ` + currencyFormatter.format(`${genPrice(log?.repair_logs)}`, {
                                code: '',
                            }),
                            fontSize: '16',
                            margin: [60, 2],
                        }
                    ]
                ],
            },
            // {
            //     canvas: [
            //         {
            //             type: 'line',
            //             x1: 0,
            //             y1: 0,
            //             x2: 515,
            //             y2: 0,
            //             lineWidth: 1,
            //         },
            //     ],
            // },
            // {
            //     //หัวข้อ
            //     alignment: "center",
            //     margin: [0, 20, 0, 20],
            //     text: "ใบแจ้งยาง",
            //     fontSize: "23",
            //     bold: true,
            // },
            {
                margin: [0, 30, 30, 0],
                style: 'tableExample',
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0 ? '#c2dec2' : null;
                    },
                },
                table: {
                    widths: ['5%', '15%', '15%', '15%', '15%', '30%', '12%'],
                    heights: [10, 10, 10, 10, 30, 10, 10, 10],
                    headerRows: 1,
                    body: [
                        [
                            {
                                text: 'ลำดับ',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'วันที่แจ้งซ่อม',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'วันที่ซ่อมสำเร็จ',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'เลขไมล์',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'ทะเบียน หัว/หาง',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'รายละเอียดการซ่อม',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            },
                            {
                                text: 'ราคารวม',
                                colSpan: 1,
                                fontSize: 14,
                                // alignment: 'center',
                            }
                        ],
                        ...data,
                        [
                            {
                                text: ` ( ${THBText(genPrice(log?.repair_logs))} )`,
                                colSpan: 6,
                                fontSize: 16,
                                alignment: 'center',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {
                                text: currencyFormatter.format(`${genPrice(log?.repair_logs)}`, {
                                    code: '',
                                }),
                                colSpan: 1,
                                fontSize: 16,
                                // alignment: 'center',
                            },
                        ],
                    ],
                },
            }
        ],
        images: {
            company_logo: company?.company_logo,
        },
    };
    pdfMake.createPdf(docDefinition).open();

}