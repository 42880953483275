import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/actions/';
import { firebaseAuth } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import * as UserInfo from 'config/UserInfo';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { uploadImageFile } from '../../../util/image.functions';
import hash from 'object-hash';
import _ from 'lodash';
import { Button } from '@chakra-ui/react';

export default function CreateEmployee() {
    const { register, handleSubmit, reset, errors } = useForm();
    const [image, setImage] = useState([]);
    const [idCardImage, setIdCardImage] = useState([]);
    const [drivingLiceneImage, setDrivingLiceneImage] = useState([]);
    const { handleSignupByHRMS } = useContext(firebaseAuth);
    const dispatch = useDispatch();
    const position = useSelector((state) => state.position);
    const [department_id, setDepartmentID] = useState(null);
    useEffect(() => {
        dispatch(actions.positionAll());
        return () => {};
    }, []);
    const onSubmit = async (data, e) => {
        if (
            data.password === data.confirm_password &&
            (_.size(image) || _.size(idCardImage) || _.size(drivingLiceneImage))
        ) {
            if (_.size(image)) {
                console.log('มีรูปโปรไฟล์');
                const filename = hash({ image, date: new Date() });
                uploadImageFile(filename, image[0].data_url).then(
                    async (url) => {
                        console.log('URL : ' + url);
                        data.employee_image = url;
                        e.preventDefault();
                        console.log(data);
                    }
                );
            }
            if (_.size(idCardImage)) {
                console.log('มีรูปบัตรประชาชน');
                const filename = hash({ idCardImage, date: new Date() });
                uploadImageFile(filename, idCardImage[0].data_url).then(
                    async (url) => {
                        console.log('URL : ' + url);
                        data.idCard_image = url;
                        e.preventDefault();
                        console.log(data);
                    }
                );
            }
            if (_.size(drivingLiceneImage)) {
                console.log('มีรูปใบขับขี่');
                const filename = hash({ drivingLiceneImage, date: new Date() });
                uploadImageFile(filename, drivingLiceneImage[0].data_url).then(
                    async (url) => {
                        console.log('URL : ' + url);
                        data.driving_license_image = url;
                        e.preventDefault();
                        console.log(data);
                    }
                );
            }
            data.department_id = department_id;
            await handleSignupByHRMS(data.employee_email, data.password, data);
            alert('ลงทะเบียนสำเร็จ');
            reset();
            setImage([]);
            setIdCardImage([]);
            setDrivingLiceneImage([]);
        } else if (data.password === data.confirm_password) {
            data.department_id = department_id;
            await handleSignupByHRMS(data.employee_email, data.password, data);
            alert('ลงทะเบียนสำเร็จ');
            reset();
            setImage([]);
        } else {
            alert('รหัสผ่านไม่ตรงกัน');
        }
    };
    const genPositionOption = () =>
        _.map(position, (_position) => (
            <option key={_position.position_id} value={_position.position_id}>
                {_position.position_name}
            </option>
        ));
    const findDepapartmentID = (position_id) => {
        return _.find(position, (_position) => {
            return _position.position_id === position_id;
        });
    };
    return (
        <>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <h2 className="text-gray-800 text-xl font-semibold py-1">
                                    เพิ่มพนักงาน
                                </h2>
                                <div className="flex justify-between py-6">
                                    <Link to={'/hrms/employee'}>
                                        <Button variant="outline">กลับ</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="flex content-center items-center justify-center h-full mb-12">
                                <div className="w-full lg:w-6/12 px-4">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="flex flex-wrap">
                                            <div className="w-full px-4">
                                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                    รูปภาพประจำตัว
                                                </label>
                                                <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                                                    <ImageUpload
                                                        images={image}
                                                        setImages={setImage}
                                                        preview_size={'100px'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        อีเมล{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_email"
                                                        type="email"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="example@email.com"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full  px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        รหัสผ่าน{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="password"
                                                        type="password"
                                                        required
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="รหัสผ่านอย่างน้อย 6 ตัวอักษร"
                                                        ref={register}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full  px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ยืนยันรหัสผ่าน{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="confirm_password"
                                                        type="password"
                                                        required
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="ยืนยันรหัสผ่าน"
                                                        ref={register}
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ชื่อ{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_first_name"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="ชื่อ"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        นามสกุล{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_last_name"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="นามสกุล"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ตำแหน่ง{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>

                                                    <select
                                                        name="position_id"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        onChange={(e) => {
                                                            setDepartmentID(
                                                                findDepapartmentID(
                                                                    e.target
                                                                        .value
                                                                )?.department_id
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            selected
                                                        >
                                                            กรุณาเลือกตำแหน่งของพนักงาน
                                                        </option>
                                                        {genPositionOption()}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    เพศ{' '}
                                                    <label className="text-red-600 text-xs">
                                                        {' '}
                                                        *
                                                    </label>
                                                </label>

                                                <select
                                                    name="employee_gender"
                                                    required
                                                    ref={register}
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                >
                                                    <option
                                                        value={
                                                            UserInfo.GENDER.MALE
                                                        }
                                                    >
                                                        {UserInfo.GENDER.MALE}
                                                    </option>
                                                    <option
                                                        value={
                                                            UserInfo.GENDER
                                                                .FEMALE
                                                        }
                                                    >
                                                        {UserInfo.GENDER.FEMALE}
                                                    </option>
                                                    <option
                                                        value={
                                                            UserInfo.GENDER
                                                                .OTHERS
                                                        }
                                                    >
                                                        {UserInfo.GENDER.OTHERS}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        เบอร์โทรศัพท์{' '}
                                                        <label className="text-red-600 text-xs">
                                                            {' '}
                                                            *
                                                        </label>
                                                    </label>
                                                    <input
                                                        name="employee_phone_number"
                                                        type="text"
                                                        required
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="เบอร์โทรศัพท์"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-6/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        วันหมดอายุใบขับขี่{' '}
                                                    </label>
                                                    <input
                                                        name="driving_license"
                                                        type="date"
                                                        ref={register}
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="วันหมดอายุใบขับขี่"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full  px-4">
                                                <div className="relative w-full  mb-3">
                                                    <label
                                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ที่อยู่
                                                    </label>
                                                    <textarea
                                                        name="employee_address"
                                                        ref={register}
                                                        rows="5"
                                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                        placeholder="ที่อยู่"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full  px-4">
                                            <div className="relative w-full mb-4">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    รหัสไปรษณีย์{' '}
                                                </label>
                                                <input
                                                    name="employee_postcode"
                                                    type="text"
                                                    ref={register}
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                    placeholder="รหัสไปรษณีย์"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full px-4">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                รูปภาพบัตรประชาชน
                                            </label>
                                            <div className="rounded-lg  bg-white p-2  mt-1 mb-2">
                                                <ImageUpload
                                                    images={idCardImage}
                                                    setImages={setIdCardImage}
                                                    preview_size={'100px'}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full px-4">
                                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                                รูปภาพใบขับขี่
                                            </label>
                                            <div className="rounded-lg  bg-white p-2  mt-1 mb-6">
                                                <ImageUpload
                                                    images={drivingLiceneImage}
                                                    setImages={
                                                        setDrivingLiceneImage
                                                    }
                                                    preview_size={'100px'}
                                                />
                                            </div>
                                        </div>

                                        <div className="text-center px-4 gap-0 py-3">
                                            <Button
                                                isFullWidth
                                                colorScheme="blue"
                                                variant="solid"
                                                type="submit"
                                            >
                                                สร้างบัญชี
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
