import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";

import { DELIVERY_STATUS } from '../../../../config/constant/DeliveryStatus';
import { printPDF } from './report/DeliveryOrderReport';

export default function DeliveryMonthLists({ delivery_log, company, id }) {


    const genTd = () => {
        return _.map(delivery_log, (log, index) => (
            <Tr key={log.repair_log_id} className="cursor-pointer whitespace-nowrap"
            >
                <Td>{index + 1}</Td>
                <Td>
                    {moment(log?.start_date).format('L')}
                </Td>
                <Td>
                    {moment(log?.end_date).format('L')}
                </Td>
                <Td>
                    {log?.delivery_status === DELIVERY_STATUS[0] && (
                        <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                            {log?.delivery_status}
                        </div>
                    )}
                    {log?.delivery_status === DELIVERY_STATUS[1] && (
                        <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                            {log?.delivery_status}
                        </div>
                    )}
                    {log?.delivery_status === DELIVERY_STATUS[2] && (
                        <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                            {log?.delivery_status}
                        </div>
                    )}
                    {log?.delivery_status === DELIVERY_STATUS[3] && (
                        <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                            {log?.delivery_status}
                        </div>
                    )}
                    {/* <div className="text-xs text-center px-2 text-blue-500 hover:text-blue-700">
                        รายละเอียด
                    </div> */}
                </Td>
                <Td>
                    {/* เลขที่ใบแจ้งหนี้ */}
                    <div>
                        {_.map(
                            log?.delivery_assignment_logs,
                            (eachaAssignment, index) => (
                                <div className="text-center" key={index}>
                                    {(eachaAssignment?.assignment_log?.invoice_number) ? (
                                        <span className="text-xs font-semibold ">
                                            {
                                                eachaAssignment?.assignment_log
                                                    ?.invoice_number
                                            }
                                        </span>
                                    ) : (
                                        <span className="text-xs font-semibold ">
                                            -
                                        </span>
                                    )}

                                </div>
                            )
                        )}
                    </div>
                </Td>
                <Td>
                    <div>
                        {_.map(
                            log?.delivery_assignment_logs,
                            (eachaAssignment, index) => (
                                <div className="text-center mb-2" key={index}>
                                    <span
                                        className="text-xs cursor-pointer"
                                        onClick={() => {
                                            printPDF(
                                                eachaAssignment?.assignment_log,
                                                log,
                                                company
                                            );
                                        }}
                                    >
                                        <span className="text-blue-500 hover:text-blue-700 ">
                                            {'พิมพ์ใบส่งของ'}
                                        </span>

                                        <i className="fas fa-print mx-2 "></i>
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </Td>
                <Td>
                    <div>
                        {_.map(
                            _.uniqBy(
                                _.map(
                                    log?.delivery_assignment_logs,
                                    (eachAssignment) =>
                                        _.find(
                                            log?.delivery_assignment_logs,
                                            (assignment) =>
                                                assignment.assignment_log_id ===
                                                eachAssignment.assignment_log_id
                                        )
                                ),
                                (selectedAssignment) =>
                                    selectedAssignment?.assignment_log?.customer_company
                            ),
                            (selectedAssignment) => (
                                <div className="mb-2">
                                    <div className="font-semibold">
                                        {
                                            selectedAssignment?.assignment_log?.shipper
                                                ?.customer_company
                                        }
                                    </div>
                                    <div className="text-xs whitespace-normal">
                                        {
                                            selectedAssignment?.assignment_log?.shipper?.customer_address
                                        }
                                    </div>
                                    <div className="text-xs whitespace-normal">
                                        {
                                            selectedAssignment?.assignment_log?.shipper?.customer_postcode
                                        }
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </Td>{' '}
                <Td>
                    <div>
                        {_.map(
                            _.uniqBy(
                                _.map(
                                    log?.delivery_assignment_logs,
                                    (eachAssignment) =>
                                        _.find(
                                            log?.delivery_assignment_logs,
                                            (assignment) =>
                                                assignment.assignment_log_id ===
                                                eachAssignment.assignment_log_id
                                        )
                                ),
                                (selectedAssignment) =>
                                    selectedAssignment?.assignment_log?.shipper?.customer_company
                            ),
                            (selectedAssignment) => (
                                <div className="mb-2">
                                    <div className="font-semibold">
                                        {
                                            selectedAssignment?.assignment_log?.shipper
                                                ?.customer_company
                                        }
                                    </div>
                                    <div className="text-xs whitespace-normal">
                                        {
                                            selectedAssignment?.assignment_log?.shipper?.customer_address
                                        }
                                    </div>
                                    <div className="text-xs whitespace-normal">
                                        {
                                            selectedAssignment?.assignment_log?.shipper?.customer_postcode
                                        }
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </Td>{' '}

                <Td>
                    {' '}
                    {_.map(
                        log?.delivery_assignment_logs,
                        (eachaAssignment, index) => (
                            <div key={index} className="text-center">
                                {(eachaAssignment?.assignment_log?.container_size) ? (
                                    <span className="text-xs mr-2">
                                        {
                                            eachaAssignment?.assignment_log
                                                ?.container_size
                                        }
                                    </span>
                                ) : (
                                    <span className="text-xs mr-2">
                                        -
                                    </span>
                                )}

                            </div>
                        )
                    )}
                </Td>
                <Td>
                    {(log?.delivery_vehicle_logs?.length != 0) ? (
                        <div className="flex flex-col">
                            {_.map(
                                log?.delivery_vehicle_logs,
                                (eachVehicle, index) => (
                                    <span key={index}>
                                        <span className="text-xs ">
                                            {
                                                eachVehicle?.vehicle
                                                    ?.category_vehicle
                                                    ?.category_name
                                            }
                                        </span>
                                        {' : '}{' '}
                                        <span className="text-xs font-semibold">
                                            {eachVehicle?.vehicle?.serial_number}
                                        </span>
                                    </span>
                                )
                            )}
                        </div>
                    ) : (
                        <div className="text-xs mr-2 font-semibold">
                            -
                        </div>
                    )}
                </Td>
                <Td>
                    <div className="text-xs text-center font-semibold">
                        {log.seal_no ? (
                            <div>{log.seal_no}</div>
                        ) : (
                            <div>-</div>
                        )}
                    </div>
                </Td>
            </Tr>
        ))
    }

    return (
        <div >
            <Table className="bg-white break-words rounded-sm" variant="simple" size="sm">
                <Thead className="whitespace-nowrap">
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>วันรับ</Th>
                        <Th>วันส่ง</Th>
                        <Th>สถานะ</Th>
                        <Th>เลขที่ใบแจ้งหนี้</Th>
                        <Th>ใบส่งของ</Th>
                        <Th>ผู้ส่ง</Th>
                        <Th>ผู้รับ</Th>
                        <Th>ขนาด</Th>
                        <Th>ทะเบียนรถ</Th>
                        <Th>หมายเลขซีล</Th>
                    </Tr>
                </Thead>
                <Tbody >{genTd()}</Tbody>
            </Table>
        </div>
    )
}