import React from 'react';
import * as actions from '../../../../redux/actions/';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { Button, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';

export default function EditSystemInfo({ system_info }) {
    const { register, handleSubmit, reset } = useForm();
    const dispatch = useDispatch();
    const onSubmit = async (data, e) => {
        console.log(data);
        const confirm = window.confirm('อัพเดทข้อมูล');
        if (confirm)
            dispatch(
                actions.systemInfoPut(system_info.system_info_id, data)
            ).then(() => {
                alert('บันทึกสำเร็จ');
            });
    };
    const genModuleListsCheckBox = () =>
        _.map(system_info.module_lists, (module, index) => {
            if (module.module_name_en != 'crm' && module.module_name_en != 'tms')
            return (
                <div key={module.module_id}>
                    <FormControl display="flex">
                        <FormLabel>
                            <div className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                {module.module_name_th}
                            </div>
                        </FormLabel>
                        <Switch
                            size="lg"
                            name={`module_lists.${module.module_id}`}
                            ref={register}
                            defaultChecked={module.enable_status}
                        />
                    </FormControl>
                </div>
            );
        });

    return system_info ? (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12">
                        <h6 className="text-gray-500 text-xs mt-3 mb-6 font-bold uppercase font-sans ">
                            ข้อมูลระบบ
                        </h6>
                        <div className="flex flex-wrap ">
                            <div className="w-full px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อระบบ
                                    </label>
                                    <input
                                        name="system_name"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={system_info.system_name}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="ชื่อระบบ"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อบริษัท / หน่วยงาน เจ้าของระบบ
                                    </label>
                                    <input
                                        name="system_owner"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={system_info.system_owner}
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="ชื่อระบบ"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        เว็บไซต์เจ้าของระบบ
                                    </label>
                                    <input
                                        name="system_owner_url"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={
                                            system_info.system_owner_url
                                        }
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="ชื่อระบบ"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อบริษัท / หน่วยงาน เจ้าของระบบ
                                    </label>
                                    <input
                                        name="system_sponsor"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={
                                            system_info.system_sponsor
                                        }
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="ชื่อระบบ"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        ชื่อบริษัท / หน่วยงาน เจ้าของระบบ
                                    </label>
                                    <input
                                        name="system_sponsor_url"
                                        type="text"
                                        required
                                        ref={register}
                                        defaultValue={
                                            system_info.system_sponsor_url
                                        }
                                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                        placeholder="ชื่อระบบ"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className="text-gray-500 text-xs mt-3 mb-6 font-bold uppercase font-sans ">
                            การแสดงผลโมดูลระบบ
                        </h6>
                        <div className="w-full px-4 pb-3">
                            {genModuleListsCheckBox()}
                        </div>
                    </div>
                </div>
                <div className="text-center mt-6 px-4">
                    <Button isFullWidth colorScheme="blue" type="submit">
                        บันทึก
                    </Button>
                </div>
            </form>
        </div>
    ) : (
        <div>
            <SpinnerLoading />
        </div>
    );
}
