import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';
import { Link } from 'react-router-dom';
import IndexNavbar from 'components/common/Navbars/IndexNavbar.js';
import Footer from 'components/common/Footers/Footer.js';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import _ from 'lodash';
export default function Index() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const system_info = useSelector((state) => state.system_info);
    const storage_remember = window.localStorage?.remember_erp;
    useEffect(() => {
        dispatch(actions.systemInfoAll()).then(() => {
            dispatch(actions.meGet(storage_remember)).then(() => {
                setIsLoading(true);
            });
        });
        return () => {};
    }, []);

    const findModuleList = (find_module_name) => {
        return _.find(system_info.module_lists, function (module) {
            return module.module_name_en === find_module_name;
        });
    };

    return isLoading ? (
        <>
            <IndexNavbar fixed />
            <section className="container mx-auto items-center flex flex-wrap min-h-screen font-sans">
                <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-20 lg:mt-0">
                    <div className="my-4  px-4">
                        <Link to={'/data'}>
                            <div className="bg-green-500 shadow rounded text-center py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                <i className="fas fa-user fa-3x text-white"></i>
                                <p className="text-sm text-white mt-4">
                                    ข้อมูลของฉัน
                                </p>
                            </div>
                        </Link>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 ">
                        {/* {findModuleList('tms').enable_status ? (
                            <div className="my-4  px-4">
                                <Link to={'/tms'}>
                                    {' '}
                                    <div className="bg-yellow-500   shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                                        <i className="fas fa-file-invoice fa-3x text-white"></i>
                                        <p className="text-sm text-white mt-4">
                                            บริหารจัดการการขนส่ง
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )} */}

                        {findModuleList('cmms').enable_status ? (
                            <div className="my-4  px-4">
                                <Link to={'/cmms'}>
                                    {' '}
                                    <div className="bg-blue-900 shadow rounded text-center  py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                        <i className="fas fa-tools fa-3x text-white"></i>
                                        <p className="text-sm text-white mt-4 ">
                                            บริหารจัดการการแจ้งซ่อม
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}

                        {findModuleList('fms').enable_status ? (
                            <div className="my-4  px-4">
                                <Link to={'/fms'}>
                                    <div className="bg-yellow-500  shadow rounded text-center  py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                        <i className="fas fa-truck fa-3x text-white"></i>
                                        <p className="text-sm text-white mt-4 ">
                                            ข้อมูลรถ
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}

                        {findModuleList('hrms').enable_status ? (
                            <div className="my-4  px-4">
                                <Link to={'/hrms'}>
                                    <div className="bg-red-500 shadow rounded text-center py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                        <i className="fas fa-users fa-3x text-white"></i>
                                        <p className="text-sm text-white mt-4 ">
                                            บริหารจัดการทรัพยากรบุคคล
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}

                        {/* {findModuleList('crm').enable_status ? (
                            <div className="my-4  px-4">
                                <Link to={'/crm'}>
                                    <div className="bg-indigo-500 shadow rounded text-center py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                        <i className="fas fa-address-book fa-3x text-white"></i>
                                        <p className="text-sm text-white mt-4 ">
                                            บริหารลูกค้าสัมพันธ์
                                        </p>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )} */}

                        <div className="my-4  px-4">
                            {' '}
                            <Link to={'/system'}>
                                <div className=" bg-gray-800 shadow rounded text-center  py-8 px-4 lg:h-40 transition-transform transform hover:scale-105">
                                    <i className="fas fa-cogs fa-3x text-white"></i>
                                    <p className="text-sm text-white mt-4 ">
                                        จัดการระบบ
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    ) : (
        <SpinnerLoading />
    );
}
