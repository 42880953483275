import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import { ASSIGNMENT_STATUS } from '../../../../config/constant/AssignmentStatus';
export default function QuotationLists({
    transport_assignment,
    handleOpenAssignmentModal,
    handleReadyAssignment,
    handleDeleteAssignment,
}) {
    const genTd = () => {
        return _.map(transport_assignment, (_transport_assignment, index) => (
            <Tr
                key={_transport_assignment.assignment_id}
                className="cursor-pointer  whitespace-nowrap"
            >
                {/* ลำดับ */}
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {' '}
                    <div>{index + 1} </div>
                </Td>
                {/* วันรับ */}
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    <div>
                        {moment(_transport_assignment?.pickup_date).format(
                            'DD/MM/YYYY'
                        )}
                    </div>
                </Td>
                {/* วันส่งของ*/}
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {moment(_transport_assignment?.delivery_date).format(
                        'DD/MM/YYYY'
                    )}
                </Td>

                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {' '}
                    <div>
                        {_transport_assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[0] && (
                                <div className="text-xs text-center px-1  bg-blue-100 hover:bg-blue-200 rounded whitespace-nowrap">
                                    {_transport_assignment?.assignment_status}
                                </div>
                            )}
                        {_transport_assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[1] && (
                                <div className="text-xs text-center px-1 bg-yellow-100 hover:bg-yellow-200 rounded whitespace-nowrap">
                                    {_transport_assignment?.assignment_status}
                                </div>
                            )}
                        {_transport_assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[2] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {_transport_assignment?.assignment_status}
                                </div>
                            )}

                        {_transport_assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[3] && (
                                <div className="text-xs text-center px-2 bg-red-100 hover:bg-red-200 rounded   whitespace-nowrap">
                                    {_transport_assignment?.assignment_status}
                                </div>
                            )}

                        {_transport_assignment?.assignment_status ===
                            ASSIGNMENT_STATUS[4] && (
                                <div className="text-xs text-center px-2 bg-green-100 hover:bg-green-200 rounded  whitespace-nowrap">
                                    {_transport_assignment?.assignment_status}
                                </div>
                            )}
                    </div>
                    <div className="text-xs text-center px-2 text-blue-500 hover:text-blue-700">
                        รายละเอียด
                    </div>
                </Td>

                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >

                    {(_transport_assignment?.invoice_number) ? (
                        <div className="font-semibold">
                            {_transport_assignment?.invoice_number}
                        </div>
                    ) : (
                        <div className="font-semibold text-center">
                            -
                        </div>
                    )}

                </Td>

                <Td
                    className="whitespace-normal"
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    <div className="whitespace-nowrap font-semibold">
                        {' '}
                        {_transport_assignment.shipper.customer_company}
                    </div>
                    <div className="text-xs">
                        {_transport_assignment?.shipper_address +
                            ' ' +
                            _transport_assignment?.shipper_postcode}{' '}
                    </div>
                </Td>
                <Td
                    className="whitespace-normal"
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    <div className="whitespace-nowrap font-semibold">
                        {' '}
                        {_transport_assignment.consignee.customer_company}
                    </div>
                    <div className="text-xs ">
                        {_transport_assignment?.consignee_address +
                            ' ' +
                            _transport_assignment?.consignee_postcode}
                    </div>
                </Td>
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {(_transport_assignment?.vehicle?.serial_number) ? (
                        <div className="font-semibold text-center">
                            {_transport_assignment?.vehicle?.serial_number}
                        </div>
                    ) : (
                        <div className="font-semibold text-center">
                            -
                        </div>
                    )}

                </Td>
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {(_transport_assignment?.container_size) ? (
                        <div className="text-xs text-center">
                            {_transport_assignment?.container_size}
                        </div>
                    ) : (
                        <div className="text-xs text-center">
                            -
                        </div>
                    )}

                </Td>
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    {(_transport_assignment?.number_of_packages) ? (
                        <div className="text-xs text-center">
                            {_transport_assignment?.number_of_packages}
                        </div>
                    ) : (
                        <div className="text-xs text-center">
                            -
                        </div>
                    )}

                </Td>
                <Td
                    onClick={() =>
                        handleOpenAssignmentModal(
                            _transport_assignment
                        )
                    }
                >
                    <div className="text-xs text-center">
                        {_transport_assignment?.load_weight} {' กิโลกรัม '}
                    </div>
                </Td>
                <Td>
                    {handleReadyAssignment && (
                        <Button
                            className="mr-2"
                            colorScheme={'green'}
                            size={'sm'}
                            onClick={() => {
                                handleReadyAssignment(
                                    _transport_assignment?.assignment_id
                                );
                            }}
                        >
                            <div className="text-xs">พร้อม</div>
                        </Button>
                    )}
                    {_transport_assignment?.assignment_status ===
                        ASSIGNMENT_STATUS[4] ||
                        _transport_assignment?.assignment_status ===
                        ASSIGNMENT_STATUS[0] ? (
                        <Button
                            colorScheme={'red'}
                            size={'sm'}
                            onClick={() => {
                                handleDeleteAssignment(
                                    _transport_assignment
                                );
                            }}
                        >
                            <div className="text-xs">ยกเลิก</div>
                        </Button>
                    ) : (
                        <div>
                            <Button
                                colorScheme={'red'}
                                size={'sm'}
                                disabled
                            >
                                <div className="text-xs">ยกเลิก</div>
                            </Button>
                        </div>
                    )}
                </Td>
            </Tr>
        ));
    };
    return (
        <div className="flex-auto">
            <Table
                className="bg-white   break-words rounded-sm "
                variant="simple"
                size="sm"
            >
                <Thead className="whitespace-nowrap">
                    <Tr>
                        <Th>ลำดับ</Th>
                        <Th>วันรับ</Th>
                        <Th>วันส่ง</Th>
                        <Th>สถานะ</Th>
                        <Th>เลขที่ใบแจ้งหนี้</Th>
                        <Th>ผู้ส่ง</Th>
                        <Th>ผู้รับ</Th>
                        <Th>หมายเลขคอนเทนเนอร์</Th>
                        <Th>ขนาด</Th>
                        <Th>พาเลท/หีบห่อ</Th>
                        <Th>น้ำหนักรวม</Th>
                        <Th>ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTd()}</Tbody>
            </Table>
        </div>
    );
}
