import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/tailwind.css';

// Overide Axios Request
import axios from 'axios';

//PrivateRoute Contexts
import PrivateRoute from 'contexts/PrivateRoute';
//AuthProvider Contexts
import AuthProvider from './contexts/AuthContext';

//Home or Portal Menu
import Home from 'views/Home';

// layouts
import Auth from 'layouts/Auth.js';
import ME from 'layouts/ME.js';
import HRMS from 'layouts/HRMS.js';
import CRM from 'layouts/CRM.js';
import TMS from 'layouts/TMS';
import FMS from 'layouts/FMS';
import CMMS from 'layouts/CMMS';
import System from 'layouts/System';

import { ChakraProvider } from '@chakra-ui/react';

const store = configureStore();
if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
}

axios.interceptors.request.use((req) => {
    // Add Access token to Axios Request
    req.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'accesstoken_erp'
    )}`;
    return req;
});

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <AuthProvider>
                <ChakraProvider>
                    <div className="font-sans">
                        <Switch>
                            {/* add routes with layouts */}
                            <Route path="/auth" component={Auth} />
                            {/*PrivateRoute*/}
                            <PrivateRoute>
                                <Route exact path="/" component={Home} />
                                <Route path="/data" component={ME} />
                                <Route path="/hrms" component={HRMS} />
                                <Route path="/crm" component={CRM} />
                                <Route path="/fms" component={FMS} />
                                <Route path="/tms" component={TMS} />
                                <Route path="/cmms" component={CMMS} />
                                <Route path="/system" component={System} />
                            </PrivateRoute>
                            {/* add redirect for NotFound page */}
                            <Redirect from="*" to="/auth" />
                        </Switch>
                    </div>
                </ChakraProvider>
            </AuthProvider>
        </Provider>
    </Router>,
    document.getElementById('root')
);
