import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import SpinnerLoading from 'components/Loading/SpinnerLoading';
import * as actions from '../../../redux/actions';

export default function CreateCategory() {
    const { register, handleSubmit, errors, reset } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        return () => {};
    }, []);

    const onSubmit = (data, e) => {
        dispatch(actions.category_vehiclePost(data)).then(() => {
            alert('บันทึกสำเร็จ');
            reset();
        });
    };

    return isLoading ? (
        <div>
            <div>
                <h2 className="text-gray-800 text-xl font-semibold">
                    สร้างหมวดหมู่รถ
                </h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4 justify-between">
                    <div>
                        <Link to="/fms/categories">
                            <Button variant="outline">กลับ</Button>
                        </Link>
                    </div>
                    {/* <div>
                        <Button type="submit" colorScheme="blue">
                            บันทึก
                        </Button>
                    </div> */}
                </div>
                <div className="p-4">
                    <div className="flex flex-wrap content-center items-center justify-center h-full">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    ชื่อหมวดหมู่{' '}
                                    <label className="text-red-600 text-xs">
                                        {' '}
                                        *
                                    </label>
                                </label>
                                <input
                                    name="category_name"
                                    required
                                    ref={register}
                                    placeholder="ชื่อหมวดหมู่"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></input>
                            </div>
                            <div className="relative w-full mb-3">
                                <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    รายละเอียด
                                </label>
                                <textarea
                                    name="category_detail"
                                    required
                                    ref={register}
                                    placeholder="รายละเอียดหมวดหมู่"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ></textarea>
                            </div>
                            <div className="text-center mt-6">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    type="submit"
                                >
                                    บันทึก
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <>
            <SpinnerLoading />
        </>
    );
}
