import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/";
import { useForm } from "react-hook-form";
import ImageUploader from "components/ImageUpload/ImageUpload";
import { uploadImageFile } from "util/image.functions";
import { Button } from "@chakra-ui/react";

import { BUSINESS_TYPE, CUSTOMER_TYPE } from "../../../config/CustomerConfig";

import _, { size } from "lodash";
import hash from "object-hash";

export default function CreateCustomer() {
  const { register, handleSubmit, reset, errors } = useForm();
  const [picture, setPicture] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const onSubmit = async (data, e) => {
    if (size(picture)) {
      const filename = hash({ picture: picture, date: new Date() });
      uploadImageFile(filename, picture[0].data_url).then((url) => {
        console.log("URL : " + url);
        data.customer_logo = url;
        dispatch(actions.customerPost(data)).then(() => {
          reset();
          setPicture([]);
          alert("บันทึกข้อมูลลูกค้าสำเร็จ");
        });
      });
    } else {
      dispatch(actions.customerPost(data)).then(() => {
        reset();
        setPicture([]);
        alert("บันทึกข้อมูลลูกค้าสำเร็จ");
      });
    }
  };
  const genBusinessType = () =>
    _.map(BUSINESS_TYPE, (type, index) => (
      <option key={index} value={type}>
        {type}
      </option>
    ));

  const genCustomerType = () =>
    _.map(CUSTOMER_TYPE, (type, index) => (
      <option key={index} value={type}>
        {type}
      </option>
    ));
  return (
    <>
      <div>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full">
            <div className="relative flex flex-col min-w-0 break-words w-full ">
              <div>
                <h2 className="text-gray-800 text-xl font-semibold py-1">
                  เพิ่มข้อมูลลูกค้า
                </h2>
              </div>
              <div className="flex py-6 justify-between">
                <Button variant="outline" onClick={() => { history.goBack() }}>กลับ</Button>
              </div>
              <div className="flex content-center items-center justify-center h-full mb-12">
                <div className="w-full lg:w-6/12 px-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap ">
                      <div className="w-full px-4">
                        <h6 className="text-gray-500 text-xs mt-3 mb-6 font-bold uppercase">
                          ข้อมูลธุรกิจ
                        </h6>
                        <div className="flex flex-wrap">
                          <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ประเภทธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <select
                                name="customer_business_type"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {genBusinessType()}
                              </select>
                            </div>
                          </div>
                          <div className="w-full lg:w-6/12 px-4">
                            <div className="relative w-full mt-6">
                              <select
                                name="customer_type"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                              >
                                {genCustomerType()}
                              </select>
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ชื่อธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <input
                                name="customer_company"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ชื่อธุรกิจ"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                เลขที่ผู้เสียภาษี
                          </label>
                              <input
                                name="customer_taxes_id"
                                type="text"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เลขที่ผู้เสียภาษี"
                              />
                            </div>
                          </div>

                          <div className="w-full  px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                เบอร์ธุรกิจ <label className="text-red-600 text-xs"> *</label>
                          </label>
                              <input
                                name="customer_phone_number"
                                type="text"
                                required
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="เบอร์ธุรกิจ"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                ที่อยู่
                              </label>
                              <textarea
                                name="customer_address"
                                ref={register}
                                rows="2"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="ที่อยู่"
                              />
                            </div>
                          </div>
                          <div className="w-full  px-4">
                            <div className="relative w-full  mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                รหัสไปรษณีย์
                              </label>
                              <input
                                name="customer_postcode"
                                ref={register}
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-xs shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                placeholder="รหัสไปรษณีย์"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-full  px-4">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            โลโก้
                          </label>
                          <div className="rounded-lg  bg-white p-4  mt-3 mb-6">
                            <ImageUploader
                              images={picture}
                              setImages={setPicture}
                            />
                          </div>
                        </div>
                        <div className="text-center px-4 gap-0 py-3">
                          <Button
                            isFullWidth
                            colorScheme="blue"
                            size="sm"
                            variant="solid"
                            type="submit"
                          >
                            บันทึก
                          </Button>
                        </div>
                      </div>

                    </div>{" "}


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
