import React from 'react';
import PropTypes from 'prop-types';
import { VEHICLE_STATUS } from 'config/constant/VehicleStatus';

export default function CardVehicleDashbroad({
    serial_number,
    status,
    vehicle_image,
    category_name,
    sub_category_name,
}) {
    return (
        <div className="items-center p-4 bg-white rounded-lg  shadow-xl dark:bg-gray-800">
            <div className="flex justify-center object-cover h-48 w-full">
                {vehicle_image ? (
                    <>
                        {' '}
                        <img alt="..." src={vehicle_image} />
                    </>
                ) : (
                    <>
                        {' '}
                        <img alt="..." src={require('assets/img/truck.png')} />
                    </>
                )}
            </div>
            <div className="my-2">
                <p className="mb-2 text-gray-700  text-center text-sm">
                    {serial_number}
                </p>

                <p className="text-sm text-gray-500 text-center">
                    {'ประเภท : ' + category_name + ' , ' + sub_category_name}
                </p>
                {status === VEHICLE_STATUS[0] ? (
                    <div className="text-sm  text-red-600 text-center">
                        <label className=" text-gray-600 ">
                            {'สถานะ : '}
                        </label>
                        {status}
                    </div>
                ) : (
                    <div className="text-sm  text-green-600 text-center">
                        <label className=" text-gray-600 ">
                            {'สถานะ : '}
                        </label>
                        {status}
                    </div>
                )}
            </div>
        </div>
    );
}

CardVehicleDashbroad.defaultProps = {
    serial_number: 'ป้ายทะเบียน',
    status: 'สถานะ',
    vehicle_image: 'รูปภาพรถ',
    category_name: 'ประเภทรถ',
    sub_category_name: 'หมวดหมู่ย่อย',
};

CardVehicleDashbroad.propTypes = {
    serial_number: PropTypes.string,
    status: PropTypes.string,
    vehicle_image: PropTypes.string,
    category_name: PropTypes.string,
    sub_category_name: PropTypes.string,
};
