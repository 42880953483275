import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";

// components

import Sidebar from "components/common/Sidebar/Sidebar-FMS";
import FooterAdmin from "components/common/Footers/FooterAdmin.js";

// views
import VehiclesManagement from "views/fms/vehicles-management/VehiclesManagement";
import VehicleDriverManagement from "views/fms/vehicleDriver-management/VehicleDriverManagement";
import CreateVehicle from "views/fms/createVehicle/CreateVehicle";
import EditVehicle from "views/fms/editVehicle/EditVehicle";
import DetailVehicle from "views/fms/detailsVehicle/DetailVehicle";
import Categories from "views/fms/categories/Categories";
import CreateCategory from "views/fms/createCategory/CreateCategory";
import EditCategory from "views/fms/editCategory/EditCategory";
import DetailCategory from "views/fms/detailCategory/DetailCategory";
import MatchVehicleAndDriver from "views/fms/matchVehicleAndDriver/MatchVehicleAndDriver";
import EditVehicleDriver from "views/fms/editVehicleDriver/EditVehicleDriver";
import RouteManagement from "views/fms/route-management/RouteManagement";
import CreateRoute from "views/fms/createRoute/CreateRoute";
import CreatePath from "views/fms/createRoute/CreatePath";
import DetailRoute from "views/fms/detailRoute/DetailRoute";
import EditRoute from "views/fms/editRoute/EditRoute";
import EditPath from "views/fms/editRoute/EditPath";

//components loading
import SpinnerLoading from "components/Loading/SpinnerLoading";
//functions
import checkRole from "../util/checkroles";
import CreateSubCategory from "views/fms/createSubcategory/CreateSubCategory";
import EditSubCategory from "views/fms/editSubcategory/EditSubCategory";

export default function FMS() {
  const location = useLocation();
  const history = useHistory();
  const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
  const system_info = useSelector((state) => state.system_info);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("FMS Layout : ACTIVE");
    dispatch(actions.systemInfoAll()).then(() => {
      dispatch(actions.meGet(storage_remember)).then(() => { });
    });
    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me && system_info && system_info.isLoading === true) {
      const isAccess = checkRole(
        me?.position?.department?.department_roles,
        location.pathname
      );
      console.log(isAccess);
      if (isAccess) {
        setIsLoading(true);
        console.log("ได้รับการอนุญาตเข้าถึง");
      } else {
        console.log("ไม่ได้รับอนุญาต");
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้");
        history.push("/");
      }
    }
    return () => { };
  }, [me]);

  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full max-w-full flex-grow flex-1">
                  <h6 className="uppercase text-gray-500 text-xs font-semibold">
                    บริหารจัดการรถ
                  </h6>
                </div>
              </div>
            </div>
            <div className="px-4">
              <Switch>
                <Route path="/fms/categories" exact component={Categories} />

                <Route
                  path="/fms/category/create"
                  exact
                  component={CreateCategory}
                />
                <Route
                  path="/fms/category/edit/:id"
                  exact
                  component={EditCategory}
                />
                <Route
                  path="/fms/category/detail/:id"
                  exact
                  component={DetailCategory}
                />
                <Route
                  path={`/fms/category/:id/subcategory/create`}
                  exact
                  component={CreateSubCategory}
                />
                <Route
                  path={`/fms/category/:id/subcategory/edit`}
                  exact
                  component={EditSubCategory}
                />
                {/* เกี่ยวกับรถ หาง และตู้ */}

                <Route
                  path="/fms/category/:id/vehicle"
                  exact
                  component={VehiclesManagement}
                />
                <Route
                  path="/fms/vehicle/driver"
                  exact
                  component={VehicleDriverManagement}
                />
                <Route
                  path="/fms/category/:id/vehicle/create"
                  exact
                  component={CreateVehicle}
                />
                <Route
                  path="/fms/category/:id/vehicle/edit/:vehicle_id"
                  exact
                  component={EditVehicle}
                />
                <Route
                  path="/fms/category/:id/vehicle/detail/:vehicle_id"
                  exact
                  component={DetailVehicle}
                />
                <Route
                  path="/fms/vehicle/match/driver"
                  exact
                  component={MatchVehicleAndDriver}
                />
                <Route
                  path="/fms/vehicle/driver/edit/:id"
                  exact
                  component={EditVehicleDriver}
                />
                <Route
                  path="/fms/route/management"
                  exact
                  component={RouteManagement}
                />
                <Route
                  path="/fms/route/create"
                  exact
                  component={CreateRoute}
                />
                <Route
                  path="/fms/route/detail/:id"
                  exact
                  component={DetailRoute}
                />
                <Route
                  path="/fms/route/detail/:id/edit"
                  exact
                  component={EditRoute}
                />
                <Route
                  path="/fms/route/detail/:id/create/path"
                  exact
                  component={CreatePath}
                />
                <Route
                  path="/fms/route/detail/:id/edit/path/:pathId"
                  exact
                  component={EditPath}
                />
                <Redirect from="/fms" to="/fms/categories" />
              </Switch>
            </div>
            <div />
          </div>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  );
}
