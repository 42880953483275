import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    Spinner,
    Button,
    ButtonGroup,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/th';
import { Link } from 'react-router-dom';

import DelayedNotifyModal from './NotifyModal';
import * as actions from '../../../redux/actions';

export default function InfoNotifyModal({
    openStatus,
    closeHandle,
    notifyData,
    showedOnModalMileage,
    selectedVehicle,
}) {
    const dispatch = useDispatch();
    const [isShowDelayNotifyModal, setIsShowDelayNotifyModal] = useState(false);

    const handleDeleteNotification = () => {
        const confirm = window.confirm('ยืนยันการลบการแจ้งเตือน');
        if (confirm) {
            closeHandle();
            dispatch(actions.notifyDelete(notifyData?.notify_id)).then(() => {
                dispatch(actions.vehicleAllByHeadAndTail());
            });
        }
    };

    return (
        <div>
            <Modal isOpen={openStatus} onClose={closeHandle} size="xl">
                <ModalOverlay />
                <DelayedNotifyModal
                    closeHandle={() => {
                        setIsShowDelayNotifyModal(false);
                    }}
                    openStatus={isShowDelayNotifyModal}
                    modalMode="edit"
                    notifyData={notifyData}
                    vehicle={selectedVehicle}
                    handleParentClose={closeHandle}
                />
                <ModalContent>
                    <ModalHeader className="font-sans text-base">
                        แก้ไขการนัดหมาย
                    </ModalHeader>
                    <ModalCloseButton />
                    {notifyData ? (
                        <ModalBody className="p-4 m-4 mt-0 pb-6 font-sans">
                            <h6 className="text-sm font-bold mt-2">
                                งานที่นัดหมาย
                            </h6>
                            <h3> {notifyData?.title} </h3>

                            <h6 className="text-sm font-bold mt-3">
                                กำหนดการซ่อม
                            </h6>

                            {notifyData?.goal_mileage !== '-' && (
                                <div className="my-3">
                                    <h5>
                                        <i className="fas fa-tachometer-alt" />{' '}
                                        เลขไมล์ {notifyData?.goal_mileage} km.
                                    </h5>
                                    <p>
                                        {showedOnModalMileage -
                                            notifyData?.goal_mileage <
                                        0 ? (
                                            <div>
                                                เหลืออีก{' '}
                                                {Math.abs(
                                                    showedOnModalMileage -
                                                        notifyData?.goal_mileage
                                                )}{' '}
                                                km.
                                            </div>
                                        ) : (
                                            <div className="text-red-500 text-sm">
                                                เกินไป{' '}
                                                {Math.abs(
                                                    showedOnModalMileage -
                                                        notifyData?.goal_mileage
                                                )}{' '}
                                                km.
                                            </div>
                                        )}
                                    </p>
                                </div>
                            )}

                            {notifyData?.goal_date !== '-' && (
                                <div className="my-3">
                                    <h5>
                                        <i className="fas fa-calendar-alt" />{' '}
                                        นัดแจ้งเตือน{' '}
                                        {moment(notifyData?.goal_date).format(
                                            'll'
                                        )}
                                    </h5>
                                    {moment().isBefore(
                                        moment(notifyData?.goal_date)
                                    ) ? (
                                        <h5 className="text-green-500">
                                            เหลืออีก{' '}
                                            {Math.abs(
                                                moment
                                                    .duration(
                                                        new moment().diff(
                                                            new moment(
                                                                notifyData?.goal_date
                                                            )
                                                        )
                                                    )
                                                    .days()
                                            )}{' '}
                                            วัน
                                        </h5>
                                    ) : (
                                        <h5 className="text-red-500">
                                            เกินไป{' '}
                                            {Math.abs(
                                                moment
                                                    .duration(
                                                        new moment().diff(
                                                            new moment(
                                                                notifyData?.goal_date
                                                            )
                                                        )
                                                    )
                                                    .days()
                                            )}{' '}
                                            วัน
                                        </h5>
                                    )}
                                </div>
                            )}
                            <div className="flex justify-end gap-2">
                                <ButtonGroup
                                    isAttached
                                    colorScheme="blue"
                                    size="sm"
                                >
                                    <Menu colorScheme="blue">
                                        <MenuButton
                                            as={Button}
                                            rightIcon={
                                                <i className="fas fa-chevron-down" />
                                            }
                                        >
                                            แจ้งซ่อม
                                        </MenuButton>

                                        <MenuList>
                                            <Link
                                                to={`/cmms/repair/vehicle/create/admin`}
                                            >
                                                <MenuItem>แจ้งซ่อมรถ</MenuItem>
                                            </Link>
                                            <Link to="/cmms/repair/wheel/create/admin">
                                                <MenuItem>แจ้งซ่อมยาง</MenuItem>
                                            </Link>
                                        </MenuList>
                                    </Menu>
                                </ButtonGroup>

                                <Button
                                    colorScheme="yellow"
                                    size="sm"
                                    color="white"
                                    onClick={() =>
                                        setIsShowDelayNotifyModal(true)
                                    }
                                >
                                    เลื่อนกำหนด
                                </Button>
                                <Button
                                    colorScheme="red"
                                    size="sm"
                                    onClick={handleDeleteNotification}
                                >
                                    ลบ
                                </Button>
                                <Button
                                    colorScheme="gray"
                                    size="sm"
                                    onClick={closeHandle}
                                >
                                    ยกเลิก
                                </Button>
                            </div>
                        </ModalBody>
                    ) : (
                        <Spinner />
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}
