// Common Module
export const ME_GET = 'ME_GET';
export const ME_RESET = 'ME_RESET';

// HRMS Module

export const EMPLOYEE_ALL = 'EMPLOYEE_ALL';
export const EMPLOYEE_ALL_BY_DELIVERY = 'EMPLOYEE_ALL_BY_TRANSPORT_DELIVERY';
export const EMPLOYEE_ALL_BY_STATUS = 'EMPLOYEE_ALL_BY_TRANSPORT_STATUS';
export const EMPLOYEE_ALL_BY_DEPARTMENT = 'EMPLOYEE_ALL_BY_DEPARTMENT';
export const EMPLOYEE_GET = 'EMPLOYEE_GET';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PUT = 'EMPLOYEE_PUT';
export const EMPLOYEE_DEL = 'EMPLOYEE_DEL';

export const DEPARTMENT_ALL = 'DEPARTMENT_ALL';
export const DEPARTMENT_GET = 'DEPARTMENT_GET';
export const DEPARTMENT_POST = 'DEPARTMENT_POST';
export const DEPARTMENT_PUT = 'DEPARTMENT_PUT';
export const DEPARTMENT_DEL = 'DEPARTMENT_DEL';

export const POSITION_ALL = 'POSITION_ALL';
export const POSITION_ALL_BY_DEPARTMENT = 'POSITION_ALL_BY_DEPARTMENT';
export const POSITION_GET = 'POSITION_GET';
export const POSITION_POST = 'POSITION_POST';
export const POSITION_PUT = 'POSITION_PUT';
export const POSITION_DEL = 'POSITION_DEL';
export const POSITION_DEL_BY_DEPARTMENT = 'POSITION_DEL_BY_DEPARTMENT';

//CRM Module
export const CUSTOMER_ALL = 'CUSTOMER_ALL';
export const CUSTOMER_GET = 'CUSTOMER_GET';
export const CUSTOMER_POST = 'CUSTOMER_POST';
export const CUSTOMER_PUT = 'CUSTOMER_PUT';
export const CUSTOMER_DEL = 'CUSTOMER_DEL';

export const CONTACT_ALL = 'CONTACT_ALL';
export const CONTACT_ALL_BY_CUSTOMER = 'CONTACT_ALL_BY_CUSTOMER';
export const CONTACT_GET = 'CONTACT_GET';
export const CONTACT_POST = 'CONTACT_POST';
export const CONTACT_PUT = 'CONTACT_PUT';
export const CONTACT_DEL = 'CONTACT_DEL';

export const NOTE_ALL_BY_CUSTOMER = 'NOTE_ALL_BY_CUSTOMER';
export const NOTE_GET = 'NOTE_GET';
export const NOTE_POST = 'NOTE_POST';
export const NOTE_PUT = 'NOTE_PUT';
export const NOTE_DEL = 'NOTE_DEL';
export const NOTE_DEL_BY_CUSTOMER = 'NOTE_DEL_BY_CUSTOMER';

//CMMS Module
export const MILEAGE_ALL = 'MILEAGE_ALL';
export const MILEAGE_ALL_BY_VEHICLE = 'MILEAGE_ALL_BY_VEHICLE';
export const MILEAGE_GET = 'MILEAGE_GET';
export const MILEAGE_GET_LATEST = 'MILEAGE_GET_LATEST';
export const MILEAGE_ALL_LATEST = 'MILEAGE_ALL_LATEST';
export const MILEAGE_POST = 'MILEAGE_POST';
export const MILEAGE_PUT = 'MILEAGE_PUT';
export const MILEAGE_DEL = 'MILEAGE_DEL';

export const PROBLEM_ALL = 'PROBLEM_ALL';
export const PROBLEM_GET = 'PROBLEM_GET';
export const PROBLEM_POST = 'PROBLEM_POST';
export const PROBLEM_PUT = 'PROBLEM_PUT';
export const PROBLEM_DEL = 'PROBLEM_DEL';

export const REPAIR_WHEEL_ALL = 'REPAIR_WHEEL_ALL';
export const REPAIR_WHEEL_GET = 'REPAIR_WHEEL_GET';
export const REPAIR_WHEEL_POST = 'REPAIR_WHEEL_POST';
export const REPAIR_WHEEL_PUT = 'REPAIR_WHEEL_PUT';
export const REPAIR_WHEEL_DEL = 'REPAIR_WHEEL_DEL';

export const REPAIR_TYPE_ALL = 'REPAIR_TYPE_ALL';
export const REPAIR_TYPE_GET = 'REPAIR_TYPE_GET';
export const REPAIR_TYPE_POST = 'REPAIR_TYPE_POST';
export const REPAIR_TYPE_PUT = 'REPAIR_TYPE_PUT';
export const REPAIR_TYPE_DEL = 'REPAIR_TYPE_DEL';
export const REPAIR_TYPE_ALL_BY_STATUS = 'REPAIR_TYPE_ALL_BY_STATUS';

export const REPAIR_ALL_VEHICLE = 'REPAIR_ALL_VEHICLE';
export const REPAIR_ALL_WHEEL = 'REPAIR_ALL_WHEEL';
export const REPAIR_ALL_VEHICLE_BY_STATUS = 'REPAIR_ALL_VEHICLE_BY_STATUS';
export const REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE =
    'REPAIR_ALL_VEHICLE_BY_SUB_VEHICLE';
export const REPAIR_ALL_WHEEL_BY_STATUS = 'REPAIR_ALL_WHEEL_BY_STATUS';
export const REPAIR_GET_VEHICLE = 'REPAIR_GET_VEHICLE';
export const REPAIR_GET_WHEEL = 'REPAIR_GET_WHEEL';
export const REPAIR_POST_VEHICLE = 'REPAIR_POST_VEHICLE';
export const REPAIR_POST_WHEEL = 'REPAIR_POST_WHEEL';
export const REPAIR_PUT = 'REPAIR_PUT';
export const REPAIR_DEL = 'REPAIR_DEL';

export const NOTIFY_ALL = 'NOTIFY_ALL';
export const NOTIFY_GET = 'NOTIFY_GET';
export const NOTIFY_POST = 'NOTIFY_POST';
export const NOTIFY_PUT = 'NOTIFY_PUT';
export const NOTIFY_DEL = 'NOTIFY_DEL';
export const NOTIFY_ALL_ACTIVE = 'NOTIFY_ALL_ACTIVE';
export const NOTIFY_ALL_DELAY = 'NOTIFY_ALL_DELAY';
export const NOTIFY_BY_EMPLOYEE = 'NOTIFY_BY_EMPLOYEE';
export const NOTIFY_BY_VEHICLE = 'NOTIFY_BY_VEHICLE';

export const TYPE_ALL = 'TYPE_ALL';
export const TYPE_GET = 'TYPE_GET';
export const TYPE_POST = 'TYPE_POST';
export const TYPE_PUT = 'TYPE_PUT';
export const TYPE_DEL = 'TYPE_DEL';

//FMS Module
export const CATEGORY_VEHICLE_ALL = 'CATEGORY_VEHICLE_ALL';
export const CATEGORY_VEHICLE_GET = 'CATEGORY_VEHICLE_GET';
export const CATEGORY_VEHICLE_POST = 'CATEGORY_VEHICLE_POST';
export const CATEGORY_VEHICLE_PUT = 'CATEGORY_VEHICLE_PUT';
export const CATEGORY_VEHICLE_DEL = 'CATEGORY_VEHICLE_DEL';

export const SUB_CATEGORY_VEHICLE_ALL = 'SUB_CATEGORY_VEHICLE_ALL';
export const SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY =
    'SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY';
export const SUB_CATEGORY_VEHICLE_GET = 'SUB_CATEGORY_VEHICLE_GET';
export const SUB_CATEGORY_VEHICLE_POST = 'SUB_CATEGORY_VEHICLE_POST';
export const SUB_CATEGORY_VEHICLE_PUT = 'SUB_CATEGORY_VEHICLE_PUT';
export const SUB_CATEGORY_VEHICLE_DEL = 'SUB_CATEGORY_VEHICLE_DEL';

export const VEHICLE_ALL = 'VEHICLE_ALL';
export const VEHICLE_ALL_BY_DELIVERY = 'VEHICLE_ALL_BY_TRANSPORT_ASSIGNMENT';
export const VEHICLE_ALL_BY_CATEGORY = 'VEHICLE_ALL_BY_CATEGORY';
export const VEHICLE_ALL_BY_STATUS = 'VEHICLE_ALL_BY_STATUS';
export const VEHICLE_ALL_BY_HEAD_AND_TAIL = 'VEHICLE_ALL_BY_HEAD_AND_TAIL';
export const VEHICLE_GET = 'VEHICLE_GET';
export const VEHICLE_POST = 'VEHICLE_POST';
export const VEHICLE_PUT = 'VEHICLE_PUT';
export const VEHICLE_DEL = 'VEHICLE_DEL';

export const CAR_OWNER_ALL = 'CAR_OWNER_ALL';
export const CAR_OWNER_GET = 'CAR_OWNER_GET';
export const CAR_OWNER_GET_BY_DRIVER = 'CAR_OWNER_GET_BY_DRIVER';
export const CAR_OWNER_POST = 'CAR_OWNER_POST';
export const CAR_OWNER_PUT = 'CAR_OWNER_PUT';
export const CAR_OWNER_DEL = 'CAR_OWNER_DEL';

export const ROUTE_ALL = 'ROUTE_ALL';
export const ROUTE_GET = 'ROUTE_GET';
export const ROUTE_POST = 'ROUTE_POST';
export const ROUTE_PUT = 'ROUTE_PUT';
export const ROUTE_DEL = 'ROUTE_DEL';

export const PATH_ALL = 'PATH_ALL';
export const PATH_GET = 'PATH_GET';
export const PATH_POST = 'PATH_POST';
export const PATH_PUT = 'PATH_PUT';
export const PATH_DEL = 'PATH_DEL';

//Sales Module
export const QUOTATION_ALL = 'QUOTATION_ALL';
export const QUOTATION_GET = 'QUOTATION_GET';
export const QUOTATION_POST = 'QUOTATION_POST';
export const QUOTATION_PUT = 'QUOTATION_PUT';
export const QUOTATION_DEL = 'QUOTATION_DEL';

//MRP Module
export const SALES_ORDER_ALL = 'SALES_ORDER_ALL';
export const SALES_ORDER_GET = 'SALES_ORDER_GET';
export const SALES_ORDER_GET_BY_DELIVERY =
    'SALES_ORDER_GET_BY_TRANSPORT_DELIVERY';
export const SALES_ORDER_POST = 'SALES_ORDER_POST';
export const SALES_ORDER_PUT = 'SALES_ORDER_PUT';
export const SALES_ORDER_DEL = 'SALES_ORDER_DEL';

export const PRODUCT_ALL = 'PRODUCT_ALL';
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_POST = 'PRODUCT_POST';
export const PRODUCT_PUT = 'PRODUCT_PUT';
export const PRODUCT_DEL = 'PRODUCT_DEL';

export const PROCESS_ALL = 'PROCESS_ALL';
export const PROCESS_ALL_BY_PRODUCT = 'PROCESS_ALL_BY_PRODUCT';
export const PROCESS_GET = 'PROCESS_GET';
export const PROCESS_POST = 'PROCESS_POST';
export const PROCESS_PUT = 'PROCESS_PUT';
export const PROCESS_DEL = 'PROCESS_DEL';

export const CATEGORY_ALL = 'CATEGORY_ALL';
export const CATEGORY_GET = 'CATEGORY_GET';
export const CATEGORY_POST = 'CATEGORY_POST';
export const CATEGORY_PUT = 'CATEGORY_PUT';
export const CATEGORY_DEL = 'CATEGORY_DEL';

export const SUB_CATEGORY_ALL = 'SUB_CATEGORY_ALL';
export const SUB_CATEGORY_GET = 'SUB_CATEGORY_GET';
export const SUB_CATEGORY_POST = 'SUB_CATEGORY_POST';
export const SUB_CATEGORY_PUT = 'SUB_CATEGORY_PUT';
export const SUB_CATEGORY_DEL = 'SUB_CATEGORY_DEL';
export const SUB_CATEGORY_ALL_BY_CATEGORY = 'SUB_CATEGORY_ALL_BY_CATEGORY';

//TMS

export const ASSIGNMENT_ALL = 'ASSIGNMENT_ALL';
export const ASSIGNMENT_GET = 'ASSIGNMENT_GET';
export const ASSIGNMET_POST = 'ASSIGNMET_POST';
export const ASSIGNMENT_PUT = 'ASSIGNMENT_PUT';
export const ASSIGNMENT_DEL = 'ASSIGNMENT_DEL';

export const TRANSPORT_ASSIGNMENT_ALL = 'TRANSPORT_ASSIGNMENT_ALL';
export const TRANSPORT_ASSIGNMENT_GET = 'TRANSPORT_ASSIGNMENT_GET';
export const TRANSPORT_ASSIGNMENT_GET_BY_DRIVER =
    'TRANSPORT_ASSIGNMENT_GET_BY_DRIVER';
export const TRANSPORT_ASSIGNMENT_GET_BY_STATUS =
    'TRANSPORT_ASSIGNMENT_GET_BY_STATUS';
export const TRANSPORT_ASSIGNMENT_GET_BY_PROCESSING_STATUS =
    'TRANSPORT_ASSIGNMENT_GET_PROCESSING_BY_STATUS';
export const TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE =
    'TRANSPORT_ASSIGNMENT_GET_BY_ASSIGNMENT_TYPE';
export const TRANSPORT_ASSIGNMENT_POST = 'TRANSPORT_ASSIGNMENT_POST';
export const TRANSPORT_ASSIGNMENT_PUT = 'TRANSPORT_ASSIGNMENT_PUT';
export const TRANSPORT_ASSIGNMENT_DEL = 'TRANSPORT_ASSIGNMENT_DEL';

export const DELIVERY_ALL = 'DELIVERY_ALL';
export const DELIVERY_GET = 'DELIVERY_GET';
export const DELIVERY_GET_BY_DRIVER = 'DELIVERY_GET_BY_DRIVER';
export const DELIVERY_GET_BY_STATUS = 'DELIVERY_GET_BY_STATUS';
export const DELIVERY_GET_BY_PROCESSING_STATUS =
    'DELIVERY_GET_BY_PROCESSING_STATUS';
export const DELIVERY_GET_INVERT_TYPE = 'DELIVERY_GET_INVERT_TYPE';
export const DELIVERY_POST = 'DELIVERY_POST';
export const DELIVERY_PUT = 'DELIVERY_PUT';
export const DELIVERY_PUT_STATUS = 'DELIVERY_PUT_STATUS';
export const DELIVERY_DEL = 'DELIVERY_DEL';

//System Module

export const SYSTEM_INFO_ALL = 'SYSTEM_INFO_ALL';
export const SYSTEM_INFO_GET = 'SYSTEM_INFO_GET';
export const SYSTEM_INFO_POST = 'SYSTEM_INFO_POST';
export const SYSTEM_INFO_PUT = 'SYSTEM_INFO_PUT';
export const SYSTEM_INFO_DEL = 'SYSTEM_INFO_DEL';

export const COMPANY_ALL = 'COMPANY_ALL';
export const COMPANY_GET = 'COMPANY_GET';
export const COMPANY_POST = 'COMPANY_POST';
export const COMPANY_PUT = 'COMPANY_PUT';
export const COMPANY_DEL = 'COMPANY_DEL';

// LOG
export const REPAIR_LOG_ALL = 'REPAIR_LOG_ALL';
export const REPAIR_LOG_ALL_VEHICLE = 'REPAIR_LOG_ALL_VEHICLE';
export const REPAIR_LOG_VEHICLE_BY_ID = 'REPAIR_LOG_VEHICLE_BY_ID';
export const REPAIR_LOG_ALL_VEHICLE_BY_ID = 'REPAIR_LOG_ALL_VEHICLE_BY_ID';
export const REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL =
    'REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL';
export const REPAIR_LOG_ALL_WHEEL = 'REPAIR_LOG_ALL_WHEEL';
export const REPAIR_LOG_ALL_WHEEL_BY_ID = 'REPAIR_LOG_ALL_WHEEL_BY_ID';
export const REPAIR_LOG_WHEEL_BY_ID = 'REPAIR_LOG_WHEEL_BY_ID';
export const REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL =
    'REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL';
export const REPAIR_LOG_POST = 'REPAIR_LOG_POST';
export const REPAIR_LOG_PUT = 'REPAIR_LOG_PUT';
export const REPAIR_LOG_DEL = 'REPAIR_LOG_DEL';

export const REPAIR_MONTH_ALL_VEHICLE = 'REPAIR_MONTH_ALL_VEHICLE';
export const REPAIR_MONTH_GET_VEHICLE = 'REPAIR_MONTH_GET_VEHICLE';
export const REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL =
    'REPAIR_MONTH_GET_VEHICLE_BY_HEAD_AND_TAIL';
export const REPAIR_MONTH_ALL_WHEEL = 'REPAIR_MONTH_ALL_WHEEL';
export const REPAIR_MONTH_GET_WHEEL = 'REPAIR_MONTH_GET_WHEEL';
export const REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL =
    'REPAIR_MONTH_GET_WHEEL_BY_HEAD_AND_TAIL';

export const DELIVERY_MONTH_ALL = 'DELIVERY_MONTH_ALL';
export const DELIVERY_MONTH_GET = 'DELIVERY_MONTH_GET';
export const DELIVERY_MONTH_GET_ALL_TYPE = 'DELIVERY_MONTH_GET_ALL_TYPE';
