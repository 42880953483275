import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import CardInfoBackward from '../../common/Cards/CardInfoBackward';
import { FormControl, Input, Button, Textarea } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';

import { DELIVERY_STATUS } from '../../../config/constant/DeliveryStatus';
import DeliveryAssignment from '../../Calendar/Components/DeliveryAssignment';
import PathLists from '../../Calendar/Components/PathLists';
import { CATEGORY_VEHICLE } from 'config/constant/CategoryVehicle';

export default function DisplayMode({
    selectedDeliveryOrder,
    handleToAssignmentModal,
    allAssignments,
    handleToEditorMode,
}) {

    const [startDate, setStartDate] = useState(
        selectedDeliveryOrder.start_reservation
    );
    const [endDate, setEndDate] = useState(
        selectedDeliveryOrder.end_reservation
    );

    const filterValue = (type, delivery_vehicles) => {

        const result = _.find(delivery_vehicles, (_vehicle) => {

            if (_vehicle?.vehicle?.category_id === type) {
                return {
                    value: _vehicle?.vehicle_id,
                    label:
                        _vehicle?.vehicle?.sub_category_vehicle?.sub_category_name +
                        '/' +
                        _vehicle?.vehicle?.serial_number,
                };
            }

        });
        if (result != undefined) {
            return result;
        } else {
            return null;
        }

    }
    const [selectVechicleData, setSelectVechicleData] = useState([filterValue(CATEGORY_VEHICLE[0], selectedDeliveryOrder.delivery_vehicles), filterValue(CATEGORY_VEHICLE[1], selectedDeliveryOrder.delivery_vehicles), filterValue(CATEGORY_VEHICLE[2], selectedDeliveryOrder.delivery_vehicles)]);
    useEffect(() => {

        return () => {

        }
    }, [selectVechicleData])

    return (
        <div>
            <div className="flex flex-wrap gap-2 font-sans">

                <div className="w-full  ml-3">

                    <div className="flex mb-2 gap-2">
                        <div className="w-1/2">
                            <FormControl>
                                <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                                    เริ่มจอง
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-base shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date[0]);
                                    }}
                                    disabled
                                />
                            </FormControl>
                        </div>
                        <div className="w-1/2">
                            <FormControl>
                                <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                                    สิ้นสุด
                                </label>
                                <Flatpickr
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-base shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    // data-enable-time
                                    value={endDate}
                                    onChange={(date) => {
                                        setEndDate(date[0]);
                                    }}
                                    disabled
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="mb-2">
                        <label className="block uppercase text-gray-700 text-base font-bold mb-2">
                            ยานพาหนะ
                        </label>

                        <div className="w-full flex gap-1">
                            <div className="w-1/3">
                                <label className="text-sm font-semibold">
                                    หัวรถ
                                </label>
                                <CardInfoBackward
                                    flat
                                    icon="fas fa-truck"
                                    subtitle={selectVechicleData[0]?.vehicle?.sub_category_vehicle?.sub_category_name +
                                        '/' +
                                        selectVechicleData[0]?.vehicle?.serial_number}
                                    title={"หัวรถ"}
                                />
                            </div>
                            <div className="w-1/3">
                                <label className="text-sm font-semibold">
                                    หางรถ
                                </label>

                                <CardInfoBackward
                                    flat
                                    icon="fas fa-truck"
                                    subtitle={selectVechicleData[1]?.vehicle?.sub_category_vehicle?.sub_category_name +
                                        '/' +
                                        selectVechicleData[1]?.vehicle?.serial_number}
                                    title={"หางรถ"}
                                />
                            </div>
                            <div className="w-1/3">
                                <label className="text-sm font-semibold">
                                    ตู้คอนเทนเนอร์
                                </label>

                                <CardInfoBackward
                                    flat
                                    icon="fas fa-truck"
                                    subtitle={selectVechicleData[2]?.vehicle?.sub_category_vehicle?.sub_category_name +
                                        '/' +
                                        selectVechicleData[2]?.vehicle?.serial_number}
                                    title={"ตู้คอนเทนเนอร์"}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="mb-2">
                        <FormControl>
                            <label className="block uppercase text-gray-700 text-base mb-1">
                                <b>หมายเลขซีล</b> {selectedDeliveryOrder?.seal_no}
                            </label>
                        </FormControl>
                    </div>

                    <div className="flex">
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1">
                                <b>น้ำหนักรวม</b> {_.sum(
                                    _.map(
                                        selectedDeliveryOrder.delivery_assignments,
                                        (eachAssignment) =>
                                            parseFloat(
                                                eachAssignment?.assignment
                                                    ?.load_weight
                                            )
                                    )
                                ).toFixed(2)}{' '}
                                กิโลกรัม
                            </label>
                        </div>
                        <div className="w-1/2">
                            <label className="block uppercase text-gray-700 text-base mb-1">
                                <b>จำนวนรวม</b> {_.sum(
                                    _.map(
                                        selectedDeliveryOrder.delivery_assignments,
                                        (eachAssignment) =>
                                            parseFloat(
                                                eachAssignment?.assignment
                                                    ?.number_of_packages
                                            )
                                    )
                                ).toFixed(0)}{' '}
                                ชิ้น
                            </label>
                        </div>{' '}
                    </div>
                </div>
            </div>
            <div className="font-sans mt-2 ml-3">
                <FormControl>
                    <label className="block uppercase text-gray-700 text-base mb-1">
                        <b>หมายเหตุ</b> {selectedDeliveryOrder.delivery_note}
                    </label>
                </FormControl>
            </div>{' '}

            <div className="font-sans mt-2 ml-3">
                <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                    รายการการจัดส่ง
                </label>
                {_.isEmpty(selectedDeliveryOrder.delivery_assignments) ? (
                    <p>ยังไม่มีรายการจัดส่ง</p>
                ) : (
                    <DeliveryAssignment
                        assignments={
                            selectedDeliveryOrder.delivery_assignments
                        }

                        allSystemAssignments={allAssignments}
                    />
                )}
            </div>
            <div className="font-sans mt-2 ml-3">
                <label className="block uppercase text-gray-700 text-base font-bold mb-1">
                    รายการการเดินรถ
                </label>
                {_.size(selectedDeliveryOrder?.route?.paths) ? (
                    <PathLists
                        path={selectedDeliveryOrder?.route?.paths}
                    />
                ) : (
                    <div className="flex content-center items-center justify-center  rounded rounded-t-lg bg-white p-4">
                        ไม่มีข้อมูล
                    </div>
                )}
            </div>
        </div>
    );
}
