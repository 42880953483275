import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SUB_CATEGORY_REPAIR } from "../../../config/constant/SubCategoryRepair";
import { Button, Textarea, Input, Select } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalFooter
} from '@chakra-ui/react';

export default function ModalAddDetailWheel({ openHandle, setIsOpenModal, repair_wheel }) {

    const [addIndex, setAddIndex] = useState();
    const [addText, setAddText] = useState();
    const [addPrice, setAddPrice] = useState();
    const [indexWheel, setIndexWheel] = useState([]);

    const handleOnEdit = (index) => {
        setAddIndex(index)
        setAddText(repair_wheel[index]?.wheel_detail);
        setAddPrice(repair_wheel[index]?.price);
        setIsOpenModal(true)
    }
    const handleOnClose = () => {
        setIsOpenModal(false)
    }

    useEffect(() => {
        setIndexWheel(indexWheel);
        return () => { };
    }, [indexWheel,repair_wheel]);

    const changeText = (index) => {
        const data = {
            index_wheel: parseInt(index),
            wheel_detail: " ",
            price: " "
        }
        if (addText != undefined) {
            data.wheel_detail = addText;
            setAddText();
        } else {
            data.wheel_detail = " ";
            setAddText();
        }
        if (addPrice != undefined) {
            data.price = addPrice;
            setAddPrice();
        } else {
            data.price = 0;
            setAddPrice();
        }

        indexWheel.slice(index, 1)
        setIndexWheel(indexWheel);
        repair_wheel.push(data)
        setIsOpenModal(false);

    }

    const genIndex = (indexWheel) => {

        for (let i = 1; i < 23; i++) {

            const findRepairWheel = _.find(repair_wheel, { "index_wheel": i })
            if (findRepairWheel === undefined) {
                indexWheel[i] = i;
            }

        }

        return _.map(indexWheel, (_index) => {
            if (_index != null) {
                return <option value={_index}>ล้อที่ {_index}</option>
            }

        })
    }
    return (
        <>
            <div className="flex flex-wrap">

                <Modal
                    isOpen={openHandle}
                    onClose={handleOnClose}
                    isCentered
                >
                    <ModalOverlay />
                    <ModalContent className="font-sans">

                        <ModalHeader>
                            เพิ่มรายการซ่อมยาง
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <label className="block uppercase text-gray-700 text-sm mb-2">
                                เลือกล้อ
                            </label>
                            <Select
                                onChange={(e) => {
                                    setAddIndex(e.target.value);
                                }}
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                {genIndex(indexWheel)}
                            </Select>
                            <label className="block uppercase text-gray-700 text-sm mt-2 mb-2">
                                รายละเอียด
                            </label>
                            <Textarea
                                placeholder="รายละเอียด"
                                onChange={(e) => { setAddText(e.target.value) }}
                            />
                            <label className="block uppercase text-gray-700 text-sm mb-2">
                                ราคา
                            </label>
                            <Input
                                placeholder="ราคา"
                                onChange={(e) => { setAddPrice(e.target.value) }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme="blue"
                                type="submit"
                                onClick={() => { changeText(addIndex) }}
                            >
                                บันทึก
                            </Button>
                            <Button
                                colorScheme="gray"
                                type="button"
                                onClick={() => handleOnClose()}

                            >
                                ยกเลิก
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </div>
        </>
    );
}

ModalAddDetailWheel.defaultProps = {
    repair_wheel: 'repair_wheel',
};

ModalAddDetailWheel.propTypes = {
    repair_wheel: PropTypes.object,
};
