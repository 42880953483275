import axios from "axios";
import {
  CAR_OWNER_ALL,
  CAR_OWNER_GET,
  CAR_OWNER_GET_BY_DRIVER,
  CAR_OWNER_POST,
  CAR_OWNER_PUT,
  CAR_OWNER_DEL,
} from "../types";

export const carOwnerAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + "/car_owner").then((res) => {
      console.log("Request Server to Get All Car_owner");
      dispatch({ type: CAR_OWNER_ALL, payload: res.data });
    });
  };
};

export const carOwnerGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/car_owner/" + payload)
      .then((res) => {
        console.log("Request Server to Get an Car_owner");
        if (res.data) {
          dispatch({ type: CAR_OWNER_GET, payload: res.data });
        } else {
          dispatch({ type: CAR_OWNER_GET, payload: null });
        }
      });
  };
};

export const carOwnerGetByDriver = (payload) => {
  console.log("payload : ", payload);
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/car_owner/driver/" + payload)
      .then((res) => {
        console.log("Request Server to Get an CarOwner by driver");
        if (res.data) {
          dispatch({ type: CAR_OWNER_GET_BY_DRIVER, payload: res.data });
        } else {
          dispatch({ type: CAR_OWNER_GET_BY_DRIVER, payload: null });
        }
      });
  };
};

export const carOwnerPost = (payload) => {
  console.log("Post CarOwner", payload);
  return async (dispatch) => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/car_owner", payload)
      .then((res) => {
        console.log("Request Server to post an car_owner");
        dispatch({ type: CAR_OWNER_POST, payload: null });
      });
  };
};

export const carOwnerPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + "/car_owner/" + id, payload)
      .then((res) => {
        console.log("Request Server to put an carOwner");
        dispatch({ type: CAR_OWNER_PUT, payload: null });
      });
  };
};

export const carOwnerDelete = (payload) => {
  console.log("payload", payload);
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + "/car_owner/" + payload)
      .then((res) => {
        console.log("Request Server to Delete an carOwner");
        dispatch({ type: CAR_OWNER_DEL, payload: null });
      });
  };
};