import React, { useState } from 'react';
import _ from 'lodash';

export default function PathLists({
    path
}) {

    const genCardPath = () => {
        return _.map(path, (_path) => (
            <div className=" w-1/3  p-1">
                <div className="p-4 shadow rounded-md border">
                    <div className="flex flex-row">
                        <div className="relative w-full flex flex-wrap">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ลำดับ :
                            </label>
                            <label className="px-2  text-gray-700 text-sm">
                                {_path?.index}
                            </label>
                        </div>
                        <div className="relative w-full flex flex-wrap">
                            <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                ราคาน้ำมัน :
                            </label>
                            <label className="px-2  text-gray-700 text-sm">
                                {_path?.gasoline}
                            </label>
                        </div>
                    </div>
                    <div className="relative w-full flex flex-wrap">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            เริ่มต้น :
                        </label>
                        <label className="px-2  text-gray-500 text-sm">
                            {_path?.origin_expected}
                        </label>
                    </div>
                    <div className="relative w-full flex flex-wrap">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            ปลายทาง :
                        </label>
                        <label className="px-2  text-gray-500 text-sm">
                            {_path?.destination_expected}
                        </label>
                    </div>
                    <div className="relative w-full flex flex-wrap">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                        >
                            ระยะทาง :
                        </label>
                        <label className="px-2  text-gray-500 text-sm">
                            {_path?.distance}
                        </label>
                    </div>
                    <div className="relative w-full flex flex-wrap">
                        <label
                            className="block uppercase text-gray-700 text-xs font-bold"
                            htmlFor="grid-password"
                        >
                            เวลาเดินทาง :
                        </label>
                        <label className="px-2 text-gray-500 text-sm">
                            {_path?.duration}
                        </label>
                    </div>
                </div>
            </div>
        ))
    }

    console.log("path", path);
    return (
        <div className=" flex-auto">
            <div className="relative flex flex-wrap">
                {genCardPath()}
            </div>
        </div>
    )
}