import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/actions";

// components

import Sidebar from "components/common/Sidebar/Sidebar-HRMS";
import FooterAdmin from "components/common/Footers/FooterAdmin.js";

// views
import Dashboard from "views/hrms/dashboard/Dashboard";
import ManagementEmployee from "views/hrms/management-employee/ManagementEmployee";
import EmployeeProfile from "views/hrms/employeeProfile/EmployeeProfile";
import CreateEmployee from "views/hrms/createEmployee/CreateEmployee";

import Department from "views/hrms/department/Department";
import DetailDepartment from "views/hrms/detailDepartment/DetailDepartment";
import CreateDepartment from "views/hrms/createDepartment/CreateDepartment";
import CreatePosition from "views/hrms/createPosition/CreatePosition";
import EditEmployee from "views/hrms/editEmployee/EditEmployee";
import EditDepartment from "views/hrms/editDepartment/EditDepartment";
import EditPosition from "views/hrms/editPosition/EditPosition";

import SpinnerLoading from "components/Loading/SpinnerLoading";
import checkRole from "../util/checkroles";

import { PERMISSION_LEVEL } from "../config/constant/PermissionLevel";
import NotFound from "components/NotFound/NotFound";

export default function HRMS() {
  const location = useLocation();
  const history = useHistory();
  const storage_remember = window.localStorage.remember_erp; // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me); // ข้อมูลสำหรับ authorize จาก db server
  const system_info = useSelector((state) => state.system_info);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState("");

  useEffect(() => {
    console.log("HRMS Layout : ACTIVE");
    dispatch(actions.systemInfoAll()).then(() => {
      dispatch(actions.meGet(storage_remember)).then(() => {});
    });
    return () => {
      dispatch(actions.meReset());
    };
  }, []);

  useEffect(() => {
    if (me && system_info && system_info.isLoading === true) {
      const isAccess = checkRole(
        me?.position?.department?.department_roles,
        location.pathname
      );

      //หากไม่มี position และ position_id = 1จะเป็นบัญชีเริ่มต้น
      if (me.position === null && me.position_id === "1") {
        setPermissionLevel(PERMISSION_LEVEL[2]);
      } else {
        setPermissionLevel(me?.position?.permission_level);
      }

      console.log(isAccess);
      if (isAccess) {
        setIsLoading(true);
        console.log("ได้รับการอนุญาตเข้าถึง");
      } else {
        console.log("ไม่ได้รับอนุญาต");
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้");
        history.push("/");
      }
    }
    return () => {};
  }, [me]);
  return isLoading ? (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <div className="px-4 py-4 md:px-10 mx-auto w-full min-h-screen ">
          <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded  min-h-90-screen">
            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full max-w-full flex-grow flex-1">
                  <h6 className="uppercase text-gray-500 text-xs font-semibold">
                    ระบบสารสนเทศเพื่อการบริหารจัดการทรัพยากรบุคคล
                  </h6>
                </div>
              </div>
            </div>
            <div className="px-4">
              <Switch>
                <Redirect exact from="/hrms" to="/hrms/dashboard" />

                <Route exact path="/hrms" component={Dashboard} />

                <Route exact path="/hrms/dashboard" component={Dashboard} />

                <Route
                  exact
                  path="/hrms/dashboard/profile/:id"
                  component={EmployeeProfile}
                />

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/employee"
                    component={ManagementEmployee}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/employee/create"
                    component={CreateEmployee}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/employee/edit/:id"
                    component={EditEmployee}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route exact path="/hrms/department" component={Department} />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/department/details/:id"
                    component={DetailDepartment}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    path="/hrms/department/create"
                    component={CreateDepartment}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/department/edit/:id"
                    component={EditDepartment}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/department/:id/position/create"
                    component={CreatePosition}
                  />
                )}

                {(permissionLevel === PERMISSION_LEVEL[2] ||
                  permissionLevel === PERMISSION_LEVEL[1]) && (
                  <Route
                    exact
                    path="/hrms/department/:id/position/edit/:pid"
                    component={EditPosition}
                  />
                )}

                <Route component={NotFound} />
              </Switch>
            </div>
            <div />
          </div>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  );
}
