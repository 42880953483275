/*eslint-disable*/
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { firebaseAuth } from 'contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
// components

import { Button, Badge } from '@chakra-ui/react';

export default function Navbar(props) {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const system_info = useSelector((state) => state.system_info);
    const me = useSelector((state) => state.me);
    const { handleSignout } = useContext(firebaseAuth);
    const history = useHistory();
    const onSignOut = async () => {
        await handleSignout();
        history.push('/login');
    };

    return system_info && me && system_info.isLoading === true ? (
        <>
            <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between">
                        <div className="flex gap-2">
                            <Link
                                to="/"
                                className="text-gray-800 text-base font-semibold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
                            >
                                <div className="flex gap-2">
                                    <span className="self-center ">
                                        {system_info.system_name}
                                    </span>
                                </div>
                            </Link>
                        </div>

                        <div className="flex  ">
                            <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-2 font-bold hidden md:block ">
                                <i className="fas fa-user text-gray-500 mr-2 text-sm"></i>{' '}
                                {me.employee_first_name +
                                    ' ' +
                                    me.employee_last_name}
                                <Badge
                                    colorScheme="gray"
                                    className="mb-1"
                                ></Badge>
                            </div>
                            <Link
                                to="/auth/login"
                                className="mt-2 ml-auto  bg-white lg:bg-transparent  lg:shadow-none"
                            >
                                <Button
                                    size="xs"
                                    leftIcon={
                                        <i className="fas fa-sign-out-alt"></i>
                                    }
                                    variant="outline"
                                    onClick={() => {
                                        onSignOut();
                                    }}
                                >
                                    <div className="font-normal">
                                        ออกจากระบบ
                                    </div>
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    ) : (
        <div></div>
    );
}
