import {
    CAR_OWNER_ALL,
    CAR_OWNER_GET,
    CAR_OWNER_GET_BY_DRIVER,
    CAR_OWNER_POST,
    CAR_OWNER_PUT,
    CAR_OWNER_DEL,
} from "../../actions/types"

const initialState = {
    car_owner: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CAR_OWNER_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case CAR_OWNER_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case CAR_OWNER_GET_BY_DRIVER:
            return {
                ...action.payload,
                isLoading: true,
            };
        case CAR_OWNER_POST:
            return action.payload;
        case CAR_OWNER_PUT:
            return action.payload;
        case CAR_OWNER_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}