import {
    VEHICLE_ALL,
    VEHICLE_GET,
    VEHICLE_PUT,
    VEHICLE_DEL,
    VEHICLE_POST,
    VEHICLE_ALL_BY_DELIVERY,
    VEHICLE_ALL_BY_HEAD_AND_TAIL,
    VEHICLE_ALL_BY_CATEGORY,
    VEHICLE_ALL_BY_STATUS,
} from '../../actions/types';

const initialState = {
    vehicle: null,
    isLoading: false,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case VEHICLE_ALL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case VEHICLE_ALL_BY_CATEGORY:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case VEHICLE_ALL_BY_DELIVERY:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case VEHICLE_ALL_BY_HEAD_AND_TAIL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case VEHICLE_ALL_BY_STATUS:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case VEHICLE_GET:
            return {
                ...action.payload,
                isLoading: true,
            };
        case VEHICLE_POST:
            return action.payload;
        case VEHICLE_PUT:
            return action.payload;
        case VEHICLE_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}
