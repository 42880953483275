import {
  SUB_CATEGORY_VEHICLE_ALL,
  SUB_CATEGORY_VEHICLE_GET,
  SUB_CATEGORY_VEHICLE_PUT,
  SUB_CATEGORY_VEHICLE_DEL,
  SUB_CATEGORY_VEHICLE_POST,
  SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY,
} from "../../actions/types";

const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case SUB_CATEGORY_VEHICLE_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case SUB_CATEGORY_VEHICLE_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case SUB_CATEGORY_VEHICLE_ALL_BY_CATEGORY:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case SUB_CATEGORY_VEHICLE_POST:
      return action.payload;
    case SUB_CATEGORY_VEHICLE_PUT:
      return action.payload;
    case SUB_CATEGORY_VEHICLE_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
