import {
    REPAIR_LOG_ALL,
    REPAIR_LOG_ALL_VEHICLE,
    REPAIR_LOG_VEHICLE_BY_ID,
    REPAIR_LOG_ALL_VEHICLE_BY_ID,
    REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL,
    REPAIR_LOG_ALL_WHEEL,
    REPAIR_LOG_WHEEL_BY_ID,
    REPAIR_LOG_ALL_WHEEL_BY_ID,
    REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL,
    REPAIR_LOG_POST,
    REPAIR_LOG_PUT,
    REPAIR_LOG_DEL,
    //ดึงข้อมูลการแจ้งซ่อมทั้งหมดตามเดือน
    REPAIR_MONTH_ALL_VEHICLE,
    REPAIR_MONTH_ALL_WHEEL
} from "../../actions/types";

const initialState = {
    repair_log: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REPAIR_LOG_ALL:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_LOG_ALL_VEHICLE:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_LOG_ALL_WHEEL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_LOG_ALL_VEHICLE_BY_ID:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_LOG_ALL_WHEEL_BY_ID:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_LOG_ALL_VEHICLE_BY_HEAD_AND_TAIL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_LOG_ALL_WHEEL_BY_HEAD_AND_TAIL:
            return {
                arr: [...action.payload],
                isLoading: true,
            };
        case REPAIR_LOG_VEHICLE_BY_ID:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_LOG_WHEEL_BY_ID:
            return {
                ...action.payload,
                isLoading: true,
            };
        case REPAIR_LOG_POST:
            return action.payload;
        case REPAIR_LOG_PUT:
            return action.payload;
        case REPAIR_LOG_DEL:
            return { isLoading: true };
        default:
            return state;
    }
}