import {
  PROCESS_ALL,
  PROCESS_ALL_BY_PRODUCT,
  PROCESS_GET,
  PROCESS_PUT,
  PROCESS_DEL,
  PROCESS_POST,
} from "../../actions/types";

const initialState = {
  process: null,
  isLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PROCESS_ALL:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case PROCESS_ALL_BY_PRODUCT:
      return {
        arr: [...action.payload],
        isLoading: true,
      };
    case PROCESS_GET:
      return {
        ...action.payload,
        isLoading: true,
      };
    case PROCESS_POST:
      return action.payload;
    case PROCESS_PUT:
      return action.payload;
    case PROCESS_DEL:
      return { isLoading: true };
    default:
      return state;
  }
}
